import { authorised } from "../../request";

type Args = {
  videoId: string;
  userId: number;
};

export async function denyPermission(args: Args) {
  const API = authorised();

  return API.request({
    method: "POST",
    url: `api/studio/themis/${args.videoId}/collaborators/${args.userId}/deny_permission/`,
  });
}
