import React from "react";
import { PageLayout } from "../components/PageLayout";
import { FeedbackWidget } from "../components/FeedbackWidget";
import { SEO } from "../components/SEO";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { TrialPlanBanner } from "../components/TrialPlanBanner";
import { useAuth } from "../contexts/UserContext";
import { ProfileOverviewForm } from "../forms/profile/ProfileOverviewForm";
import { ProfileWorkForm } from "../forms/profile/ProfileWorkForm";
import { PasswordForm } from "../forms/profile/PasswordForm";
import { DeleteForm } from "../forms/profile/DeleteForm";
import { deleteMe } from "../actions/account/deleteMe";
import { customToast } from "../components/customToast";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { LanguageForm } from "../forms/profile/LanguageForm";

type MenuItemProps = {
  title?: string;
  form: React.ReactNode;
};

function MenuItem(props: MenuItemProps) {
  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        flex-direction: row;
        margin-top: 50px;
        margin-left: 40px;
        padding-bottom: 50px;
        border-bottom: 1px solid #eae8e8;

        &:last-child {
          border-bottom: none;
        }
      `}
    >
      <div
        css={css`
          width: 250px;
        `}
      >
        {props.title && (
          <div
            css={css`
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              margin-top: 20px;
              color: #252424;
            `}
          >
            {props.title}
          </div>
        )}
      </div>
      <div
        css={css`
          display: flex;
          flex: 1 0 10%;
        `}
      >
        <div
          css={css`
            width: 100%;
            max-width: 600px;
          `}
        >
          {props.form}
        </div>
      </div>
    </div>
  );
}

export function ProfilePage() {
  const { t } = useTranslation();
  const { userprofile, refetch, deauthenticate } = useAuth();

  const onConfirmDelete = async () => {
    try {
      await deleteMe();
      deauthenticate();
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  if (!userprofile) {
    return null;
  }

  return (
    <>
      <PageLayout>
        <SEO>
          <title>{t("account.personal-info")}</title>
        </SEO>
        {!userprofile?.is_pro && <TrialPlanBanner />}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
          `}
        >
          <MenuItem
            title={t("profile_page.display_language")}
            form={<LanguageForm />}
          />
          <MenuItem
            title={t("profile_page.profile_overview")}
            form={<ProfileOverviewForm data={userprofile} refetch={refetch} />}
          />
          <MenuItem
            title={t("profile_page.work_information")}
            form={<ProfileWorkForm data={userprofile} refetch={refetch} />}
          />
          <MenuItem
            title={t("profile_page.password")}
            form={<PasswordForm data={userprofile} />}
          />
          <MenuItem form={<DeleteForm onDelete={() => onConfirmDelete()} />} />
          {/* <MenuItem
            title={t("profile_page.email_notification")}
            form={<Notifications />}
          /> */}
        </div>
      </PageLayout>

      <FeedbackWidget />
    </>
  );
}
