import React from "react";
import { css } from "styled-components/macro";
import { ModalCloseButton } from "./ModalCloseButton";

type ModalHeaderProps = {
  className?: string;
  children?: React.ReactNode[] | React.ReactNode | string | null;
  closeIcon?: boolean;
  onClose?: () => void;
  noPadding?: boolean;
};

export function ModalHeader(props: ModalHeaderProps) {
  return (
    <div className={props.className}>
      {props.children && (
        <div
          className="wrapper"
          css={css`
            position: relative;
            margin-bottom: 0;
            padding-right: ${props.noPadding ? "0" : "30px"};
          `}
        >
          {props.children}
          {props.closeIcon && <ModalCloseButton onClose={props.onClose} />}
        </div>
      )}
    </div>
  );
}
