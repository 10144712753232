import { authorised } from "../../request";
import { PermissionRequest } from "../types/Collaborator";

type Args = {
  videoId: string;
};

export async function getPermissionRequests(args: Args) {
  const API = authorised();

  const response = await API.request<PermissionRequest[]>({
    method: "GET",
    url: `api/studio/themis/${args.videoId}/collaborators/permission_requests/`,
  });

  return response.data;
}
