import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";

export function TextToolbar(props: { visible: boolean }) {
  const { t } = useTranslation();

  return (
    <div
      className="editor-toolbar"
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      css={css`
        ${!props.visible &&
        css`
          visibility: hidden;
          opacity: 0;
          position: absolute;
          pointer-events: none;
        `}

        .tox .tox-tbtn.tox-tbtn--enabled {
          color: ${theme.colors.primary};
          background: #ffe8de;
        }
        .tox-collection__item-checkmark {
          display: none;
        }
        .tox .tox-tbtn.tox-tbtn--enabled:hover {
          background: ${theme.colors.primary};
          color: #fff;
        }
        .tox .tox-split-button:hover {
          box-shadow: none;
        }
        .tox .tox-tbtn--enabled svg,
        .tox .tox-tbtn--enabled:hover svg {
          fill: currentColor;
        }
        .tox.tox-tinymce-inline .tox-editor-header {
          border: 1px solid #f2f2f2;
          box-shadow: 0px 0px 13px #e5e5e5;
          border-radius: 21.5px;
          padding: 3px 0;
        }
        .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
          border-right: 1px solid #cdcdcd;
        }
        .tox .tox-tbtn {
          height: 26px;
        }
        .tox .tox-tbtn--bespoke {
          background: none;
          border: 1px solid #252424;
          border-radius: 15px;
        }
        .tox .tox-tbtn--bespoke:hover {
          border: 1px solid transparent;
        }
        .tox .tox-tbtn--bespoke + .tox-tbtn--bespoke {
          background: #f2f2f2;
          border-radius: 2px;
          border: none;
          width: 60px;
        }
        .tox .tox-toolbar__group:last-of-type svg {
          fill: #e95b2e !important;
        }
        .tox .tox-toolbar__group:last-of-type:hover svg {
          fill: #e95b2e !important;
        }

        .tox-tinymce--disabled {
          visibility: hidden !important;
          opacity: 0 !important;
          display: none !important;
        }
        .tox .tox-dialog-wrap__backdrop {
          background-color: rgba(39, 39, 39, 0.4);
        }
        .tox .tox-dialog__header .tox-button {
          border-radius: 50%;
          background: #e95b2e;
          color: white;
          padding: 0;
        }
        .tox .tox-dialog__header .tox-button:hover {
          background: #e95b2e;
          color: white !important;
        }
        .tox .tox-dialog {
          border-radius: 20px;
        }
        .tox .tox-dialog__footer .tox-dialog__footer-end .tox-button {
          border: none;
        }
        .tox .tox-dialog__footer .tox-dialog__footer-end :nth-child(even) {
          background: #e95b2e;
        }
        .tox .tox-dialog__body .tox-form__group:first-of-type label {
          padding-bottom: 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 140%;
          color: #000000;
        }
        .tox .tox-dialog__header {
          display: none;
        }
        .tox .tox-dialog__body .tox-form__group:first-of-type label:after {
          content: "${t("video-editor.side-bar.hyperlink-new-tab")}";
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          padding-top: 10px;
          color: #505050;
        }
        .tox .tox-dialog__body .tox-form__group:first-of-type input {
          background: #f8f8f8;
          border: 1px solid #8e8e8e;
          border-radius: 8px;
        }
        .tox .tox-dialog__body .tox-form__group {
          display: none;
        }
        .tox .tox-dialog__body .tox-form__group:first-of-type {
          display: block;
        }
        .tox .tox-collection--list .tox-collection__item--active {
          color: ${theme.colors.white};
          background: ${theme.colors.primary};
        }
        .tox .tox-collection--list .tox-collection__item--enabled {
          color: ${theme.colors.primary}!important;
          background: #f5e8de !important;
        }
        .tox
          .tox-collection--list:not(.tox-background-menu)
          .tox-collection__item.tox-collection__item--active:not(
            .tox-collection__item--state-disabled
          ):not(.tox-fancymenuitem)
          .tox-collection__item-label
          > * {
          background: none !important;
        }
        .tox .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):focus,
        .tox
          .tox-tbtn:not(.tox-tbtn--disabled):not(
            .tox-tbtn--enabled
          ):focus-visible,
        .tox .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):hover {
          background: inherit;
          box-shadow: none;
          color: inherit;
        }
        .tox .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):hover {
          background: #e95b2e;
          color: white !important;
        }
        .tox
          .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):hover
          .tox-tbtn__select-label {
          color: white;
        }
        .tox .tox-tbtn--bespoke .tox-tbtn__select-label {
          color: ${theme.colors.black};
        }
        .tox
          .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):focus
          svg,
        .tox
          .tox-tbtn:not(.tox-tbtn--disabled):not(
            .tox-tbtn--enabled
          ):focus-visible
          svg,
        .tox
          .tox-tbtn:not(.tox-tbtn--disabled):not(.tox-tbtn--enabled):hover
          svg {
          fill: currentColor;
        }
        .tox
          .tox-collection--list:not(.tox-background-menu)
          .tox-collection__item.tox-collection__item--active:not(
            .tox-collection__item--state-disabled
          ):not(.tox-fancymenuitem)
          svg {
          fill: currentColor !important;
          color: inherit;
        }
        .tox.tox-tinymce-inline {
          z-index: 0;
        }
        .tox .tox-tbtn[aria-label="Insert/edit link"] {
          width: unset;
          background: unset;
          :after {
            content: "${t("video-editor.side-bar.hyperlink")}";
            color: ${theme.colors.primary};
            font-weight: 500;
            font-size: 14px;
            margin-left: 12px;
            padding-right: 5px;
          }
        }
        .tox .tox-tbtn[aria-label="Insert/edit link"].tox-tbtn--enabled {
          background: #f5e8de;
        }
        .tox .tox-tbtn[aria-label="Insert/edit link"]:hover {
          background: ${theme.colors.primary} !important;
          color: white;
          :after {
            color: white;
          }
          svg {
            fill: white !important;
          }
        }
        .tox .tox-tbtn[aria-label="Fonts"] {
          margin-right: 10px;
          height: 27px;
        }
        .tox .tox-tbtn[aria-label="Bold"] {
          margin-right: 5px;
        }
        .tox .tox-tbtn[aria-label="Italic"] {
          margin-right: 5px;
        }
        .tox .tox-tbtn[aria-label="Underline"] {
          margin-right: 5px;
        }
        .tox .tox-tbtn[aria-label="Align left"] {
          margin-right: 5px;
        }
        .tox .tox-tbtn[aria-label="Align center"] {
          margin-right: 5px;
        }
        .tox .tox-tbtn[aria-label="Bullet list"] {
          margin-right: 5px;
        }
        .tox .tox-collection--list .tox-collection__group {
          max-height: 240px;
        }
        .tox-collection__item-label {
          font-size: 16px;
        }
        .tox .tox-toolbar__primary {
          background-image: none;
        }
      `}
    ></div>
  );
}
