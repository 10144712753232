import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { VideoDocument, VideoElementState } from "../../types/Video";
import { useEditor } from "../../contexts/EditorContext";
import { useStorage } from "../../contexts/StorageContext";
import { useAuth } from "../../../contexts/UserContext";
import {
  InteractiveModal,
  InteractiveType,
} from "../../components/InteractiveModal";
import { TooltipArea } from "../../../components/Tooltip";
import { ReactComponent as DocumentIcon } from "../../../assets/icons/Document.svg";
import { ReactComponent as OpenFileIcon } from "../../../assets/icons/OpenFile.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/Plus2.svg";

import { withHttp } from "../../helpers/withHttp";
import { calculateThemiDocumentElements } from "../../helpers/video";

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 9px;
  background: #ffffff;
  border: 1px solid #eae8e8;
  border-radius: 6px;
  cursor: pointer;
`;

export function ElementDocumentRenderer(props: {
  element: VideoDocument;
  state: VideoElementState;
}) {
  const { t } = useTranslation();
  const { userprofile } = useAuth();
  const { video } = useStorage();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [interactiveModal, setInteractiveModal] = useState<boolean>(false);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const { getInPixel } = useEditor();

  const rootElement = document.getElementById("root");

  useEffect(() => {
    const rect = wrapperRef.current?.getBoundingClientRect();

    if (rect) {
      setPositionX(rect.left + rect.width);
      setPositionY(rect.top + rect.height);
    }
  }, []);

  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) return;

    const mouseMove = (e: MouseEvent) => {
      const rect = wrapperRef.current?.getBoundingClientRect();

      if (rect) {
        setPositionX(rect.left + rect.width);
        setPositionY(rect.top + rect.height);
      }
    };
    wrapper.addEventListener("mousemove", mouseMove);

    return () => {
      wrapper.removeEventListener("mousemove", mouseMove);
    };
  }, [rootElement]);

  if (!rootElement) return;

  const height = wrapperRef.current?.clientHeight;

  return (
    <div
      css={css`
        background: #ffffff;
        border: 1px solid #eae8e8;
        border-radius: 9px;
        display: flex;
        align-items: center;
        padding: ${getInPixel(29, 0.65)}px ${getInPixel(19, 0.65)}px;
        font-weight: 500;
        line-height: 120%;
        position: relative;
        height: 100%;
      `}
      ref={wrapperRef}
    >
      <div
        css={css`
          width: ${getInPixel(61, 0.65)}px;
          height: ${getInPixel(61, 0.65)}px;
          min-width: ${getInPixel(61, 0.65)}px;
          min-height: ${getInPixel(61, 0.65)}px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fae7d9;
          border-radius: 6px;
          margin-right: ${getInPixel(20, 0.65)}px;
        `}
      >
        <DocumentIcon
          width={getInPixel(24, 0.65)}
          height={getInPixel(31, 0.65)}
        />
      </div>

      <div
        css={css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        <div
          css={css`
            font-size: ${getInPixel(20, 0.65)}px;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.3;
          `}
          title={props.element.document.title}
        >
          {props.element.document.title}
        </div>

        {props.element.document.description && (
          <div
            css={css`
              font-size: ${getInPixel(16, 0.65)}px;
              color: #616161;
              margin-top: ${getInPixel(11, 0.65)}px;
              line-height: 1.3;
              text-overflow: ellipsis;
              overflow: hidden;
            `}
          >
            {props.element.document.description}
          </div>
        )}

        {(props.element.document.file?.file || props.element.document.link) && (
          <div
            css={css`
              position: absolute;
              bottom: ${getInPixel(6, 0.65)}px;
              right: ${getInPixel(10, 0.65)}px;
              cursor: pointer;
            `}
          >
            <Link
              to={{
                pathname:
                  withHttp(props.element.document.link) ||
                  props.element.document.file?.file ||
                  "",
              }}
              target="_blank"
              css={css`
                text-decoration: none;
              `}
            >
              <OpenFileIcon
                width={getInPixel(13, 0.65)}
                height={getInPixel(13, 0.65)}
              />
              <span
                css={css`
                  font-weight: 500;
                  font-size: ${getInPixel(16, 0.65)}px;
                  line-height: 120%;
                  color: #252424;
                  margin-left: ${getInPixel(7, 0.65)}px;
                `}
              >
                {t("document.create.open-file")}
              </span>
            </Link>
          </div>
        )}
      </div>

      {!(
        !!userprofile?.restrictions.uploaded_document &&
        calculateThemiDocumentElements(video?.schema.schema.elements) >=
          userprofile.restrictions.uploaded_document
      ) &&
        createPortal(
          <div
            css={css`
              position: absolute;
              top: 0px;
              left: 0px;
              transform: translate3d(
                ${positionX + getInPixel(10, 0.65)}px,
                ${positionY -
                (height ? height / 2 + getInPixel(23, 0.65) : 0)}px,
                0
              );
            `}
          >
            <TooltipArea
              text={t("document.create.tooltip")}
              staticPosition
              noArrow
            >
              <AddButton onClick={() => setInteractiveModal(true)}>
                <PlusIcon />
              </AddButton>
            </TooltipArea>
          </div>,
          rootElement
        )}

      <InteractiveModal
        interactiveType={InteractiveType.Document}
        isOpen={interactiveModal}
        onClose={() => setInteractiveModal(false)}
      />
    </div>
  );
}
