import React, { useCallback, useEffect, useMemo } from "react";
import { useAuth } from "../../contexts/UserContext";
import { getCollaborators } from "../actions/getCollaborators";
import {
  CollaborationMode,
  Collaborator,
  CollaboratorMeta,
  CollaboratorRole,
  PermissionRequest,
} from "../types/Collaborator";
import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { useTranslation } from "react-i18next";
import { getPermissionRequests } from "../actions/getPermissionRequests";
import { grantPermission } from "../actions/grantPermission";
import { denyPermission } from "../actions/denyPermission";
import { removeCollaborator } from "../actions/removeCollaborator";
import { updateCollaborator } from "../actions/updateCollaborator";
import { sendInvitesToViewers } from "../actions/sendInvitesToViewers";
import { shareVideo } from "../actions/shareVideo";
import { VideoPublishStatus } from "../types/Video";
import { useStorage } from "./StorageContext";
import { publishVideo } from "../actions/publishVideo";
import { sendEvent } from "../../helpers/tracking";
import { calculateVideoDuration } from "../helpers/video";

const SharingContext = React.createContext<{
  collaborators: Collaborator[];
  viewers: Collaborator[];
  permissionRequests: PermissionRequest[];

  sharingApi: {
    fetchCollaborators: () => Promise<void>;
    onUpdateCollaborator: (
      userId: number,
      role: CollaboratorRole
    ) => Promise<void>;
    onRemoveCollaborator: (userId: number) => Promise<void>;
    fetchPerrmissionRequest: () => Promise<void>;
    onApprovePermissionRequest: (request: PermissionRequest) => Promise<void>;
    onDenyPermissionRequest: (request: PermissionRequest) => Promise<void>;
    onSendManyInvites: (userIds: number[]) => Promise<void>;
    onSendInvite: (user: CollaboratorMeta) => Promise<void>;
    onPublish: () => Promise<void>;
  };
}>({
  collaborators: [],
  viewers: [],
  permissionRequests: [],

  sharingApi: {
    fetchCollaborators: () => Promise.resolve(),
    onUpdateCollaborator: () => Promise.resolve(),
    onRemoveCollaborator: () => Promise.resolve(),
    fetchPerrmissionRequest: () => Promise.resolve(),
    onApprovePermissionRequest: () => Promise.resolve(),
    onDenyPermissionRequest: () => Promise.resolve(),
    onSendManyInvites: () => Promise.resolve(),
    onSendInvite: () => Promise.resolve(),
    onPublish: () => Promise.resolve(),
  },
});

export function SharingProvider(props: {
  children: React.ReactNode;
  videoId: string;
}) {
  const { t } = useTranslation();
  const { userprofile } = useAuth();
  const {
    video,
    api: { getLastEvent, updateVideoPublishStatus },
  } = useStorage();

  const [collaborators, setCollaborators] = React.useState<Collaborator[]>([]);
  const [viewers, setViewers] = React.useState<Collaborator[]>([]);
  const [permissionRequests, setPermissionRequests] = React.useState<
    PermissionRequest[]
  >([]);

  const fetchCollaborators = useCallback(async () => {
    try {
      const collaborators = await getCollaborators({
        videoId: props.videoId,
        mode: CollaborationMode.EDIT,
      });

      setCollaborators(
        collaborators.filter(
          (collaborator) => collaborator.role !== CollaboratorRole.VIEWER
        )
      );
      setViewers(
        collaborators.filter(
          (collaborator) => collaborator.role === CollaboratorRole.VIEWER
        )
      );
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  }, [t, props.videoId]);

  const onUpdateCollaborator = useCallback(
    async (userId: number, role: CollaboratorRole) => {
      try {
        await updateCollaborator(props.videoId, userId, role);
        await fetchCollaborators();
      } catch (error: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId, fetchCollaborators]
  );

  const onRemoveCollaborator = useCallback(
    async (userId: number) => {
      try {
        await removeCollaborator(props.videoId, userId);
        await fetchCollaborators();
      } catch (error: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId, fetchCollaborators]
  );

  const fetchPerrmissionRequest = useCallback(async () => {
    try {
      const requests = await getPermissionRequests({
        videoId: props.videoId,
      });

      setPermissionRequests(requests);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  }, [t, props.videoId]);

  const onApprovePermissionRequest = useCallback(
    async (request: PermissionRequest) => {
      try {
        await grantPermission({
          videoId: props.videoId,
          userId: request.user.id,
          role: request.role,
        });
        await fetchPerrmissionRequest();
        await fetchCollaborators();
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId, fetchCollaborators, fetchPerrmissionRequest]
  );

  const onDenyPermissionRequest = useCallback(
    async (request: PermissionRequest) => {
      try {
        await denyPermission({
          videoId: props.videoId,
          userId: request.user.id,
        });
        await fetchPerrmissionRequest();
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId, fetchPerrmissionRequest]
  );

  const onSendManyInvites = useCallback(
    async (userIds: number[]) => {
      try {
        await sendInvitesToViewers({
          videoId: props.videoId,
          userIds: userIds,
        });

        sendEvent("Invited Viewers", {
          themiId: props.videoId,
          viewersNumber: userIds.length,
        });

        await fetchCollaborators();
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId, fetchCollaborators]
  );

  const onSendInvite = useCallback(
    async (user: CollaboratorMeta) => {
      try {
        await shareVideo({ videoId: props.videoId, user: user });

        if (!localStorage.getItem("USER_INVITED")) {
          localStorage.setItem("USER_INVITED", "true");
        }

        sendEvent("Invited Collaborator", {
          themiId: props.videoId,
          role: user.role,
        });

        await fetchCollaborators();
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, props.videoId, fetchCollaborators]
  );

  const onPublish = useCallback(async () => {
    if (video?.uuid) {
      try {
        const lastEventId = await getLastEvent();
        if (!lastEventId) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(t("publish.share.error")),
            })
          );

          return;
        }
        await publishVideo(video.uuid, lastEventId);
        await updateVideoPublishStatus(VideoPublishStatus.PUBLISHED);

        sendEvent("Publish Themi", {
          themiId: video.uuid,
          publisher: userprofile?.id,
          numberOfSequences: video.schema.schema.scenes.length,
          duration: calculateVideoDuration(video.schema.schema) / 1000,
          features: video.schema.schema.elements.reduce((features, element) => {
            if (!features.includes(element.type)) {
              features.push(element.type);
            }
            return features;
          }, [] as string[]),
        });
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    }
  }, [getLastEvent, t, updateVideoPublishStatus, userprofile, video]);

  useEffect(() => {
    fetchCollaborators();
  }, [fetchCollaborators]);

  useEffect(() => {
    fetchPerrmissionRequest();
  }, [fetchPerrmissionRequest]);

  const sharingApi = useMemo(
    () => ({
      fetchCollaborators,
      onUpdateCollaborator,
      onRemoveCollaborator,
      fetchPerrmissionRequest,
      onApprovePermissionRequest,
      onDenyPermissionRequest,
      onSendManyInvites,
      onSendInvite,
      onPublish,
    }),
    [
      fetchCollaborators,
      onUpdateCollaborator,
      onRemoveCollaborator,
      fetchPerrmissionRequest,
      onApprovePermissionRequest,
      onDenyPermissionRequest,
      onSendManyInvites,
      onSendInvite,
      onPublish,
    ]
  );

  return (
    <SharingContext.Provider
      value={{
        collaborators,
        viewers,
        permissionRequests,
        sharingApi,
      }}
    >
      {props.children}
    </SharingContext.Provider>
  );
}

export function useSharing() {
  const context = React.useContext(SharingContext);

  if (context === undefined) {
    throw new Error("useSharing must be used within a SharingProvider");
  }

  return context;
}
