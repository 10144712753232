import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { ChoseButton } from "../components/ChoseButton";

import {
  Modal,
  ModalLeftside,
  ModalRightside,
  ModalSides,
} from "../components/Modal";
import { UpgrateToProAd } from "../components/UpgrateToProAd";
import { Headers } from "../helpers/layout";
import { ReactComponent as DocumentsIcon } from "../assets/icons/Documents.svg";
import { ReactComponent as AIBadgeIcon } from "../assets/icons/AIBadge.svg";
import { AiSummarizer } from "../VideoEditor/components/AiSummarizer";
import { ModalCloseButton } from "../components/Modal/ModalCloseButton";
import { useAi } from "../VideoEditor/contexts/AiContext";
import { useAuth } from "../contexts/UserContext";
import { useEffect, useState } from "react";
import { AIContentGenerator } from "../VideoEditor/components/AIContentGenerator";

export function ModalAI(props: { isOpen: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const { statusModal } = useAi();
  const [mode, setMode] = useState<"summarize" | "generate">("summarize");
  const { userprofile } = useAuth();

  useEffect(() => {
    if (statusModal === "success") {
      props.onClose();
    }
  }, [statusModal, props]);

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{ maxWidth: "930px", padding: 0, maxHeight: "70vh" }}
    >
      <ModalSides>
        <ModalLeftside
          css={css`
            min-height: 635px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          `}
        >
          <div>
            <div
              css={css`
                display: flex;
              `}
            >
              <AIBadgeIcon color={"#616161"} />
              <span
                css={css`
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 140%;
                  color: #616161;
                  margin-left: 13px;
                `}
              >
                Themis AI
              </span>
            </div>

            <Headers.H3
              css={css`
                margin-left: 22px;
                font-size: 20px;
              `}
            >
              Categories
              <span
                css={css`
                  background: linear-gradient(
                    90deg,
                    #e95b2e 0%,
                    #ff812f 102.63%
                  );
                  border-radius: 5px;
                  font-weight: 400;
                  font-size: 14px;
                  padding: 3px 8px;
                  margin-left: 10px;
                  color: #fff;
                `}
              >
                Beta
              </span>
            </Headers.H3>

            <ChoseButton
              icon={<DocumentsIcon fill="#252424" />}
              css={css`
                margin-top: 39px;
              `}
              isActive={mode === "summarize"}
              onClick={() => setMode("summarize")}
            >
              {t("ai.summarize")}
            </ChoseButton>
            <ChoseButton
              icon={<DocumentsIcon fill="#252424" />}
              css={css`
                margin-top: 10px;
              `}
              isActive={mode === "generate"}
              onClick={() => setMode("generate")}
            >
              Generate content
            </ChoseButton>
          </div>
          {!userprofile?.is_pro && (
            <UpgrateToProAd
              text={t("ai.upgrade-pro.text")}
              title={t("upgrade-ad.pro")}
              buttonText={t("upgrade-ad.button-text")}
              location="AI Modal"
            />
          )}
        </ModalLeftside>
        <ModalRightside>
          <ModalCloseButton
            css={css`
              right: 20px;
              top: 20px;
            `}
            onClose={props.onClose}
          />
          {mode === "summarize" && <AiSummarizer />}
          {mode === "generate" && (
            <AIContentGenerator
              onContentAdded={() => {
                props.onClose();
              }}
            />
          )}
        </ModalRightside>
      </ModalSides>
    </Modal>
  );
}
