import React from "react";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Column, Spacing } from "../../helpers/layout";
import { InputField } from "../fields/InputField";
import { ImageUploadField } from "../fields/ImageUploadField";
import { Button } from "../../components/Button";

import { ImageType } from "../../types/Image";
import { InputType } from "../../components/InputComponent";
import { uploadImage } from "../../actions/image";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { WorkspaceOverview } from "../../types/Workspace";

type FormProps = {
  data: WorkspaceOverview;
  onSubmit: (values: WorkspaceOverview) => void;
};

export function WorkspaceOverviewForm(props: FormProps) {
  const { t } = useTranslation();

  const re = new RegExp("[a-zA-Z-]");

  const validationSchema = Yup.object().shape({
    name: Yup.string().matches(re).required(),
    industry: Yup.string().matches(re).required(),
  });

  const initialValues: WorkspaceOverview = {
    logo: props.data.logo || null,
    name: props.data.name || "",
    industry: props.data.industry || "",
  };

  return (
    <Formik<WorkspaceOverview>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(
        values: WorkspaceOverview,
        { setSubmitting }: FormikHelpers<WorkspaceOverview>
      ) => {
        try {
          setSubmitting(true);
          props.onSubmit(values);
        } catch (error: any) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, dirty, isValid }) => {
        return (
          <Form
            noValidate
            css={css`
              width: 100%;
            `}
          >
            <Column gutter={Spacing.xl}>
              <ImageUploadField
                label={`${t("account.avatar")}:`}
                name={"logo"}
                type={ImageType.HIDDEN}
                disableLibrary
                uploadFn={uploadImage}
              />

              <InputField
                name={"name"}
                type={InputType.TEXT}
                placeholder={t("workspace-settings.company_name")}
                css={css`
                  width: 100%;
                `}
              />

              <InputField
                name={"industry"}
                type={InputType.TEXT}
                placeholder={t("workspace-settings.industry")}
                css={css`
                  width: 100%;
                `}
              />

              <div>
                <Button
                  isSubmitting={isSubmitting}
                  type="submit"
                  disabled={!dirty || !isValid}
                >
                  {t("profile_page.update")}
                </Button>
              </div>
            </Column>
          </Form>
        );
      }}
    </Formik>
  );
}
