import React from "react";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Column, Row, Spacing } from "../../helpers/layout";
import { InputField } from "../fields/InputField";
import { Button } from "../../components/Button";

import { UserProfile } from "../../types/UserProfile";
import { InputType } from "../../components/InputComponent";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { updateMe } from "../../actions/account";

export type FormValues = {
  first_name: string;
  last_name: string;
  company_role: string;
  city: string;
};

export function ProfileWorkForm(props: {
  data: UserProfile;
  refetch: () => void;
}) {
  const { t } = useTranslation();

  const re = new RegExp("[a-zA-Z-]");

  const validationSchema = Yup.object().shape({
    company_role: Yup.string(),
    city: Yup.string().matches(re).required(),
  });

  const initialValues: FormValues = {
    first_name: props.data.first_name,
    last_name: props.data.last_name,
    company_role: props.data.company_role || "",
    city: props.data.city || "",
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (
        values: FormValues,
        { setSubmitting }: FormikHelpers<FormValues>
      ) => {
        try {
          setSubmitting(true);

          await updateMe(values);
          props.refetch();
          customToast.success(t("status.success"));
        } catch (error: any) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, dirty, isValid }) => {
        return (
          <Form
            noValidate
            css={css`
              width: 100%;
            `}
          >
            <Column gutter={Spacing.xl}>
              <Row
                gutter={Spacing.xl}
                css={css`
                  width: 100%;
                `}
              >
                <InputField
                  name={"company_role"}
                  type={InputType.TEXT}
                  placeholder={`${t("account.company_role")}`}
                  css={css`
                    width: 100%;
                  `}
                />

                <InputField
                  name={"city"}
                  type={InputType.TEXT}
                  placeholder={`${t("account.city")}`}
                  css={css`
                    width: 100%;
                  `}
                />
              </Row>

              <div>
                <Button
                  isSubmitting={isSubmitting}
                  type="submit"
                  disabled={!dirty || !isValid}
                >
                  {t("profile_page.update")}
                </Button>
              </div>
            </Column>
          </Form>
        );
      }}
    </Formik>
  );
}
