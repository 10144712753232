import React from "react";
import { css } from "styled-components/macro";

import { Avatar } from "../../VideoEditor/components/Avatar";

import { Button } from "../../components/Button";
import { useTranslation } from "react-i18next";
import {
  CollaboratorRole,
  PermissionRequest,
} from "../../VideoEditor/types/Collaborator";

export type UserPermissionRequestProps = {
  request: PermissionRequest;
  onApprove: () => void;
  onDeny: () => void;
  className?: string;
};

export function UserPermissionRequest(props: UserPermissionRequestProps) {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        flex-direction: row;
      `}
    >
      <Avatar
        key={props.request.user.id}
        url={props.request.user.avatar?.image}
        border
        className="avatar"
        size={43}
        css={css`
          border-width: 2px;
        `}
        name={
          props.request.user.first_name + " " + props.request.user.last_name
        }
      />

      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: left;
            margin-left: 24px;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          `}
        >
          <span>
            {props.request.user.first_name} {props.request.user.last_name}
            &nbsp;
          </span>

          <span>
            {props.request.role === CollaboratorRole.VIEWER
              ? t("permission-request.ask-view")
              : t("permission-request.ask-edit")}
          </span>
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: row;
            margin-left: 24px;
          `}
        >
          <Button
            css={css`
              background-color: transparent;
              color: #e95b2e;
              font-size: 14px;
              font-weight: 500;
              padding: 0;
              min-width: 0;
              margin-right: 43px;
            `}
            hoverStyles={`none`}
            onClick={props.onDeny}
          >
            {t("permission-request.deny")}
          </Button>
          <Button
            css={css`
              background-color: transparent;
              color: #e95b2e;
              font-size: 14px;
              font-weight: 500;
              padding: 0;
              min-width: 0;
            `}
            hoverStyles={`none`}
            onClick={props.onApprove}
          >
            {t("permission-request.approve")}
          </Button>
        </div>
      </div>
    </div>
  );
}
