import { Modal } from "../components/Modal";
import TourOne from "../assets/img/tour-one.png";
import TourTwo from "../assets/img/tour-two.png";
import TourThreeEn from "../assets/img/tour-three-en.png";
import TourThreeDe from "../assets/img/tour-three-de.png";
import TourFour from "../assets/img/tour-four.png";
import { useTranslation } from "react-i18next";
import { Carousel } from "../components/Carousel";

export function ScreensTourModal(props: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t, i18n } = useTranslation();
  const content: { image: JSX.Element; title: string; description: string }[] =
    [
      {
        image: (
          <img width="161px" style={{ marginTop: 50 }} src={TourOne} alt="" />
        ),
        title: t("dashboard.product-tour.first-screen.title"),
        description: t("dashboard.product-tour.first-screen.description"),
      },
      {
        image: <img width="364px" src={TourTwo} alt="" />,
        title: t("dashboard.product-tour.second-screen.title"),
        description: t("dashboard.product-tour.second-screen.description"),
      },
      {
        image:
          i18n.language === "en" ? (
            <img width="100%" src={TourThreeEn} alt="" />
          ) : (
            <img width="100%" src={TourThreeDe} alt="" />
          ),
        title: t("dashboard.product-tour.third-screen.title"),
        description: t("dashboard.product-tour.third-screen.description"),
      },
      {
        image: <img width="437px" src={TourFour} alt="" />,
        title: t("dashboard.product-tour.forth-screen.title"),
        description: t("dashboard.product-tour.forth-screen.description"),
      },
    ];
  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{
        maxWidth: "666px",
        height: "612px",
        padding: "0",
        background: "linear-gradient(180deg, #FAE7D9 -8.7%, #FFFFFF 59.24%)",
      }}
    >
      <Carousel content={content} onClose={props.onClose} />
    </Modal>
  );
}
