import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { getColorByText } from "../../helpers/color";
import { ReactComponent as AvatarFillerIcon } from "../../assets/icons/AvatarFiller.svg";

function getInitials(name: string) {
  if (!name) {
    return "NN";
  }

  const words = name.split(" ");

  if (!words.length) {
    return "NN";
  }

  const initials = words.map((n) => {
    if (!n) {
      return "";
    }

    return n[0].toUpperCase();
  });

  if (initials.length === 1) {
    return initials[0];
  }

  return initials[0] + initials[initials.length - 1];
}

export function Avatar(props: {
  url?: string | null;
  name?: string;
  className?: string;
  border?: boolean;
  dynamicBorder?: boolean;
  size?: number;
  color?: string;
  filler?: boolean;
  square?: boolean;
}) {
  const { t } = useTranslation();
  const {
    url,
    name,
    className,
    size = 24,
    border,
    dynamicBorder,
    filler,
  } = props;
  const color = props.color
    ? props.color
    : name
    ? getColorByText(name)
    : "#242424";

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${size}px;
        height: ${size}px;
        min-width: ${size}px;
        min-height: ${size};
        overflow: hidden;
        position: relative;
        border-radius: ${props.square ? "9px" : "50%"};
        ${(dynamicBorder && !url) || border
          ? css`
              border: 1px solid ${color};
            `
          : css`
              border: none;
            `}
      `}
      className={`avatar ${className}`}
    >
      {url ? (
        <img
          css={css`
            object-fit: cover;
            width: 100%;
            height: 100%;
          `}
          src={url}
          alt={t("account.avatar")}
        />
      ) : filler || !name ? (
        <div
          css={css`
            position: relative;
            width: ${size}px;
            height: ${size}px;
            border-radius: 50%;
            background: #252424;
            user-select: none;
          `}
        >
          <AvatarFillerIcon
            width={size * 0.5}
            height={size * 0.5}
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          />
        </div>
      ) : (
        <div
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            width: ${size}px;
            height: ${size}px;
            color: ${color};
            text-align: center;
            font-weight: bold;
            line-height: ${size * 0.9}px;
            font-size: ${size / 3}px;
            border-radius: 50%;
            background: #fff;
            user-select: none;
          `}
        >
          {getInitials(name)}
        </div>
      )}
    </div>
  );
}
