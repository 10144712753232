import React from "react";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";

export function Triangle(props: {
  degree?: number;
  size?: number;
  color?: string;
  className?: string;
}) {
  const { degree = -45, size = 15, color = theme.colors.primary } = props;
  return (
    <div
      css={css`
        overflow: hidden;
        position: relative;
        border-radius: 20%;
        transform: translateY(50%) rotate(${degree + 30}deg) skewY(30deg)
          scaleX(0.866);
        pointer-events: none;
        width: ${size}px;
        height: ${size}px;
        :after {
          width: ${size}px;
          height: ${size}px;
          position: absolute;
          background: ${color};
          pointer-events: auto;
          content: "";
          border-radius: 20% 20% 50% 20%;
          transform: scaleX(1.155) skewY(-30deg) rotate(-30deg)
            translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
        }
        :before {
          width: ${size}px;
          height: ${size}px;
          position: absolute;
          background: ${color};
          pointer-events: auto;
          content: "";
          border-radius: 20% 20% 20% 50%;
          transform: scaleX(1.155) skewY(-30deg) rotate(-30deg)
            translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
        }
      `}
      className="triangle"
    ></div>
  );
}
