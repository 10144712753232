import React from "react";
import { css } from "styled-components/macro";

type ModalFooterProps = {
  children?: React.ReactNode | React.ReactNode[] | string;
  className?: string;
};

export function ModalLeftside(props: ModalFooterProps) {
  return (
    <div
      css={css`
        width: 250px;
        flex: 0 0 250px;
        padding: 45px 17px 17px;
        background: #f1f1f1;
      `}
      className={props.className}
    >
      {props.children}
    </div>
  );
}
