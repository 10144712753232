import { UserMeta } from "./UserProfile";

export enum CommentVisibility {
  CREATOR = "CREATOR",
  VIEWER = "VIEWER",
}

export interface Comment {
  id: number;
  scene_id: string;
  top: number;
  left: number;
  content: string;
  author: UserMeta;
  is_read?: boolean;
  created_at: string;
  parent: number | undefined | null;
  visibility: CommentVisibility;
  timeline?: number;
}

export type CommentPostRequest = Pick<
  Comment,
  "scene_id" | "top" | "left" | "content" | "visibility"
> & { parent_id?: number; timeline?: number };

export type CommenPutRequest = {
  id: number;
  comment: string;
};
