import ReactContentEditable from "react-contenteditable";

export function ContentEditable(props: {
  html: string;
  onChange: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  tagName?: string;
  innerRef?: React.RefObject<HTMLElement>;
  disableWrap?: boolean;
}) {
  return (
    <ReactContentEditable
      innerRef={props.innerRef}
      style={props.style}
      tagName={props.tagName}
      disabled={props.disabled}
      html={props.html}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onPaste={(e) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
      }}
      onKeyDown={(event) => {
        if (props.disableWrap && event.key === "Enter") {
          event.preventDefault();
        }
      }}
    />
  );
}
