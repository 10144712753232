import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { customToast } from "../../components/customToast";
import { Button } from "../../components/Button";
import { InputField } from "../fields/InputField";
import { InputType } from "../../components/InputComponent";
import { Column, Spacing } from "../../helpers/layout";
import { resetPassword } from "../../actions/auth/resetPassword";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { useCheckMobile } from "../../hooks/useCheckMobile";

interface FormValues {
  email: string;
}

export function ResetPasswordForm() {
  const history = useHistory();
  const { t } = useTranslation();
  const { isMobile } = useCheckMobile();

  return (
    <Formik<FormValues>
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);

          await resetPassword(values.email);
          customToast.success(t("status.success"));
          history.push("/check-email");
        } catch (error: any) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form
          noValidate
          css={css`
            ${isMobile &&
            css`
              display: flex;
              flex-direction: column;
              height: 100%;
            `}
          `}
        >
          <Column
            gutter={Spacing.m}
            css={css`
              margin-top: 50px;

              ${isMobile &&
              css`
                margin-top: 40px;
              `};
            `}
          >
            <InputField
              name="email"
              type={InputType.TEXT}
              placeholder={t("auth.form.email")}
              css={css`
                padding: 0 30px;

                ${isMobile &&
                css`
                  padding: 0;
                `}
              `}
              hideTooltip
              errorBottom
            />
          </Column>
          <Button
            isSubmitting={isSubmitting}
            disabled={!isValid}
            type="submit"
            background="#e95b2e"
            hoverStyles="none"
            css={css`
              margin: 40px 0 0 0;
              line-height: 28px;
              padding: 6px 26px;
              color: #fff;
              font-size: 16px;

              ${isMobile &&
              css`
                margin: auto 0 0 0;
                font-size: 20px;
                padding: 10px 0;
              `};
            `}
          >
            {t("auth.reset.continue")}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
