import {
  VideoElementArrow,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { css } from "styled-components/macro";
import { calculateProportionalSize } from "../../helpers/renderer";
import { usePlayer } from "../../contexts/PlayerContext";

export function ElementArrowRenderer(props: {
  element: VideoElementArrow;
  state: VideoElementState;
}) {
  const { config, cta } = props.element;
  const { canvasSize } = usePlayer();
  const strokeSize = calculateProportionalSize(
    config.strokeSize,
    canvasSize.width
  );

  const arrowSize = strokeSize * 2;
  const width = props.state.width;
  const height = props.state.height;
  const hasCTA =
    cta &&
    ((cta.type === "link" && cta.url) || (cta.type === "file" && cta.file));

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        cursor: ${hasCTA ? "pointer" : "default"};

        svg {
          width: 100%;
          height: 100%;
        }
      `}
      onClick={() => {
        if (!hasCTA) return;

        if (cta.type === "link" && cta.url) {
          window.open(cta.url, "_blank");
        } else if (cta.type === "file" && cta.file) {
          window.open(cta.file.file, "_blank");
        }
      }}
    >
      {props.element.type === "arrow-1" && (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <line
            x1="0"
            y1={height / 2}
            x2={width - strokeSize}
            y2={height / 2}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
          <line
            x1={width - arrowSize - strokeSize}
            y1={height / 2 - arrowSize}
            x2={width - strokeSize}
            y2={height / 2 + strokeSize / 6}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
          <line
            x1={width - arrowSize - strokeSize}
            y1={height / 2 + arrowSize}
            x2={width - strokeSize}
            y2={height / 2 - strokeSize / 6}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
        </svg>
      )}
      {props.element.type === "arrow-2" && (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <line
            x1="0"
            y1={height / 2}
            x2={width - strokeSize}
            y2={height / 2}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
          <polygon
            points={`
            ${width - arrowSize},${height / 2 - arrowSize}
            ${width - arrowSize},${height / 2 + arrowSize}
            ${width},${height / 2}
          `}
            fill={config.fillColor}
            fillOpacity={config.fillOpacity / 100}
          />
        </svg>
      )}
      {props.element.type === "arrow-3" && (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <line
            x1="0"
            y1={height / 2}
            x2={width - strokeSize}
            y2={height / 2}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
          <circle
            cx={width - arrowSize}
            cy={height / 2}
            r={arrowSize}
            fill={config.fillColor}
            fillOpacity={config.fillOpacity / 100}
          />
        </svg>
      )}
      {props.element.type === "line" && (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <line
            x1="0"
            y1={height / 2}
            x2={width - strokeSize}
            y2={height / 2}
            stroke={config.strokeColor}
            strokeWidth={config.strokeSize}
            strokeOpacity={config.strokeOpacity / 100}
          />
        </svg>
      )}
    </div>
  );
}
