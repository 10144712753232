import React, { useState } from "react";
import { css } from "styled-components/macro";
import { SEO } from "../components/SEO";
import { PageLayout } from "../components/PageLayout";
import { FeedbackWidget } from "../components/FeedbackWidget";
import { useAuth } from "../contexts/UserContext";
import { Redirect, useHistory } from "react-router";
import { ScreensTourModal } from "../modals/ScreensTourModal";
import { useLocation } from "react-router-dom";
import { DashboardGuide } from "../components/DashboardGuide";
import { DashboardTutorial } from "../components/DashboardTutorial";
import { useNewFetch } from "../useAPI";
import { getVideos } from "../actions/themi/getVideos";
import { pageLoading } from "../helpers/pageLoading";
import { DashboardThemiList } from "../components/DashboardThemiList";
import { CardModeType } from "../businessComponents/Workspace/Card";
import { DashboardTips } from "../components/DashboardTips";
// import { UpgradePro } from "../components/UpgradePro";
import { ModalRequestAccess } from "../modals/ModalRequestAccess";
import { useTranslation } from "react-i18next";
import { inspirationThemis } from "../inspiration/inspirations";
import { useCheckMobile } from "../hooks/useCheckMobile";
import { MobileFallbackPage } from "./mobileFallbackPage";

export type Template = {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  iconBackground: string;
  previews: string[];
};

export function DashboardPage(props: {}) {
  const { userprofile } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [showTour, setShowTour] = useState(false);

  const get_started_completed = localStorage.getItem("GET_STARTED_COMPLETED");

  const {
    data: themis,
    error: themisError,
    refetch: refetchThemis,
  } = useNewFetch(getVideos, {
    limit: 3,
    status: "DRAFTS",
  });

  if (!themis || themisError) {
    return pageLoading(themisError);
  }

  return (
    <PageLayout>
      <SEO>
        <title>Dashboard</title>
      </SEO>
      <div>
        {showTour && (
          <ScreensTourModal
            isOpen={showTour}
            onClose={() => setShowTour(false)}
          />
        )}
      </div>
      <ModalRequestAccess
        isOpen={location.search.includes("?no_access=true")}
        onClose={() => history.push("/")}
      />
      <div
        css={css`
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
          margin-bottom: 42px;
        `}
      >
        {t("dashboard-page.title")} {userprofile?.first_name}!
      </div>
      {!get_started_completed && (
        <DashboardGuide
          setShowTour={() => setShowTour(true)}
          themiExists={themis.count > 0}
        />
      )}
      {themis && themis.count === 0 && <DashboardTutorial />}

      {/* Refactor statistics on next iteration*/}
      {/* {themis && themis.count > 0 && <DashboardStatistics minutes={"5"} />} */}

      <DashboardThemiList
        title={t("dashboard-page.activity.title")}
        description={t("dashboard-page.activity.description")}
        video={themis.results}
        refetch={refetchThemis}
        cardsMode={CardModeType.EDIT}
      />

      <DashboardTips />

      <DashboardThemiList
        title={t("dashboard-page.inspiration.title")}
        description={t("dashboard-page.inspiration.description")}
        video={inspirationThemis}
        refetch={refetchThemis}
        cardsMode={CardModeType.INSPIRATION}
      />
      <FeedbackWidget />
    </PageLayout>
  );
}

export function DashboardPageMiddleware(props: {}) {
  const { isAuthenticated } = useAuth();
  const { isMobile } = useCheckMobile();

  if (isAuthenticated === null) return null;

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: isMobile ? "/get-started" : "/login",
        }}
      />
    );
  }

  if (isMobile) {
    return <MobileFallbackPage />;
  }

  return <DashboardPage {...props} />;
}
