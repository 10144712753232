import { authorised } from "../../request";
import { CollaboratorMeta } from "../types/Collaborator";
import { Video } from "../types/Video";

type Args = {
  videoId: string;
  user: CollaboratorMeta;
};

export async function shareVideo(args: Args) {
  const API = authorised();

  return API.request<Video>({
    method: "POST",
    url: `/api/studio/themis/${args.videoId}/collaborators/`,
    data: args.user,
  });
}
