import { ReactComponent as LeftArrowIcon } from "../VideoEditor/assets/icons/LeftArrow.svg";
import { css } from "styled-components/macro";

export function BackButton(props: { label: string; onClick: () => void }) {
  return (
    <button
      css={css`
        display: inline-flex;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;

        &:hover,
        &:active {
          color: #e95b2e;

          svg {
            fill: #e95b2e;
          }
        }
      `}
      onClick={props.onClick}
    >
      <LeftArrowIcon />
      <span
        css={css`
          margin-left: 10px;
          font-weight: 600;
          font-size: 16px;
        `}
      >
        {props.label}
      </span>
    </button>
  );
}
