import { Drawing } from "./Drawing";
import { Document } from "./Document";
import { Contact } from "./Contact";
import { File } from "./File";
import { Image } from "./Image";
import { CollaboratorRole } from "./Collaborator";
import { UserMeta } from "../../types/UserProfile";

export type VideoElementTextConfig = {
  fontFamily: string;
  fontSize: number;
  textAlign: "left" | "center" | "right";
  bold: boolean;
  italic: boolean;
  underline: boolean;
  color: string;
  opacity: number;
};

export type VideoElementDrawConfig = {
  mode: "draw" | "highlight" | "eraser" | null;
  lineWidth: number;
  color: string;
  opacity: number;
};

export type VideoElementShapeConfig = {
  fillColor: string;
  fillOpacity: number;
  strokeColor: string;
  strokeLineCap: string;
  strokeOpacity: number;
  strokeSize: number;
};

export type ReactionType =
  | "blush"
  | "heart"
  | "thinking_face"
  | "bulb"
  | "tada"
  | "clap"
  | "thumbsup"
  | "thumbsdown"
  | "slightly_frowning_face";

export type VideoElementReactionConfig = {
  reactions: Array<ReactionType>;
};

export type VideoElementState = {
  id: string;
  start_time: number;
  duration: number;
  top: number;
  left: number;
  scale: number;
  rotation: number;
  width: number;
  height: number;
};

export type VideoScene = {
  id: string;
  backgroundColor: string;
  duration: number;
  elements: VideoElement[];
  enableCollaboration: boolean;
  order: number;
  start_time: number;
  name: string;
};

export type ReorderingScene = {
  id: VideoScene["id"];
  order: number;
};

export type VideoElementUserTag = {
  id: string;
  name: string;
  email: string;
  avatar: Image | null;
  responsibilities: string;
  facebook_url: string;
  twitter_url: string;
  linkedin_url: string;
  instagram_url: string;
  xing_url: string;
  relevant_url: string;
};

export type VideoElementUserTagging = VideoElementGeneric & {
  type: "user-tagging";
  tags: VideoElementUserTag[];
  config: {};
};

export type VideoChartPie = VideoElementGeneric & {
  type: "chart-pie";
  data: any;
  config: {};
};

export type VideoElementImage = VideoElementGeneric & {
  type: "image";
  image: Image;
  config: {};
  cta?: VideoElementCTAConfig;
};

export type VideoElementVideo = VideoElementGeneric & {
  type: "video";
  file: File;
  config: {};
};

export type VideoElementPreview = VideoElementGeneric & {
  type: "video-preview";
  link: string;
  config: {};
};

export type VideoElementAudio = VideoElementGeneric & {
  type: "audio";
  file: File;
  title: string;
  config: {};
};
export type VideoElementRecordPrompt = VideoElementGeneric & {
  type: "record-prompt";
  instruction: string;
};

export type VideoTooltips = VideoElementGeneric & {
  [word: string]: string;
};

export type VideoElementCTAConfig = {
  type: "link" | "file";
  url: string;
  file: File | null;
};

export type VideoElementText = VideoElementGeneric & {
  type: "text";
  text: string;
  config?: VideoElementTextConfig;
  tooltips?: VideoTooltips;
  cta?: VideoElementCTAConfig;
};

export type VideoElementRectangle = VideoElementGeneric & {
  type: "rectangle";
  config: VideoElementShapeConfig;
  cta?: VideoElementCTAConfig;
};

export type VideoElementGif = VideoElementGeneric & {
  type: "gif";
  image: {
    id: string | number;
    url: string;
    src: {
      url: string;
      width: number;
    };
    title: string;
    tags: string[];
  };
  config: {};
};

export type VideoElementEllipse = VideoElementGeneric & {
  type: "ellipse";
  config: VideoElementShapeConfig;
  cta?: VideoElementCTAConfig;
};

export type VideoElementStar = VideoElementGeneric & {
  type: "star";
  config: VideoElementShapeConfig;
  cta?: VideoElementCTAConfig;
};

export type VideoElementPolygon = VideoElementGeneric & {
  type: "polygon";
  config: VideoElementShapeConfig;
  cta?: VideoElementCTAConfig;
};

export type VideoElementReaction = VideoElementGeneric & {
  type: "reaction";
  config: VideoElementReactionConfig;
};

export type VideoElementOpenQuestion = VideoElementGeneric & {
  type: "open-question";
  title: string;
  config: {};
};

export type VideoElementPollChoice = {
  id: string;
  text: string;
  is_correct: boolean;
};

export type VideoElementPoll = VideoElementGeneric & {
  type: "poll";
  title: string;
  subtitle: string;
  disclaimer: string;
  choices: VideoElementPollChoice[];
  config: {};
  subtype: "single-choice" | "multiple-choice";
};

export type VideoElementArrow = VideoElementGeneric & {
  type: "arrow-1" | "arrow-2" | "arrow-3" | "line";
  config: VideoElementShapeConfig;
  cta?: VideoElementCTAConfig;
};

export type VideoDrawing = VideoElementGeneric & {
  type: "drawing";
  drawing: Drawing;
  config: VideoElementDrawConfig;
};

export type VideoDocument = VideoElementGeneric & {
  type: "document";
  document: Document;
  config: {};
};

export type VideoContact = VideoElementGeneric & {
  type: "contact";
  contact: Contact;
  config: {};
};

export type VideoElement =
  | VideoElementVideo
  | VideoElementAudio
  | VideoElementRecordPrompt
  | VideoElementText
  | VideoElementGif
  | VideoElementImage
  | VideoDrawing
  | VideoElementRectangle
  | VideoElementEllipse
  | VideoElementStar
  | VideoElementPolygon
  | VideoElementOpenQuestion
  | VideoElementPoll
  | VideoChartPie
  | VideoElementReaction
  | VideoElementUserTagging
  | VideoElementArrow
  | VideoElementPreview
  | VideoDocument
  | VideoContact;

export type VideoElementShape =
  | VideoElementRectangle
  | VideoElementEllipse
  | VideoElementStar
  | VideoElementPolygon
  | VideoElementArrow;

export type VideoElementResources =
  | VideoElementOpenQuestion
  | VideoElementPoll
  | VideoElementReaction
  | VideoElementUserTagging;

export type VideoElementGeneric = {
  id: string;
  states: VideoElementState[];
  scene_id: string;
  order: number;
  touched?: boolean;
  config?: {};
};

export type VideoSchema = {
  elements: VideoElement[];
  scenes: VideoScene[];
};

export enum VideoPublishStatus {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  ARCHIVED = "ARCHIVED",
}

export type Video = {
  uuid: string;
  current_user_role: CollaboratorRole;
  schema: {
    schema: VideoSchema;
    last_change?: {
      timestamp: string;
      user_id: string;
      id: string;
    };
  };
  duration?: number;
  preview_schema?: VideoSchema;
  name: string;
  collaborators: {
    role: CollaboratorRole;
    user: UserMeta;
  }[];
  publish_status: VideoPublishStatus;
  publish_date: string | null;
  archived_at: string | null;
  comments_count: number;
};

export type PublishedVideo = {
  publish_date: string;
  name: string;
  schema: VideoSchema;
  current_user_role: CollaboratorRole;
};

export type VideoElementResizeDirection =
  | "nw"
  | "ne"
  | "sw"
  | "se"
  | "n"
  | "s"
  | "w"
  | "e";

export type VideoElementRotatePosition = "nw";
