import { forwardRef } from "react";
import { css } from "styled-components/macro";

export const ThemiBuilderBody = forwardRef(function (
  props: {
    fullHeight: boolean;
    children: React.ReactNode;
  },
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <div
      css={css`
        flex: 1 0 10%;
        overflow-x: scroll;
      `}
      ref={ref}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          max-width: 620px;
          padding: 90px 0;
          margin: 0 auto;
          box-sizing: border-box;

          @media (max-height: 950px) {
            padding: 40px 0;
          }

          ${props.fullHeight &&
          css`
            height: 100%;
          `};
        `}
      >
        {props.children}
      </div>
    </div>
  );
});
