import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Headers } from "../helpers/layout";
import { Button } from "./Button";
import { ReactComponent as CancelIcon } from "../VideoEditor/assets/icons/Cancel.svg";

export function BigMessage(props: {
  icon: React.ReactNode;
  title: string;
  description: string;
  button: {
    text: string | React.ReactNode;
    onClick: () => void;
  } | null;
  isOpen: boolean;
  onClose: () => void;
  goToDashboard?: boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  if (!props.isOpen) {
    return null;
  }

  return (
    <div
      css={css`
        position: absolute;
        top: 69px;
        width: 100%;
        padding: 15px 56px;
        background: #252424;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        z-index: 10;
        color: #fff;
      `}
    >
      <div
        css={css`
          flex: 1 0 10%;
        `}
      >
        <div>
          <div
            css={css`
              display: inline-block;
              vertical-align: middle;
            `}
          >
            {props.icon}
          </div>
          <Headers.H3
            css={css`
              position: relative;
              font-weight: 600;
              font-size: 16px;
              color: inherit;
              display: inline-block;
              vertical-align: middle;
              margin: 0;
              margin-left: 10px;
              top: -3px;
              line-height: normal;
            `}
          >
            {props.title}
          </Headers.H3>
        </div>
        <p
          css={css`
            margin: 0;
            line-height: normal;
          `}
        >
          {props.description}
        </p>
      </div>{" "}
      {props.goToDashboard && (
        <div
          css={css`
            flex: 0 0 auto;
          `}
        >
          <Button
            css={css`
              background: #252424;
              color: #fff;
              border: 1px solid #fff;
              border-radius: 32px;
              :hover {
                background: #000;
              }
            `}
            onClick={() => {
              history.push("/");
            }}
          >
            {t("old-browsers.go-dashboard")}
          </Button>
        </div>
      )}
      {props.button && (
        <div
          css={css`
            flex: 0 0 auto;
            margin-left: 30px;
            padding-right: 50px;
          `}
        >
          <Button
            css={css`
              background: #fff;
              color: #252424;
              border-radius: 32px;
              :hover {
                background: #fff;
              }
            `}
            onClick={() => {
              props.button!.onClick();
            }}
          >
            {props.button.text}
          </Button>
        </div>
      )}
      <button
        css={css`
          background: transparent;
          padding: 0;
          margin: 0;
          cursor: pointer;
          border: 0;
          -webkit-appearance: none;
          outline: none;
          position: absolute;
          top: 22px;
          right: 45px;
          width: auto;
          height: auto;

          :hover {
            opacity: 0.8;
          }
          :active {
            opacity: 0.95;
          }
        `}
        onClick={props.onClose}
      >
        <CancelIcon
          css={css`
            width: 12px;
            height: 12px;
          `}
        />
      </button>
    </div>
  );
}
