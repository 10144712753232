import { authorised } from "../../request";
import { CollaboratorRole } from "../types/Collaborator";

type Args = {
  videoId: string;
  userId: number;
  role: CollaboratorRole;
};

export async function grantPermission(args: Args) {
  const API = authorised();

  return API.request({
    method: "POST",
    url: `api/studio/themis/${args.videoId}/collaborators/${args.userId}/grant_permission/`,
    data: {
      role: args.role,
    },
  });
}
