import { css } from "styled-components/macro";
import logo from "../assets/img/logo.png";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "./LanguageSelector";
import { sendEvent } from "../helpers/tracking";
import { useAuth } from "../contexts/UserContext";

export function Footer(props: { className?: string }) {
  const { t } = useTranslation();
  const { userprofile } = useAuth();

  return (
    <div
      css={css`
        margin: auto 0 0 0;
        background: #fae7d9;

        a {
          text-decoration: none;
        }
      `}
      className={props.className}
    >
      <div
        css={css`
          padding: 10px 35px;
          padding-right: 100px;
          display: flex;
          flex-flow: row wrap;
        `}
      >
        <div
          css={css`
            margin-right: 45px;
            flex: 0 0 auto;
          `}
        >
          <a
            href={`https://themis.eu`}
            target={`_blank`}
            rel={`noopener noreferrer`}
          >
            <img
              css={css`
                cursor: pointer;
                width: 100px;
                vertical-align: middle;
              `}
              src={logo}
              alt={`Themi Logo`}
            />
          </a>
        </div>
        <div
          css={css`
            flex: 1 0 10%;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;

            a {
              color: #e95b2e;
              margin: 0 30px;
            }
          `}
        >
          <a
            href={`https://themis.eu/`}
            target={`_blank`}
            rel={`noopener noreferrer`}
          >
            Home
          </a>
          <a
            href={`https://themis.eu/about`}
            target={`_blank`}
            rel={`noopener noreferrer`}
          >
            {t("footer.about-us")}
          </a>
          {!userprofile?.is_pro && (
            <a
              href={`https://calendly.com/themis-sales/demo`}
              target={`_blank`}
              rel={`noopener noreferrer`}
              onClick={() => {
                sendEvent("Upgrade button clicked", {
                  location: "Footer",
                });
              }}
            >
              Upgrade
            </a>
          )}
          <a
            href={`https://themis.eu/faq/`}
            target={`_blank`}
            rel={`noopener noreferrer`}
          >
            {t("footer.faq")}
          </a>
          <a
            href={`https://themis.eu/terms-and-conditions`}
            target={`_blank`}
            rel={`noopener noreferrer`}
          >
            {t("footer.terms-and-conditions")}
          </a>
          <a
            href={`https://themis.eu/datenschutzerklarung/`}
            target={`_blank`}
            rel={`noopener noreferrer`}
          >
            {t("footer.privacy-policy")}
          </a>
        </div>
        <div>
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
}
