import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { SEO } from "../../components/SEO";
import { LoginForm } from "../../forms/auth/LoginForm";
import { AuthLayout } from "../../components/AuthLayout";
import { useCheckMobile } from "../../hooks/useCheckMobile";

export function LoginPage() {
  const { t } = useTranslation();
  const { isMobile } = useCheckMobile();

  return (
    <AuthLayout>
      <SEO>
        <title>{t("auth.login.title")}</title>
      </SEO>
      <div
        css={css`
          margin: auto;
          max-width: 700px;
          text-align: center;
          padding: 0 150px;
          display: flex;
          flex-direction: column;
          height: 100%;

          ${isMobile &&
          css`
            text-align: left;
            padding: 0;
          `}
        `}
      >
        <div
          css={css`
            font-weight: 500;
            font-size: 30px;
            line-height: 140%;
            margin-bottom: 10px;

            ${isMobile &&
            css`
              font-weight: 600;
              font-size: 25px;
              margin-bottom: 4px;
            `}
          `}
        >
          {t("auth.login.title")}
        </div>
        <div
          css={css`
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            color: #616161;

            ${isMobile &&
            css`
              font-weight: 400;
              font-size: 16px;
            `}
          `}
        >
          {t("auth.login.description")}
        </div>
        <LoginForm />
      </div>
    </AuthLayout>
  );
}
