import React from "react";
import {
  VideoContact,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { getInPixelCurry } from "./";

export async function ContactRenderer(props: {
  element: VideoContact;
  state: VideoElementState;
  width: number;
}) {
  const getInPixel = getInPixelCurry(0.65)(props.width);

  function convertImageToBase64(
    imgUrl: string,
    callback: (res: string) => void
  ) {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      if (ctx) ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      callback && callback(dataUrl);
    };
    image.src = imgUrl;
  }

  function convertImageToBase64Async(imgUrl: string): Promise<string> {
    return new Promise<string>((resovle) =>
      convertImageToBase64(imgUrl, resovle)
    );
  }
  const url: string =
    props.element.contact.avatar && props.element.contact.avatar.file
      ? await convertImageToBase64Async(props.element.contact.avatar.file)
      : "";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: `${getInPixel(19)}px ${getInPixel(20)}px`,
        fontWeight: 500,
        lineHeight: "120%",
        position: "relative",
        color: "black",
        backgroundColor: "white",
        borderRadius: "9px",
        border: "1px solid rgb(234, 232, 232)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: `${getInPixel(61)}px`,
          height: `${getInPixel(61)}px`,
          minWidth: `${getInPixel(61)}px`,
          minHeight: `${getInPixel(61)}`,
          overflow: "hidden",
          position: "relative",
          borderRadius: "50%",
          border: "none",
          marginRight: `${getInPixel(25)}px`,
        }}
      >
        {url ? (
          <img
            src={url}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            alt={props.element.contact.name}
          />
        ) : (
          <div
            style={{
              position: "relative",
              width: `${getInPixel(61)}px`,
              height: `${getInPixel(61)}px`,
              borderRadius: "50%",
              backgroundColor: "rgb(37, 36, 36)",
            }}
          >
            <svg
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: `${getInPixel(31)}px`,
                height: `${getInPixel(35)}px`,
              }}
              viewBox="0 0 31 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.46854 18.3693C6.23566 19.4053 3.31413 21.2525 0.975694 23.7386C0.349207 24.4098 0 25.2981 0 26.2214C0 27.1448 0.34917 28.0331 0.975694 28.7043C3.48126 31.3641 6.65312 33.2862 10.1524 34.2655C13.6521 35.2448 17.3482 35.2448 20.8476 34.2655C24.3469 33.2862 27.5187 31.3641 30.0243 28.7043C30.6508 28.0331 31 27.1448 31 26.2214C31 25.2981 30.6508 24.4098 30.0243 23.7386C27.6862 21.2518 24.7647 19.4046 21.5315 18.3693C21.2245 18.2575 21.0039 17.9837 20.9566 17.6569C20.9097 17.3301 21.044 17.004 21.3061 16.8077C22.6357 15.7622 23.6601 14.3713 24.2703 12.7833C24.8809 11.1949 25.0546 9.46879 24.7726 7.7887C24.2046 4.66353 22.1319 2.03228 19.249 0.775562C16.3663 -0.481247 13.0537 -0.197509 10.4207 1.53189C7.78762 3.26129 6.18149 6.2081 6.14027 9.3855C6.13954 12.284 7.45641 15.0213 9.71073 16.8077C9.97215 17.0066 10.104 17.3352 10.053 17.6624C10.0025 17.9895 9.77716 18.2615 9.46807 18.3693H9.46854Z"
                fill="rgb(142, 142, 142)"
              />
            </svg>
          </div>
        )}
      </div>
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <div
          style={{
            display: "inline-block",
            background: "rgb(201, 177, 255)",
            borderRadius: "16px",
            padding: `${getInPixel(7)}px ${getInPixel(20)}px`,
          }}
        >
          <span
            style={{
              fontSize: `${getInPixel(14)}px`,
              lineHeight: 1,
              textAlign: "center",
              color: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            {props.element.contact.job_title}
          </span>
        </div>

        {props.element.contact.name && (
          <div
            style={{
              fontSize: `${getInPixel(20)}px`,
              color: "black",
              marginTop: `${getInPixel(9)}px`,
              lineHeight: 1.3,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {props.element.contact.name}
          </div>
        )}
      </div>
    </div>
  );
}
