import { useCallback, useEffect, useRef, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";
import { useAuth } from "../../contexts/UserContext";

import { SceneNavigatorItem } from "./SceneNavigatorItem";
import { DisableUI } from "./DisableUI";
import { TooltipArea } from "../../components/Tooltip";
import { canEdit } from "../helpers/collaborator";

import { ReorderingScene } from "../types/Video";

import { ReactComponent as PlusIcon } from "../assets/icons/Plus_CreateScene.svg";
import { generateNextEmptyScene } from "../helpers/video";
import { theme } from "../../themes/variables";
import { defaultSequenceDuration } from "../../constants";

export const slideWidth = 200;
export const margin = 10;
export const slideHeight = 121;

export function SceneNavigator() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const { t } = useTranslation();

  const { api, video } = useStorage();
  const { scenes, setCurrentTime, scrollRef, findScene, videoDuration } =
    usePlayback();
  const { userprofile } = useAuth();
  const carouselWidth = slideWidth;

  useEffect(() => {
    const calculateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    setTimeout(() => {
      calculateWidth();
    }, 100);

    window.addEventListener("resize", calculateWidth);

    return () => {
      window.removeEventListener("resize", calculateWidth);
    };
  }, []);

  const createScene = useCallback(async () => {
    const name = t("scene.title");
    const { scene, elements } = generateNextEmptyScene(name, scenes.length, t);

    await api.createSceneWithContent(scene, elements);

    const startTime = scenes.reduce((sum, s) => sum + s.duration, 0);

    setCurrentTime(startTime);

    scrollRef.current?.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [api, t, setCurrentTime, scenes, scrollRef]);

  const hasScrollbar = carouselWidth > containerWidth;

  const moveScene = useCallback(
    async (from: ReorderingScene, to: ReorderingScene) => {
      const fromScene = findScene(from.id);
      const toScene = findScene(to.id);

      if (fromScene && toScene) {
        await api.reorderScene(
          {
            ...fromScene.scene,
          },
          { ...toScene.scene }
        );
        setCurrentTime(toScene.scene.start_time);
      }
    },
    [api, findScene, setCurrentTime]
  );

  const videoDurationLimits =
    !!userprofile &&
    videoDuration + defaultSequenceDuration >=
      userprofile?.restrictions.duration;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding: 0;
        background: #f5f5f5;
        height: 100%;
      `}
      id="themis-timeline"
    >
      <div
        css={css`
          flex: 1 0 10%;
        `}
      >
        <div
          css={css`
            height: 100%;
            position: relative;
          `}
          ref={containerRef}
        >
          <div
            ref={scrollRef}
            css={css`
              position: relative;
              width: 100%;
              height: 100%;
              overflow-x: hidden;
              overflow-y: scroll;
              padding-top: 27px;

              /* width */
              ::-webkit-scrollbar {
                height: 9px;
                border-radius: 5px;
                width: 4px;
              }

              /* Track */
              ::-webkit-scrollbar-track {
                background: ${hasScrollbar ? "#f2f2f2" : "transparent"};
                border-radius: 5px;
              }

              /* Handle */
              ::-webkit-scrollbar-thumb {
                background: #cdcdcd;
                border-radius: 5px;
              }

              /* Handle on hover */
              ::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
            `}
          >
            <div
              css={css`
                position: absolute;
                width: ${carouselWidth}px;
                left: 0;
                top: 20px;
              `}
            >
              {scenes.map((scene) => {
                return (
                  <SceneNavigatorItem
                    scene={scene}
                    index={scene.order}
                    moveScene={moveScene}
                    key={scene.id}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        css={css`
          flex: 0 0 50px;
          height: auto;
          background: #ffffff;
          border-top: 1px solid #eae8e8;
          display: flex;
          justify-content: center;
        `}
      >
        <TooltipArea
          show={videoDurationLimits}
          text={t("video-editor.limits.video-length")}
          staticPosition
          offsetX={-200}
          offsetY={-80}
          maxWidth={300}
          noArrow
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <DisableUI
            show={!canEdit(video?.current_user_role) || videoDurationLimits}
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <div
              css={css`
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #8e8e8e;
                :hover {
                  color: ${theme.colors.primary};
                }
              `}
              onClick={createScene}
            >
              <PlusIcon />

              <span
                css={css`
                  margin-left: 21px;
                `}
              >
                {t("sequence.add-sequence")}
              </span>
            </div>
          </DisableUI>
        </TooltipArea>
      </div>
    </div>
  );
}
