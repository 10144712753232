import styled, { css } from "styled-components/macro";
import { Headers } from "../helpers/layout";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { sendEvent } from "../helpers/tracking";
import { createVideo } from "../VideoEditor/actions/createVideo";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button } from "../components/Button";
import { useEffect, useState } from "react";
import { ReactComponent as ThemiLogo } from "../assets/icons/Themi.svg";
import { customToast } from "../components/customToast";
import { Template } from "./templates/types";
import { generateTemplate } from "./templates";

const Status = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

export function ThemiBuilderTemplateReady(props: { templateData: Template }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const history = useHistory();
  const [themiId, setThemiId] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);

    async function createNewThemi() {
      const { scenes, elements } = generateTemplate(props.templateData, t);

      try {
        const response = await createVideo({
          name: props.templateData.title,
          initial_content: {
            scenes,
            elements,
          },
        });

        sendEvent("Create Themi", {
          themiId: response.data.uuid,
          type: "themi-builder",
          category: props.templateData.category,
          selectedTopics: props.templateData.selectedTopics,
        });

        setThemiId(response.data.uuid);
      } catch (error: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      } finally {
        setIsLoading(false);
      }
    }

    createNewThemi();
  }, [history, t, props.templateData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const isReady = seconds >= 1 && !isLoading;

  return (
    <div
      css={css`
        text-align: center;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
      `}
    >
      <ThemiLogo />
      <Headers.H2
        css={css`
          position: relative;
          width: 100%;
          margin-top: 20px;
          margin-bottom: 70px;
          font-size: 20px;
          font-weight: 500;
        `}
      >
        <Status show={seconds < 1}>
          {t("themi-builder.template-ready.ready-in-a-moment")}
        </Status>
        <Status show={isReady}>
          {t("themi-builder.template-ready.ready")}
        </Status>
      </Headers.H2>
      <div>
        <Button
          css={css`
            padding: 0 30px;
            min-width: 178px;
            height: 46px;
            font-weight: 700;
            font-size: 20px;
            line-height: 46px;
            background: #e95b2e;
            transition: background 0.3s ease-in-out;
          `}
          disabled={!isReady}
          onClick={() => {
            if (window.location.search.includes("product_tour=true")) {
              history.push(`/video-editor/${themiId}?product_tour=true`);
            } else {
              history.push(`/video-editor/${themiId}`);
            }
          }}
        >
          {t("themi-builder.template-ready.go-to-creator")}
        </Button>
      </div>
    </div>
  );
}
