import { authorised } from "../../request";
import { Video } from "../../VideoEditor/types/Video";

export type SearchResult = {
  themi: Video;
  highlights: [
    {
      text_content: string[];
      themi_name: string[];
    }
  ];
};

export async function getSearch({
  text,
}: {
  text?: string;
} = {}) {
  const API = authorised();

  return API.request<SearchResult[]>({
    method: "POST",
    url: "/api/studio/themis/search/",
    data: {
      text,
    },
  });
}
