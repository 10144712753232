import { css } from "styled-components/macro";
import { ReactComponent as ThemiLogoWhite } from "../assets/icons/ThemiLogoWhite.svg";
import { ReactComponent as ContactUs } from "../assets/icons/ContactUs.svg";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";

export function TrialPlanBanner() {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        flex-direction: row;
        padding: 16px 57px;
        background-color: #252424;
        border-radius: 8px;
        justify-content: space-between;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-right: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <ThemiLogoWhite />
          <div
            css={css`
              font-weight: 600;
              font-size: 16px;
              line-height: 28px;
              color: #ffffff;
              margin-left: 10px;
            `}
          >
            {t("profile_page.banner_title")}
          </div>
        </div>
        <div
          css={css`
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #ffffff;
          `}
        >
          {t("profile_page.banner_description")}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Button
          onClick={() =>
            window.open(
              "https://calendly.com/themis-sales/demo",
              "_blank"
            )
          }
          hoverStyles="none"
          icon={<ContactUs />}
          css={css`
            background-color: #fff;
            color: #252424;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
          `}
        >
          {t("profile_page.contact_us")}
        </Button>
      </div>
    </div>
  );
}
