import React from "react";
import { css } from "styled-components/macro";
import { Avatar } from "../../VideoEditor/components/Avatar";

import { WorkspaceUserWithRole } from "../../types/Workspace";

export function UserRole(props: {
  user: WorkspaceUserWithRole;
  className?: string;
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      className={props.className}
    >
      <Avatar
        key={props.user.user.id}
        url={props.user.user.avatar?.image}
        border
        className="avatar"
        size={51}
        name={props.user.user.first_name + " " + props.user.user.last_name}
      />

      <div
        css={css`
          display: flex;
          align-items: left;
          flex-direction: column;
          margin-left: 20px;
          white-space: nowrap;
          overflow: hidden;
        `}
      >
        <span
          css={css`
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #252424;
          `}
        >
          {props.user.user.first_name} {props.user.user.last_name}
        </span>

        <span
          css={css`
            font-weight: 500;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #505050;
            line-height: 140%;
          `}
        >
          {props.user.role}
        </span>
      </div>
    </div>
  );
}
