import React from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";

export function CircularProgressBar(props: {
  size: number;
  strokeWidth: number;
  strokeWidthInner?: number;
  percent: number;
  color?: string;
  backgroundColor?: string;
  children?: React.ReactNode | string | null;
}) {
  const {
    size,
    strokeWidth,
    strokeWidthInner = strokeWidth,
    percent,
    children,
    color = theme.colors.blueBackground,
  } = props;
  const radius = (size - strokeWidth) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percent) / 100;

  return (
    <div
      css={css`
        width: ${size}px;
        height: ${size}px;
        position: relative;
      `}
    >
      <svg width={size} height={size} viewBox={viewBox}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidthInner}px`}
          css={css`
            fill: none;
            stroke: ${props.backgroundColor || "#ddd"};
          `}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
          css={css`
            fill: none;
            stroke: ${color};
            stroke-linecap: round;
            stroke-linejoin: round;
          `}
        />
      </svg>
      {props.children && (
        <div
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          `}
        >
          {children}
        </div>
      )}
    </div>
  );
}
