import { css } from "styled-components/macro";
import { Headers } from "../helpers/layout";
import { ReactComponent as HandoverIcon } from "../assets/icons/Handover.svg";
import { ReactComponent as RocketIcon } from "../assets/icons/Rocket.svg";
import { ReactComponent as LightBulbIcon } from "../assets/icons/LightBulb.svg";
import { ReactComponent as CheckedIcon } from "../assets/icons/CheckedIcon.svg";
import { useState } from "react";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { sendEvent } from "../helpers/tracking";
import { createVideo } from "../VideoEditor/actions/createVideo";
import { generateDefaultEmptyScene } from "../VideoEditor/helpers/video";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { customToast } from "../components/customToast";

const CategoryCard = (props: {
  icon: React.ReactNode;
  title: string;
  description: string;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      css={css`
        position: relative;
        background: #ffffff;
        border: 1px solid #eae8e8;
        border-radius: 13px;
        padding: 0 20px;
        height: 107px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 35px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        top: 0;
        box-shadow: 0px 5px 25px #fff;

        @media (max-height: 950px) {
          margin-bottom: 20px;
        }

        &:hover {
          box-shadow: 0px 17px 25px #e3e3e3;
          top: -10px;
        }

        ${props.isActive &&
        css`
          box-shadow: 0px 17px 25px #e3e3e3;
          border: 1px solid #e95b2e;
          top: -10px;
        `}

        &:last-child {
          margin-bottom: 0;
        }
      `}
      onClick={props.onClick}
    >
      <div
        css={css`
          flex: 0 0 55px;
          height: 55px;
          margin-right: 27px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border: 1px solid #fff;

          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

          ${props.isActive &&
          css`
            border: 1px solid #e95b2e;
          `}
        `}
      >
        {props.icon}
      </div>
      <div
        css={css`
          flex: 1 0 10%;
        `}
      >
        <Headers.H4
          css={css`
            font-weight: 600;
            font-size: 20px;
          `}
        >
          {props.title}
        </Headers.H4>
        <p
          css={css`
            font-weight: 400;
            font-size: 14px;
            color: #616161;
          `}
        >
          {props.description}
        </p>
        {props.isActive && (
          <div
            css={css`
              position: absolute;
              top: 10px;
              right: 10px;
            `}
          >
            <CheckedIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export function ThemiBuilderCategorySelector(props: {
  category: string | null;
  onSelect: (category: string) => void;
}) {
  const { t } = useTranslation();
  const name = t("scene.add-introduction");
  const emptyContent = generateDefaultEmptyScene(name, 0, t);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const categories = [
    {
      icon: <HandoverIcon />,
      key: "handover",
      title: t("themi-builder.category-selector.handover.title"),
      description: t("themi-builder.category-selector.handover.description"),
    },
    {
      icon: <RocketIcon />,
      key: "onboarding",
      title: t("themi-builder.category-selector.onboarding.title"),
      description: t("themi-builder.category-selector.onboarding.description"),
    },
    {
      icon: <LightBulbIcon />,
      key: "knowledge-sharing",
      title: t("themi-builder.category-selector.knowledge-sharing.title"),
      description: t(
        "themi-builder.category-selector.knowledge-sharing.description"
      ),
    },
  ];

  return (
    <div>
      <Headers.H2
        css={css`
          margin-bottom: 40px;
        `}
      >
        1. {t("themi-builder.category-selector.title")}
      </Headers.H2>

      <div
        css={css`
          padding: 0 40px;
        `}
      >
        {categories.map((category) => (
          <CategoryCard
            key={category.key}
            icon={category.icon}
            title={category.title}
            description={category.description}
            isActive={props.category === category.key}
            onClick={() => {
              sendEvent("Themi Builder: Selected Category", {
                category: category.key,
              });

              props.onSelect(category.key);
            }}
          />
        ))}
      </div>
      <div
        css={css`
          padding: 60px 0 0 0;
          text-align: center;

          @media (max-height: 950px) {
            padding: 30px 0 0 0;
          }
        `}
      >
        {t("themi-builder.category-selector.footer.or")}{" "}
        <button
          css={css`
            background: none;
            border: none;
            color: inherit;
            font: inherit;
            cursor: pointer;

            ${isLoading &&
            css`
              color: #ccc;
            `}
          `}
          disabled={isLoading}
          onClick={async function () {
            setIsLoading(true);

            try {
              const response = await createVideo({
                name: t("new-themi.title"),
                initial_content: {
                  scenes: [emptyContent.scene],
                  elements: emptyContent.elements,
                },
              });

              sendEvent("Create Themi", {
                themiId: response.data.uuid,
                type: "blank",
              });

              if (window.location.search.includes("product_tour=true")) {
                history.push(
                  `/video-editor/${response.data.uuid}?product_tour=true`
                );
              } else {
                history.push(`/video-editor/${response.data.uuid}`);
              }
            } catch (error: any) {
              customToast.error(
                t("status.error", {
                  error: serverErrorHandler(error),
                })
              );
            } finally {
              setIsLoading(false);
            }
          }}
        >
          <b>
            <u>{t("themi-builder.category-selector.footer.blank_themi")}</u>
          </b>
        </button>
      </div>
    </div>
  );
}
