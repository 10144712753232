import { authorised, notAuthorised } from "../../request";
import {
  Video,
  PublishedVideo,
  VideoPublishStatus,
} from "../../VideoEditor/types/Video";

export async function getVideo(
  videoId: string,
  isPublic?: boolean
): Promise<Video> {
  const API = isPublic ? notAuthorised : authorised();

  const response = await API.request<PublishedVideo>({
    method: "GET",
    url: `/api/studio/themis/${videoId}/published/`,
  });

  return {
    name: response.data.name,
    uuid: videoId,
    schema: {
      schema: response.data.schema,
    },
    publish_date: response.data.publish_date,
    archived_at: null,
    publish_status: VideoPublishStatus.PUBLISHED,
    collaborators: [],
    current_user_role: response.data.current_user_role,
    comments_count: 0,
  };
}
