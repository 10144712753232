import { useTranslation } from "react-i18next";
import { BigMessage } from "../components/BigMessage";
import { useAi } from "../VideoEditor/contexts/AiContext";
import { usePlayback } from "../VideoEditor/contexts/PlaybackContext";
import { ReactComponent as AIBadgeIcon } from "../assets/icons/AIBadgeWhite.svg";

export function ModalAiSuccess(props: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { lastCreatedSceneId } = useAi();
  const { setCurrentTime, findScene, scrollToScene } = usePlayback();

  const found = lastCreatedSceneId ? findScene(lastCreatedSceneId) : null;

  return (
    <BigMessage
      isOpen={props.isOpen}
      onClose={props.onClose}
      icon={<AIBadgeIcon width={22} height={22} />}
      title={t("ai.modal.success.title")}
      description={t("ai.modal.success.description")}
      button={
        found
          ? {
              text: t("ai.modal.go-to-sequence"),
              onClick: () => {
                if (found) {
                  setCurrentTime(found.scene.start_time);
                  scrollToScene(found.index);

                  props.onClose();
                }
              },
            }
          : null
      }
    />
  );
}
