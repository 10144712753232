import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { Video } from "../../VideoEditor/types/Video";
import { getVideo as getPublishedVideo } from "../actions/getVideo"; // change when publishing is ready
import { getVideo as getDraftVideo } from "../../VideoEditor/actions/getVideo"; // change when publishing is ready
import { CollaboratorRole } from "../../VideoEditor/types/Collaborator";
import { calculateProportionalSize } from "../helpers/renderer";

export type CanvasSize = {
  width: number;
  height: number;
};

const PlayerContext = React.createContext<{
  canvasSize: CanvasSize;
  setCanvasSize: (size: CanvasSize) => void;
  video: Video | null;
  showControls: boolean;
  setShowControls: (show: boolean) => void;
  getInPixel: (size: number, coef?: number) => number;
}>({
  video: null,
  canvasSize: {
    width: 0,
    height: 0,
  },
  setCanvasSize: () => {},
  showControls: false,
  setShowControls: () => {},
  getInPixel: () => 0,
});

export function PlayerProvider(props: {
  videoId?: string;
  video?: Video;
  children: (video: Video) => React.ReactNode;
  preview?: boolean;
  public?: boolean;
}) {
  const [showControls, setShowControls] = useState(false);
  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0,
  });
  const [video, setVideo] = useState<Video | null>(
    props.video ? props.video : null
  );
  const history = useHistory();

  useEffect(() => {
    if (!props.videoId) return;

    const video = props.preview
      ? getDraftVideo(props.videoId)
      : getPublishedVideo(props.videoId, props.public);

    video
      .then((video) => {
        if (video) {
          setVideo(video);
        }
      })
      .catch((e) => {
        history.push(
          `/?no_access=true&request=${CollaboratorRole.VIEWER}&themi_id=${props.videoId}`
        );
      });
  }, [props.videoId, history, props.preview, props.public]);

  const getInPixel = useCallback(
    (size: number, coef?: number) => {
      if (!coef) coef = 1;
      return calculateProportionalSize(size, canvasSize.width) * coef;
    },
    [canvasSize.width]
  );

  return (
    <PlayerContext.Provider
      value={{
        video,
        canvasSize,
        setCanvasSize,
        showControls,
        setShowControls,
        getInPixel,
      }}
    >
      {video && props.children(video)}
    </PlayerContext.Provider>
  );
}

export function usePlayer() {
  const context = React.useContext(PlayerContext);
  if (context === undefined) {
    throw new Error("usePlayer must be used within a PlayerProvider");
  }
  return context;
}
