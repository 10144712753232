import { css } from "styled-components/macro";
import { useComments } from "../contexts/CommentsContext";
import { VideoElement } from "../types/Video";
import { Elements } from "./Elements";

export function FrameRenderer(props: { elements: VideoElement[] }) {
  const { commentMode, commentsOpen } = useComments();

  return (
    <div>
      <div
        css={css`
          ${commentsOpen &&
          commentMode &&
          css`
            pointer-events: none;
          `}
        `}
      >
        <Elements elements={props.elements} />
      </div>
    </div>
  );
}
