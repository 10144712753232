import React, { useState } from "react";
import { css } from "styled-components/macro";
// import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { createColumnHelper } from "@tanstack/react-table";

import { getUsers } from "../../actions/workspace";

import { pageLoading } from "../../helpers/pageLoading";
import { useNewFetch } from "../../useAPI";

import { WorkspaceUserWithRole } from "../../types/Workspace";

import { PageTitle } from "../../components/PageTitle";
import { PageLayout } from "../../components/PageLayout";
import { FeedbackWidget } from "../../components/FeedbackWidget";
import { Table } from "../../components/Table";
import { Button } from "../../components/Button";
import { Avatar } from "../../VideoEditor/components/Avatar";
import { InviteUsersModal } from "../../businessComponents/Workspace/InviteUsersModal";
import { EditUserModal } from "../../businessComponents/Workspace/EditUserModal";
import { AcceptRequestTable } from "../../businessComponents/Workspace/AcceptRequestTable";

// import { ReactComponent as SearchSvg } from "../../assets/icons/Search.svg";
import { ReactComponent as PersonSvg } from "../../assets/icons/Person.svg";
// import { ReactComponent as PixelSvg } from "../../assets/icons/Pixel.svg";

export function WorkspaceUserManagment() {
  // const { t } = useTranslation();

  const params = useParams<{
    workspaceId: string;
  }>();

  const {
    data: users,
    error: usersError,
    refetch,
  } = useNewFetch(getUsers, {
    workspace_id: params.workspaceId,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isAcceptRequestOpen] = useState(false);

  if (!users || usersError) {
    return pageLoading(usersError);
  }

  const columnHelper = createColumnHelper<WorkspaceUserWithRole>();

  const columns = [
    columnHelper.accessor("user", {
      header: () => <span>Name</span>,
      cell: (info) => {
        const user = info.getValue();
        return (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Avatar
              size={24}
              border={true}
              url={user.avatar?.image}
              name={`${user.first_name} ${user.last_name}`}
            />

            <div
              css={css`
                margin-left: 20px;
                color: #252424;
              `}
            >
              {user.first_name} {user.last_name}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("user.email", {
      header: () => <span>Email</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("role", {
      header: () => <span>Access</span>,
      cell: (info) => info.getValue(),
    }),
    // columnHelper.accessor("user.teams", {
    //   header: () => <span>Team</span>,
    //   cell: () => {
    //     // const val = info.getValue();
    //     // return val.map((el) => {
    //     //   return <span>{el.title}</span>;
    //     // });
    //   },
    // }),
    columnHelper.accessor("user.themi_videos", {
      id: "lastName",
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span>Themi videos</span>,
    }),
    columnHelper.display({
      id: "edit",
      meta: {
        align: "right",
      },
      cell: (info) => {
        // const [isOpen, setIsOpen] = useState(false);
        return (
          <div
            css={css`
              text-align: right;
            `}
          >
            {/* <Button
              icon={<PixelSvg width={13} height={13} color="#fff" />}
              onClick={() => setIsOpen(true)}
            >
              Edit
            </Button> */}
            <EditUserModal
              user={info.row.original}
              workspaceId={params.workspaceId}
              onDelete={async () => await refetch()}
              onEdit={async () => await refetch()}
              // isOpen={isOpen}
              // onClose={() => setIsOpen(false)}
            />
          </div>
        );
      },
    }),
  ];

  return (
    <PageLayout>
      <PageTitle title={"User management"}>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {/* <div
            css={css`
              display: flex;
              align-items: center;
              position: relative;
              width: 100%;
            `}
          >
            <SearchSvg width={24} height={24} display="flex" color="#616161" />
            <input
              placeholder={"Search for a user"}
              css={css`
                width: 100%;
                height: 42px;
                box-sizing: border-box;
                background: none;
                border: none;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                outline: none;
                color: #616161;
                margin-left: 9px;

                ::placeholder {
                  font-size: inherit;
                  color: inherit;
                }
              `}
              onChange={(e) => {
                console.log(e.target.value);
              }}
            />
          </div> */}
          {/* 
          <Button
            css={css`
              background: #ffffff;
              border: 1px solid #cdcdcd;
              border-radius: 9px;
              padding: 10px 11px;
              color: #000000;
              margin-right: 36px;
              min-width: unset;
              :hover {
                background: #fbfafa;
              }
              ${isAcceptRequestOpen &&
              css`
                background: #f9f9f9;
              `}
            `}
            icon={
              <div
                css={css`
                  color: #505050;
                  background: #eae8e8;
                  border-radius: 2px;

                  padding: 2px 5px;
                `}
              >
                2
              </div>
            }
            onClick={() => setIsAcceptRequestOpen(!isAcceptRequestOpen)}
          >
            <span
              css={css`
                white-space: nowrap;
              `}
            >
              Access requests
            </span>
          </Button> */}

          <Button
            css={css`
              background: #ffffff;
              border: 1px solid #cdcdcd;
              border-radius: 9px;
              padding: 10px 11px;
              color: #000000;
              margin-right: 36px;
              min-width: unset;
              :hover {
                background: #fbfafa;
              }
            `}
            icon={<PersonSvg />}
            onClick={() => setIsOpen(true)}
          >
            <span
              css={css`
                white-space: nowrap;
              `}
            >
              Invite users
            </span>
          </Button>
        </div>
      </PageTitle>

      {isAcceptRequestOpen ? (
        <AcceptRequestTable />
      ) : (
        <Table<WorkspaceUserWithRole> data={users} columns={columns} />
      )}

      <InviteUsersModal
        workspaceId={params.workspaceId}
        isOpen={isOpen}
        onClose={async () => {
          await refetch();

          setIsOpen(false);
        }}
      />

      <FeedbackWidget />
    </PageLayout>
  );
}
