import React from "react";
import { ReactComponent as CameraPurple } from "../assets/icons/CameraPurple.svg";
import { ReactComponent as CameraYellow } from "../assets/icons/CameraYellow.svg";
import { ReactComponent as CameraOrange } from "../assets/icons/CameraOrange.svg";
import { ReactComponent as HomePlus } from "../assets/icons/HomePlus.svg";
import productPicture from "../assets/img/product.png";

import { css } from "styled-components/macro";
import { Button } from "./Button";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

type TutorialStepProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
};

function Step(props: TutorialStepProps) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 70px;

        :last-child {
          margin-bottom: 0;
        }
      `}
    >
      <div
        css={css`
          width: 43px;
          align-items: center;
          margin-right: 24px;
        `}
      >
        {props.icon}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          line-height: 23px;
        `}
      >
        <div
          css={css`
            margin-bottom: 7px;
            font-weight: 500;
            font-size: 18px;
            color: #252424;
          `}
        >
          {props.title}
        </div>
        <div
          css={css`
            font-weight: 400;
            font-size: 16px;
            color: #616161;
          `}
        >
          {props.description}
        </div>
      </div>
    </div>
  );
}

export function DashboardTutorial() {
  const history = useHistory();
  const { t } = useTranslation();

  const steps: TutorialStepProps[] = [
    {
      title: t("dashboard-page.guide.step-1.title"),
      description: t("dashboard-page.guide.step-1.description"),
      icon: <CameraPurple />,
    },
    {
      title: t("dashboard-page.guide.step-2.title"),
      description: t("dashboard-page.guide.step-2.description"),
      icon: <CameraYellow />,
    },
    {
      title: t("dashboard-page.guide.step-3.title"),
      description: t("dashboard-page.guide.step-3.description"),
      icon: <CameraOrange />,
    },
  ];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
      `}
    >
      <div
        css={css`
          margin-bottom: 48px;
          display: flex;
          width: 100%;
          align-items: center;
        `}
      >
        <div
          css={css`
            font-weight: 500;
            font-size: 30px;
            line-height: 42px;
            color: #252424;
          `}
        >
          {t("dashboard-page.guide.title")}
        </div>
        <Button
          css={css`
            background: #e95b2e;
            color: #fff;
            margin: auto 85px auto auto;
            font-weight: 700;
            font-size: 18px;
            flex: none;
            order: 1;
            flex-grow: 0;
          `}
          icon={<HomePlus />}
          onClick={() => {
            history.push("/themi-builder");
          }}
          hoverStyles="none"
        >
          {t("dashboard-page.guide.cta")}
        </Button>
      </div>
      <div
        css={css`
          display: flex;
          width: 100%;
          height: auto;
        `}
      >
        <div>
          {steps.map((step, index) => (
            <Step key={index} {...step} />
          ))}
        </div>

        <div
          css={css`
            width: 420px;
            min-height: 300px;
            margin: auto 0 auto auto;
            border-radius: 10px;
          `}
        >
          <img
            css={css`
              width: 100%;
              height: auto;
            `}
            src={productPicture}
            alt={`Themis Product`}
          />
        </div>
      </div>
    </div>
  );
}
