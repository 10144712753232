import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import {
  VideoDocument,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { usePlayer } from "../../contexts/PlayerContext";
import { ReactComponent as DocumentIcon } from "../../../assets/icons/Document.svg";
import { ReactComponent as OpenFileIcon } from "../../../assets/icons/OpenFile.svg";

import { withHttp } from "../../../VideoEditor/helpers/withHttp";

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 9px;
  background: #ffffff;
  border: 1px solid #eae8e8;
  border-radius: 6px;
  cursor: pointer;
`;

export function ElementDocumentRenderer(props: {
  element: VideoDocument;
  state: VideoElementState;
}) {
  const { t } = useTranslation();
  const { getInPixel } = usePlayer();

  return (
    <div
      css={css`
        background: #ffffff;
        border: 1px solid #eae8e8;
        border-radius: 9px;
        display: flex;
        align-items: center;
        padding: ${getInPixel(29, 0.65)}px ${getInPixel(29, 0.65)}px;
        font-weight: 500;
        line-height: 120%;
        position: relative;
        :hover {
          box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
        }
      `}
    >
      <div
        css={css`
          width: ${getInPixel(61, 0.65)}px;
          height: ${getInPixel(61, 0.65)}px;
          min-width: ${getInPixel(61, 0.65)}px;
          min-height: ${getInPixel(61, 0.65)}px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fae7d9;
          border-radius: 6px;
          margin-right: ${getInPixel(20, 0.65)}px;
        `}
      >
        <DocumentIcon
          width={getInPixel(24, 0.65)}
          height={getInPixel(31, 0.65)}
        />
      </div>

      <div
        css={css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        <div
          css={css`
            font-size: ${getInPixel(20, 0.65)}px;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.3;
          `}
          title={props.element.document.title}
        >
          {props.element.document.title}
        </div>

        {props.element.document.description && (
          <div
            css={css`
              font-size: ${getInPixel(16, 0.65)}px;
              color: #616161;
              margin-top: ${getInPixel(11, 0.65)}px;
              line-height: 1.3;
              text-overflow: ellipsis;
              overflow: hidden;
            `}
          >
            {props.element.document.description}
          </div>
        )}

        {(props.element.document.file?.file || props.element.document.link) && (
          <div
            css={css`
              position: absolute;
              bottom: ${getInPixel(6, 0.65)}px;
              right: ${getInPixel(10, 0.65)}px;
              cursor: pointer;
            `}
          >
            <Link
              to={{
                pathname:
                  withHttp(props.element.document.link) ||
                  props.element.document.file?.file ||
                  "",
              }}
              target="_blank"
              css={css`
                text-decoration: none;
              `}
            >
              <OpenFileIcon
                width={getInPixel(13, 0.65)}
                height={getInPixel(13, 0.65)}
              />
              <span
                css={css`
                  font-weight: 500;
                  font-size: ${getInPixel(16, 0.65)}px;
                  line-height: 120%;
                  color: #252424;
                  margin-left: ${getInPixel(7, 0.65)}px;
                `}
              >
                {t("document.create.open-file")}
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
