import React, { useState } from "react";
import { css } from "styled-components/macro";
import {
  CollaborationMode,
  CollaboratorRole,
} from "../../VideoEditor/types/Collaborator";
import { QueryInput } from "../../components/QueryInput";
import { getUsersBySearch } from "../../VideoEditor/actions/getUsersBySearch";
import { Button } from "../../components/Button";
import { useSharing } from "../../VideoEditor/contexts/SharingContext";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/UserContext";
import { Select } from "../../components/Select";
import { customToast } from "../../components/customToast";
import { emailIsValid } from "../../helpers/email";

type RoleOptionType = { value: CollaboratorRole; label: string };

type ShareFormProps = {
  mode: CollaborationMode;
  onCancel: () => void;
};

export default function ShareForm(props: ShareFormProps) {
  const { t } = useTranslation();
  const [opened, setOppened] = useState<boolean>(false);
  const [selectedEmails, setSelectedEmails] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  const [emailMessage, setEmailMessage] = useState<string>("");
  const { userprofile } = useAuth();

  const { sharingApi } = useSharing();

  const [selectedRole, setSelectedRole] = useState<CollaboratorRole>(
    props.mode === CollaborationMode.VIEW
      ? CollaboratorRole.VIEWER
      : CollaboratorRole.EDITOR
  );

  const roleOptions: RoleOptionType[] = [
    {
      value: CollaboratorRole.OWNER,
      label: t("share.dropdown.owner"),
    },
    {
      value: CollaboratorRole.EDITOR,
      label: t("share.dropdown.editor"),
    },
    {
      value: CollaboratorRole.COMMENTER,
      label: t("share.dropdown.commenter"),
    },
  ];

  const onInvite = () => {
    if (selectedEmails) {
      try {
        sharingApi.onSendInvite({
          email: selectedEmails[0].value,
          role: selectedRole,
        });
        customToast.success(t("share.success"));
      } catch (error) {
        customToast.error(t("share.error"));
      }
    }
    setEmailMessage("");
    setSelectedEmails([]);
  };

  return (
    <div
      css={css`
        width: 100%;
        margin-top: 45px;
      `}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        <QueryInput
          query={{
            getFunction: getUsersBySearch,
            options: {
              limit: 5,
              offset: 0,
            },
            mapper: (data) => {
              return data.data.results.map((user) => ({
                label: user.email,
                value: user.email,
              }));
            },
          }}
          styles={{
            control: (provided, state) => ({
              ...provided,
              minHeight: 47,
              paddingRight: 100,
              border: `1px solid ${state.isFocused ? "#E95B2E" : "#8e8e8e"}`,
              outline: "none",
              boxShadow: "none",
              "&:hover": {
                border: `1px solid ${state.isFocused ? "#E95B2E" : "#8e8e8e"}`,
              },
              input: {
                minWidth: "100px !important",
              },
            }),
            multiValue: (provided) => ({
              ...provided,
              background: "#FFFFFF",
              border: "1px solid #EAE8E8",
              borderRadius: 7,
            }),
            option: (provided, state) => ({
              ...provided,
              background: state.isSelected ? "#FAE7D9" : "#FFFFFF",
              color: state.isSelected ? "#E95B2E" : "#000000",
              "&:hover": {
                background: "#E95B2E",
                color: "#FFFFFF",
              },
            }),
          }}
          onChange={(value) => {
            const emails = value.filter((item) => emailIsValid(item.value));

            setSelectedEmails(emails);
          }}
          value={selectedEmails}
          hideArrow
          disableSearch={!userprofile?.is_pro}
        />
        {props.mode === CollaborationMode.EDIT && (
          <div
            onClick={() => setOppened(!opened)}
            css={css`
              position: absolute;
              display: flex;
              top: 12px;
              right: 14px;
              cursor: pointer;
            `}
          >
            <Select
              value={roleOptions.find(
                (option) => option.value === selectedRole
              )}
              options={roleOptions}
              onChange={(value) => {
                if (value) {
                  setSelectedRole(value.value as CollaboratorRole);
                } else {
                  setSelectedRole(CollaboratorRole.VIEWER);
                }
              }}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border: "none",
                  height: 26,
                  minHeight: 26,
                  fontSize: 16,
                  outline: "none",
                  boxShadow: "none",
                  "&:hover": {
                    border: "none",
                    background: "#EAE8E8",
                  },
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                  height: "26px",
                  padding: "0 6px",
                }),

                input: (provided, state) => ({
                  ...provided,
                  margin: "0px",
                }),
                indicatorSeparator: (state) => ({
                  display: "none",
                }),
                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  height: "26px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  background: state.isSelected ? "#FAE7D9" : "#FFFFFF",
                  color: state.isSelected ? "#E95B2E" : "#000000",
                  "&:hover": {
                    background: "#E95B2E",
                    color: "#FFFFFF",
                  },
                  fontSize: 14,
                }),
              }}
            />
          </div>
        )}
      </div>
      <div
        css={css`
          display: flex;
          width: 100%;
          height: 100%;
          margin-top: 24px;
        `}
      >
        {/* TODO: create new generic component for textarea, on creating new UI library */}
        <textarea
          name={"message"}
          placeholder={t("share.cta.add_message")}
          onChange={(e) => setEmailMessage(e.target.value)}
          value={emailMessage}
          maxLength={300}
          css={css`
            display: flex;
            width: 100%;
            height: 47px;
            resize: none;
            border: 1px solid #8e8e8e;
            border-radius: 8px;
            outline: none;
            padding: 14px;
            background-color: #f8f8f8;
            font-weight: 400;
            font-size: 16px;
            line-height: 17px;

            :focus {
              border: 1px solid #252424;
              background-color: #fff;
            }
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-top: 30px;
        `}
      >
        <Button
          onClick={props.onCancel}
          hoverStyles="none"
          color="#000"
          background="#fff"
          css={css`
            border: 1.5px solid #252424;
            margin-right: 18px;
          `}
        >
          {t("share.cta.cancel")}
        </Button>
        <Button
          disabled={!selectedEmails.length}
          onClick={onInvite}
          hoverStyles="none"
          color="#fff"
          background="#E95B2E"
        >
          {t("share.cta.invite")}
        </Button>
      </div>
    </div>
  );
}
