import { css } from "styled-components/macro";
import { ReactComponent as RedoIcon } from "../assets/icons/Redo.svg";
import { ReactComponent as UndoIcon } from "../assets/icons/Undo.svg";
import { useStorage } from "../contexts/StorageContext";
import { Button } from "./Button";

export function HistoryButtons() {
  const { undo, redo, hasRedo, hasUndo } = useStorage();
  return (
    <>
      <Button
        css={css`
          background: transparent;
          padding: 10px;
          min-width: 0;
          color: #e95b2e;
          ${!hasUndo &&
          css`
            color: #eae8e8;
            pointer-events: none;
          `};
        `}
        icon={<UndoIcon />}
        onClick={undo}
      ></Button>
      <Button
        icon={<RedoIcon />}
        css={css`
          background: transparent;
          padding: 10px;
          min-width: 0;
          color: #e95b2e;
          ${!hasRedo &&
          css`
            color: #eae8e8;
            pointer-events: none;
          `};
        `}
        onClick={redo}
      ></Button>
    </>
  );
}
