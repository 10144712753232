import React from "react";
import { css } from "styled-components/macro";
import { ReactComponent as LabelPro } from "../../assets/icons/LabelPro.svg";
import { useAuth } from "../../contexts/UserContext";
import { Avatar } from "../../VideoEditor/components/Avatar";

export function TrialWorkspaceDropdown() {
  const { userprofile } = useAuth();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 310px;
        background-color: #fff;
        border: 1px solid #eae8e8;
        border-radius: 9px;
        box-sizing: border-box;
        z-index: 2;
        cursor: default;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          padding: 30px 30px 30px 0px;
        `}
      >
        <Avatar
          url={userprofile?.avatar?.image}
          name={`${userprofile?.first_name} ${userprofile?.last_name}`}
          border
          square
          size={50}
          css={css`
            margin-left: 30px;
          `}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: 25px;
          `}
        >
          <div
            css={css`
              font-weight: 500;
              font-size: 16px;
              line-height: 28px;
            `}
          >
            {"My workspace (trial)"}
          </div>
          <div
            css={css`
              font-weight: 500;
              font-size: 16px;
              line-height: 28px;
              color: #616161;
            `}
          >
            {userprofile?.email}
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row;
          width: 100%;
          background-color: #252424;
          padding: 9px 0;
          border-radius: 0px 0px 9px 9px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        `}
        onClick={() => {
          window.open("https://calendly.com/themis-sales/demo", "_blank");
        }}
      >
        <div
          css={css`
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            color: #ffffff;
          `}
        >
          {"Create a workspace"}
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: 20px;
          `}
        >
          <LabelPro />
        </div>
      </div>
    </div>
  );
}
