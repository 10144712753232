import { authorised } from "../../request";
import { UserWorkspace } from "../../types/Workspace";

export async function getWorkspace() {
  const API = authorised();

  const response = await API.request<UserWorkspace>({
    method: "GET",
    url: `/api/studio/themis/workspaces/`,
  });

  return response.data;
}
