import React from "react";
import { css } from "styled-components/macro";
import { Button } from "../Button";
import { ReactComponent as HomePlus } from "../../assets/icons/HomePlus.svg";
// import { ReactComponent as NotificationBell } from "../../assets/icons/NotificationBell.svg";

import { Search } from "../Search";
import { useAuth } from "../../contexts/UserContext";
import { Avatar } from "../../VideoEditor/components/Avatar";
import { DisableUI } from "../../VideoEditor/components/DisableUI";
import { TooltipArea } from "../../components/Tooltip";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export function Header(props: { search?: React.ReactNode }) {
  const { userprofile } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();

  const showMaxThemisLimitation = !!(
    userprofile &&
    !userprofile.is_pro &&
    userprofile?.themis_count >= 6
  );

  return (
    <header
      css={css`
        display: flex;
        width: 100%;
        flex-direction: row;
        margin-top: 30px;
        margin-bottom: 70px;
        align-items: center;
      `}
    >
      {props.search || <Search />}
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
        `}
      >
        <TooltipArea
          show={showMaxThemisLimitation}
          text={t("video-editor.header.limitations.max-themis")}
          css={css`
            height: 100%;
          `}
          maxWidth={250}
          offsetX={-250}
          offsetY={45}
          staticPosition
          noArrow
        >
          <DisableUI
            show={showMaxThemisLimitation}
            css={css`
              height: 100%;
            `}
          >
            <Button
              css={css`
                background: linear-gradient(
                  90.04deg,
                  #e95b2e 0.04%,
                  #ff812f 65.62%
                );
                color: #fff;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                margin-right: 20px;
              `}
              icon={<HomePlus />}
              onClick={() => {
                history.push("/themi-builder");
              }}
              disabled={showMaxThemisLimitation}
              hoverStyles="none"
            >
              {t("navigation-top.new_themi")}
            </Button>
          </DisableUI>
        </TooltipArea>
        {/* <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 45px;
            cursor: pointer;

            border-radius: 50%;
            padding: 10px;
            background: #ffffff;
            box-shadow: 0px 0px 6px #d7d7d7;
          `}
        >
          <NotificationBell />
        </div> */}

        <div
          onClick={() => history.push("/account/me")}
          css={css`
            display: flex;
            align-items: center;
            cursor: pointer;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              font-weight: 500;
              font-size: 18px;
              line-height: 28px;
              margin-right: 10px;
              text-align: end;
            `}
          >
            <span
              css={css`
                color: "#000";
              `}
            >
              {`${userprofile?.first_name}`}&nbsp;{`${userprofile?.last_name}`}
            </span>
            <span
              css={css`
                color: #616161;
              `}
            >
              {userprofile?.company_role}
            </span>
          </div>
          <Avatar
            url={userprofile?.avatar?.image}
            name={`${userprofile?.first_name} ${userprofile?.last_name}`}
            border
            size={50}
          />
        </div>
      </div>
    </header>
  );
}
