import { VideoElement, VideoScene } from "../../../VideoEditor/types/Video";
import { v4 as uuid } from "uuid";
import { Template } from "../types";
import { defaultSequenceDuration } from "../../../constants";

export function generateHandoverClientHistoryTemplate(
  data: Template,
  order: number,
  t: any
) {
  const sceneId = uuid();
  const scene: VideoScene = {
    id: sceneId,
    backgroundColor: "#fff",
    duration: defaultSequenceDuration,
    order,
    name: t("themi-builder.template.client-history.scene-name"),
    enableCollaboration: true,
    start_time: 0,
    elements: [],
  };

  const firstElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    text: `<p style="font-family: Arial; font-size: 4.57cqw;"><strong>${t(
      "themi-builder.template.client-history.title"
    )}</strong></p>`,
    type: "text",
    order: 0,
    states: [
      {
        id: uuid(),
        top: 21.21,
        left: 35.35,
        scale: 1,
        width: 636.3000000000001,
        height: 57.834724540901504,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const secondElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // It's often useful to understand more about the client's history with your company. Add information on past projects or work, and any key learnings worth noting - for example, why certain decisions were made and what factors need to be considered going forward.
    text: `<p style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.client-history.handover-description"
    )}</p>`,
    type: "text",
    order: 1,
    states: [
      {
        id: uuid(),
        top: 84.84,
        left: 35.35,
        scale: 1,
        width: 636.3000000000001,
        height: 67.86727879799666,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const thirdElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // Add a history of the client account. (Best practice: 2-3 sentences)
    text: `<p style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.client-history.second-description"
    )}</p>`,
    type: "text",
    order: 2,
    states: [
      {
        id: uuid(),
        top: 171.99191360601003,
        left: 35.35,
        scale: 1,
        width: 307.54499999999996,
        height: 151.46058639399038,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const fourthElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    type: "record-prompt",
    order: 3,
    states: [
      {
        id: uuid(),
        top: 171.40176335559266,
        left: 367.64,
        scale: 1,
        width: 307.54499999999996,
        height: 152.0507366444076,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
    touched: true,
    instruction: t("themi-builder.template.record-prompt.general-description"),
  };

  const scenes: VideoScene[] = [scene];

  const elements: VideoElement[] = [
    firstElement,
    secondElement,
    thirdElement,
    fourthElement,
  ];

  return { scenes, elements };
}
