import { isEqual } from "lodash";
import React, { useEffect } from "react";
import { calculateProportionalSize } from "../../VideoPlayer/helpers/renderer";
import { useComments } from "../contexts/CommentsContext";
import { useEditor } from "../contexts/EditorContext";
import { Comment } from "../../types/Comment";
import { CommentBox, CommentMarker } from "../components/Comments";
import { usePlayback } from "../contexts/PlaybackContext";
import { VideoScene } from "../types/Video";

function CommentRendererPure(props: { comments: Comment[] }) {
  const [extended, setExtended] = React.useState<boolean>(false);
  const { canvasSize } = useEditor();
  const { expandedComment } = useComments();
  const firstComment = props.comments[0];

  useEffect(() => {
    if (props.comments.find((comment) => comment.id === expandedComment))
      setExtended(true);
  }, [props.comments, expandedComment]);

  if (!firstComment) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: calculateProportionalSize(firstComment.top, canvasSize.width),
        left: calculateProportionalSize(firstComment.left, canvasSize.width),
      }}
    >
      {extended ? (
        <CommentBox comments={props.comments} setExtended={setExtended} />
      ) : (
        <CommentMarker comments={props.comments} setExtended={setExtended} />
      )}
    </div>
  );
}

export const CommentRenderer = React.memo(
  CommentRendererPure,
  (oldProps, newProps) => {
    const is = isEqual(oldProps, newProps);

    return is;
  }
);

const sortCommentsByParentId = (
  comments: Comment[],
  activeScene: VideoScene | null
) => {
  if (!activeScene) return;

  const rootComments = comments.filter(
    (comment) => comment.parent === null && comment.scene_id === activeScene.id
  );
  if (!rootComments.length) return [];

  const result: any[] = [];
  rootComments.forEach((rootComment) => {
    const thread = [rootComment];
    comments.forEach((comment) => {
      if (comment.parent === rootComment.id) {
        thread.push(comment);
      }
    });
    result.push(thread);
  });
  return result;
};

function CommentsRendererPure() {
  const { activeScene } = usePlayback();
  const { commentMode } = useComments();
  const { comments } = useComments();

  const sortedComments = sortCommentsByParentId(comments, activeScene);

  if (!commentMode || !sortedComments) return null;

  return (
    <>
      {sortedComments.map((comments, key) => (
        <CommentRenderer comments={comments} key={key} />
      ))}
    </>
  );
}

export const CommentsRenderer = React.memo(
  CommentsRendererPure,
  (oldProps, newProps) => {
    const is = isEqual(oldProps, newProps);

    return is;
  }
);
