import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../../components/Dropdown";
import { Button } from "../../components/Button";
import { CheckboxComponent } from "../../components/CheckboxComponent";

import { ReactComponent as FilterIcon } from "../../assets/icons/Filter.svg";

export type FilterItemOption = {
  id: string;
  name: string;
  isActive: boolean;
};

export type FilterItemsType = {
  id: string;
  name: string;
  options: FilterItemOption[];
};

export type FilterType = {
  items: FilterItemsType[];
  disabled?: boolean;
  onChange?: (option: FilterItemOption, sectionId: string) => void;
};

export function Filter(props: FilterType) {
  const { t } = useTranslation();
  const [openedDropdown, setOpenedDropdown] = useState(false);

  return (
    <Dropdown
      opened={openedDropdown}
      disabled={props.disabled}
      onOpen={() => setOpenedDropdown(true)}
      onClose={() => setOpenedDropdown(false)}
      css={css`
        padding: 14px 16px;
        min-width: 200%;
        margin-top: 4px;
      `}
      options={props.items.map((item, idx) => {
        return (
          <div
            key={idx}
            css={
              props.items.length - 1 !== idx &&
              css`
                margin-bottom: 15px;
                padding-bottom: 20px;
                border-bottom: 1px solid #cdcdcd;
              `
            }
          >
            <div
              css={css`
                color: #616161;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                margin-bottom: 14px;
              `}
            >
              {item.name}
            </div>
            {item.options.map((i) => {
              return (
                <CheckboxComponent
                  key={i.id}
                  value={i.isActive}
                  label={i.name}
                  css={css`
                    margin-bottom: 20px;
                    :last-of-type {
                      margin-bottom: 0;
                    }
                    .label {
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 140%;
                      color: #000000;
                      padding-left: 32px;
                    }
                  `}
                  onChange={() => {
                    props.onChange && props.onChange(i, item.id);
                  }}
                />
              );
            })}
          </div>
        );
      })}
    >
      <Button
        css={css`
          background: ${openedDropdown ? "#F9F9F9" : "#fff"};
          border: 1px solid #cdcdcd;
          border-radius: 9px;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: #000000;
          padding: 10px;
          :hover {
            background-color: rgba(0, 0, 0, 0.025);
          }
          :active {
            background-color: rgba(0, 0, 0, 0.05);
          }
          :disabled {
            background: #ffffff;
            border: 1px solid rgba(205, 205, 205, 0.4);
            opacity: 0.4;
          }
        `}
        disabled={props.disabled}
        icon={<FilterIcon />}
      >
        {t("video-editor.workspace.filter")}
      </Button>
    </Dropdown>
  );
}
