import { Form, Formik } from "formik";
import { css } from "styled-components/macro";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { customToast } from "../../components/customToast";
import { CheckboxField } from "../fields/CheckboxField";
import { InputField } from "../fields/InputField";
import { InputType } from "../../components/InputComponent";
import { Button } from "../../VideoEditor/components/Button";
import { theme } from "../../themes/variables";
import { Column, Spacing } from "../../helpers/layout";
import { FormValues, registration } from "../../actions/auth/registration";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { USER_CONFIRM_EMAIL } from "../../actions/auth/resendConfirmationEmail";
import PasswordInstructions from "../../components/PasswordInstructions";
import { sendEvent } from "../../helpers/tracking";
import { useState } from "react";
import { useCheckMobile } from "../../hooks/useCheckMobile";
import { useAuth } from "../../contexts/UserContext";

function getParam(url: string, param: string) {
  const value = new URLSearchParams(window.location.search).get(param);

  // replace spaces with +
  if (value) {
    return value.replace(/ /g, "+");
  }

  return null;
}

export function RegistrationForm() {
  const history = useHistory();
  const { isMobile } = useCheckMobile();
  const { authenticate } = useAuth();

  const [showPasswordInstructions, setShowPasswordInstructions] =
    useState(false);

  const re = new RegExp("[a-zA-Z-]");
  const passwordRe = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&$%^*+,.:;<=>?({//\\\\})\\[\\]`~|\"'_-])(?=.{9,150})"
  );
  const { t } = useTranslation();

  const emailQueryArgument = getParam(history.location.search, "email");

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().matches(re).required(t("auth.empty_field_error")),
    last_name: Yup.string().matches(re).required(t("auth.empty_field_error")),
    email: Yup.string().email().required(t("auth.email_error")),
    password: Yup.string().matches(passwordRe).required(),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], t("auth.pwd-not-match"))
      .required(t("auth.pwd-not-match")),
    checkbox: Yup.boolean()
      .oneOf([true], t("auth.signup.accept_tos"))
      .required(),
  });

  const initialValues: FormValues = {
    first_name: "",
    last_name: "",
    email: emailQueryArgument || "",
    password: "",
    password2: "",
    marketing_emails: true,
  };

  const token = getParam(history.location.search, "token");

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);

          const response = await registration({
            ...values,
            token: token || undefined,
          });

          sendEvent("Registration");

          if (!response.data.email_confirmed) {
            localStorage.setItem(USER_CONFIRM_EMAIL, values.email);
          }
          if (response.data.is_verified && response.data.token) {
            authenticate(response.data.token, response.data.userprofile);

            if (response.data.themi_id) {
              history.push(`/video-editor/${response.data.themi_id}`);
            }

            return;
          }
          history.push("/verify-email");
        } catch (error: any) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid, values }) => {
        return (
          <Form
            noValidate
            css={css`
              ${isMobile &&
              css`
                display: flex;
                flex-direction: column;
                height: 100%;
              `}
            `}
          >
            <Column
              gutter={Spacing.l}
              css={css`
                margin-top: 50px;

                ${isMobile &&
                css`
                  margin-top: 40px;
                `};
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: row;

                  ${isMobile &&
                  css`
                    flex-direction: column;
                  `}
                `}
              >
                <InputField
                  name="first_name"
                  type={InputType.TEXT}
                  placeholder={t("auth.form.first-name")}
                  hideTooltip
                  errorBottom
                  css={css`
                    width: 100%;
                    margin-right: 1.5rem;

                    ${isMobile &&
                    css`
                      margin-bottom: 1.5rem;
                      margin-right: 0;
                    `}
                  `}
                />
                <InputField
                  name="last_name"
                  type={InputType.TEXT}
                  placeholder={t("auth.form.last-name")}
                  hideTooltip
                  errorBottom
                  css={css`
                    width: 100%;
                  `}
                />
              </div>
              <InputField
                name="email"
                type={InputType.EMAIL}
                placeholder={t("auth.form.email")}
                disabled={!!emailQueryArgument}
                hideTooltip
                errorBottom
              />

              <InputField
                hideTooltip
                name="password"
                type={InputType.PASSWORD}
                placeholder={t("auth.form.create-password")}
                onFocus={() => setShowPasswordInstructions(true)}
                onBlur={() => setShowPasswordInstructions(false)}
              />
              {showPasswordInstructions && (
                <PasswordInstructions password={values.password} />
              )}
              <InputField
                name="password2"
                type={InputType.PASSWORD}
                placeholder={t("auth.form.repeat-password")}
                hideTooltip
                errorBottom
              />

              <CheckboxField
                name="checkbox"
                hideTooltip
                errorBottom
                css={css`
                  margin-top: 30px;
                  margin-bottom: 40px;
                `}
              >
                <div
                  css={css`
                    width: 100%;
                    font-size: 14px;
                    line-height: 17px;
                    margin-right: 40px;
                    margin-top: 3px;
                    white-space: wrap;
                    text-align: left;
                    line-height: 1.6;

                    a {
                      font-weight: bold;
                    }
                  `}
                >
                  <Trans i18nKey="auth.signup.agree">
                    I have read and agree with the
                    <a
                      href="https://themis.eu/terms-and-conditions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms of service
                    </a>
                    and
                    <a
                      href="https://themis.eu/datenschutzerklarung/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy policy.
                    </a>
                  </Trans>
                </div>
              </CheckboxField>
            </Column>

            <Button
              isSubmitting={isSubmitting}
              type="submit"
              disabled={!isValid}
              css={css`
                width: 100%;
                margin: 0;
                color: ${theme.colors.white};
                font-weight: 700;
                font-size: 16px;
                line-height: 28px;

                ${isMobile &&
                css`
                  margin: auto 0 0 0;
                  font-size: 20px;
                  padding: 10px 0;
                `};
              `}
            >
              {t("auth.signup.cta")}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
