import { ElementGifRenderer } from "../renderers/elements/ElementGifRenderer";
import { ElementImageRenderer } from "../renderers/elements/ElementImageRenderer";
import { ElementAudioRenderer } from "../renderers/elements/ElementAudioRenderer";
import { ElementVideoRenderer } from "../renderers/elements/ElementVideoRenderer";
import { ElementReactionRenderer } from "../renderers/elements/ElementReactionRenderer";
import { ElementArrowRenderer } from "../renderers/elements/ElementArrowRenderer";
import { ElementEllipseRenderer } from "../renderers/elements/ElementEllipseRenderer";
import { ElementPolygonRenderer } from "../renderers/elements/ElementPolygonRenderer";
import { ElementRectangleRenderer } from "../renderers/elements/ElementRectangleRenderer";
import { ElementStarRenderer } from "../renderers/elements/ElementStarRenderer";
import { ElementDrawingRenderer } from "../renderers/elements/ElementDrawingRenderer";
import { ElementTextRenderer } from "../renderers/elements/ElementTextRenderer";
import { ElementOpenQuestionRenderer } from "../renderers/elements/ElementOpenQuestionRenderer";
import { ElementPollRenderer } from "../renderers/elements/ElementPollRenderer";
import { ElementVideoPreviewRenderer } from "../renderers/elements/ElementVideoPreviewRenderer";
import { ElementUserTaggingRenderer } from "../renderers/elements/ElementUserTaggingRenderer";
import { ElementRecordRendererPrompt } from "../renderers/elements/ElementRecordRendererPrompt";
import { ElementDocumentRenderer } from "../renderers/elements/ElementDocumentRenderer";
import { ElementContactRenderer } from "../renderers/elements/ElementContactRenderer";
import { canvasWidth } from "../components/ScenePreview";
import { VideoElement, VideoElementState } from "../types/Video";
import { fontSizes } from "../components/RichTextEditor";

export function getElementRenderer(element: VideoElement): any {
  switch (element.type) {
    case "video-preview":
      return ElementVideoPreviewRenderer;
    case "arrow-1":
      return ElementArrowRenderer;
    case "arrow-2":
      return ElementArrowRenderer;
    case "arrow-3":
      return ElementArrowRenderer;
    case "line":
      return ElementArrowRenderer;
    case "ellipse":
      return ElementEllipseRenderer;
    case "polygon":
      return ElementPolygonRenderer;
    case "rectangle":
      return ElementRectangleRenderer;
    case "star":
      return ElementStarRenderer;
    case "text":
      return ElementTextRenderer;
    case "drawing":
      return ElementDrawingRenderer;
    case "gif":
      return ElementGifRenderer;
    case "image":
      return ElementImageRenderer;
    case "audio":
      return ElementAudioRenderer;
    case "video":
      return ElementVideoRenderer;
    case "reaction":
      return ElementReactionRenderer;
    case "open-question":
      return ElementOpenQuestionRenderer;
    case "poll":
      return ElementPollRenderer;
    case "user-tagging":
      return ElementUserTaggingRenderer;
    case "record-prompt":
      return ElementRecordRendererPrompt;
    case "document":
      return ElementDocumentRenderer;
    case "contact":
      return ElementContactRenderer;
  }

  return null;
}

export type FontSize = typeof fontSizes[number];

export function calculateRelativeSize(size: FontSize) {
  return `${Number(Number(size / 7).toFixed(2))}cqw`;
}

export function calculateReverseRelativeSize(size: number) {
  return Math.floor(size * 7);
}

export function calculateProportionalSize(
  size: number,
  currentCanvasWidth: number
) {
  return (size * currentCanvasWidth) / canvasWidth;
}
export function calculateReverseProportionalSize(
  size: number,
  currentCanvasWidth: number
) {
  return (size * canvasWidth) / currentCanvasWidth;
}

export function getAdjustedState(
  state: VideoElementState,
  canvasWidth: number
): VideoElementState {
  return {
    ...state,
    width: calculateProportionalSize(state.width, canvasWidth),
    height: calculateProportionalSize(state.height, canvasWidth),
    top: calculateProportionalSize(state.top, canvasWidth),
    left: calculateProportionalSize(state.left, canvasWidth),
  };
}
export function getReverseAdjustedState(
  state: VideoElementState,
  canvasWidth: number
): VideoElementState {
  return {
    ...state,
    width: calculateReverseProportionalSize(state.width, canvasWidth),
    height: calculateReverseProportionalSize(state.height, canvasWidth),
    top: calculateReverseProportionalSize(state.top, canvasWidth),
    left: calculateReverseProportionalSize(state.left, canvasWidth),
  };
}
