import { useEffect, useRef, useState } from "react";
import { ThemiBuilderBody } from "./ThemiBuilderBody";
import { ThemiBuilderHeader } from "./ThemiBuilderHeader";
import { css } from "styled-components/macro";
import { ThemiBuilderCategorySelector } from "./ThemiBuilderCategorySelector";
import { BackButton } from "../components/BackButton";
import { useHistory } from "react-router";
import { ThemiBuilderTopicsSelector } from "./ThemiBuilderTopicsSelector";
import { Button } from "../components/Button";
import { useTranslation } from "react-i18next";
import { ThemiBuilderTemplateReady } from "./ThemiBuilderTemplateReady";
import { TemplateTopics } from "./templates/types";
import { useAuth } from "../contexts/UserContext";

export function ThemiBuilder() {
  const [currentStep, setCurrentStep] = useState(0);
  const bodyRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [title, setTitle] = useState("");
  const [selectedTopics, setSelectedTopics] = useState<TemplateTopics>({});
  const { userprofile } = useAuth();
  const noTopics = Object.values(selectedTopics).every((x) => x.length === 0);

  useEffect(() => {
    // scroll to top with animation
    if (bodyRef.current) {
      bodyRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [currentStep]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100vh;
      `}
    >
      <ThemiBuilderHeader
        currentStep={currentStep}
        setStep={(step) => {
          setCurrentStep(step);
        }}
      />
      <ThemiBuilderBody ref={bodyRef} fullHeight={currentStep === 2}>
        {currentStep < 2 && (
          <div
            css={css`
              margin-bottom: 60px;

              @media (max-height: 950px) {
                margin-bottom: 20px;
              }
            `}
          >
            <BackButton
              label={t("themi-builder.back-to-dashboard")}
              onClick={() => {
                history.push("/");
              }}
            />
          </div>
        )}

        {currentStep === 0 && (
          <ThemiBuilderCategorySelector
            category={selectedCategory}
            onSelect={(category) => {
              setSelectedCategory(category);

              if (category === "handover") {
                setSelectedTopics({
                  "handover-introduction": ["introduction"],
                });
              } else if (category === "onboarding") {
                setSelectedTopics({
                  "onboarding-introduction": ["welcome"],
                });
              } else if (category === "knowledge-sharing") {
                setSelectedTopics({
                  "topic-introduction": ["introduction"],
                });
              } else {
                setSelectedTopics({});
              }
            }}
          />
        )}
        {currentStep === 1 && (
          <ThemiBuilderTopicsSelector
            category={selectedCategory}
            title={title}
            onTitleChange={(title) => {
              setTitle(title);
            }}
            selectedTopics={selectedTopics}
            onTopicSelect={(topic: string, option: string) => {
              setSelectedTopics((prev) => {
                const current = prev[topic] || [];

                if (current.includes(option)) {
                  return {
                    ...prev,
                    [topic]: current.filter((x) => x !== option),
                  };
                }

                return {
                  ...prev,
                  [topic]: [...current, option],
                };
              });
            }}
          />
        )}
        {currentStep === 2 && (
          <ThemiBuilderTemplateReady
            templateData={{
              category: selectedCategory!,
              title,
              selectedTopics,
              context: {
                userprofile,
              },
            }}
          />
        )}
        {currentStep < 2 && (
          <div
            css={css`
              text-align: center;
              margin-top: 50px;
              display: flex;
              flex-flow: row wrap;
              align-items: center;
              justify-content: center;

              @media (max-height: 950px) {
                margin-top: 30px;
              }
            `}
          >
            {currentStep > 0 && (
              <Button
                css={css`
                  padding: 0;
                  width: 178px;
                  height: 46px;
                  margin-right: 60px;
                  background: #ffffff;
                  border: 1px solid #252424;
                  color: #000;
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 44px;
                  box-sizing: border-box;

                  &:hover {
                    border-color: #e95b2e;
                    color: #e95b2e;
                    background: #fff;
                  }
                `}
                onClick={() => {
                  setCurrentStep((step) => (step > 1 ? step - 1 : 0));
                }}
              >
                {t("themi-builder.pagination.previous")}
              </Button>
            )}
            <Button
              css={css`
                padding: 0;
                width: 178px;
                height: 46px;
                font-weight: 700;
                font-size: 20px;
                line-height: 46px;
                background: #e95b2e;
              `}
              onClick={() => {
                if (currentStep === 2) {
                  history.push("/themi-builder/preview");
                  return;
                }

                setCurrentStep((step) => (step < 2 ? step + 1 : 2));
              }}
              disabled={
                currentStep === 0
                  ? !selectedCategory
                  : currentStep === 1
                  ? !title || noTopics
                  : false
              }
            >
              {t("themi-builder.pagination.next")}
            </Button>
          </div>
        )}
      </ThemiBuilderBody>
    </div>
  );
}
