import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Button } from "../../components/Button";
import { InputType } from "../../components/InputComponent";
import { InputField } from "../fields/InputField";
import { Column, Spacing } from "../../helpers/layout";
import { setPassword } from "../../actions/auth/setPassword";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { useCheckMobile } from "../../hooks/useCheckMobile";

interface FormValues {
  new_password: string;
  confirm_password: string;
}

export function SetPasswordForm() {
  const { isMobile } = useCheckMobile();

  const params = useParams<{
    uuid: string;
  }>();

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Formik<FormValues>
      initialValues={{
        new_password: "",
        confirm_password: "",
      }}
      validationSchema={Yup.object().shape({
        new_password: Yup.string().min(8).required(),
        confirm_password: Yup.string()
          .oneOf([Yup.ref("new_password"), null], t("auth.pwd-not-match"))
          .required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);

          await setPassword(values.new_password, params.uuid);

          customToast.success(t("status.success"));

          history.push("/login");
        } catch (error) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form
          noValidate
          css={css`
            ${isMobile &&
            css`
              display: flex;
              flex-direction: column;
              height: 100%;
            `}
          `}
        >
          <Column
            gutter={Spacing.l}
            css={css`
              margin-top: 50px;

              ${isMobile &&
              css`
                margin-top: 40px;
              `};
            `}
          >
            <InputField
              name="new_password"
              type={InputType.PASSWORD}
              placeholder={t("auth.set-password.new")}
              css={css`
                padding: 0 40px;

                ${isMobile &&
                css`
                  padding: 0;
                `}
              `}
            />

            <InputField
              name="confirm_password"
              type={InputType.PASSWORD}
              placeholder={t("auth.set-password.confirm")}
              css={css`
                padding: 0 40px;

                ${isMobile &&
                css`
                  padding: 0;
                `}
              `}
            />
          </Column>

          <Button
            isSubmitting={isSubmitting}
            disabled={!isValid}
            type="submit"
            background="#e95b2e"
            hoverStyles="none"
            css={css`
              width: 100%;
              line-height: 28px;
              margin: 40px 0 0 0;
              color: #fff;
              font-weight: 700;
              font-size: 16px;

              ${isMobile &&
              css`
                margin: auto 0 0 0;
                font-size: 20px;
                padding: 10px 0;
              `};
            `}
          >
            {t("auth.set-password.cta")}
          </Button>

          {!isMobile && (
            <Link
              css={css`
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                margin-top: 20px;
                cursor: pointer;
              `}
              to="/login"
            >
              {t("auth.check-email.back")}
            </Link>
          )}
        </Form>
      )}
    </Formik>
  );
}
