import { VideoElement, VideoScene } from "../../../VideoEditor/types/Video";
import { v4 as uuid } from "uuid";
import { Template } from "../types";
import { defaultSequenceDuration } from "../../../constants";

export function generateKnowledgeSharingCoreConceptsTemplate(
  data: Template,
  order: number,
  t: any
) {
  const sceneId = uuid();
  const scene: VideoScene = {
    id: sceneId,
    backgroundColor: "#fff",
    duration: defaultSequenceDuration,
    order,
    name: t("themi-builder.template.core-concepts.scene-name"),
    enableCollaboration: true,
    start_time: 0,
    elements: [],
  };

  const firstElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    text: `<p style="font-family: Arial; font-size: 4.57cqw;"><strong>${t(
      "themi-builder.template.core-concepts.title"
    )}</strong></p>`,
    type: "text",
    order: 0,
    states: [
      {
        id: uuid(),
        top: 21.21,
        left: 35.35,
        scale: 1,
        width: 451.5239565943239,
        height: 57.834724540901504,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const secondElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // This is where you go into detail on the learning topic. Add the key learning concepts and explanations, and aid your content with images and video or audio recordings.
    text: `<p style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.core-concepts.description"
    )}</p>`,
    type: "text",
    order: 1,
    states: [
      {
        id: uuid(),
        top: 84.84,
        left: 35.35,
        scale: 1,
        width: 450.3436560934892,
        height: 44.85141903171953,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const thirdElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // Add your learning content here. Duplicate this sequence if you need more space.
    text: `<ul>\n<li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.core-concepts.second-description"
    )}</li>\n</ul>`,
    type: "text",
    order: 2,
    states: [
      {
        id: uuid(),
        top: 194.41762312186978,
        left: 36.530300500834706,
        scale: 1,
        width: 467.4580133555927,
        height: 144.58681135225376,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const fourthElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    type: "record-prompt",
    order: 3,
    states: [
      {
        id: uuid(),
        top: 42.98728192821372,
        left: 522.2829716193656,
        scale: 1,
        width: 145.2300751252091,
        height: 44.7002139338096,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
    instruction: "Add narrative",
  };

  const scenes: VideoScene[] = [scene];

  const elements: VideoElement[] = [
    firstElement,
    secondElement,
    thirdElement,
    fourthElement,
  ];

  return { scenes, elements };
}
