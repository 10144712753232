import { useHistory, useLocation } from "react-router-dom";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { ReactComponent as ThemiLogo } from "../../assets/icons/ThemiLogo.svg";
import { ReactComponent as LabelPro } from "../../assets/icons/LabelPro.svg";
import { ReactComponent as NavHome } from "../../assets/icons/NavHome.svg";
import { ReactComponent as NavWorkspace } from "../../assets/icons/NavWorkspace.svg";
import { ReactComponent as NavProfile } from "../../assets/icons/NavProfile.svg";
import { ReactComponent as NavLogout } from "../../assets/icons/NavLogout.svg";
import { ReactComponent as NavSubscription } from "../../assets/icons/NavSubscription.svg";
import { ReactComponent as SelectArrow } from "../../assets/icons/SelectArrow.svg";
import { useAuth } from "../../contexts/UserContext";
import { UpgrateToProAd } from "../UpgrateToProAd";
import { sendEvent } from "../../helpers/tracking";
import { useRef, useState } from "react";
import { useClickOutside } from "../../VideoEditor/hooks/useClickOutside";
import { useWorkspace } from "../../contexts/WorkspaceContext";
import { WorkspaceDropdown } from "./WorkspaceDropdown";
import { TrialWorkspaceDropdown } from "./TrialWorkspaceDropdown";

type NavItemProps = {
  title: string;
  icon: React.ReactNode;
  route?: string;
  onClick?: () => void;
  isPro?: boolean;
  isActive?: boolean;
};

function NavItem(props: NavItemProps) {
  const history = useHistory();

  const onClick = () => {
    if (props.route) {
      if (props.route.includes("http")) {
        window.open(props.route, "_blank");
      } else {
        history.push(props.route);
      }
    } else if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      css={css`
        display: flex;
        position: relative;
        width: 100%;
        height: 32px;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        margin-bottom: 15px;
        color: ${props.isActive ? "#E95B2E" : "#252424"};

        :hover {
          color: #e95b2e;
        }

        :nth-child(4) {
          margin-bottom: 60px;
        }

        :last-child {
          margin-bottom: 45px;
        }
      `}
      onClick={onClick}
    >
      {props.isActive && (
        <div
          css={css`
            position: absolute;
            height: 100%;
            width: 4px;
            background-color: #e95b2e;
            border-radius: 0 40% 40% 0;
            left: 0;
          `}
        />
      )}
      <div
        css={css`
          margin-left: 35px;
          display: flex;
        `}
      >
        <div
          css={css`
            display: flex;
            width: 30px;
            align-items: center;
          `}
        >
          {props.icon}
        </div>
        <span
          css={css`
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
          `}
        >
          {props.title}
        </span>
        {props.isPro && (
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-left: 10px;
            `}
          >
            <LabelPro />
          </div>
        )}
      </div>
    </div>
  );
}

export function Navigation() {
  const location = useLocation();
  const { t } = useTranslation();
  const { deauthenticate, userprofile } = useAuth();
  const { workspace } = useWorkspace();
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const workspaceDropdown = useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setDropDownVisible(true);
  };

  const handleClose = () => {
    if (dropDownVisible) setDropDownVisible(false);
  };

  useClickOutside(workspaceDropdown, handleClose);

  let menuItems: NavItemProps[] = [
    {
      title: t("navigation-left.dashboard"),
      icon: <NavHome />,
      route: "/",
      isActive: location.pathname === "/",
    },
    {
      title: t("navigation-left.workspace"),
      icon: <NavWorkspace />,
      route: "/workspace",
      isActive: location.pathname.includes("/workspace"),
    },
    {
      title: t("navigation-left.profile"),
      icon: <NavProfile />,
      route: "/account/me",
      isActive: location.pathname === "/account/me",
    },
    {
      title: t("navigation-left.subscription"),
      icon: <NavSubscription />,
      onClick: () => {
        sendEvent("Upgrade button clicked", {
          type: "Menu Link",
          location: "Sidebar Navigation",
        });

        window.open("https://calendly.com/themis-sales/demo", "_blank");
      },
      isPro: true,
      isActive: location.pathname === "/subscription",
    },
    {
      title: t("navigation-left.logout"),
      icon: <NavLogout />,
      onClick: () => deauthenticate(),
    },
  ];

  if (userprofile?.is_pro) {
    menuItems = menuItems.filter((item) => !item.isPro);
  }

  return (
    <aside
      css={css`
        position: relative;
        flex: 0 0 250px;
        border-right: 1px solid #f2f2f2;
        background-color: #f1f1f1;
        padding-top: 35px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 48px);
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-left: 35px;
          margin-bottom: 43px;
          color: #252424;
          line-height: 28px;
        `}
      >
        <ThemiLogo
          css={css`
            margin-bottom: 12px;
          `}
        />
        <span
          css={css`
            font-size: 14px;
            font-weight: 500;
          `}
        >
          {t("navigation-left.header.subtitle")}
        </span>
        <div
          css={css`
            position: relative;
            padding-right: 25px;
          `}
        >
          <div
            onClick={() => (dropDownVisible ? handleClose() : handleOpen())}
            css={css`
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              width: fit-content;
              padding: 0 4px;
              left: -4px;
              border-radius: 5px;
              :hover {
                background-color: #fff;
              }
            `}
          >
            <div
              css={css`
                position: relative;
                font-size: 20px;
                font-weight: 600;
                cursor: pointer;
              `}
            >
              {workspace?.workspace
                ? workspace.workspace.name
                : t("navigation-left.header.title")}
            </div>
            <div
              css={css`
                display: inline-block;
                vertical-align: middle;
                margin-left: 20px;
              `}
            >
              <SelectArrow width={12} />
            </div>
          </div>
          {dropDownVisible && (
            <div
              css={css`
                position: absolute;
                top: 100%;
                margin-top: 3px;
                left: 0;
                z-index: 2;
              `}
              ref={workspaceDropdown}
            >
              {workspace?.workspace ? (
                <WorkspaceDropdown workspace={workspace} />
              ) : (
                <TrialWorkspaceDropdown />
              )}
            </div>
          )}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          line-height: 28px;
        `}
      >
        <span
          css={css`
            font-size: 14px;
            font-weight: 500;
            color: #616161;
            margin-left: 35px;
            text-transform: uppercase;
          `}
        >
          {t("navigation-left.main_label")}
        </span>
        {menuItems.map((item, index) => (
          <NavItem key={index} {...item} />
        ))}
      </div>
      <div
        css={css`
          display: flex;
          border-top: 1px solid #7f7f7f;
          margin: 0 23px 25px 23px;
        `}
      />
      {/* <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 50px;
        `}
      >
        <span
          css={css`
            font-size: 14px;
            font-weight: 500;
            color: #616161;
            margin-left: 35px;
            margin-right: 10px;
            line-height: 28px;
          `}
        >
          TEAMS
        </span>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <LabelPro />
        </div>
      </div> */}
      {!userprofile?.is_pro && (
        <div
          css={css`
            margin: 0 15px;
          `}
        >
          <UpgrateToProAd location="Sidebar Navigation" />
        </div>
      )}
    </aside>
  );
}
