import React from "react";
import { css } from "styled-components/macro";
import { FeedbackWidget } from "./FeedbackWidget";
import { AuthSide } from "./AuthSide";
import { Footer } from "./Footer";
import { ReactComponent as ThemisLogo } from "../assets/icons/ThemisLogo.svg";
import { ReactComponent as ArrowBack } from "../assets/icons/ArrowBack.svg";

import { useCheckMobile } from "../hooks/useCheckMobile";
import { useHistory } from "react-router-dom";

export function AuthLayout(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const { isMobile } = useCheckMobile();
  const history = useHistory();

  return (
    <>
      <div
        css={css`
          position: relative;
          display: flex;
          flex-flow: row wrap;
          justify-content: stretch;
          height: 100vh;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 40px;
            left: 20px;
          `}
          onClick={() => {
            if (isMobile) {
              // if redirected from another page, go back to that page
              if (history.length > 2) {
                history.goBack();
              } else {
                history.push("/get-started");
              }
            } else {
              window.location.replace("https://themis.eu");
            }
          }}
        >
          {isMobile ? <ArrowBack /> : <ThemisLogo />}
        </div>
        <div
          css={css`
            position: absolute;
            top: 40px;
          `}
        ></div>
        <main
          css={css`
            display: flex;
            flex: 1 1 auto;
            padding: 20px;
          `}
        >
          <section
            css={css`
              margin: 100px auto;
              width: 100%;

              ${isMobile &&
              css`
                margin: 55px auto;
              `}
            `}
          >
            {props.children}
          </section>
        </main>
        {!isMobile && <AuthSide />}

        {!isMobile && (
          <Footer
            css={css`
              width: 100%;
            `}
          />
        )}
      </div>
      {!isMobile && <FeedbackWidget />}
    </>
  );
}
