import { VideoElement, VideoScene } from "../../../VideoEditor/types/Video";
import { v4 as uuid } from "uuid";
import { Template } from "../types";
import { defaultSequenceDuration } from "../../../constants";

export function generateHandoverOverviewTemplate(
  data: Template,
  order: number,
  t: any
) {
  const sceneId = uuid();
  const scene: VideoScene = {
    id: sceneId,
    backgroundColor: "#fff",
    duration: defaultSequenceDuration,
    order,
    name: t("themi-builder.template.handover-overview.scene-name"),
    enableCollaboration: true,
    start_time: 0,
    elements: [],
  };

  const firstElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    text: `<p style="font-family: Arial; font-size: 4.57cqw;"><strong>${t(
      "themi-builder.template.handover-overview.title"
    )}</strong></p>`,
    type: "text",
    order: 0,
    states: [
      {
        id: uuid(),
        top: 21.21,
        left: 35.35,
        scale: 1,
        width: 636.3000000000001,
        height: 57.834724540901504,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
    touched: true,
  };

  const secondElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // What are the main responsibilities you are handing over today? You can always add more later on.
    text: `<p style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.handover-overview.description"
    )}</p>`,
    type: "text",
    order: 1,
    states: [
      {
        id: uuid(),
        top: 106.08540901502505,
        left: 35.35,
        scale: 1,
        width: 309.2977462437396,
        height: 67.67695534223705,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
    touched: true,
  };

  const thirdElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // List out key responsibilities here:
    // What are your typical daily tasks?
    // What software is critical to your role?
    // What important process or system are you responsible for?
    text: `<ul>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.handover-overview.points.one"
    )}</li>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.handover-overview.points.two"
    )}</li>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.handover-overview.points.three"
    )}</li>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.handover-overview.points.four"
    )}</li>
    </ul>`,
    type: "text",
    order: 2,
    states: [
      {
        id: uuid(),
        top: 201.14754904006682,
        left: 35.94015025041737,
        scale: 1,
        width: 309.2977462437396,
        height: 157.57011686143574,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
    touched: true,
  };

  const fourthElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    type: "record-prompt",
    order: 3,
    states: [
      {
        id: uuid(),
        top: 107.66553631051752,
        left: 367.64,
        scale: 1,
        width: 307.54499999999996,
        height: 215.78696368948235,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
    touched: true,
    instruction: t("themi-builder.template.record-prompt.general-description"),
  };

  const scenes: VideoScene[] = [scene];

  const elements: VideoElement[] = [
    firstElement,
    secondElement,
    thirdElement,
    fourthElement,
  ];

  return { scenes, elements };
}
