import { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { Button } from "./Button";
import { useStorage } from "../contexts/StorageContext";
import { ModalShare } from "../../modals/ModalShare";

export function ShareButton() {
  const { t } = useTranslation();
  const [videoShare, setVideoshare] = useState<boolean>(false);
  const { video } = useStorage();

  return (
    <div>
      <Button
        css={css`
          color: #fff;
          font-weight: 700;
          min-width: 6em;
          padding: 10px 16px;
          :hover {
            background-color: #fe521c;
          }
        `}
        onClick={() => {
          if (video) setVideoshare(true);
        }}
      >
        {t("dashboard-page.checklist.step-4.cta")}
      </Button>

      <ModalShare isOpen={videoShare} onClose={() => setVideoshare(false)} />
    </div>
  );
}
