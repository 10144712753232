import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";
import { AiProvider } from "./contexts/AiContext";
import { CollaborationProvider } from "./contexts/CollaborationContext";
import { CommentsProvider } from "./contexts/CommentsContext";
import { EditorProvider } from "./contexts/EditorContext";
import { PlaybackProvider } from "./contexts/PlaybackContext";
import { RecordingProvider } from "./contexts/RecordingContext";
import { StorageProvider } from "./contexts/StorageContext";
import { FileUploadProvider } from "./contexts/FileUploadContext";
import { EditorLayout } from "./EditorLayout";
import { SharingProvider } from "./contexts/SharingContext";

export function VideoEditor() {
  const params = useParams<{
    videoId: string;
  }>();

  return (
    <DndProvider backend={HTML5Backend}>
      <StorageProvider videoId={params.videoId}>
        {(video) => {
          return (
            <CollaborationProvider videoId={params.videoId}>
              <SharingProvider videoId={params.videoId}>
                <AiProvider videoId={params.videoId}>
                  <EditorProvider>
                    <RecordingProvider>
                      <PlaybackProvider video={video}>
                        <CommentsProvider videoId={params.videoId}>
                          <FileUploadProvider video={video}>
                            <EditorLayout />
                          </FileUploadProvider>
                        </CommentsProvider>
                      </PlaybackProvider>
                    </RecordingProvider>
                  </EditorProvider>
                </AiProvider>
              </SharingProvider>
            </CollaborationProvider>
          );
        }}
      </StorageProvider>
    </DndProvider>
  );
}
