import React, { useCallback } from "react";
import { usePlayback } from "../../VideoEditor/contexts/PlaybackContext";
import { ReactComponent as PlayIcon } from "../assets/icons/Play.svg";
import { ReactComponent as PauseIcon } from "../assets/icons/Pause.svg";
import { css } from "styled-components/macro";
import { useStorage } from "../contexts/StorageContext";

const PlayButtonPure = () => {
  const { playing, play, pause } = usePlayback();
  const { video } = useStorage();
  const onClick = useCallback(() => {
    if (playing) {
      pause();
    } else {
      play();
    }
  }, [playing, play, pause]);

  return (
    <button
      css={css`
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        box-shadow: 0px 0px 13px #e5e5e5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          fill: #e95b2e !important;
        }

        path {
          stroke-width: 0 !important;
        }
        ${video?.schema.schema.elements.length === 0 &&
        css`
          color: #696969;
          pointer-events: none;
        `}
      `}
      onClick={onClick}
    >
      {playing ? (
        <PauseIcon
          css={css`
            color: #e95b2e;
          `}
        />
      ) : (
        <PlayIcon />
      )}
    </button>
  );
};
export const PlayButton = React.memo(PlayButtonPure);
