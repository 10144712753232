import React, { useMemo } from "react";
import { css } from "styled-components/macro";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Headers } from "../helpers/layout";
import { Button } from "../components/Button";
import { Dropdown, DropdownItem } from "../components/Dropdown";
import { ReactComponent as ThreeDotsIcon } from "../assets/icons/ThreeDots.svg";
import { ReactComponent as PlayIcon } from "../assets/icons/Play.svg";
import { ReactComponent as EyeIcon } from "../assets/icons/Eye.svg";
import { ReactComponent as PencilIcon } from "../assets/icons/Pencil.svg";
import { ScenePreview } from "../VideoEditor/components/ScenePreview";
import { getVideoScenes } from "../VideoEditor/helpers/video";
import { Video } from "../VideoEditor/types/Video";
import { Collaborators } from "../VideoEditor/components/Collaborators";
import { calculateVideoDuration } from "../VideoEditor/helpers/video";
import { theme } from "../themes/variables";
import { CollaboratorRole } from "../VideoEditor/types/Collaborator";
import { ReactComponent as ReactionIcon } from "../VideoEditor/assets/icons/Reaction.svg";
import { ReactComponent as ArchiveIcon } from "../assets/icons/DropDown-Archive.svg";
import { handleArchiveRecover } from "../helpers/handleArchiveRecover";

import { useNewFetch } from "../useAPI";
import { getViewsStats } from "../actions/views/getViewsStats";
import { UserMeta } from "../types/UserProfile";

export function ThemiLongCard(props: {
  video: Video;
  videoStatus?: string;
  onArchive: () => Promise<void>;
}) {
  const { video } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const isArchived = video.archived_at !== null;
  const { data: viewStats } = useNewFetch(getViewsStats, {
    themi_id: video.uuid,
  });

  const dropdownOptions = [
    {
      label: t("drop-down.archive"),
    },
  ];

  const videoDuration = useMemo(() => {
    return calculateVideoDuration(video.preview_schema);
  }, [video]);

  return (
    <div
      css={css`
        width: 100%;
        margin-bottom: 20px;
      `}
      key={video.uuid}
    >
      <div
        css={css`
          width: 100%;
          height: auto;
          border: 1px solid #e2e2e2;
          border-radius: 11px;
          display: flex;
        `}
      >
        <div
          css={css`
            width: 100%;
            max-width: 209px;
            min-height: 117px;
            background-color: #d9d9d9;
            border-radius: 11px 0 0 11px;
            overflow: hidden;
            cursor: pointer;
          `}
          onClick={() => {
            history.push(`/video-editor/${video.uuid}`);
          }}
        >
          {video.preview_schema && (
            <ScenePreview
              scene={getVideoScenes(video.preview_schema)[0]}
              width={400}
              css={css`
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top left;
              `}
            />
          )}
        </div>

        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              width: 100%;
              display: flex;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                padding-left: 21px;
              `}
            >
              <Headers.H4
                css={css`
                  padding-top: 2px;
                  cursor: pointer;
                `}
                onClick={() => {
                  history.push(`/video-editor/${video.uuid}`);
                }}
              >
                {video.name}
              </Headers.H4>

              <div
                css={css`
                  font-size: 16px;
                  line-height: 28px;
                `}
              >
                <span
                  css={css`
                    font-size: 16px;
                    line-height: 28px;
                    color: ${theme.colors.primary};
                  `}
                >
                  {DateTime.fromSeconds(videoDuration / 1000).toFormat("mm:ss")}
                </span>

                <span
                  css={css`
                    padding-left: 21px;

                    color: #7f7f7f;
                  `}
                >
                  {video.publish_date
                    ? DateTime.fromISO(video.publish_date).toFormat("dd/MM/yy")
                    : ""}
                </span>
              </div>
            </div>

            <div
              css={css`
                display: flex;
                justify-content: center;
                margin-top: 10px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  font-size: 12px;
                  margin: 1.5px 12px 0 0;
                `}
              >
                {/* TODO: Add views data */}
                {viewStats?.total_views}
                <EyeIcon
                  css={css`
                    margin-left: 3px;
                  `}
                />
              </div>

              <Link
                to={`/video/${video?.uuid}`}
                css={css`
                  display: flex;

                  ${video.publish_status !== "PUBLISHED" &&
                  css`
                    pointer-events: none;
                    opacity: 0.5;
                  `}
                `}
              >
                <Button
                  icon={<PlayIcon />}
                  css={css`
                    width: 21px;
                    height: 21px;
                    border-radius: 50%;
                    align-items: center;
                    padding: 0;
                    min-width: 0;
                  `}
                  hoverStyles="none"
                ></Button>
              </Link>

              <Button
                icon={<PencilIcon />}
                css={css`
                  width: 21px;
                  height: 21px;
                  border-radius: 50%;
                  align-items: center;
                  padding: 0;
                  min-width: 0;
                  margin-left: 8px;
                `}
                hoverStyles="none"
                onClick={() => {
                  history.push(`/video-editor/${video.uuid}`);
                }}
              ></Button>

              <Dropdown
                options={dropdownOptions.map((option) => {
                  return (
                    <Button
                      css={css`
                        border: none;
                        padding: 0;
                        background-color: transparent;
                        color: black;
                        border-radius: 0;
                      `}
                      key={option.label}
                      hoverStyles={"none"}
                      onClick={async () =>
                        await handleArchiveRecover(video, props.onArchive, t)
                      }
                    >
                      <DropdownItem>
                        {isArchived ? (
                          t("drop-down.recover")
                        ) : (
                          <>
                            <ArchiveIcon
                              css={css`
                                align-self: center;
                                margin-right: 5px;
                              `}
                            />
                            {option.label}
                          </>
                        )}
                      </DropdownItem>
                    </Button>
                  );
                })}
              >
                <Button
                  css={css`
                    display: flex;
                    flex-direction: column;
                    min-width: 2em;
                    background-color: transparent;
                    color: #000;
                    padding-left: 14px;
                    padding-top: 2px;
                  `}
                  hoverStyles="none"
                  icon={<ThreeDotsIcon />}
                ></Button>
              </Dropdown>
            </div>
          </div>

          <div
            css={css`
              display: flex;
              justify-content: space-between;

              margin-left: 24px;
              margin-bottom: 12px;
              margin-top: 31px;
              margin-right: 34px;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <div
                css={css`
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 28px;
                  color: #7f7f7f;
                `}
              >
                {t("collaborators.owner")}
              </div>

              <div
                css={css`
                  display: flex;
                `}
              >
                <Collaborators
                  users={video.collaborators.reduce<UserMeta[]>(
                    (acc, collaborator) => {
                      if (collaborator.role !== CollaboratorRole.OWNER)
                        return acc;
                      return [...acc, collaborator.user];
                    },
                    []
                  )}
                  maxCollaborators={4}
                  isHover={false}
                />
              </div>
            </div>

            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <div
                css={css`
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 28px;
                  color: #7f7f7f;
                `}
              >
                {t("collaborators.reactions")}
              </div>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <ReactionIcon />{" "}
                <span
                  css={css`
                    margin-left: 6px;
                  `}
                >
                  {/* TODO: Add reaction data */}0
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
