import {
  calculateProportionalSize,
  calculateReverseProportionalSize,
} from "./renderer";
import {
  VideoElement,
  VideoElementResizeDirection,
  VideoElementState,
} from "../types/Video";
import { CanvasSize } from "../contexts/EditorContext";

export function recalculatePosition(
  e: MouseEvent,
  type: VideoElement["type"],
  canvasSize: CanvasSize,
  refs: {
    initialStateRef: React.MutableRefObject<VideoElementState>;
    wrapperRef: React.RefObject<HTMLDivElement>;
    resizingRef: React.MutableRefObject<VideoElementResizeDirection | null>;
    draggingRef: React.MutableRefObject<boolean>;
    rotatingRef: React.MutableRefObject<"nw" | null>;
    offsetRef: React.MutableRefObject<{
      x: number;
      y: number;
    }>;
  }
): VideoElementState | undefined {
  const initialWidth = refs.initialStateRef.current.width;
  const initialHeight = refs.initialStateRef.current.height;
  const initialLeft = refs.initialStateRef.current.left;
  const initialTop = refs.initialStateRef.current.top;
  const initialRotation = refs.initialStateRef.current.rotation;
  if (
    !refs.wrapperRef.current ||
    !(
      refs.resizingRef.current ||
      refs.draggingRef.current ||
      refs.rotatingRef.current
    )
  )
    return;

  const rect = refs.wrapperRef.current.getBoundingClientRect();

  if (!rect) return;

  const isElementInFocus =
    document.activeElement?.attributes.getNamedItem("contenteditable")
      ?.value === "true";

  // Mouse position
  let x = e.clientX - rect.left;
  let y = e.clientY - rect.top;

  x = Math.max(0, x);
  y = Math.max(0, y);

  x = Math.min(x, canvasSize.width);
  y = Math.min(y, canvasSize.height);

  let width = calculateProportionalSize(initialWidth, canvasSize.width);
  let height = calculateProportionalSize(initialHeight, canvasSize.width);
  let left = calculateProportionalSize(initialLeft, canvasSize.width);
  let top = calculateProportionalSize(initialTop, canvasSize.width);
  let deltaX = 0;
  let deltaY = 0;
  let rotation = initialRotation;
  const enableProportionalResize =
    e.shiftKey || type === "video" || type === "image" || type === "gif";

  if (refs.resizingRef.current) {
    if (refs.resizingRef.current === "nw") {
      if (enableProportionalResize) {
        deltaY = y - top;

        width = width - deltaY;
        height = height - deltaY * (initialHeight / initialWidth);
        left = left + deltaY;
        top = top + deltaY * (initialHeight / initialWidth);
      } else {
        deltaX = x - left;
        deltaY = y - top;

        width = width - deltaX;
        height = height - deltaY;
        left = left + deltaX;
        top = top + deltaY;
      }
    } else if (refs.resizingRef.current === "ne") {
      if (enableProportionalResize) {
        deltaY = y - top;

        width = width - deltaY;
        height = height - deltaY * (initialHeight / initialWidth);
        top = top + deltaY * (initialHeight / initialWidth);
      } else {
        deltaX = x - left - width;
        deltaY = y - top;

        width = width + deltaX;
        height = height - deltaY;
        top = top + deltaY;
      }
    } else if (refs.resizingRef.current === "sw") {
      if (enableProportionalResize) {
        deltaX = x - left;
        deltaY = y - top - height;

        width = width - deltaX;
        height = height - deltaX * (initialHeight / initialWidth);
        left = left + deltaX;
      } else {
        deltaX = x - left;
        deltaY = y - top - height;

        width = width - deltaX;
        height = height + deltaY;
        left = left + deltaX;
      }
    } else if (refs.resizingRef.current === "se") {
      if (enableProportionalResize) {
        deltaX = x - left - width;
        width = width + deltaX;
        height = height + deltaX * (initialHeight / initialWidth);
      } else {
        deltaX = x - left - width;
        deltaY = y - top - height;
        width = width + deltaX;
        height = height + deltaY;
      }
    } else if (refs.resizingRef.current === "n") {
      if (enableProportionalResize) {
        deltaY = y - top;
        height = height - deltaY;
        width = width - deltaY * (initialWidth / initialHeight);
        top = top + deltaY;
        left = left + deltaY * (initialWidth / initialHeight);
      } else {
        deltaY = y - top;
        height = height - deltaY;
        top = top + deltaY;
      }
    } else if (refs.resizingRef.current === "s") {
      if (enableProportionalResize) {
        deltaY = y - top - height;
        height = height + deltaY;
        width = width + deltaY * (initialWidth / initialHeight);
        left = left - deltaY * (initialWidth / initialHeight);
      } else {
        deltaY = y - top - height;
        height = height + deltaY;
      }
    } else if (refs.resizingRef.current === "w") {
      if (enableProportionalResize) {
        deltaX = x - left;
        width = width - deltaX;
        height = height - deltaX * (initialHeight / initialWidth);
        left = left + deltaX;
      } else {
        deltaX = x - left;
        width = width - deltaX;
        left = left + deltaX;
      }
    } else if (refs.resizingRef.current === "e") {
      if (enableProportionalResize) {
        deltaX = x - left - width;
        width = width + deltaX;
        height = height + deltaX * (initialHeight / initialWidth);
      } else {
        deltaX = x - left - width;
        width = width + deltaX;
      }
    }
  } else if (refs.draggingRef.current && !isElementInFocus) {
    left = x - refs.offsetRef.current.x;
    top = y - refs.offsetRef.current.y;
  } else if (refs.rotatingRef.current) {
    const centerX = left + width / 2;
    const centerY = top + height / 2;

    const handlePositionLeft =
      refs.rotatingRef.current === "nw" ? left : left + width;
    const handlePositionTop =
      refs.rotatingRef.current === "nw" ? top : top + height;

    const angle =
      Math.atan2(y - centerY, x - centerX) -
      Math.atan2(handlePositionTop - centerY, handlePositionLeft - centerX);

    const newAngle = angle * (180 / Math.PI);

    rotation = newAngle;
  }

  // Position bigger than 0,0
  left = Math.max(0, left);
  top = Math.max(0, top);

  // Size smaller than canvas
  if (width > canvasSize.width) {
    width = canvasSize.width;
    height = width * (initialHeight / initialWidth);
  }

  if (height > canvasSize.height) {
    height = canvasSize.height;
    width = height * (initialWidth / initialHeight);
  }

  // Doesn't go outside canvas
  if (left + width > canvasSize.width) {
    left = canvasSize.width - width;
  }

  if (top + height > canvasSize.height) {
    top = canvasSize.height - height;
  }

  // Minimum size
  if (width < 30 || height < 30) {
    if (width > height) {
      height = 30;
      width = height * (initialWidth / initialHeight);
    } else {
      width = 30;
      height = width * (initialHeight / initialWidth);
    }
  }

  return {
    ...refs.initialStateRef.current,
    width: calculateReverseProportionalSize(width, canvasSize.width),
    height: calculateReverseProportionalSize(height, canvasSize.width),
    left: calculateReverseProportionalSize(left, canvasSize.width),
    top: calculateReverseProportionalSize(top, canvasSize.width),
    rotation,
  };
}
