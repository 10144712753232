import React, { useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { css } from "styled-components/macro";
import { VideoElementState, VideoElementText } from "../../types/Video";
import { useStorage } from "../../contexts/StorageContext";
import { useEditor } from "../../contexts/EditorContext";
import { useClickOutside } from "../../hooks/useClickOutside";
import { RichTextEditor } from "../../components/RichTextEditor";
import { convertToRichText } from "../../helpers/video";
import { canEdit } from "../../helpers/collaborator";

export function ElementTextRenderer(props: {
  element: VideoElementText;
  state: VideoElementState;
  onChange: (height: number) => void;
}) {
  const { api, video } = useStorage();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const passedText = convertToRichText(props.element).text;

  const editableElement = React.useRef<HTMLDivElement | null>(
    (wrapperRef.current?.querySelector(
      "[contenteditable]"
    ) as HTMLDivElement) || null
  );

  const { setTextEditorInit } = useEditor();
  const clickPosition = React.useRef({ x: 0, y: 0 });
  const [selected, setSelected] = React.useState(false);
  const [activated, setActivated] = React.useState(false);
  const selectedRef = React.useRef(selected);
  const [text, setText] = React.useState(passedText);

  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  useEffect(() => {
    if (!selectedRef.current) {
      setText(passedText);
    }
  }, [passedText]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateElement = useCallback(
    debounce(async (elementId: string, text: string) => {
      await api.updateElement(elementId, {
        text: text,
        config: {},
      });
    }, 1000),
    [api]
  );

  const handleContentEditable = (content: any) => {
    if (content === text) return;

    setText(content);
    updateElement(props.element.id, content);

    if (!editableElement.current) {
      editableElement.current = wrapperRef.current?.querySelector(
        "[contenteditable]"
      ) as HTMLDivElement;
    }

    const heightOfElement = editableElement.current.clientHeight;
    props.onChange(heightOfElement);
  };

  useClickOutside(wrapperRef, () => {
    setSelected(false);
    setActivated(false);
    editableElement.current = null;

    // clear selection
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  });

  useEffect(() => {
    if (selected) {
      const el = wrapperRef.current?.querySelector(
        "[contenteditable]"
      ) as HTMLDivElement;
      if (el) {
        const range = document.createRange();
        const selection = window.getSelection();

        range.selectNodeContents(el);
        range.collapse(false);

        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
  }, [selected]);

  return (
    <div
      ref={wrapperRef}
      id={`text-element-${props.element.id}`}
      onMouseDown={(e) => {
        clickPosition.current = {
          x: e.clientX,
          y: e.clientY,
        };
      }}
      onMouseUp={(e) => {
        if (
          Math.abs(clickPosition.current.x - e.clientX) < 5 &&
          Math.abs(clickPosition.current.y - e.clientY) < 5
        ) {
          setSelected(true);

          if (selected) {
            setActivated(true);
          }
        }
      }}
      css={css`
        position: relative;
        height: 100%;

        li {
          margin-left: 1.2em;
        }

        ${!selected &&
        css`
          user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;

          a {
            pointer-events: none;
          }
        `}
      `}
    >
      <RichTextEditor
        static={!canEdit(video?.current_user_role) || !selected}
        html={text}
        onInit={() => setTextEditorInit(true)}
        onChange={handleContentEditable}
        id={`editor-${props.element.id}`}
      />
      {!activated && (
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          `}
        />
      )}
    </div>
  );
}
