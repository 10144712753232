import { authorised } from "../../request";
import { WorkspaceUserRole } from "../../types/Workspace";

export async function updateUserRole(data: {
  workspaceId: string;
  userEmail: string;
  role: WorkspaceUserRole;
}) {
  const API = authorised();

  return await API.request({
    method: "POST",
    url: `/api/studio/themis/workspaces/${data.workspaceId}/invite/`,
    data: {
      user_emails: [data.userEmail],
      role: data.role,
    },
  });
}
