import React from "react";
import { css } from "styled-components/macro";
import { Loader } from "../../../components/Loader";

type ElementButtonProps = {
  type?: "button" | "submit";
  children?: React.ReactNode;
  title?: string;
  name?: string;
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
  iconRight?: boolean;
  isSubmitting?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  isActive?: boolean;
};

export function ElementButton(props: ElementButtonProps) {
  return (
    <button
      type={props.type || "button"}
      name={props.name}
      title={props.title}
      css={css`
        width: 28px;
        height: 28px;
        background-color: #ffffff;
        border: none;
        border-radius: 50%;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        margin: 0 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;

        :disabled {
          cursor: default;
          background: #858585;
        }

        :hover {
          color: #e95b2e;
        }

        ${props.isActive &&
        css`
          border: 1px solid #e95b2e !important
          color: #e95b2e !important;
        `}
      `}
      disabled={props.disabled || props.isSubmitting}
      className={props.className}
      onClick={props.onClick}
    >
      {props.isSubmitting ? (
        <Loader size={15} />
      ) : (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {props.icon && !props.iconRight && (
            <span
              css={css`
                display: flex;
                justify-content: center;
                ${props.children &&
                css`
                  margin-right: 10px;
                `};
              `}
            >
              {props.icon}
            </span>
          )}

          <span
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            {props.children}
          </span>

          {props.icon && props.iconRight && (
            <span
              css={css`
                display: flex;
                ${props.children &&
                css`
                  margin-left: 10px;
                `}
              `}
            >
              {props.icon}
            </span>
          )}
        </div>
      )}
    </button>
  );
}
