import React, { useEffect, useMemo, useRef } from "react";
import { css } from "styled-components/macro";
import { ReactComponent as StepComplete } from "../assets/icons/StepComplete.svg";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";

import { Button } from "./Button";
import { SCREENS_TOUR_SEEN } from "./Carousel";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

type StepProps = {
  step: number;
  isCompleted: boolean;
  isActive: boolean;
  title: string;
  description: string;
  cta?: () => void;
  cta_title?: string;
};

type DashboardGuideProps = {
  setShowTour: () => void;
  themiExists: boolean;
};

function Step(props: StepProps) {
  return (
    <div
      css={css`
        display: flex;
        position: relative;
        cursor: pointer;
        flex-direction: row;
        align-items: center;
        padding: 20px 20px 30px 16px;
        border: 1px solid
          ${props.isCompleted || props.isActive ? "#e95b2e" : "#cdcdcd"};
        border-radius: 9px;
        background-color: #fff;

        ${props.isActive &&
        css`
          top: -10px;

          :hover {
            box-shadow: 0px 25px 24px #fae7d9;
          }
        `}
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          width: 33px;
          margin-right: 20px;
        `}
      >
        {props.isCompleted ? (
          <StepComplete />
        ) : (
          <div
            css={css`
              width: 33px;
              height: 33px;
              border-radius: 50px;
              background-color: ${props.isActive ? "#FAE7D9" : "#f2f2f2"};
              text-align: center;
              align-items: center;
            `}
          >
            <div
              css={css`
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                color: ${props.isActive ? "#e95b2e" : "#616161"};
              `}
            >
              {props.step}
            </div>
          </div>
        )}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          text-align: left;
          /* width: 60%; */
        `}
      >
        <div
          css={css`
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #252424;
            margin-bottom: 7px;
          `}
        >
          {props.title}
        </div>
        <div
          css={css`
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            color: #616161;
          `}
        >
          {props.description}
        </div>
      </div>
      {props.cta && props.isActive && props.cta_title && (
        <Button
          css={css`
            background-color: #252424;
            white-space: nowrap;
            color: #fff;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            padding: 5px 20px;
            min-width: 1rem;
            margin: 0 0 0 auto;
          `}
          onClick={props.cta}
          hoverStyles="none"
        >
          {props.cta_title}
        </Button>
      )}
    </div>
  );
}

export function DashboardGuide(props: DashboardGuideProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const [progress, setProgress] = React.useState(0);

  const preogress_colapsed = localStorage.getItem("PROGRESS_COLLAPSED");
  const [collapse, setCollapse] = React.useState<boolean>(
    preogress_colapsed === "true" ? true : false
  );

  const content = useRef(null as null | HTMLDivElement);

  const { setShowTour, themiExists } = props;

  const steps: StepProps[] = useMemo(() => {
    let list = [
      {
        step: 1,
        isCompleted: true,
        title: t("dashboard-page.checklist.step-1.title"),
        description: t("dashboard-page.checklist.step-1.description"),
      },
      {
        step: 2,
        isCompleted: !!localStorage.getItem(SCREENS_TOUR_SEEN),
        title: t("dashboard-page.checklist.step-2.title"),
        description: t("dashboard-page.checklist.step-2.description"),
        cta_title: t("dashboard-page.checklist.step-2.cta"),
        cta: () => {
          setShowTour();
        },
      },
      {
        step: 3,
        isCompleted: themiExists,
        title: t("dashboard-page.checklist.step-3.title"),
        description: t("dashboard-page.checklist.step-3.description"),
        cta_title: t("dashboard-page.checklist.step-3.cta"),
        cta: () => {
          history.push("/themi-builder");
        },
      },
      {
        step: 4,
        isCompleted: !!localStorage.getItem("USER_INVITED"),
        title: t("dashboard-page.checklist.step-4.title"),
        description: t("dashboard-page.checklist.step-4.description"),
        cta_title: t("dashboard-page.checklist.step-4.cta"),
      },
    ];

    const nextUserStep = list.find((step) => {
      return !step.isCompleted;
    });

    return list.map((step) => {
      return {
        ...step,
        isActive: step.step === nextUserStep?.step,
      };
    });
  }, [setShowTour, themiExists, t, history]);

  useEffect(() => {
    const stepsCompleted = steps.filter((step) => step.isCompleted).length;
    setProgress((stepsCompleted / steps.length) * 100);
  }, [steps]);

  useEffect(() => {
    if (progress === 100) {
      if (!localStorage.getItem("GET_STARTED_COMPLETED")) {
        localStorage.setItem("GET_STARTED_COMPLETED", "true");
      }
    }
  }, [progress]);

  useEffect(() => {
    localStorage.setItem("PROGRESS_COLLAPSED", collapse.toString());
  }, [collapse]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        border: 1px solid #cdcdcd;
        background-color: #fffbf9;
        border-radius: 11px;
        padding: 10px 30px 0px 23px;
        margin-bottom: 80px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;

          width: 100%;
          margin-bottom: 10px;
        `}
      >
        <div
          css={css`
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            white-space: nowrap;
          `}
        >
          {t("dashboard-page.checklist.title")}
        </div>
        <div
          css={css`
            position: relative;
            width: 100%;
            height: 7px;
            border-radius: 7px;
            background-color: #ffc39c;
            margin-left: 55px;
          `}
        >
          <div
            css={css`
              position: absolute;
              left: 0;
              width: ${progress}%;
              height: 7px;
              border-radius: 7px;
              background-color: #e95b2e;
            `}
          >
            <div
              css={css`
                position: absolute;
                right: 0;
                top: -6px;
                width: 38px;
                height: 18px;
                background-color: #e95b2e;
                box-shadow: 0px 0px 0px 3px rgba(233, 91, 46, 0.17);
                border-radius: 9px;
                font-weight: 600;
                font-size: 12px;
                color: #ffffff;
                text-align: center;
              `}
            >
              {progress.toFixed(0)}%
            </div>
          </div>
        </div>
        <div
          onClick={() => setCollapse(!collapse)}
          css={css`
            margin-left: 20px;
            cursor: pointer;
          `}
        >
          <Chevron
            css={css`
              margin-left: auto;
              transition: transform 0.6s ease;
              transform: ${collapse ? `rotate(-90deg)` : `none`};
            `}
          />
        </div>
      </div>
      <div
        ref={content}
        css={css`
          overflow: hidden;
          position: relative;
          max-height: ${collapse ? "0px" : "auto"};

          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 60px;
          grid-row-gap: 30px;
          padding-right: 50px;
          padding-top: ${collapse ? "0px" : "20px"};
          padding-bottom: ${collapse ? "0px" : "30px"};
        `}
      >
        {steps.map((step, index) => (
          <Step key={index} {...step} />
        ))}
      </div>
    </div>
  );
}
