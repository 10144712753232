import React, { useCallback, useEffect, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { liveQuery } from "dexie";
import { useHistory } from "react-router";
import { db } from "../db";

import { ModalDelete } from "../../modals/ModalDelete";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { sendEvent } from "../../helpers/tracking";
import { canEdit } from "../helpers/collaborator";

import { CollaboratorRole } from "../types/Collaborator";
import { VideoPublishStatus } from "../types/Video";

import { useStorage } from "../contexts/StorageContext";
import { UserMeta } from "../../types/UserProfile";

import { Dropdown } from "../../components/Dropdown";
import { TooltipArea } from "../../components/Tooltip";
import { Button } from "../../components/Button";
import { ActionButton } from "../../components/ActionButton";
import { DisableUI } from "./DisableUI";

import { deleteVideo } from "../actions/deleteVideo";
import { duplicateVideo as duplicateVideoRequest } from "../../VideoEditor/actions/duplicateVideo";
import { customToast } from "../../components/customToast";

import { ReactComponent as SmallArrowIcon } from "../../assets/icons/SmallArrow.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/icons/Duplicate.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/Trash.svg";
import { theme } from "../../themes/variables";
import { useSharing } from "../contexts/SharingContext";

export function ThemiControlsDropdown(props: { users: UserMeta[] }) {
  const {
    sharingApi: { onPublish },
  } = useSharing();
  const [enabled, setEnabled] = useState<boolean>(false);
  const [opened, setOppened] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const {
    video,
    api: { getLastEvent },
  } = useStorage();

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const onConfirmDelete = useCallback(async () => {
    if (video) {
      try {
        await deleteVideo(video.uuid);
      } catch (error: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      } finally {
        history.push("/themi");
      }
    }
  }, [t, video, history]);

  const duplicateVideo = useCallback(async () => {
    if (video) {
      try {
        const response = await duplicateVideoRequest({
          uuid: video.uuid,
        });

        customToast.success(
          t("video-editor.header.dropdown.duplicate-success")
        );

        sendEvent("Duplicated Themi", {
          themiId: response.data.uuid,
        });

        history.push(`/workspace/draft`);
      } catch (error: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    }
  }, [t, video, history]);

  useEffect(() => {
    const getIsEnabled = async () => {
      const lastEvent = await getLastEvent();
      const isEnabled = !!lastEvent;

      if (isEnabled) {
        setEnabled(isEnabled);

        if (subscription) {
          subscription.unsubscribe();
        }
      }
    };

    const videoUpdatesObservable = liveQuery(() =>
      db.video_state_updates.where("video_id").equals(video!.uuid).toArray()
    );

    const subscription = videoUpdatesObservable.subscribe(async (updates) => {
      getIsEnabled();
    });

    getIsEnabled();

    return () => {
      subscription.unsubscribe();
    };
  }, [getLastEvent, video]);

  return (
    <>
      <Dropdown
        disabled
        opened={opened}
        css={css`
          right: unset;
          right: ${i18n.language === "de" ? "-50" : "0"}px;
          top: calc(100% + 8px);
        `}
        containerStyle={{
          width: "100%",
          justifyContent: "flex-end",
        }}
        id="themis-publish"
        options={
          <>
            <DisableUI show={!canEdit(video?.current_user_role) || !enabled}>
              <div>
                <TooltipArea
                  text={t("video-editor.header.dropdown.publish-tooltip")}
                  show={canEdit(video?.current_user_role) && enabled}
                  staticPosition
                  maxWidth={300}
                  offsetY={-12}
                  offsetX={4}
                >
                  <ActionButton
                    onClick={onPublish}
                    css={css`
                      padding: 0;
                      background: transparent;
                      color: ${theme.colors.dark};
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 28px;
                      :hover {
                        background: none;
                      }
                      svg {
                        color: ${theme.colors.dark};
                      }
                      :disabled {
                        color: #8e8e8e;
                      }
                    `}
                    disabled={!canEdit(video?.current_user_role) || !enabled}
                    titles={{
                      default: t("video-editor.header.dropdown.update"),
                      loading: t("video-editor.header.dropdown.updating"),
                      loaded: t("video-editor.header.dropdown.updated"),
                    }}
                    icon={
                      canEdit(video?.current_user_role) &&
                      props.users.length >= 1 &&
                      enabled && (
                        <div
                          css={css`
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background: #51ba6f;
                          `}
                        ></div>
                      )
                    }
                  ></ActionButton>
                </TooltipArea>
              </div>
            </DisableUI>

            <div>
              <TooltipArea
                show={
                  video?.current_user_role !== CollaboratorRole.OWNER ||
                  video.publish_status === VideoPublishStatus.PUBLISHED
                }
                text={t("video-editor.header.dropdown.delete-disabled")}
                staticPosition
                maxWidth={300}
                offsetY={-12}
                offsetX={4}
              >
                <DisableUI
                  show={
                    video?.current_user_role !== CollaboratorRole.OWNER ||
                    video.publish_status === VideoPublishStatus.PUBLISHED
                  }
                >
                  <Button
                    onClick={() => setDeleteModal(true)}
                    css={css`
                      padding: 0;
                      background: transparent;
                      color: ${theme.colors.dark};
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 28px;
                      white-space: nowrap;
                      :hover {
                        background: none;
                      }
                      svg {
                        color: ${theme.colors.dark};
                      }
                      :disabled {
                        background: unset;
                        color: #8e8e8e;
                      }
                    `}
                    disabled={
                      video?.current_user_role !== CollaboratorRole.OWNER ||
                      video.publish_status === VideoPublishStatus.PUBLISHED
                    }
                    icon={<TrashIcon width={15} height={15} />}
                  >
                    {t("video-editor.header.dropdown.delete")}
                  </Button>
                </DisableUI>
              </TooltipArea>
            </div>

            <TooltipArea
              staticPosition
              maxWidth={300}
              offsetY={-12}
              offsetX={4}
              text={t("video-editor.header.dropdown.duplicate-tooltip")}
            >
              <DisableUI show={!canEdit(video?.current_user_role)}>
                <Button
                  onClick={duplicateVideo}
                  css={css`
                    padding: 0;
                    background: transparent;
                    color: ${theme.colors.dark};
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 28px;
                    :hover {
                      background: none;
                    }
                    svg {
                      color: ${theme.colors.dark};
                    }
                    > div {
                      justify-content: left;
                    }
                    :disabled {
                      background: unset;
                      color: #8e8e8e;
                    }
                  `}
                  disabled={!canEdit(video?.current_user_role)}
                  icon={<DuplicateIcon width={15} height={15} />}
                >
                  {t("video-editor.header.dropdown.duplicate")}
                </Button>
              </DisableUI>
            </TooltipArea>
          </>
        }
      >
        <Button
          css={css`
            border: none;
            padding: 0;
            background-color: transparent;
            color: black;
            border-radius: 0;
            padding: 5px;
            margin-left: 19px;
            min-width: unset;
          `}
          hoverStyles={"none"}
          onClick={() => setOppened(!opened)}
        >
          <SmallArrowIcon />
        </Button>
      </Dropdown>

      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={onConfirmDelete}
        title={t("modal.delete.title")}
        description={t("video-editor.header.dropdown.delete-description")}
      />
    </>
  );
}
