import { css } from "styled-components/macro";
import { Avatar } from "../components/Avatar";
import { Dropdown, DropdownItem } from "./DropDown";
import { UserMeta } from "../../types/UserProfile";

type CollaboratorsProps = {
  users: UserMeta[];
  maxCollaborators: number;
  isHover?: boolean;
  isValueClickable?: boolean;
  className?: string;
  dynamicBorder?: boolean;
};

export function Collaborators(props: CollaboratorsProps) {
  const results =
    props.users.length > props.maxCollaborators
      ? props.users.slice(0, props.maxCollaborators)
      : props.users;

  const value = props.users.length - results.length;
  const newResults = props.users.slice(
    props.maxCollaborators,
    props.users.length
  );

  function getDropdownItem(el: UserMeta, showAvatar: boolean) {
    return (
      <DropdownItem
        key={el.id}
        css={css`
          ${css`
            :nth-child(4n-3) .avatar {
              border: 1px solid #a47aff;
            }
            :nth-child(4n-2) .avatar {
              border: 1px solid #f8b856;
            }
            :nth-child(4n-1) .avatar {
              border: 1px solid #e95b2e;
            }
            :nth-child(4n) .avatar {
              border: 1px solid #ffc39c;
            }
          `}
          align-items: ${showAvatar ? "flex-start" : "center"};
          ${!showAvatar &&
          css`
            justify-content: center;
            text-align: center;
          `}
        `}
      >
        {showAvatar && (
          <Avatar
            className="avatar"
            key={el.id}
            url={el.avatar?.image}
            name={`${el.first_name} ${el.last_name}`}
          />
        )}
        <p
          css={css`
            margin-left: ${showAvatar ? "13px" : "0"};
            align-self: center;
          `}
        >
          {el.first_name}&nbsp;{el.last_name}
        </p>
      </DropdownItem>
    );
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
      className={props.className}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          padding-right: 9px;
        `}
      >
        {results &&
          results.map((result, key) => {
            return (
              <div
                key={key}
                css={css`
                  .avatar {
                    margin-right: -9px;
                  }
                `}
              >
                {props.isHover ? (
                  <Dropdown onHover options={[getDropdownItem(result, false)]}>
                    <Avatar
                      dynamicBorder={props.dynamicBorder}
                      key={result.id}
                      url={result.avatar?.image}
                      className="avatar"
                      css={css`
                        position: relative;
                      `}
                      size={28}
                      name={`${result.first_name} ${result.last_name}`}
                    />
                  </Dropdown>
                ) : (
                  <Avatar
                    dynamicBorder={props.dynamicBorder}
                    key={result.id}
                    url={result.avatar?.image}
                    className="avatar"
                    css={css`
                      position: relative;
                    `}
                    size={28}
                    name={`${result.first_name} ${result.last_name}`}
                  />
                )}
              </div>
            );
          })}
      </div>
      {props.users.length > props.maxCollaborators &&
        (props.isValueClickable ? (
          <Dropdown
            onHover
            options={newResults.map((el) => getDropdownItem(el, true))}
            css={css`
              margin-left: 10px;
              align-self: center;
              .dropdown-container {
                left: -100px;
                :after {
                  right: -70px;
                }
              }
            `}
          >
            <div
              css={css`
                position: relative;
                width: 28px;
                height: 28px;
                background-color: transparent;
                border-radius: 50%;
                text-align: center;
                line-height: 28px;
                font-size: 12px;
                background: #e5e1ee;
                color: #a47aff;
                z-index: 999;
                cursor: default;
              `}
            >
              +{value}
            </div>
          </Dropdown>
        ) : (
          <div
            css={css`
              position: relative;
              width: 28px;
              height: 28px;
              background-color: #ffffff;
              border-radius: 50%;
              text-align: center;
              line-height: 28px;
              font-size: 14px;
              border: 2px solid #ffffff;
              color: #626262;
              align-self: center;
              margin-top: 2px;
              cursor: default;
            `}
          >
            {value}+
          </div>
        ))}
    </div>
  );
}
