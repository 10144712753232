import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { customToast } from "../../components/customToast";
import { InputType } from "../../components/InputComponent";
import { InputField } from "../fields/InputField";
import { Column, Row, Spacing } from "../../helpers/layout";
import { login } from "../../actions/auth/login";
import { useAuth } from "../../contexts/UserContext";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { USER_CONFIRM_EMAIL } from "../../actions/auth/resendConfirmationEmail";
import { sendEvent } from "../../helpers/tracking";
import { Button } from "../../components/Button";
import { ReactComponent as Google } from "../../assets/icons/Google.svg";
import { CheckboxField } from "../fields/CheckboxField";
import { useCheckMobile } from "../../hooks/useCheckMobile";

interface FormValues {
  username: string;
  password: string;
}

export function LoginForm() {
  const { t } = useTranslation();
  const { authenticate } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useCheckMobile();

  return (
    <Formik<FormValues>
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().email().required(),
        password: Yup.string().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);

          const response = await login(values.username, values.password);

          sendEvent("Login");

          if (!response.data.email_confirmed) {
            localStorage.setItem(USER_CONFIRM_EMAIL, values.username);

            history.push("/verify-email");
          } else {
            authenticate(response.data.token, response.data.userprofile);
          }
        } catch (error: any) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form
          noValidate
          css={css`
            ${isMobile &&
            css`
              display: flex;
              flex-direction: column;
              height: 100%;
            `}
          `}
        >
          <Column
            gutter={Spacing.l}
            css={css`
              margin-top: 50px;

              ${isMobile &&
              css`
                margin-top: 40px;
              `};
            `}
          >
            <InputField
              type={InputType.TEXT}
              name="username"
              placeholder={t("auth.form.email")}
              hideTooltip
              errorBottom
            />
            <InputField
              type={InputType.PASSWORD}
              name="password"
              placeholder={t("auth.form.password")}
              hideTooltip
              errorBottom
            />
            <Row
              css={css`
                justify-content: space-between;
                align-items: center;
              `}
            >
              <CheckboxField name="checkbox" hideTooltip errorBottom>
                <div
                  css={css`
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 120%;
                    color: #252424;
                    margin-top: 2px;
                  `}
                >
                  {t("auth.login.remember")}
                </div>
              </CheckboxField>

              <Link
                css={css`
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 120%;
                  text-decoration-line: none;
                  color: #e95b2e;
                `}
                to={`/reset-password${location.search}`}
              >
                {t("auth.login.forgot-password")}
              </Link>
            </Row>
          </Column>

          <Button
            isSubmitting={isSubmitting}
            disabled={!isValid}
            type="submit"
            background="#e95b2e"
            hoverStyles="none"
            css={css`
              width: 100%;
              line-height: 28px;
              margin: 40px 0 0 0;
              color: #fff;
              font-weight: 700;
              font-size: 16px;

              ${isMobile &&
              css`
                margin: auto 0 0 0;
                font-size: 20px;
                padding: 10px 0;
              `};
            `}
          >
            {t("auth.login.login")}
          </Button>
          <Button
            type="submit"
            background="#fff"
            css={css`
              display: none; // TODO: Google integration is not ready yet
              width: 100%;
              line-height: 28px;
              margin-top: 19px;
              color: #252424;
              border: 1px solid #252424;
            `}
            hoverStyles="none"
            icon={<Google />}
          >
            {"Login with Google"}
          </Button>

          {!isMobile && (
            <Link
              css={css`
                display: flex;
                justify-content: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 120%;
                margin-top: 35px;
                color: #252424;
                text-decoration-line: none;
              `}
              to={`/register${location.search}`}
            >
              {t("auth.login.no-account")}&nbsp;
              <p
                css={css`
                  font-weight: bold;
                  color: #e95b2e;
                `}
              >
                {t("auth.login.its-free")}
              </p>
            </Link>
          )}
        </Form>
      )}
    </Formik>
  );
}
