import React, { useState, useRef } from "react";
import { css } from "styled-components/macro";
import { useClickOutside } from "../../hooks/useClickOutside";
import ArrowIcon from "../../assets/icons/DownArrow.svg";

type ElementDropdownProps = {
  children: React.ReactNode | React.ReactNode[] | string | null;
  className?: string;
  onSelect: (value: string) => void;
  options: Array<{
    value: string;
    label: string;
  }>;
};

type ElementDropdownItemProps = {
  children: React.ReactNode | React.ReactNode[] | string | null;
  className?: string;
  onClick?: () => void;
};

export function ElementDropdownItem(props: ElementDropdownItemProps) {
  return (
    <div
      css={css`
        padding: 3px;
        border-bottom: none;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        font-size: 13px;
        margin: 5px 0;
        min-width: 0px;

        :hover {
          background-color: #e95b2e;
          color: #fff;
        }
      `}
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}

export function ElementDropdown(props: ElementDropdownProps) {
  const [opened, setOpened] = useState(false);
  const collaboratersDropDown = useRef(null);

  useClickOutside(collaboratersDropDown, () => {
    setOpened(false);
  });

  return (
    <div
      css={css`
        position: relative;

        height: 30px;
        border: none;
        background-color: white;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        padding-left: 6px;
        font-weight: 500;
        font-size: 16px;
      `}
      className={props.className}
      ref={collaboratersDropDown}
    >
      <div
        onClick={() => {
          setOpened((opened) => !opened);
        }}
        css={css`
          position: relative;
          cursor: pointer;
          padding-right: 23px;

          :before {
            position: absolute;
            right: 7px;
            top: 2px;
            width: 10px;
            height: 25px;
            content: "";
            background-image: url(${ArrowIcon});
            background-repeat: no-repeat;
            transform: ${opened ? "rotate(180deg)" : "rotate(0deg)"};
            background-position: center;
          }
        `}
      >
        {props.children}
      </div>
      <div
        css={css`
          position: absolute;
          background: #ffffff;
          top: 100%;
          left: 0;
          margin-top: 2px;
          box-sizing: border-box;
          transition: visibility 0.3s linear, opacity 0.3s linear;
          z-index: 1;
          border-radius: 9px;
          border: none;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          color: #000;
          width: 90px;
          visibility: hidden;
          opacity: 0;
          border-radius: 7px;

          ${opened &&
          css`
            visibility: visible;
            opacity: 1;
          `}
        `}
      >
        {props.options.map((option) => {
          return (
            <ElementDropdownItem
              key={option.value}
              onClick={() => {
                props.onSelect(option.value);
                setOpened(false);
              }}
            >
              {option.label}
            </ElementDropdownItem>
          );
        })}
      </div>
    </div>
  );
}
