import React, { useState, useRef } from "react";
import { css } from "styled-components/macro";
import { useClickOutside } from "../hooks/useClickOutside";
import TriangleIcon from "../assets/icons/Triangle_Collaborators.svg";

export function DropdownItem(props: {
  children: React.ReactNode | React.ReactNode[] | string | null;
  className?: string;
}) {
  return (
    <div
      css={css`
        padding: 3px;
        border-bottom: none;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        font-size: 13px;
        margin-left: 12px;
        margin-right: 12px;
        min-width: 0px;

        :first-of-type {
          border-radius: 10px 10px 0 0;
          margin-top: 10px;
        }
        :last-of-type {
          border-radius: 0 0 10px 10px;
          margin-bottom: 10px;
        }
      `}
      className={props.className}
    >
      {props.children}
    </div>
  );
}

export function Dropdown(props: {
  onHover?: boolean;
  children?: React.ReactNode | React.ReactNode[] | string | null;
  options?: React.ReactNode | React.ReactNode[] | string | null;
  className?: string;
}) {
  const [opened, setOpened] = useState(false);
  const collaboratersDropDown = useRef(null);

  useClickOutside(collaboratersDropDown, () => {
    setOpened(false);
  });

  return (
    <div
      css={css`
        position: relative;
      `}
      className={props.className}
      ref={collaboratersDropDown}
    >
      <div
        onClick={
          !props.onHover
            ? () => {
                setOpened((opened) => !opened);
              }
            : undefined
        }
        onMouseEnter={
          props.onHover
            ? () => {
                setOpened((opened) => !opened);
              }
            : undefined
        }
        onMouseLeave={
          props.onHover
            ? () => {
                setOpened((opened) => !opened);
              }
            : undefined
        }
      >
        {props.children}
      </div>
      <div
        className="dropdown-container"
        css={css`
          position: absolute;
          background: #252424;
          top: 100%;
          left: ${props.onHover ? "-60px" : "-85px"};
          margin-left: 50%;
          margin-top: 20px;
          box-sizing: border-box;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.3s linear, opacity 0.3s linear;
          z-index: 1;
          border-radius: 9px;
          border: none;
          color: #ffff;
          min-width: ${props.onHover ? "130px" : "170px"};

          ${opened &&
          css`
            visibility: visible;
            opacity: 1;
          `}
          ::after {
            display: block;
            position: absolute;
            width: 28px;
            height: 18px;
            top: -12px;
            right: 0px;
            left: 0;
            margin: auto;
            background-image: url(${TriangleIcon});
            background-repeat: no-repeat;
            content: "";
          }
        `}
      >
        {props.options}
      </div>
    </div>
  );
}
