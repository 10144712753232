import React, { useCallback, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import {
  getVideos,
  QueryVideoStatus,
  QueryVideoSort,
} from "../actions/themi/getVideos";
import { Video } from "../VideoEditor/types/Video";
import { pageLoading } from "../helpers/pageLoading";
import { Headers, Row } from "../helpers/layout";
import { PageTitle } from "../components/PageTitle";
import { PageLayout } from "../components/PageLayout";
import { Button } from "../components/Button";
import { UpgradePro } from "../components/UpgradePro";
import { FeedbackWidget } from "../components/FeedbackWidget";
import { Pagination } from "../components/Pagination";
import { NoResult } from "../businessComponents/Workspace/NoResult";
import {
  Navigation,
  NavigationType,
} from "../businessComponents/Workspace/Navigation";
import { Card, CardModeType } from "../businessComponents/Workspace/Card";
import {
  Filter,
  FilterItemsType,
  FilterItemOption,
} from "../businessComponents/Workspace/Filter";

import { ReactComponent as PixelIcon } from "../assets/icons/Pixel.svg";
import { ReactComponent as ArchiveIcon } from "../assets/icons/Archive2.svg";
import { ReactComponent as NavWorkspace } from "../assets/icons/NavWorkspace.svg";
import { usePagination } from "../hooks/usePagination";

function getSafeParams(params: { [key: string]: string }) {
  const urlParams = new URLSearchParams();

  if (typeof params === "object") {
    for (const [key, value] of Object.entries(params)) {
      urlParams.set(key, value);
    }
  }

  return urlParams.toString();
}

export function WorkspacePage() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [isArchiveActive, setIsArchiveActive] = useState(false);
  const filterQueryParam = new URLSearchParams(window.location.search).get(
    "filter"
  );
  const sortQueryParam = new URLSearchParams(window.location.search).get(
    "sort"
  );

  const {
    response: { data: videos, error: videosError, refetch: refetchVideos },
    pagination: { onChose, onNext, onPrev, count, page },
  } = usePagination<
    {
      count: number;
      next: null | string;
      previous: null | string;
      results: Video[];
    },
    {
      status: QueryVideoStatus;
      sort: QueryVideoSort;
    }
  >(getVideos, {
    internalArgs: {
      status:
        filterQueryParam === "SHARED"
          ? "SHARED"
          : filterQueryParam === "MY_THEMIS"
          ? "MY_THEMIS"
          : isArchiveActive
          ? "ARCHIVED"
          : "PUBLISHED",
      sort: sortQueryParam === "OLD" ? "OLD" : "NEW",
    },
    limit: 9,
  });

  const onChangeFilter = useCallback(
    (option: FilterItemOption, sectionId: string) => {
      if (sectionId === "filter") {
        const params: { [key: string]: string } = {};
        if (sortQueryParam) {
          params.sort = sortQueryParam;
        }
        if (filterQueryParam !== option.id) {
          params.filter = option.id;
        }
        history.push({
          search: getSafeParams(params),
        });
      } else if (sectionId === "sort") {
        const params: { [key: string]: string } = {};
        if (filterQueryParam) {
          params.filter = filterQueryParam;
        }
        if (sortQueryParam !== option.id) {
          params.sort = option.id;
        }
        history.push({
          search: getSafeParams(params),
        });
      }
    },
    [history, sortQueryParam, filterQueryParam]
  );

  if (!videos || videosError) {
    return pageLoading(videosError);
  }

  const menuItems: NavigationType[] = [
    {
      title: t("video-editor.workspace.library"),
      icon: <NavWorkspace width={15} />,
      onClick: () => {
        history.push("/workspace");
      },
      isActive: location.pathname === "/workspace",
    },
    {
      title: t("video-editor.workspace.drafts"),
      icon: <PixelIcon width={15} />,
      onClick: () => {
        history.push("/workspace/draft");
      },
      isActive: location.pathname === "/workspace/draft",
    },
  ];

  const filterItems: FilterItemsType[] = [
    {
      id: "filter",
      name: t("video-editor.workspace.filter-by"),
      options: [
        {
          id: "MY_THEMIS",
          name: t("video-editor.workspace.my-themis"),
          isActive: filterQueryParam === "MY_THEMIS",
        },
        {
          id: "SHARED",
          name: t("video-editor.workspace.sent-to-me"),
          isActive: filterQueryParam === "SHARED",
        },
      ],
    },
    {
      id: "sort",
      name: t("video-editor.workspace.sort-by"),
      options: [
        {
          id: "NEW",
          name: t("video-editor.workspace.newest"),
          isActive: sortQueryParam === "NEW",
        },
        {
          id: "OLD",
          name: t("video-editor.workspace.oldest"),
          isActive: sortQueryParam === "OLD",
        },
      ],
    },
  ];

  return (
    <PageLayout>
      <PageTitle title={t("video-editor.workspace.title")} />

      <div
        css={css`
          margin-top: 60px;
          width: 100%;
          position: relative;
          display: flex;
        `}
      >
        {menuItems.map((item, key) => {
          return <Navigation {...item} key={key} />;
        })}
        <div
          css={css`
            height: 1px;
            background: #cdcdcd;
            position: absolute;
            left: -48px;
            bottom: -1px;
            width: calc(100% + 96px);
            z-index: 0;
          `}
        ></div>
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 34px;
        `}
      >
        <Headers.H4
          css={css`
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
          `}
        >
          {isArchiveActive
            ? t("video-editor.workspace.archive")
            : t("video-editor.workspace.library")}
        </Headers.H4>

        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Button
            css={css`
              background: ${isArchiveActive ? "#F9F9F9" : "#ffffff"};
              border: 1px solid #cdcdcd;
              border-radius: 9px;
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              color: #000000;
              padding: 10px;
              margin-right: 34px;
              :hover {
                background-color: rgba(0, 0, 0, 0.025);
              }
              :active {
                background-color: rgba(0, 0, 0, 0.05);
              }
            `}
            icon={<ArchiveIcon />}
            onClick={() => {
              setIsArchiveActive(!isArchiveActive);
              history.push({
                search: getSafeParams({}),
              });
            }}
          >
            {t("video-editor.workspace.archive")}
          </Button>

          <Filter
            items={filterItems}
            onChange={onChangeFilter}
            disabled={isArchiveActive}
          />
        </div>
      </div>

      <div
        css={css`
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #616161;
          margin-top: 19px;
        `}
      >
        {isArchiveActive
          ? t("video-editor.workspace.archive-description")
          : t("video-editor.workspace.library-free-description")}
      </div>

      {videos.results.length === 0 && <NoResult />}

      {videos.results.length > 0 && (
        <>
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(auto-fill, 330px);
              grid-column-gap: 40px;
              margin-top: 45px;
            `}
          >
            {videos.results.map((video, idx) => {
              if (videos.results.length > 2 && idx === 2) {
                return (
                  <>
                    <UpgradePro location="Workspace Page" />
                    <Card
                      key={video.uuid}
                      video={video}
                      videoStatus={video.publish_status}
                      refetch={refetchVideos}
                      onClick={() => history.push(`/video/${video.uuid}`)}
                      mode={CardModeType.VIEW}
                    />
                  </>
                );
              }
              return (
                <Card
                  key={video.uuid}
                  video={video}
                  videoStatus={video.publish_status}
                  refetch={refetchVideos}
                  onClick={() => history.push(`/video/${video.uuid}`)}
                  mode={CardModeType.VIEW}
                />
              );
            })}
          </div>

          <Row align="center" justify="center">
            <Pagination
              css={css`
                margin-top: 40px;
                margin-bottom: 10px;
              `}
              arrows
              currentItemIndex={page}
              itemsCount={count}
              onChose={onChose}
              onNext={onNext}
              onPrev={onPrev}
            />
          </Row>
        </>
      )}

      <FeedbackWidget />
    </PageLayout>
  );
}
