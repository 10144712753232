import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { calculateRelativeSize } from "../helpers/renderer";

export const fontSizes = [
  8, 10, 12, 13, 14, 15, 16, 18, 24, 26, 30, 32, 36, 42, 48, 64, 80, 144,
] as const;

const fontString = fontSizes
  .map((size) => `${size}=${calculateRelativeSize(size)}`)
  .join(" ");

export function RichTextEditor(props: {
  html: string;
  onChange: (event: any) => void;
  static?: boolean;
  style?: React.CSSProperties;
  tagName?: string;
  onInit?: () => void;
  id: string;
}) {
  const editorRef = useRef<Editor>(null);

  if (props.static) {
    return <div dangerouslySetInnerHTML={{ __html: props.html }} />;
  }

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      onInit={(_, editor) => {
        editorRef.current?.setState(editor);
        if (props.onInit) props.onInit();
      }}
      plugins={["lists", "link"]}
      init={{
        menubar: false,
        inline: true,
        height: "100%",
        paste_remove_styles_if_webkit: true,
        paste_merge_formats: true,
        width: "100%",
        toolbar:
          "forecolor | fontfamily fontsize | bold italic underline bullist numlist | alignleft aligncenter alignright | link",
        fixed_toolbar_container: ".editor-toolbar",
        font_size_formats: fontString,
        forced_root_block: "p",
        forced_root_block_attrs: {
          style: `font-size: ${calculateRelativeSize(16)}; font-family: Arial;`,
        },
        link_title: false,
        link_default_target: "_blank",
        init_instance_callback: function (editor) {
          editor.fire("focus");
          editor.selection.select(editor.getBody(), true);
        },
        setup: function (editor) {
          let fontSize = "";
          let fontFamily = "";
          editor.on("blur", function (e) {
            e.stopImmediatePropagation();
            return false;
          });
          // Select all text when clicking three times
          editor.on("click", function (e) {
            if (e.detail >= 3) {
              e.preventDefault();
              e.stopPropagation();
              editor.selection.select(editor.getBody(), true);
            }
          });
          editor.on("ExecCommand", function (e) {
            if (
              e.command === "InsertUnorderedList" ||
              e.command === "InsertOrderedList"
            ) {
              let node = e.target.selection.getNode();
              /* To not get pixels and default font for tinymce let's set default size and font */
              let fontSizeAllias = fontSize || calculateRelativeSize(10);
              let fontFamilyAllias = fontFamily || "Arial";

              if (fontSizeAllias)
                editor.dom.setStyle(node, "font-size", fontSizeAllias);
              if (fontFamilyAllias)
                editor.dom.setStyle(node, "font-family", fontFamilyAllias);
              if (node.children) {
                for (let i = 0; i < node.children.length; i++) {
                  if (fontSizeAllias)
                    editor.dom.setStyle(
                      node.children[i],
                      "font-size",
                      fontSizeAllias
                    );
                  if (fontFamilyAllias)
                    editor.dom.setStyle(
                      node.children[i],
                      "font-family",
                      fontFamilyAllias
                    );
                }
              }
            }
          });
          editor.on("BeforeExecCommand", function (e) {
            if (
              e.command === "InsertUnorderedList" ||
              e.command === "InsertOrderedList"
            ) {
              let node = e.target.selection.getNode();

              fontSize = node.style.fontSize;
              fontFamily = node.style.fontFamily;

              /* if node is tinymce body, then searching for kids.
              Font-sizes and font-family always apply to the whole container,
              so there is no need to worry if somewhere they are different
              */
              if (node.children && (!fontSize || !fontFamily)) {
                for (let i = 0; i < node.children.length; i++) {
                  if (fontSize || fontFamily) break;
                  if (node.children[i].style.fontSize)
                    fontSize = node.children[i].style.fontSize;
                  if (node.children[i].style.fontFamily)
                    fontFamily = node.children[i].style.fontFamily;
                }
              }
              /* Also try to get styles from parent if still not found */
              if (!fontSize) fontSize = node.parentNode.style.fontSize;
              if (!fontFamily) fontFamily = node.parentNode.style.fontFamily;
            }
            if (e.command === "FontSize" || e.command === "FontName") {
              e.preventDefault();
              const val = e.value;
              e.target.dom.setStyle(
                [
                  ...e.target.dom.select("p"),
                  ...e.target.dom.select("h1"),
                  ...e.target.dom.select("h2"),
                  ...e.target.dom.select("h3"),
                  ...e.target.dom.select("h4"),
                  ...e.target.dom.select("h5"),
                  ...e.target.dom.select("h6"),
                  ...e.target.dom.select("ul"),
                  ...e.target.dom.select("ol"),
                  ...e.target.dom.select("li"),
                  ...e.target.dom.select("div"),
                  ...e.target.dom.select("span"),
                ],
                e.command === "FontSize" ? "font-size" : "font-family",
                val
              );
            }
          });
        },
      }}
      value={props.html}
      onEditorChange={props.onChange}
      id={props.id}
      onPaste={(e) => {
        if (!e.clipboardData) return;

        const copiedText = e.clipboardData.getData("text/plain");
        let hasJson = false;

        try {
          const jsonContent = JSON.parse(copiedText);

          if (jsonContent) {
            hasJson = true;
          }
        } catch (e) {
          hasJson = false;
        }

        if (hasJson) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      }}
    />
  );
}
