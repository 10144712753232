import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { css } from "styled-components/macro";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { UserProvider } from "./contexts/UserContext";
// import { CheckVersionUpdate } from "./components/CheckVersionUpdate";
import { EnterpriseProvider } from "./contexts/EnterpriseContext";
import { ThemiRouter } from "./ThemiRouter";
import { sendEvent } from "./helpers/tracking";
import { SplashPage } from "./pages/SplashPage";
import { WorkspaceProvider } from "./contexts/WorkspaceContext";

const enableSplashScreen = false;

function App() {
  useEffect(() => {
    sendEvent("Session Started");
  }, []);

  const showSplashScreen =
    enableSplashScreen && localStorage.getItem("hideSplashScreen") !== "true";

  useEffect(() => {
    if (!enableSplashScreen) return;

    if (document.location.search.includes("hide_splash")) {
      localStorage.setItem("hideSplashScreen", "true");
      document.location.search = "";
    }
  }, []);

  return (
    <Suspense fallback="loading">
      <EnterpriseProvider>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          pauseOnHover={false}
          css={css`
            width: auto;
            svg {
              color: #242424;
            }
          `}
        />

        <UserProvider>
          <WorkspaceProvider>
            <Router>
              {showSplashScreen ? <SplashPage /> : <ThemiRouter />}
            </Router>
          </WorkspaceProvider>
        </UserProvider>
      </EnterpriseProvider>
    </Suspense>
  );
}

export default App;
