import { Route, Switch, useLocation } from "react-router-dom";
import { LoginPage } from "./pages/Auth/Login";
import { RegistrationPage } from "./pages/Auth/Registration";
import { ResetPasswordPage } from "./pages/Auth/ResetPassword";
import { SetPasswordPage } from "./pages/Auth/SetPassword";
import { PrivateRoute, PublicRoute } from "./helpers/router";
import { DashboardPageMiddleware } from "./pages/Dashboard";
import { VideoEditor } from "./VideoEditor/Editor";
import { MyThemiDashboardPage } from "./pages/MyThemiDashboard";
import { WorkspacePage } from "./pages/WorkspacePage";
import { WorkspaceDraftPage } from "./pages/WorkspaceDraftPage";
import { VideosPage } from "./pages/VideosPage";
import { MobileFallbackPage } from "./pages/mobileFallbackPage";
import { SearchPage } from "./pages/SearchPage";
import { useCheckMobile } from "./hooks/useCheckMobile";
import { EmbedPreview } from "./VideoEditor/EmbedPreview";
import { ThemiPreview } from "./VideoEditor/ThemiPreview";
import RouterWrapper from "./helpers/RouterWrapper";
import { VideoPreviewMiddleware } from "./VideoEditor/VideoPreview";
import { ThemiBuilder } from "./ThemiBuilder/ThemiBuilder";
import { CheckEmailPage } from "./pages/Auth/CheckEmail";
import { VerifyEmailPage } from "./pages/Auth/VerifyEmail";
import { RolePage } from "./pages/Auth/Role";
import { MobileStartPage } from "./pages/Auth/MobileStartPage";
import { ProfilePage } from "./pages/ProfilePage";
import { WorkspaceUserManagment } from "./pages/Workspace/UserManagment";
import { WorkspaceSettingsPage } from "./pages/WorkspaceSettingsPage";
import { WorkspaceFeaturePage } from "./pages/WorkspaceFeaturesPage";
import { InternetExplorerFallbackPage } from "./pages/InternetExplorerFallbackPage";

const authPages = [
  "/get-started",
  "/login",
  "/register",
  "/verify-email",
  "/check-email",
  "/reset-password",
  "role",
];

export const ThemiRouter = () => {
  const { isMobile } = useCheckMobile();
  const location = useLocation();
  const isAuthPage =
    authPages.find((page) => location.pathname.match(page)) ||
    location.pathname === "/";

  return (
    <Switch>
      <RouterWrapper>
        <Route path="/" exact component={DashboardPageMiddleware} />
        <Route path="/get-started" exact component={MobileStartPage} />
        <Route
          path="/explorer-fallback"
          exact
          component={InternetExplorerFallbackPage}
        />
        <PublicRoute path="/login" exact component={LoginPage} />
        <PublicRoute path="/register" exact component={RegistrationPage} />
        <PublicRoute path="/verify-email" exact component={VerifyEmailPage} />
        <PublicRoute path="/check-email" exact component={CheckEmailPage} />
        <PublicRoute
          path="/reset-password"
          exact
          component={ResetPasswordPage}
        />
        <PublicRoute
          path="/reset-password/:uuid/"
          exact
          component={SetPasswordPage}
        />

        <Route
          path="/video/:videoId"
          exact
          component={VideoPreviewMiddleware}
        />
        <PrivateRoute path="/role" exact component={RolePage} />
        {isMobile && !isAuthPage ? (
          <MobileFallbackPage />
        ) : (
          <>
            <PrivateRoute
              path="/embed/:videoId"
              exact
              component={EmbedPreview}
            />

            <PrivateRoute path="/themi/:status/" exact component={VideosPage} />
            <PrivateRoute
              path="/themi"
              exact
              component={MyThemiDashboardPage}
            />

            <PrivateRoute path="/workspace" exact component={WorkspacePage} />

            <PrivateRoute
              path="/workspace/:workspaceId/user-management/"
              exact
              component={WorkspaceUserManagment}
            />

            <PrivateRoute
              path="/workspace/draft"
              exact
              component={WorkspaceDraftPage}
            />

            <PrivateRoute
              path="/workspace-settings"
              exact
              component={WorkspaceSettingsPage}
            />

            <PrivateRoute
              path="/workspace-settings/feature-access"
              exact
              component={WorkspaceFeaturePage}
            />

            <PrivateRoute path="/account/me" exact component={ProfilePage} />
            <PrivateRoute
              path="/video-editor/:videoId"
              exact
              component={VideoEditor}
            />
            <PrivateRoute
              path="/video-preview/:videoId"
              exact
              component={ThemiPreview}
            />
            <PrivateRoute
              path="/inspiration/:videoId"
              exact
              component={ThemiPreview}
            />
            <PrivateRoute
              path="/themi-builder"
              exact
              component={ThemiBuilder}
            />
            <PrivateRoute path="/search" exact component={SearchPage} />
          </>
        )}
      </RouterWrapper>
    </Switch>
  );
};
