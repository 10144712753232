import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";

import { ReactComponent as MicIcon } from "../VideoEditor/assets/icons/MicIcon.svg";
import { ReactComponent as VideoIcon } from "../VideoEditor/assets/icons/VideoIcon.svg";
import { ReactComponent as RecordIcon } from "../VideoEditor/assets/icons/RecordCircle.svg";
import { ReactComponent as LabelPro } from "../assets/icons/LabelPro.svg";
import recordingPerson from "../VideoEditor/assets/images/recording-themi.png";
import recordingAudio from "../VideoEditor/assets/images/recording-audio.png";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../components/Modal";
import { Headers } from "../VideoEditor/helpers/headers";
import {
  RecordingType,
  useRecording,
} from "../VideoEditor/contexts/RecordingContext";
import { useAuth } from "../contexts/UserContext";
import { Video } from "../VideoEditor/types/Video";
import {
  calculateThemiAudioElements,
  calculateThemiVideoElements,
} from "../VideoEditor/helpers/video";
import { Button } from "../components/Button";
import { sendEvent } from "../helpers/tracking";

type Instructions = Array<{
  title: string;
  description: string;
}>;

export function ModalRecord(props: {
  isOpen: boolean;
  onClose: () => void;
  video: Video | null;
}) {
  const { t } = useTranslation();
  const { userprofile } = useAuth();
  const [type, setType] = useState<RecordingType | null>(null);
  const { hasPermission, askRecordingPermission, record } = useRecording();

  useEffect(() => {
    setType(null);
  }, [props.isOpen]);

  useEffect(() => {
    // ask for browser permission to use camera and microphone
    if (props.isOpen && type && !hasPermission) {
      askRecordingPermission(type);
    }
  }, [type, props.isOpen, askRecordingPermission, hasPermission]);

  const options: Array<{
    type: RecordingType;
    icon: JSX.Element;
    title: string;
    used: number;
    limit: number | null;
  }> = [
    {
      type: "audio",
      icon: <MicIcon />,
      title: t("record.modal.audio.title"),
      used: calculateThemiAudioElements(props.video?.schema.schema.elements),
      limit: userprofile?.restrictions.audio || null,
    },
    {
      type: "video",
      icon: <VideoIcon />,
      title: t("record.modal.video.title"),
      used: calculateThemiVideoElements(props.video?.schema.schema.elements),
      limit: userprofile?.restrictions.video || null,
    },
  ];

  const videoInstructions: Instructions = [
    {
      title: t("record.modal.instructions.surroundings.title"),
      description: t("record.modal.instructions.surroundings.description"),
    },
    {
      title: t("record.modal.instructions.short-recordings.title"),
      description: t("record.modal.instructions.short-recordings.description"),
    },
  ];

  const audioInstructions: Instructions = [
    {
      title: t("record.modal.instructions.echo.title"),
      description: t("record.modal.instructions.echo.description"),
    },
    {
      title: t("record.modal.instructions.short-recordings.title"),
      description: t("record.modal.instructions.short-recordings.description"),
    },
  ];

  const instuctions = type === "video" ? videoInstructions : audioInstructions;

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{ maxWidth: "650px", padding: 0 }}
    >
      {type ? (
        <>
          <ModalHeader
            closeIcon
            onClose={props.onClose}
            css={css`
              padding: 14px 21px 13px 32px;
            `}
          >
            {type === "video" ? (
              <div
                css={css`
                  padding-top: 30px;
                  text-align: center;
                `}
              >
                <img
                  src={recordingPerson}
                  css={css`
                    position: relative;
                    width: 100px;
                    right: -5px;
                  `}
                  alt=""
                />

                <Headers.H2
                  css={css`
                    font-weight: 500;
                    font-size: 20px;
                  `}
                >
                  {t("record.modal.instructions.title")}
                </Headers.H2>
              </div>
            ) : (
              <div>
                <Headers.H2>{t("record.modal.instructions.title")}</Headers.H2>
                <p
                  css={css`
                    color: #616161;
                  `}
                >
                  {t("record.modal.instructions.description")}
                </p>
                <div
                  css={css`
                    text-align: center;
                    margin-top: 45px;
                  `}
                >
                  <img src={recordingAudio} width={135} alt="" />
                </div>
              </div>
            )}
          </ModalHeader>
          <ModalBody
            css={css`
              padding: 30px 35px;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
              `}
            >
              {instuctions.map((instruction) => {
                return (
                  <div
                    key={instruction.title}
                    css={css`
                      flex: 0 0 255px;
                      min-width: 0;
                    `}
                  >
                    <Headers.H3
                      css={css`
                        font-weight: 500;
                        font-size: 16px;
                      `}
                    >
                      {instruction.title}
                    </Headers.H3>
                    <p
                      css={css`
                        font-weight: 400;
                        font-size: 14px;
                        color: #616161;
                      `}
                    >
                      {instruction.description}
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              css={css`
                margin-top: 25px;
                text-align: center;
              `}
            >
              <button
                css={css`
                  border: 1px solid #eae8e8;
                  border-radius: 43px;
                  background: #fff;
                  cursor: pointer;
                  margin: 15px 0;
                  padding: 15px 23px;
                  box-shadow: 0px 8px 14px #fff;

                  transition: all 0.2s ease-in-out;

                  ${hasPermission &&
                  css`
                    &:hover {
                      box-shadow: 0px 8px 24px #e8e8e8;
                      color: #e95b2e;
                    }
                  `}
                `}
                disabled={!hasPermission}
                onClick={(e) => {
                  e.stopPropagation();

                  record(type);
                  props.onClose();
                }}
              >
                <RecordIcon
                  css={css`
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;
                  `}
                  width={19}
                />
                <span
                  css={css`
                    display: inline-block;
                    vertical-align: middle;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                  `}
                >
                  {t("record-prompt.cta_label")}
                </span>
              </button>
              {!hasPermission && (
                <p
                  css={css`
                    font-size: 12px;
                  `}
                >
                  {type === "video"
                    ? t("record-prompt.video_permission")
                    : t("record-prompt.audio_permission")}
                </p>
              )}
            </div>
          </ModalBody>
        </>
      ) : (
        <>
          <ModalHeader
            closeIcon
            onClose={props.onClose}
            css={css`
              padding: 14px 21px 13px 32px;
            `}
          >
            <Headers.H2>{t("record.modal.title")}</Headers.H2>
            <p
              css={css`
                color: #616161;
              `}
            >
              {t("record.modal.description")}
            </p>
          </ModalHeader>

          <ModalBody
            css={css`
              padding: 0;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-flow: row wrap;
                justify-content: space-around;
                padding: 45px 30px;
              `}
            >
              {options.map((option, index) => {
                return (
                  <button
                    key={index}
                    css={css`
                      display: inline-block;
                      padding: 30px;
                      text-align: center;
                      cursor: pointer;
                      background: none;
                      border: 1px solid #eae8e8;
                      border-radius: 10px;
                      box-shadow: 0px 4px 10px #fff;
                      transition: box-shadow 0.2s ease-in-out;

                      &:hover {
                        box-shadow: 0px 4px 13px #e3e3e3;
                      }
                      ${option.limit &&
                      option.used >= option.limit &&
                      css`
                        &:hover {
                          box-shadow: none;
                          cursor: default;
                        }
                      `}

                      &:first-child {
                        margin-right: 10px;
                      }
                    `}
                    onClick={() => {
                      if (option.limit && option.used >= option.limit) {
                        return;
                      }

                      setType(option.type);
                    }}
                  >
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 57px;
                        height: 57px;
                        margin: 0 auto 20px;
                        background: #ffffff;
                        box-shadow: 0px 4px 13px #e3e3e3;
                        border-radius: 50%;
                      `}
                    >
                      {option.icon}
                    </div>
                    <Headers.H4
                      css={css`
                        font-weight: 500;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      `}
                    >
                      {option.title}

                      {option.limit && (
                        <span
                          css={css`
                            font-size: 12px;
                            font-weight: 300;
                          `}
                        >
                          {option.used}/{option.limit}
                        </span>
                      )}
                    </Headers.H4>
                  </button>
                );
              })}
            </div>
          </ModalBody>
          {!userprofile?.is_pro && (
            <ModalFooter
              css={css`
                background: #252424;
                width: 100%;
                padding: 10px 30px;
                box-sizing: border-box;
                color: #fff;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  flex: 1 0 10%;
                `}
              >
                <div
                  css={css`
                    display: inline-block;
                    vertical-align: middle;
                  `}
                >
                  <LabelPro />
                </div>
                <Headers.H4
                  css={css`
                    display: inline-block;
                    margin-left: 10px;
                    vertical-align: middle;
                    color: #fff;
                    font-size: 14px;
                  `}
                >
                  {t("record.modal.upgrade-text")}
                </Headers.H4>
              </div>
              <div
                css={css`
                  flex: 0 0 auto;
                `}
              >
                <Button
                  css={css`
                    background: #fff;
                    color: #000;
                  `}
                  onClick={() => {
                    sendEvent("Upgrade button clicked", {
                      location: "Recording modal",
                    });

                    window.open(
                      "https://calendly.com/themis-sales/demo",
                      "_blank"
                    );
                  }}
                >
                  {t("record.modal.upgrade-button-label")}
                </Button>
              </div>
            </ModalFooter>
          )}
        </>
      )}
    </Modal>
  );
}
