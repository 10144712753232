import { VideoElement, VideoScene } from "../../../VideoEditor/types/Video";
import { Template } from "../types";
import { generateKnowledgeSharingCheckKnowledgeTemplate } from "./check_knowledge";
import { generateKnowledgeSharingCoreConceptsTemplate } from "./core_concepts";
import { generateKnowledgeSharingExamplesTemplate } from "./examples";
import { generateKnowledgeSharingExtraNotesTemplate } from "./extra_notes";
import { generateKnowledgeSharingFeedbackTemplate } from "./feedback";
import { generateKnowledgeSharingGuideTemplate } from "./guide";
import { generateKnowledgeSharingIntroductionTemplate } from "./introduction";
import { generateKnowledgeSharingLearningObjectivesTemplate } from "./learning_objectives";
import { generateKnowledgeSharingTipsAndTricksTemplate } from "./tips_and_tricks";
import { generateKnowledgeSharingToolIntroductionTemplate } from "./tool_introduction";
import { generateKnowledgeSharingToolStepsTemplate } from "./tool_steps";
import { generateKnowledgeSharingToolTipsTemplate } from "./tool_tips";
import { generateKnowledgeSharingTopicOverviewTemplate } from "./topic_overview";
import { generateKnowledgeSharingAdditionalResourcesTemplate } from "./additional_resources";

export function generateKnowledgeSharingTemplate(
  data: Template,
  t: any
): {
  scenes: VideoScene[];
  elements: VideoElement[];
} {
  let scenes: VideoScene[] = [];
  let elements: VideoElement[] = [];
  let count = 0;

  if ("topic-introduction" in data.selectedTopics) {
    const topics = data.selectedTopics["topic-introduction"];

    if (topics.includes("introduction")) {
      const template = generateKnowledgeSharingIntroductionTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("overview-of-the-topic")) {
      const template = generateKnowledgeSharingTopicOverviewTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("learning-objectives")) {
      const template = generateKnowledgeSharingLearningObjectivesTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }
  }

  if ("what-to-share" in data.selectedTopics) {
    const topics = data.selectedTopics["what-to-share"];

    if (topics.includes("core-concepts")) {
      const template = generateKnowledgeSharingCoreConceptsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("step-by-step-guide")) {
      const template = generateKnowledgeSharingGuideTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("tool-walk-through")) {
      const template1 = generateKnowledgeSharingToolIntroductionTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);

      const template2 = generateKnowledgeSharingToolStepsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template2.scenes);
      elements = elements.concat(template2.elements);

      const template3 = generateKnowledgeSharingToolTipsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template3.scenes);
      elements = elements.concat(template3.elements);
    }
  }

  if ("additional-information" in data.selectedTopics) {
    const topics = data.selectedTopics["additional-information"];

    if (topics.includes("examples")) {
      const template = generateKnowledgeSharingExamplesTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("tips-and-tricks")) {
      const template = generateKnowledgeSharingTipsAndTricksTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("extra-notes")) {
      const template = generateKnowledgeSharingExtraNotesTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("feedback")) {
      const template = generateKnowledgeSharingFeedbackTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("additional-resources")) {
      const template = generateKnowledgeSharingAdditionalResourcesTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("check-knowledge")) {
      const template = generateKnowledgeSharingCheckKnowledgeTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }
  }

  return {
    scenes: scenes,
    elements: elements,
  };
}
