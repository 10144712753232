import { UserProfile } from "../types/UserProfile";

declare global {
  interface Window {
    mixpanel: any;
  }
}

export function sendEvent(event: string, properties?: object) {
  if (window.mixpanel) {
    window.mixpanel.track(event, properties);
  } else {
    console.log("Tracking event", event, properties);
  }
}
export function identifyUser(userId: number, userprofile: UserProfile) {
  if (window.mixpanel) {
    window.mixpanel.identify(String(userId));
    window.mixpanel.people.set({
      $email: userprofile.email,
      $first_name: userprofile.first_name,
      $last_name: userprofile.last_name,
      $name: userprofile.first_name + " " + userprofile.last_name,
      $created: userprofile.date_joined,
      localId: userId,
    });
  } else {
    console.log("Identifying user", userId, userprofile);
  }
}
