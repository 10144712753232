import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { customToast } from "../../components/customToast";
import { Button } from "../../VideoEditor/components/Button";
import { InputField } from "../fields/InputField";
import { InputType } from "../../components/InputComponent";
import { Column, Spacing } from "../../helpers/layout";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { profileSetup } from "../../actions/auth/profileSetup";
import { useCheckMobile } from "../../hooks/useCheckMobile";

interface FormValues {
  company_role: string;
}

export function RoleForm() {
  const history = useHistory();
  const { t } = useTranslation();
  const { isMobile } = useCheckMobile();

  return (
    <Formik<FormValues>
      initialValues={{
        company_role: "",
      }}
      validationSchema={Yup.object().shape({
        company_role: Yup.string().required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);

          await profileSetup(values.company_role);
          customToast.success(t("status.success"));
          history.push("/");
        } catch (error: any) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form
          noValidate
          css={css`
            ${isMobile &&
            css`
              display: flex;
              flex-direction: column;
              height: 100%;
            `}
          `}
        >
          <Column
            gutter={Spacing.m}
            css={css`
              margin-top: 80px;

              ${isMobile &&
              css`
                margin-top: 40px;
              `};
            `}
          >
            <InputField
              name="company_role"
              type={InputType.TEXT}
              label={t("auth.role.hint")}
              placeholder={t("auth.form.role")}
              css={css`
                padding: 0 30px;

                ${isMobile &&
                css`
                  padding: 0;
                `};
              `}
              hideTooltip
              errorBottom
            />
          </Column>

          <div
            css={css`
              display: flex;
              flex-direction: row;

              ${isMobile &&
              css`
                flex-direction: column-reverse;
                margin: auto 0 0 0;
              `}
            `}
          >
            <Button
              onClick={() => history.push("/")}
              css={css`
                margin: 40px auto;
                line-height: 28px;
                padding: 6px 26px;
                background: #fff;
                border: 1px solid #252424;
                font-size: 16px;

                ${isMobile &&
                css`
                  margin: 20px 0 0 0;
                  font-size: 20px;
                  padding: 10px 0;
                `};
              `}
            >
              {t("auth.role.skip")}
            </Button>
            <Button
              isSubmitting={isSubmitting}
              disabled={!isValid}
              type="submit"
              css={css`
                margin: 40px auto;
                line-height: 28px;
                padding: 6px 26px;
                color: #fff;
                font-size: 16px;

                ${isMobile &&
                css`
                  margin: auto 0 0 0;
                  font-size: 20px;
                  padding: 10px 0;
                `};
              `}
            >
              {t("auth.role.cta")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
