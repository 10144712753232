import { authorised } from "../../request";
import { WorkspaceOverview } from "../../types/Workspace";

export async function updateWorkspace(
  values: WorkspaceOverview,
  workspace_id: string
) {
  const API = authorised();
  const response = await API.request<WorkspaceOverview>({
    method: "PUT",
    url: `/api/studio/themis/workspaces/${workspace_id}/`,
    data: values,
  });

  return response.data;
}
