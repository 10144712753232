import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Form, Formik } from "formik";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";
import { useAuth } from "../../contexts/UserContext";
import { useEditor } from "../contexts/EditorContext";

import { Headers } from "../helpers/headers";
import { Column, Row, Spacing } from "../../helpers/layout";
import { Document } from "../types/Document";
import { ModalBody, ModalHeader } from "../../components/Modal";
import { Button } from "../../components/Button";
import { DropFile } from "./DropFile";
import { InputField } from "../../forms/fields/InputField";

import { ReactComponent as BookIcon } from "../../assets/icons/Book.svg";

import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";

import { theme } from "../../themes/variables";
import { InteractiveType } from "./InteractiveModal";
import { sendEvent } from "../../helpers/tracking";
import { calculateThemiDocumentElements } from "../helpers/video";

import { defaultSequenceDuration } from "../../constants";

type FormValues = Document;

export function InteractiveDocument(props: {
  initialValues?: Document;
  interactiveType: InteractiveType;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { activeScene } = usePlayback();
  const { api, video } = useStorage();
  const { userprofile } = useAuth();
  const { activeElementId } = useEditor();
  const {
    initialValues = {
      link: "",
      file: null,
      title: "",
      description: "",
    },
  } = props;

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(60),
    description: Yup.string().max(44),
    linkFileGroups: Yup.object().shape(
      {
        link: Yup.bool().when("file", {
          is: (secondary: Pick<Document, "file">) => !secondary,
          then: Yup.bool().oneOf([true]),
        }),
        file: Yup.bool().when("link", {
          is: (link: string) => !link,
          then: Yup.bool().oneOf([true]),
        }),
      },
      [
        ["link", "file"],
        ["file", "link"],
      ]
    ),
  });

  const createElement = useCallback(
    async (values: Document) => {
      if (!activeScene || !userprofile) return;

      const emptyStateValues = {
        link: "",
        file: null,
        title: t("themi-builder.template.common.document-title"),
        description: t("themi-builder.template.common.document-description"),
      };

      const emptyState = Object.values(values).every((value) => !value);

      const valuesToCreate = () => {
        if (emptyState) {
          return emptyStateValues;
        } else {
          return {
            ...values,
            title:
              !values.file && !values.title && values.link
                ? values.link?.substring(values.link?.lastIndexOf("/"))
                : values.title,
          };
        }
      };

      api.createElement({
        id: uuid(),
        scene_id: activeScene.id,
        order: activeScene.elements.length,
        type: "document",
        document: valuesToCreate(),
        states: [
          {
            id: uuid(),
            left: 100,
            top: 120,
            width: 431 * 0.65,
            height: 119 * 0.65,
            scale: 1,
            rotation: 0,
            start_time: 0,
            duration: defaultSequenceDuration,
          },
        ],
        config: {},
      });

      if (video) {
        sendEvent("Created Document Card", {
          video_id: video.uuid,
        });
      }
    },
    [activeScene, api, userprofile, video, t]
  );

  const updateElement = useCallback(
    async (values: Document) => {
      if (!activeScene || !userprofile || !activeElementId) return;

      api.updateElement(activeElementId, {
        document: {
          ...values,
          title:
            !values.file && !values.title && values.link
              ? values.link?.substring(values.link?.lastIndexOf("/"))
              : values.title,
        },
      });
    },
    [activeElementId, activeScene, api, userprofile]
  );

  const elementsCount = calculateThemiDocumentElements(
    video?.schema.schema.elements
  );

  return (
    <div
      css={css`
        width: 100%;
        max-height: 637px;
        overflow: auto;
        display: flex;
        flex-direction: column;
      `}
    >
      <ModalHeader
        closeIcon
        onClose={props.onClose}
        css={css`
          padding: 14px 21px 13px 32px;
        `}
      >
        <Headers.H2
          css={css`
            margin-left: 92px;
            font-weight: 500;
            font-size: 20px;
          `}
        >
          {t("document.create.upload-link")}
        </Headers.H2>
      </ModalHeader>

      <ModalBody
        css={css`
          padding: 0 122px;
          height: 100%;
          max-height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <Formik<FormValues>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: FormValues, { setSubmitting }) => {
            try {
              values = {
                ...values,
                link: values.link && values.link.trim(),
              };
              setSubmitting(true);
              if (props.initialValues) {
                updateElement(values);
              } else {
                createElement(values);
              }
              props.onClose();
            } catch (error: any) {
              customToast.error(
                t("status.error", {
                  error: serverErrorHandler(error),
                })
              );
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue, dirty, isValid }) => {
            return (
              <Form
                noValidate
                css={css`
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  justify-content: space-between;
                `}
              >
                <Column gutter={Spacing.PX_20}>
                  <InputField
                    name="link"
                    placeholder={t("document.create.link-to-url")}
                    hideTooltip
                    errorBottom
                    css={css`
                      width: 100%;
                    `}
                  />

                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 140%;
                    `}
                  >
                    {t("document.create.or")}
                  </div>

                  <DropFile
                    accept={{
                      "application/*": [
                        "msword",
                        "vnd.ms-powerpoint",
                        "pdf",
                        "vnd.ms-excel",
                      ],
                    }}
                    css={css`
                      border-radius: unset;
                      background-color: unset;
                      padding: 0;
                      .container {
                        height: 140px;
                        background: #f1f1f1;
                        border: 1px solid #eae8e8;
                        border-radius: 9px;
                      }
                    `}
                    initialMessage={
                      !values.file ? (
                        <div
                          css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: 7px 10px;
                          `}
                        >
                          <div
                            css={css`
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              width: 35px;
                              height: 35px;
                              border-radius: 50%;
                              background: white;
                              box-shadow: 0px 3px 8px #e5e5e5;
                              margin-bottom: 16px;
                            `}
                          >
                            <BookIcon />
                          </div>
                          <Headers.H5
                            css={css`
                              margin-left: 7px;
                            `}
                          >
                            {t("document.create.upload-or-drag")}
                          </Headers.H5>
                          <span
                            css={css`
                              margin-top: 2px;
                              font-size: 12px;
                              text-align: center;
                              color: #616161;
                            `}
                          >
                            {t("document.create.max-file-size")}
                          </span>
                        </div>
                      ) : (
                        <div
                          css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: 7px 10px;
                          `}
                        >
                          <div
                            css={css`
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              width: 35px;
                              height: 35px;
                              border-radius: 50%;
                              background: white;
                              box-shadow: 0px 3px 8px #e5e5e5;
                              margin-bottom: 16px;
                            `}
                          >
                            <BookIcon />
                          </div>
                          <Headers.H5
                            css={css`
                              margin-left: 7px;
                            `}
                          >
                            {values.file.title}
                          </Headers.H5>
                        </div>
                      )
                    }
                    onUpload={async (file) => {
                      if (!file) return;
                      setFieldValue("file", file);
                      setFieldValue("title", file.title);
                    }}
                  />

                  <InputField
                    name="title"
                    placeholder={t("scene.title")}
                    hideTooltip
                    errorBottom
                    maxLength={60}
                    css={css`
                      width: 100%;
                    `}
                  />

                  <InputField
                    name="description"
                    placeholder={t("document.create.description")}
                    hideTooltip
                    errorBottom
                    maxLength={44}
                    css={css`
                      width: 100%;
                    `}
                  />
                </Column>

                <Row justify="center">
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={
                      !dirty ||
                      !isValid ||
                      (!props.initialValues &&
                        !!userprofile?.restrictions.uploaded_document &&
                        elementsCount >=
                          userprofile.restrictions.uploaded_document)
                    }
                    css={css`
                      background: ${theme.colors.primary};
                      margin-top: 40px;
                      font-weight: 600;
                      margin-bottom: 16px;
                    `}
                  >
                    {t("contact-card.create.done")}
                  </Button>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </div>
  );
}
