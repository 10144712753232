import { css } from "styled-components/macro";
import ReactSelect, { StylesConfig } from "react-select";

type Option = { label: string; value: string };

export type SelectProps = {
  value?: Option;
  onChange: (value: Option | null) => void;
  options: Array<Option>;
  styles?: StylesConfig;
  menuPortalTarget?: HTMLElement;
  disabled?: boolean;
};

export const defaultStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    fontSize: 16,
    outline: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
      background: "#EAE8E8",
    },
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "#FAE7D9" : "#FFFFFF",
    color: state.isSelected ? "#E95B2E" : "#000000",
    "&:hover": {
      background: "#E95B2E",
      color: "#FFFFFF",
    },
    fontSize: 14,
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 9999,
  }),
};

export function Select(props: SelectProps) {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <ReactSelect
        options={props.options}
        value={props.value}
        onChange={(value) => props.onChange(value as Option)}
        styles={props.styles || defaultStyles}
        menuPortalTarget={props.menuPortalTarget}
        isSearchable={false}
        isDisabled={props.disabled}
      />
    </div>
  );
}
