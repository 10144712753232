import { useCallback, useState, useEffect, useRef } from "react";
import { css } from "styled-components/macro";
import { debounce } from "lodash";
import striptags from "striptags";
import { useTranslation } from "react-i18next";
import { useStorage } from "../contexts/StorageContext";
import { updateVideo } from "../actions/updateVideo";
import { canEdit } from "../helpers/collaborator";
import { useClickOutside } from "../hooks/useClickOutside";
import { customToast } from "../../components/customToast";
import { LifetimeTooltip } from "../../components/Tooltip";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { localAPI } from "../API";
import { ContentEditable } from "./ContentEditable";

export function Title() {
  const { video } = useStorage();
  const [title, setTitle] = useState(video ? video.name : "Themi");
  const [focused, setFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const titleRef = useRef(null);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (video) setTitle(video.name);
  }, [video]);

  useClickOutside(titleRef, () => {
    setFocused(false);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateTitle = useCallback(
    debounce(async (title: string) => {
      if (!video) return;

      try {
        const response = await updateVideo(video.uuid, { name: title });

        await localAPI.saveVideo(video.uuid, {
          name: response.name,
        });
      } catch (error: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    }, 1000),
    [video?.uuid]
  );

  const handleContentEditable = (event: any) => {
    const content = striptags(event.target.value, [], "");

    setTitle(content);
    updateTitle(content);
  };

  useEffect(() => {
    if (inputRef.current) {
      if (inputRef.current?.scrollWidth >= 350) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    }
  }, [title]);

  if (!video) return null;

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: #000000;
      `}
      ref={titleRef}
    >
      <LifetimeTooltip
        text={title}
        activateOnHover
        deactivateOnBlur
        verticalPosition="top"
        lifeTime={1500}
        noArrow
        show={showTooltip}
      >
        <ContentEditable
          innerRef={inputRef}
          html={title}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          disabled={!canEdit(video.current_user_role)}
          onChange={handleContentEditable}
          disableWrap
          style={
            !focused
              ? {
                  maxWidth: "350px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }
              : {
                  maxWidth: "350px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }
          }
        />
      </LifetimeTooltip>
    </div>
  );
}
