import React from "react";
import { css } from "styled-components/macro";
import { Spacing } from "../../helpers/layout";

type ModalFooterProps = {
  children?: React.ReactNode | React.ReactNode[] | string;
  className?: string;
};

export function ModalFooter(props: ModalFooterProps) {
  return (
    <div
      css={css`
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        button {
          margin: 0 ${Spacing.s};
          :first-of-type {
            margin: 0;
            margin-right: ${Spacing.s};
          }
          :last-of-type {
            margin: 0;
            margin-left: ${Spacing.s};
          }
        }
      `}
      className={props.className + " modal-footer"}
    >
      {props.children}
    </div>
  );
}
