import { css } from "styled-components/macro";
import { useRecording } from "../contexts/RecordingContext";
import recordingAudio from "../assets/images/recording-audio.png";
import React, { useEffect } from "react";

import { calculateProportionalSize } from "../renderers/ElementRenderer";
import { useEditor } from "../contexts/EditorContext";

export const RecordingWidth = 90;

export function RecordingIndicator() {
  const { recording, recordingType, mediaRecorder, countdownTime } =
    useRecording();
  const videoPreview = React.useRef<HTMLVideoElement>(null);
  const { canvasSize } = useEditor();

  useEffect(() => {
    const interval = setInterval(() => {
      if (mediaRecorder.current && videoPreview.current) {
        if (
          !videoPreview.current.srcObject &&
          mediaRecorder.current.stream.active
        ) {
          videoPreview.current.srcObject = mediaRecorder.current.stream;
        }
      }
    }, 300);

    return () => clearInterval(interval);
  }, [mediaRecorder, recording]);

  const recordingStarted = countdownTime === 0;

  const elWidth = calculateProportionalSize(RecordingWidth, canvasSize.width);

  if (recordingType === "video") {
    return (
      <div
        css={css`
          position: absolute;
          top: ${calculateProportionalSize(17, canvasSize.width)}px;
          right: ${calculateProportionalSize(20, canvasSize.width)}px;
          width: ${elWidth}px;
          height: ${elWidth}px;
          background: #fff;
          border: 1px solid #eae8e8;
          border-radius: 50%;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 11;
        `}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          <video
            css={css`
              width: ${elWidth}px;
              height: ${elWidth}px;
              border-radius: 50%;
              object-fit: cover;
              user-select: none;
              overflow: hidden;
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
            `}
            autoPlay
            muted
            ref={videoPreview}
          >
            Your browser doesn't support the video tag
          </video>
          {!recordingStarted && (
            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: ${elWidth}px;
                height: ${elWidth}px;
                border-radius: 50%;
                background: rgba(37, 36, 36, 0.3);
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-size: ${calculateProportionalSize(20, canvasSize.width)}px;
              `}
            >
              {countdownTime}
            </div>
          )}
        </div>
      </div>
    );
  }

  if (recordingType === "audio") {
    return (
      <div
        css={css`
          position: absolute;
          width: ${elWidth}px;
          height: ${elWidth}px;
          top: ${calculateProportionalSize(17, canvasSize.width)}px;
          right: ${calculateProportionalSize(20, canvasSize.width)}px;
          background: #fff;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 11;
          border: 1px solid #eae8e8;
          border-radius: 50%;

          img {
            width: 80%;
          }
        `}
      >
        <img src={recordingAudio} alt="" />

        {!recordingStarted && (
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: ${elWidth}px;
              height: ${elWidth}px;
              border-radius: 50%;
              background: rgba(37, 36, 36, 0.7);
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-size: ${calculateProportionalSize(20, canvasSize.width)}px;
            `}
          >
            {countdownTime}
          </div>
        )}
      </div>
    );
  }

  return null;
}
