import React, { useRef, useState } from "react";
import { css } from "styled-components/macro";
import { ReactComponent as ProfileDelete } from "../../assets/icons/ProfileDelete.svg";
import { ReactComponent as ChevronSmall } from "../../assets/icons/ChevronSmall.svg";
import { Button } from "../../components/Button";
import { ModalDelete } from "../../modals/ModalDelete";
import { useTranslation } from "react-i18next";

type DeleteFormProps = {
  onDelete: () => void;
};

export function DeleteForm(props: DeleteFormProps) {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const content = useRef(null as null | HTMLDivElement);
  const [deleteModal, setDeleteModal] = useState(false);

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div
        onClick={() => setShowForm(!showForm)}
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-right: 25px;
          `}
        >
          <ProfileDelete />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 40px;
          `}
        >
          <div
            css={css`
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
            `}
          >
            {t("profile_page.delete_title")}
          </div>
          <div
            css={css`
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #505050;
            `}
          >
            {t("profile_page.delete_find")}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ChevronSmall
            css={css`
              transition: transform 0.6s ease;
              transform: ${showForm ? `rotate(90deg)` : `none`};
            `}
          />
        </div>
      </div>

      <div
        ref={content}
        css={css`
          overflow: hidden;
          transition: max-height 0.6s ease;
          max-height: ${!showForm
            ? "0px"
            : content &&
              content.current &&
              content.current.scrollHeight + "px"};
        `}
      >
        <div
          css={css`
            margin-top: 32px;
          `}
        >
          {t("profile_page.delete_info")}
        </div>
        <Button
          onClick={() => setDeleteModal(true)}
          hoverStyles="none"
          css={css`
            margin-top: 30px;
          `}
        >
          {t("profile_page.delete_title")}
        </Button>
      </div>
      <ModalDelete
        title={t("profile_page.delete_modal_title")}
        description={t("profile_page.delete_modal_description")}
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={() => props.onDelete()}
      />
    </div>
  );
}
