import { useState, useCallback } from "react";
import { TextareaComponent } from "../../components/TextareaComponent";
import { Headers } from "../helpers/headers";
import { css } from "styled-components/macro";
import { Button } from "./Button";
import { customToast } from "../../components/customToast";
import { useTranslation } from "react-i18next";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { useAi } from "../contexts/AiContext";
import { Slides } from "../actions/generateAIContent";
import { ScenePreview } from "./ScenePreview";
import { generateSequenceFromSlide } from "../helpers/video";
import { useStorage } from "../contexts/StorageContext";

export function AIContentGenerator(props: { onContentAdded: () => void }) {
  const [prompt, setPrompt] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const { t } = useTranslation();
  const { generateContent } = useAi();
  const [slides, setSlides] = useState<Slides>([]);
  const { api, video } = useStorage();

  const sequences = slides.map((slide, index) => {
    const content = generateSequenceFromSlide(slide, index, t);

    return {
      ...content.scene,
      elements: content.elements,
    };
  });

  const slidesGenerated = slides.length > 0;

  const submitForm = useCallback(async () => {
    setLoading(true);

    try {
      const response = await generateContent(prompt);

      if (response) {
        setSlides(response.slides);
      }
    } catch (e: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(e),
        })
      );
    } finally {
      setLoading(false);
    }
  }, [prompt, t, generateContent]);

  const addToThemi = useCallback(async () => {
    setAdding(true);

    const maxOrder = video?.schema.schema.scenes.length || 0;

    sequences.forEach(async (scene, index) => {
      await api.createSceneWithContent(
        {
          ...scene,
          order: maxOrder + index,
        },
        scene.elements
      );
    });

    props.onContentAdded();
  }, [sequences, api, props, video]);

  return (
    <div>
      <Headers.H3>Content Generator</Headers.H3>
      {!slidesGenerated && (
        <>
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <TextareaComponent
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              label="Type Prompt"
              placeholder="Example: Presentation about climate change"
            />
          </div>
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <Button
              css={css`
                color: #fff;
              `}
              isSubmitting={loading}
              onClick={submitForm}
            >
              Generate!
            </Button>
          </div>
        </>
      )}
      {slidesGenerated && (
        <div
          css={css`
            margin-top: 20px;
            border-top: 1px solid #ccc;
            padding-top: 20px;
          `}
        >
          <Headers.H4
            css={css`
              margin-bottom: 20px;
            `}
          >
            Generated Sequences
          </Headers.H4>
          <div
            css={css`
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
            `}
          >
            {sequences.map((scene, index) => {
              return (
                <div
                  css={css`
                    flex: 0 0 48%;
                    margin-bottom: 20px;
                  `}
                  key={index}
                >
                  <ScenePreview
                    css={css`
                      border: 1px solid #ccc;
                      border-radius: 4px;
                      overflow: hidden;
                    `}
                    scene={scene}
                  />
                  <Headers.H4>Slide {index + 1}</Headers.H4>
                </div>
              );
            })}
          </div>
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <Button
              css={css`
                color: #fff;
              `}
              isSubmitting={adding}
              onClick={addToThemi}
            >
              Add to themi
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
