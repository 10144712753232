import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";
import { Image, ImageType } from "../types/Image";
import { Button } from "../components/Button";
import { ImageUploadModal } from "../modals/ImageUploadModal";

const checkIsString = (p: any): p is string => {
  return p && typeof p === "string";
};

export function ImageUpload(props: {
  id?: string;
  label?: string;
  className?: string;
  disableLibrary?: boolean;
  image?: Image | string | null;
  type?: ImageType;
  onChange: (image: Image | string | null) => void;
  uploadFn: (args: {
    file: File;
    title?: string | null;
    type?: ImageType;
    onUploadProgress?: (progressEvent: any) => void;
  }) => Promise<Image | string>;
}) {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);

  return (
    <div id={props.id} className={props.className} css={css``}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          onClick={() => setModal(true)}
          css={css`
            width: 90px;
            height: 90px;
          `}
        >
          {props.image ? (
            <img
              src={checkIsString(props.image) ? props.image : props.image.image}
              alt={
                checkIsString(props.image)
                  ? props.image
                  : props.image.upload_name
              }
              css={css`
                border-radius: 7px;
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                position: relative;
              `}
            />
          ) : (
            <div
              css={css`
                width: 100%;
                height: 100%;
                background: ${theme.colors.gray2};
                border-radius: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
              `}
            >
              {t("media.no-image")}
            </div>
          )}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: 30px;
          `}
        >
          <div
            css={css`
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
            `}
          >
            {t("profile_page.upload_profile_img")}
          </div>
          <div
            css={css`
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #505050;
              margin-bottom: 20px;
            `}
          >
            {"PNG, JPG (max 300x300px) "}
          </div>
          <Button hoverStyles="none" onClick={() => setModal(true)}>
            {t("profile_page.upload_img")}
          </Button>
        </div>
      </div>

      {modal && (
        <ImageUploadModal
          isVisible={modal}
          onSelect={async (image) => {
            props.onChange(image);
          }}
          onClose={() => {
            setModal(false);
          }}
          type={props.type}
          disableLibrary={props.disableLibrary}
          onUpload={async ({ file, title, type, onUploadProgress }) => {
            const image = await props.uploadFn({
              file: file,
              title: title,
              type: type,
              onUploadProgress,
            });

            if (image) {
              props.onChange(image);
            }
          }}
        />
      )}
    </div>
  );
}
