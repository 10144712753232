import { useEffect, useRef } from "react";
import markerSDK, { MarkerSdk } from "@marker.io/browser";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { TooltipArea } from "../components/Tooltip";
import { ReactComponent as FeedbackIcon } from "../assets/icons/Feedback.svg";

export function FeedbackButton() {
  const { t } = useTranslation();
  const widget = useRef<MarkerSdk | null>(null);

  useEffect(() => {
    async function loadWidget() {
      widget.current = await markerSDK.loadWidget({
        destination: "63bbf9a774ef29c829e9bec6", // <- Your unique destination ID
      });
    }

    loadWidget();
  }, []);

  return (
    <TooltipArea
      text={t("video-editor.header.report")}
      staticPosition
      offsetX={-220}
      offsetY={40}
      noArrow
      maxWidth={250}
    >
      <div
        css={css`
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          box-shadow: 0px 0px 3px #e5e5e5;
          border-radius: 50%;
          margin-left: 30px;
          cursor: pointer;
        `}
        id="themis-feedback"
        onClick={() => {
          if (widget.current) {
            widget.current.capture("advanced");
          }
        }}
      >
        <FeedbackIcon />
      </div>
    </TooltipArea>
  );
}
