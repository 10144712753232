import React, { useEffect, useState } from "react";

import { ReactComponent as BadConnectionIcon } from "../../assets/icons/BadConnection.svg";

export function ConnectionCheck() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    setIsOnline(navigator.onLine);

    const interval = setInterval(() => {
      setIsOnline(navigator.onLine);
    }, 10000);

    return clearInterval(interval);
  }, []);

  if (isOnline) return null;
  return <BadConnectionIcon />;
}
