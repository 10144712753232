import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { SEO } from "../../components/SEO";
import { useCheckMobile } from "../../hooks/useCheckMobile";
import { Button } from "../../components/Button";
import { useHistory } from "react-router-dom";
import { ReactComponent as ThemisLogo } from "../../assets/icons/ThemisLogo.svg";
import Logo from "../../assets/img/AuthLogoMobile.png";
import AuthWomanAvatar from "../../assets/img/AuthWomanAvatar.jpg";
import AuthManAvatar from "../../assets/img/AuthManAvatar.jpg";
import { LanguageSelector } from "../../components/LanguageSelector";
import { MovingElement } from "../../components/MovingElement";
import { AvatarCursor } from "../../components/AvatarCursor";

export function MobileStartPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useCheckMobile();

  if (!isMobile) {
    history.push("/login");
  }

  return (
    <>
      <SEO>
        <title>{t("auth.login.title")}</title>
      </SEO>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 100vh;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 28px;
          `}
        >
          <ThemisLogo />

          <LanguageSelector />
        </div>

        <MovingElement
          positionBoundaries={{ x: [-25, 25], y: [-75, 0] }}
          timeout={2000}
          initialPositions={{ x: 50, y: 100 }}
        >
          <AvatarCursor
            color="#E95B2E"
            size={38}
            avatarURL={AuthManAvatar}
            css={css`
              .triangle {
                transform: translateY(39px) translateX(33px) rotate(-195deg)
                  skewY(30deg) scaleX(0.866);
              }
              .avatar {
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
                border-width: 2px;
              }
            `}
          />
        </MovingElement>

        <MovingElement
          positionBoundaries={{ x: [-25, 25], y: [-75, 0] }}
          timeout={3500}
          css={css`
            right: 0;
          `}
          initialPositions={{ x: -50, y: 400 }}
        >
          <AvatarCursor
            color="#FFC39C"
            size={38}
            avatarURL={AuthWomanAvatar}
            css={css`
              .avatar {
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
                border-width: 2px;
              }
            `}
          />
        </MovingElement>

        <div
          css={css`
            width: 100%;
            height: 100%;
            background-image: url(${Logo});
            background-repeat: no-repeat;
            background-size: 150%;
            background-position: left;
          `}
        ></div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            padding: 0 40px;
            height: 100%;
            width: 100%;
            place-content: flex-end;
            padding-bottom: 40px;
          `}
        >
          <div
            css={css`
              width: 100%;
              font-weight: 600;
              font-size: 25px;
              line-height: 35px;
              margin-bottom: 4px;
              color: #252424;
              text-align: center;
            `}
          >
            {t("auth.mobile.welcome")}
          </div>
          <div
            css={css`
              width: 100%;
              font-weight: 400;
              font-size: 16px;
              line-height: 140%;
              color: #252424;
              text-align: center;
            `}
          >
            {t("auth.mobile.description")}
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-top: 40px;
              margin-bottom: 20px;
            `}
          >
            <Button
              onClick={() => history.push("/register")}
              css={css`
                line-height: 28px;
                padding: 10px 0;
                background: #e95b2e;
                color: #fff;
                font-size: 20px;
              `}
            >
              {t("auth.mobile.get-started")}
            </Button>
            <Button
              onClick={() => history.push("/login")}
              css={css`
                margin-top: 20px;
                line-height: 28px;
                padding: 10px 0;
                background: #fff;
                color: #252424;
                font-size: 20px;
                border: 1.5px solid #252424;
              `}
            >
              {t("auth.login.login")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
