import React, { RefObject, useCallback, useEffect } from "react";

export type RecordingType = "audio" | "video" | "screen";
export type RecordedMedia = {
  type: RecordingType;
  file: File;
  duration: number;
};
export type RecordingStatus = "active" | "paused" | "not-active";

enum Browsers {
  Firefox = "Firefox",
  Chrome = "Chrome",
  Safari = "Safari",
}

function checkBrowser(browser: Browsers) {
  if (navigator.userAgent.indexOf(browser) !== -1) return true;
  return false;
}

function getContraints(type: RecordingType): MediaStreamConstraints {
  switch (type) {
    case "audio":
      return { audio: true };
    case "video":
      const videoContrain = {
        video: {
          frameRate: { ideal: 30 },
          width: { ideal: 200 },
          height: { ideal: 200 },
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
        },
      };
      if (checkBrowser(Browsers.Firefox)) {
        return videoContrain;
      }
      return {
        ...videoContrain,
        video: {
          frameRate: { ideal: 30, max: 60 },
          width: { ideal: 200, max: 300 },
          height: { ideal: 200, max: 300 },
        },
      };
    case "screen":
      return { video: true, audio: true };
  }
}

const RecordingContext = React.createContext<{
  recording: RecordingStatus;
  recordingType: RecordingType;
  recordingTime: number;
  countdownTime: number | null;
  record: (type: RecordingType) => void;
  hasPermission: boolean | null;
  askRecordingPermission: (recordingType: RecordingType) => void;
  deviceSupported: boolean | null;
  mediaRecorder: RefObject<MediaRecorder | null>;
  onRecordingStop: React.MutableRefObject<(data: RecordedMedia) => void>;
  cancelRecording: () => void;
  pauseRecording: () => void;
  retry: () => void;
  finishRecording: () => void;
  resumeRecording: () => void;
}>({
  recording: "not-active",
  recordingType: "video",
  recordingTime: 0,
  countdownTime: 0,
  record: () => {},
  retry: () => {},
  hasPermission: null,
  askRecordingPermission: () => {},
  deviceSupported: null,
  mediaRecorder: React.createRef(),
  onRecordingStop: React.createRef() as React.MutableRefObject<
    (data: RecordedMedia) => void
  >,
  cancelRecording: () => {},
  pauseRecording: () => {},
  finishRecording: () => {},
  resumeRecording: () => {},
});

const timeout = 5;

export function RecordingProvider(props: { children: React.ReactNode }) {
  const [passedTime, setPassedTime] = React.useState<number>(0);
  const onRecordingStop = React.useRef<(data: RecordedMedia) => void>(() => {});
  const recordingTimeRef = React.useRef<number>(0);
  const mediaChunks = React.useRef<Blob[]>([]);
  const cancelledRef = React.useRef<boolean>(false);
  const deviceSupported = !!navigator.mediaDevices;
  const [hasPermission, setHasPermission] = React.useState<boolean | null>(
    null
  );
  const mediaStream = React.useRef<MediaStream | null>(null);
  const mediaRecorder = React.useRef<MediaRecorder | null>(null);
  const [recording, setRecording] =
    React.useState<RecordingStatus>("not-active");
  const [recordingType, setRecordingType] =
    React.useState<RecordingType>("video");

  const countdownTime =
    recording === "active" ? Math.max(timeout - passedTime, 0) : null;

  useEffect(() => {
    const checkPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "camera" as PermissionName,
        });
        const microphonePermissionStatus = await navigator.permissions.query({
          name: "microphone" as PermissionName,
        });

        if (
          permissionStatus.state === "granted" &&
          microphonePermissionStatus.state === "granted"
        ) {
          setHasPermission(true);
        } else {
          setHasPermission(false);
        }
      } catch (error) {
        setHasPermission(false);
      }
    };

    checkPermission();
  }, []);

  useEffect(() => {
    const recordingTime = Math.max(passedTime - timeout, 0);

    recordingTimeRef.current = recordingTime;
  }, [passedTime]);

  useEffect(() => {
    if (recording !== "active") return;

    const interval = setInterval(() => {
      if (recording) {
        setPassedTime((time) => time + 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [recording]);

  const askRecordingPermission = useCallback(
    async (recordingType: RecordingType) => {
      try {
        const constraints = getContraints(recordingType);

        navigator.mediaDevices.getUserMedia(constraints).then((_stream) => {
          setHasPermission(true);

          _stream.getTracks().forEach((track) => track.stop());
        });
      } catch (error) {
        // Handle the error
        setHasPermission(false);
      }
    },
    []
  );

  const record = useCallback((recordingType: RecordingType) => {
    cancelledRef.current = false;
    setRecording("active");
    setRecordingType(recordingType);

    const constraints = getContraints(recordingType);

    setTimeout(() => {
      if (mediaRecorder.current) {
        mediaRecorder.current.start();
      }
    }, timeout * 1000);

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((_stream) => {
        mediaStream.current = _stream;
        mediaRecorder.current = new MediaRecorder(_stream, {
          audioBitsPerSecond: 128_000,
          videoBitsPerSecond: 1_000_000,
        });

        mediaRecorder.current.ondataavailable = ({ data }: BlobEvent) => {
          mediaChunks.current.push(data);
        };

        mediaRecorder.current.onstop = () => {
          if (cancelledRef.current) {
            return;
          }

          const [chunk] = mediaChunks.current;

          const blobProperty: BlobPropertyBag = Object.assign(
            { type: chunk.type },
            recordingType === "video"
              ? { type: "video/mp4" }
              : { type: "audio/wav" }
          );
          const blob = new Blob(mediaChunks.current, blobProperty);
          const filename =
            recordingType === "video" ? "video.mp4" : "audio.wav";

          const file = new File([blob], filename, blobProperty);

          onRecordingStop.current({
            type: recordingType,
            file,
            duration: Math.max(recordingTimeRef.current * 1000, 0),
          });

          mediaRecorder.current = null;
          mediaChunks.current = [];

          if (mediaStream.current) {
            const tracks = mediaStream.current.getTracks();

            tracks.forEach((track) => {
              track.stop();
            });

            mediaStream.current = null;
          }
        };

        setHasPermission(true);
      })
      .catch((err) => {
        if (err.name === "NotAllowedError") {
          setHasPermission(false);
        }
      });

    setPassedTime(0);
    mediaChunks.current = [];
  }, []);

  const cancelRecording = useCallback(() => {
    mediaRecorder.current = null;
    mediaChunks.current = [];
    cancelledRef.current = true;

    setRecording("not-active");

    if (mediaStream.current) {
      const tracks = mediaStream.current.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });

      mediaStream.current = null;
    }
  }, []);

  const retry = useCallback(() => {
    cancelRecording();
    setTimeout(() => {
      record(recordingType);
    }, 100);
  }, [recordingType, cancelRecording, record]);

  const finishRecording = useCallback(() => {
    setRecording("not-active");

    if (
      mediaStream.current &&
      mediaRecorder.current &&
      ["recording", "paused"].includes(mediaRecorder.current.state)
    ) {
      mediaRecorder.current.stop();

      const tracks = mediaStream.current.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    }
  }, []);

  const pauseRecording = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      setRecording("paused");
      mediaRecorder.current!.pause();
    }
  }, []);

  const resumeRecording = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      setRecording("active");
      mediaRecorder.current!.resume();
    }
  }, []);

  return (
    <RecordingContext.Provider
      value={{
        recording,
        recordingType,
        recordingTime: Math.max(passedTime - timeout, 0),
        countdownTime,
        record,
        retry,
        hasPermission,
        askRecordingPermission,
        deviceSupported,
        mediaRecorder,
        onRecordingStop,
        cancelRecording,
        pauseRecording,
        resumeRecording,
        finishRecording,
      }}
    >
      {props.children}
    </RecordingContext.Provider>
  );
}

export function useRecording(props?: {
  onRecordingStop: (file: RecordedMedia) => void;
}) {
  const context = React.useContext(RecordingContext);

  if (context === undefined) {
    throw new Error("useRecording must be used within a RecordingProvider");
  }

  useEffect(() => {
    if (!props) return;

    context.onRecordingStop.current = props.onRecordingStop;
  }, [props, context.onRecordingStop]);

  return context;
}
