import { authorised } from "../../request";

export async function deleteUserFromWorkspace(data: {
  workspaceId: string;
  userId: number;
}) {
  const API = authorised();

  return await API.request({
    method: "DELETE",
    url: `/api/studio/themis/workspaces/${data.workspaceId}/delete-users/`,
    data: {
      user_ids: [data.userId],
    },
  });
}
