import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { customToast } from "../../components/customToast";
import { Button } from "../../VideoEditor/components/Button";
import { Column, Spacing } from "../../helpers/layout";
import OtpInput from "react-otp-input";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { confirmEmail } from "../../actions/auth/confirmEmail";
import {
  resendConfirmationEmail,
  USER_CONFIRM_EMAIL,
} from "../../actions/auth/resendConfirmationEmail";
import { useAuth } from "../../contexts/UserContext";
import { useCheckMobile } from "../../hooks/useCheckMobile";

interface FormValues {
  code: string;
}

export function VerifyEmailForm() {
  const history = useHistory();
  const { authenticate } = useAuth();
  const { isMobile } = useCheckMobile();
  const otpRe = new RegExp("\\d{4}");

  const userEmail = localStorage.getItem(USER_CONFIRM_EMAIL);

  const { t } = useTranslation();

  return (
    <Formik<FormValues>
      initialValues={{
        code: "",
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().matches(otpRe).required(),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        try {
          setSubmitting(true);
          const response = await confirmEmail(values.code);

          authenticate(response.data.token, response.data.userprofile);

          customToast.success(t("status.success"));
          history.push("/role");
        } catch (error: any) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, isValid, values, setValues, setSubmitting }) => (
        <Form
          noValidate
          css={css`
            ${isMobile &&
            css`
              display: flex;
              flex-direction: column;
              height: 100%;
            `}
          `}
        >
          <Column
            gutter={Spacing.l}
            css={css`
              margin-top: 50px;

              ${isMobile &&
              css`
                margin-top: 40px;
              `};
            `}
          >
            <OtpInput
              value={values.code}
              onChange={(value) => setValues({ code: value })}
              shouldAutoFocus
              numInputs={4}
              containerStyle={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "30px",
                padding: isMobile ? "0" : "0 20px",
              }}
              inputStyle={{
                width: "75px",
                height: "75px",
                border: isValid ? "1px solid #8E8E8E" : "1px solid #FF0000",
                borderRadius: "6px",
                fontSize: "30px",
              }}
              inputType="text"
              renderInput={(inputProps, i) => <input {...inputProps} />}
            />
          </Column>
          <div
            css={css`
              display: flex;
              flex-direction: column;

              ${isMobile &&
              css`
                flex-direction: column-reverse;
                height: 100%;
              `}
            `}
          >
            <Button
              isSubmitting={isSubmitting}
              disabled={!isValid}
              type="submit"
              css={css`
                margin: 40px auto;
                line-height: 28px;
                padding: 6px 26px;
                color: #fff;
                font-size: 16px;

                ${isMobile &&
                css`
                  font-size: 20px;
                  padding: 10px 0;
                  width: 100%;
                  margin: auto 0 0 0;
                `};
              `}
            >
              {t("auth.role.cta")}
            </Button>
            <div
              css={css`
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                justify-content: center;
                display: flex;
              `}
            >
              <div>{t("auth.verify.no-receive")} &nbsp;</div>
              <div
                onClick={async () => {
                  if (!userEmail) return;

                  setSubmitting(true);

                  try {
                    await resendConfirmationEmail(userEmail);
                  } catch (error) {
                    customToast.error(
                      t("status.error", {
                        error: serverErrorHandler(error),
                      })
                    );
                  } finally {
                    setSubmitting(false);
                  }
                }}
                css={css`
                  font-weight: 600;
                  cursor: pointer;
                `}
              >
                {t("auth.verify.resend")}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
