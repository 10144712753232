import { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { css } from "styled-components/macro";
import { v4 as uuid } from "uuid";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../contexts/UserContext";
import { DropImage } from "../components/DropImage";
import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";

import { Image } from "../types/Image";

import { InputComponent } from "./InputComponent";
import { Headers } from "../helpers/headers";

import { ReactComponent as FolderIcon } from "../assets/icons/Folder.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/Search.svg";

import { getImages } from "../../actions/image";
import { pageLoading } from "../../helpers/pageLoading";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";

import { defaultSequenceDuration } from "../../constants";

const ImageElement = (props: { image: Image }) => {
  const { image } = props;
  const { activeScene } = usePlayback();
  const { api } = useStorage();
  const { userprofile } = useAuth();

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: "element",
    item: {
      type: "image",
      image: image,
      states: [
        {
          id: uuid(),
          width: image.width,
          height: image.height,
        },
      ],
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const createElement = () => {
    if (!activeScene || !userprofile) return;

    api.createElement({
      id: uuid(),
      scene_id: activeScene.id,
      order: activeScene.elements.length,
      type: "image",
      image: image,
      states: [
        {
          id: uuid(),
          left: 100,
          top: 100,
          width: 100,
          height: image.height / (image.width / 100),
          scale: 1,
          rotation: 0,
          start_time: 0,
          duration: defaultSequenceDuration,
        },
      ],
      config: {},
    });
  };

  return (
    <div
      ref={dragPreview}
      onClick={createElement}
      style={{
        position: "relative",
        width: "100%",
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
    >
      <div
        css={css`
          margin-bottom: 0px;

          img {
            max-width: 100%;
            display: block;
          }
        `}
      >
        <img src={image.image} alt={image.upload_name} />
      </div>
      <div
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        `}
        ref={drag}
      ></div>
    </div>
  );
};

type ImageSelectorProps = {
  className?: string;
  enableSearch?: boolean;
  onShowMore: () => void;
  limit?: number;
  preview?: boolean;
};

export const ImageSelector = (props: ImageSelectorProps) => {
  const [loadedImages, setLoadedImages] = useState<Image[]>([]);

  const loader = useRef(null);
  const [query, setQuery] = useState("");
  const { activeScene } = usePlayback();
  const { api } = useStorage();
  const { userprofile } = useAuth();
  const { t } = useTranslation();
  const [debouncedQuery] = useDebounce(query, 500);

  const {
    response: { data: images, error: imagesError },
    data: { onClear },
  } = useInfiniteScroll<
    {
      count: number;
      next: null | string;
      previous: null | string;
      results: Image[];
    },
    {
      name: string;
    }
  >(getImages, {
    internalArgs: {
      name: debouncedQuery,
    },
    limit: 30,
    containerRef: props.preview ? null : loader,
  });

  useEffect(() => {
    images &&
      images.results &&
      setLoadedImages((loadedImages) => [...loadedImages, ...images.results]);
  }, [images]);

  const onSearch = (text: string) => {
    onClear();
    setLoadedImages([]);
    setQuery(text);
  };

  const uploadImage = (image: Image) => {
    if (!activeScene || !userprofile) return null;

    setLoadedImages((loadedImages) => [image, ...loadedImages]);

    api.createElement({
      id: uuid(),
      type: "image",
      image: image,
      scene_id: activeScene.id,
      config: {},
      order: activeScene.elements.length,
      states: [
        {
          id: uuid(),
          left: 100,
          top: 100,
          width: 100,
          height: image.height / (image.width / 100),
          scale: 1,
          rotation: 0,
          start_time: 0,
          duration: defaultSequenceDuration,
        },
      ],
    });
  };

  if (!images || imagesError) {
    return pageLoading(imagesError);
  }

  return (
    <div className={props.className}>
      {!props.enableSearch && (
        <DropImage
          initialMessage={
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 7px 10px;
              `}
            >
              <FolderIcon
                css={css`
                  align-self: flex-start;
                  width: 42px;
                  margin-top: 3px;
                `}
              />
              <Headers.H5
                css={css`
                  margin-left: 7px;
                `}
              >
                {t("media.sidebar.dragDrop")}
              </Headers.H5>
            </div>
          }
          onUpload={(image, _) => uploadImage(image)}
        />
      )}
      {props.enableSearch && (
        <div>
          <InputComponent
            value={query}
            onChange={(e) => onSearch(e.target.value)}
            icon={<SearchIcon />}
            css={css`
              height: 40px;
              padding-left: 40px;
            `}
          />
          {debouncedQuery && (
            <Headers.H4
              css={css`
                font-size: 16px;
                margin-top: 20px;
              `}
            >
              {loadedImages.length > 0
                ? `${t("media.sidebar.search.results")}${debouncedQuery}`
                : t("media.sidebar.search.images.no-results")}
            </Headers.H4>
          )}
        </div>
      )}
      <div>
        {!props.enableSearch && (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-top: 30px;
            `}
          >
            <Headers.H3>{t("media.sidebar.title.images")}</Headers.H3>
            <button
              css={css`
                border: none;
                background-color: transparent;
                align-self: flex-end;
                margin-bottom: 11px;
                text-decoration: underline;
                cursor: pointer;
                font-size: 14px;
              `}
              onClick={props.onShowMore}
            >
              {t("media.sidebar.button.showMore")}
            </button>
          </div>
        )}

        <div
          css={css`
            position: relative;
            display: flex;
            flex-flow: row wrap;
            margin-top: 12px;
          `}
        >
          {loadedImages.slice(0, props.preview ? 4 : undefined).map((image) => (
            <div
              key={image.uuid}
              css={css`
                width: 48%;
                margin-bottom: 4%;
                &:nth-child(odd) {
                  margin-right: 4%;
                }
                img {
                  width: 100%;
                  height: 73px;
                  border-radius: 9px;
                  object-fit: cover;
                }
              `}
            >
              <ImageElement image={image} />
            </div>
          ))}
          <div ref={loader} />
        </div>
      </div>
    </div>
  );
};
