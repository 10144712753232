import { VideoElement, VideoScene } from "../../../VideoEditor/types/Video";
import { generateOnboardingProcessIntroductionTemplate } from "./process_introduction";
import { Template } from "../types";
import { generateOnboardingClientContactsTemplate } from "./client_contacts";
import { generateOnboardingClientDocumentsTemplate } from "./client_documents";
import { generateOnboardingClientHistoryTemplate } from "./client_history";
import { generateOnboardingClientIntroductionTemplate } from "./client_introduction";
import { generateOnboardingClientProjectTemplate } from "./client_project";
import { generateOnboardingGoalsTemplate } from "./goals";
import { generateOnboardingIntroductionTemplate } from "./introduction";
import { generateOnboardingOverviewTemplate } from "./overview";
import { generateOnboardingProcessStepsTemplate } from "./process_steps";
import { generateOnboardingProcessTipsTemplate } from "./process_tips";
import { generateOnboardingProjectDocumentsTemplate } from "./project_documents";
import { generateOnboardingProjectIntroductionTemplate } from "./project_introduction";
import { generateOnboardingProjectNextStepsTemplate } from "./project_next_steps";
import { generateOnboardingProjectStatusTemplate } from "./project_status";
import { generateOnboardingProjectTeamTemplate } from "./project_team";
import { generateOnboardingProjectWaysTemplate } from "./project_ways";
import { generateOnboardingWelcomeTemplate } from "./welcome";
import { generateOnboardingToolIntroductionTemplate } from "./tool_introduction";
import { generateOnboardingToolStepsTemplate } from "./tool_steps";
import { generateOnboardingToolTipsTemplate } from "./tool_tips";
import { generateOnboardingTeamTemplate } from "./team";
import { generateOnboardingStakeholdersTemplate } from "./stakeholders";
import { generateOnboardingUsefulResourcesTemplate } from "./useful_resources";
import { generateOnboardingNextStepsTemplate } from "./next_steps";

export function generateOnboardingTemplate(
  data: Template,
  t: any
): {
  scenes: VideoScene[];
  elements: VideoElement[];
} {
  let scenes: VideoScene[] = [];
  let elements: VideoElement[] = [];
  let count = 0;

  if ("onboarding-introduction" in data.selectedTopics) {
    const topics = data.selectedTopics["onboarding-introduction"];

    if (topics.includes("welcome")) {
      const template = generateOnboardingWelcomeTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }
    if (topics.includes("about-the-company")) {
      const template = generateOnboardingIntroductionTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("overview-of-the-role")) {
      const template = generateOnboardingOverviewTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("goals-and-priorities")) {
      const template = generateOnboardingGoalsTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }
  }

  if ("onboarding-topic" in data.selectedTopics) {
    const topics = data.selectedTopics["onboarding-topic"];

    if (topics.includes("client")) {
      const template1 = generateOnboardingClientIntroductionTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);

      const template2 = generateOnboardingClientContactsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template2.scenes);
      elements = elements.concat(template2.elements);

      const template3 = generateOnboardingClientHistoryTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template3.scenes);
      elements = elements.concat(template3.elements);

      const template4 = generateOnboardingClientProjectTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template4.scenes);
      elements = elements.concat(template4.elements);

      const template5 = generateOnboardingClientDocumentsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template5.scenes);
      elements = elements.concat(template5.elements);
    }
    if (topics.includes("project")) {
      const template1 = generateOnboardingProjectIntroductionTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);

      const template2 = generateOnboardingProjectTeamTemplate(data, count++, t);

      scenes = scenes.concat(template2.scenes);
      elements = elements.concat(template2.elements);

      const template3 = generateOnboardingProjectStatusTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template3.scenes);
      elements = elements.concat(template3.elements);

      const template4 = generateOnboardingProjectWaysTemplate(data, count++, t);

      scenes = scenes.concat(template4.scenes);
      elements = elements.concat(template4.elements);

      const template5 = generateOnboardingProjectDocumentsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template5.scenes);
      elements = elements.concat(template5.elements);

      const template6 = generateOnboardingProjectNextStepsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template6.scenes);
      elements = elements.concat(template6.elements);
    }

    if (topics.includes("process")) {
      const template1 = generateOnboardingProcessIntroductionTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);

      const template2 = generateOnboardingProcessStepsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template2.scenes);
      elements = elements.concat(template2.elements);

      const template3 = generateOnboardingProcessTipsTemplate(data, count++, t);

      scenes = scenes.concat(template3.scenes);
      elements = elements.concat(template3.elements);
    }

    if (topics.includes("tool-walk-through")) {
      const template1 = generateOnboardingToolIntroductionTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);

      const template2 = generateOnboardingToolStepsTemplate(data, count++, t);

      scenes = scenes.concat(template2.scenes);
      elements = elements.concat(template2.elements);

      const template3 = generateOnboardingToolTipsTemplate(data, count++, t);

      scenes = scenes.concat(template3.scenes);
      elements = elements.concat(template3.elements);
    }
  }

  if ("newstarter-work" in data.selectedTopics) {
    const topics = data.selectedTopics["newstarter-work"];

    if (topics.includes("team")) {
      const template = generateOnboardingTeamTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("stakeholders")) {
      const template = generateOnboardingStakeholdersTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }
  }

  if ("anything-else" in data.selectedTopics) {
    const topics = data.selectedTopics["anything-else"];

    if (topics.includes("useful-resources")) {
      const template = generateOnboardingUsefulResourcesTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("next-steps")) {
      const template = generateOnboardingNextStepsTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }
  }

  return {
    scenes: scenes,
    elements: elements,
  };
}
