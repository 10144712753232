import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../components/Modal";
import { useStorage } from "../VideoEditor/contexts/StorageContext";
import { Button } from "../components/Button";
import { Share } from "../businessComponents/Share/Share";
import { InviteCollaborators } from "../businessComponents/Share/InviteCollaborators";
import { Video } from "../VideoEditor/types/Video";
import { LifetimeTooltip } from "../components/Tooltip";
import { InviteViewer } from "../businessComponents/Share/InviteViewer";
import { CollaboratorRole } from "../VideoEditor/types/Collaborator";
import { DisableUI } from "../VideoEditor/components/DisableUI";
import { useAuth } from "../contexts/UserContext";

export type RoleOptionType = { value: CollaboratorRole; label: string };

export enum ModalShareScreen {
  MAIN = "main",
  COLLABORATORS = "collaborators",
  VIEWERS = "viewers",
}

export type ShareProps = {
  onScreenChange: (screenName: ModalShareScreen) => void;
  video?: Video;
};

export function ModalShare(props: { isOpen: boolean; onClose: () => void }) {
  const { video } = useStorage();
  const { t } = useTranslation();
  const { userprofile } = useAuth();
  const [activeScreen, setActiveScreen] = useState<ModalShareScreen>(
    ModalShareScreen.MAIN
  );

  // const protocol = window.location.protocol;
  // const host = window.location.host;
  // const link = `<iframe width="707" height="394" src="${protocol}//${host}/embed/${video?.uuid}/" title="Themis video player" frameborder="0" allow="autoplay; clipboard-write; web-share" allowfullscreen></iframe>`;

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{
        maxWidth: "640px",
        padding: "40px",
      }}
    >
      <ModalHeader
        onClose={props.onClose}
        css={css`
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
        `}
      >
        {t("share.title")}
      </ModalHeader>
      <ModalBody
        css={css`
          padding: 0;
        `}
      >
        {activeScreen === ModalShareScreen.MAIN && (
          <Share
            onScreenChange={(screen: ModalShareScreen) =>
              setActiveScreen(screen)
            }
          />
        )}
        {activeScreen === ModalShareScreen.COLLABORATORS && (
          <InviteCollaborators
            onScreenChange={(screen: ModalShareScreen) =>
              setActiveScreen(screen)
            }
          />
        )}
        {activeScreen === ModalShareScreen.VIEWERS && (
          <InviteViewer
            onScreenChange={(screen: ModalShareScreen) =>
              setActiveScreen(screen)
            }
          />
        )}
      </ModalBody>
      {activeScreen === ModalShareScreen.MAIN && (
        <ModalFooter
          css={css`
            margin-top: 45px;
            justify-content: flex-end;
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <Button
              onClick={props.onClose}
              hoverStyles="none"
              color="#000"
              background="#fff"
              css={css`
                border: 1.5px solid #252424;
                margin-right: 18px;
              `}
            >
              {t("share.cta.cancel")}
            </Button>
            <LifetimeTooltip
              activateOnHover
              lifeTime={3000}
              width={150}
              show={
                !!video?.duration &&
                !!userprofile?.restrictions.duration &&
                video?.duration > userprofile?.restrictions.duration
              }
              text={t("video-editor.header.limitations.max-length")}
              css={css`
                height: 100%;
              `}
              noArrow
            >
              <DisableUI
                show={
                  !!video?.duration &&
                  !!userprofile?.restrictions.duration &&
                  video?.duration > userprofile?.restrictions.duration
                }
                css={css`
                  height: 100%;
                `}
              >
                <Button
                  onClick={props.onClose}
                  hoverStyles="none"
                  color="#fff"
                  background="#E95B2E"
                  disabled={
                    !!video?.duration &&
                    !!userprofile?.restrictions.duration &&
                    video?.duration > userprofile?.restrictions.duration
                  }
                >
                  {t("share.cta.apply")}
                </Button>
              </DisableUI>
            </LifetimeTooltip>
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
}
