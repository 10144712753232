import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { sendEvent } from "../helpers/tracking";
import { PlayerProvider } from "../VideoPlayer/contexts/PlayerContext";
import { ViewerCommentsProvider } from "../VideoPlayer/contexts/ViewerCommentsContext";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { PlaybackProvider } from "./contexts/PlaybackContext";
import { useAuth } from "../contexts/UserContext";

export function VideoPreviewMiddleware() {
  const { isAuthenticated } = useAuth();
  const params = useParams<{
    videoId: string;
  }>();

  useEffect(() => {
    sendEvent("View Themi", {
      themiId: params.videoId,
    });
  }, [params.videoId]);

  if (!isAuthenticated) {
    return (
      <PlayerProvider videoId={params.videoId} public>
        {(video) => (
          <PlaybackProvider video={video} autoPause>
            <VideoPlayer preview />
          </PlaybackProvider>
        )}
      </PlayerProvider>
    );
  }

  return (
    <PlayerProvider videoId={params.videoId}>
      {(video) => {
        if (video.current_user_role) {
          return (
            <PlaybackProvider video={video} autoPause>
              <ViewerCommentsProvider videoId={params.videoId}>
                <VideoPlayer commentMode />
              </ViewerCommentsProvider>
            </PlaybackProvider>
          );
        }
        return (
          <PlaybackProvider video={video} autoPause>
            <VideoPlayer preview />
          </PlaybackProvider>
        );
      }}
    </PlayerProvider>
  );
}
