import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { customToast } from "../components/customToast";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import AsyncCreatableSelect from "react-select/async-creatable";
import { StylesConfig } from "react-select";

type Option = { label: string; value: string };

export type QueryInputProps<T, Q> = {
  value: Option[];
  onChange: (value: Option[]) => void;
  query: {
    getFunction: (args: Q) => Promise<AxiosResponse<T>>;
    options: Q;
    mapper: (response: AxiosResponse<T>) => Array<Option>;
  };
  styles?: StylesConfig;
  hideArrow?: boolean;
  maxLimit?: number;
  disableSearch?: boolean;
};

export function QueryInput<T, Q>(props: QueryInputProps<T, Q>) {
  const { getFunction, options } = props.query;
  const { t } = useTranslation();
  const maxLimit = props.maxLimit || 1;

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <AsyncCreatableSelect
        isMulti
        value={props.value}
        styles={props.styles}
        autoFocus
        allowCreateWhileLoading
        isClearable={false}
        components={
          props.hideArrow
            ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
            : {}
        }
        loadOptions={async (inputValue) => {
          if (props.disableSearch) {
            return [];
          }

          try {
            const response = await getFunction({
              ...options,
              query: inputValue,
            });
            const data = props.query.mapper(response);

            return data;
          } catch (error) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          }

          return [];
        }}
        formatCreateLabel={(inputValue) => {
          return t("share.invite", { email: inputValue });
        }}
        isOptionDisabled={(option) => props.value.length >= maxLimit}
        onChange={(value: any) => {
          if (value) {
            props.onChange(value);
          }
        }}
        defaultOptions={true}
      />
    </div>
  );
}
