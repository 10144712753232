import React from "react";
import { VideoDocument, VideoElementState } from "../../types/Video";
import { getInPixelCurry } from "./";

export function DocumentRenderer(props: {
  element: VideoDocument;
  state: VideoElementState;
  width: number;
}) {
  const getInPixel = getInPixelCurry(0.65)(props.width);

  return (
    <div
      style={{
        backgroundColor: "white",
        border: "1px solid rgb(234, 232, 232)",
        borderRadius: "9px",
        display: "flex",
        alignItems: "center",
        padding: `${getInPixel(29)}px ${getInPixel(19)}px`,
        fontWeight: 500,
        lineHeight: "120%",
        position: "relative",
      }}
    >
      <div
        style={{
          width: `${getInPixel(61)}px`,
          height: `${getInPixel(61)}px`,
          minWidth: `${getInPixel(61)}px`,
          minHeight: `${getInPixel(61)}px`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(250, 231, 217)",
          borderRadius: "6px",
          marginRight: `${getInPixel(20)}px`,
        }}
      >
        <svg
          style={{
            width: `${getInPixel(24)}px`,
            height: `${getInPixel(31)}px`,
          }}
          viewBox="0 0 24 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6 0H2.4C0.360033 0 0 0.457284 0 2.44737V28.5526C0 30.584 0.439967 31 2.4 31H21.6C23.6 31 24 30.6169 24 28.5526V10.6053H13.6V0ZM24 8.1579L16 0V8.1579H24Z"
            fill="rgb(233, 91, 46)"
          />
        </svg>
      </div>

      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <div
          style={{
            fontSize: `${getInPixel(20)}px`,
            color: "black",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: 1,
          }}
        >
          {props.element.document.title}
        </div>

        {props.element.document.description && (
          <div
            style={{
              fontSize: `${getInPixel(16)}px`,
              color: "rgb(97, 97, 97)",
              marginTop: `${getInPixel(11)}px`,
              lineHeight: "1.3",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {props.element.document.description}
          </div>
        )}

        {(props.element.document.file?.file || props.element.document.link) && (
          <div
            style={{
              position: "absolute",
              bottom: `${getInPixel(6)}px`,
              right: `${getInPixel(10)}px`,
            }}
          >
            <span>
              <svg
                style={{
                  width: `${getInPixel(13)}px`,
                  height: `${getInPixel(13)}px`,
                }}
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.30656 12.9999H9.01654C9.62827 12.9999 10.2149 12.7569 10.6476 12.3243C11.0801 11.8917 11.3231 11.305 11.3231 10.6933V8.17705H10.065V10.6933C10.065 10.9714 9.95454 11.2381 9.75789 11.4346C9.56131 11.6313 9.29462 11.7417 9.01654 11.7417H2.30656C2.02848 11.7417 1.76179 11.6313 1.56521 11.4346C1.36856 11.2381 1.25812 10.9714 1.25812 10.6933V3.98331C1.25812 3.70523 1.36856 3.43855 1.56521 3.24197C1.76179 3.04532 2.02848 2.93488 2.30656 2.93488H4.8228V1.67676H2.30656C1.69483 1.67676 1.10816 1.91975 0.675542 2.3523C0.242992 2.78492 0 3.37159 0 3.98331V10.6933C0 11.305 0.242992 11.8917 0.675542 12.3243C1.10816 12.7569 1.69483 12.9999 2.30656 12.9999Z"
                  fill="rgb(37, 36, 36)"
                />
                <path
                  d="M10.2997 6.31515L12.816 3.79891C12.9337 3.68096 12.9999 3.52104 12.9999 3.35435C12.9999 3.18767 12.9337 3.02775 12.816 2.9098L10.2997 0.393555L9.41068 1.28261L10.8533 2.72529H9.22612C7.94705 2.72529 6.72036 3.2334 5.81591 4.13788C4.91143 5.0423 4.40332 6.269 4.40332 7.54809V9.64496H5.66144V7.54809C5.66144 6.60267 6.03699 5.69603 6.70551 5.02748C7.37403 4.35893 8.2807 3.98341 9.22612 3.98341H10.8533L9.41068 5.42604L10.2997 6.31515Z"
                  fill="rgb(37, 36, 36)"
                />
              </svg>

              <span
                style={{
                  fontWeight: 500,
                  fontSize: `${getInPixel(16)}px`,
                  lineHeight: "120%",
                  color: "rgb(37, 36, 36)",
                  marginLeft: `${getInPixel(7)}px`,
                }}
              >
                Open file
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
