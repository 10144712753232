import styled from "styled-components/macro";

export const ToolbarButton = styled.button`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 6px 10px;
  background-color: unset;
  background-position: center;
  transition: background 0.4s ease, border 0.4s ease, color 0.4s ease;
  border-radius: 2px;
  border: 1px solid transparent;
  :hover {
    background: #fae7d9 radial-gradient(circle, transparent 1%, #fae7d9 1%)
      center/15000%;
    color: #e95b2e;
    border: 1px solid #e95b2e;
  }
  :active {
    background-color: #e95b2e;
    background-size: 100%;
    transition: background 0s;
  }
  :disabled {
    pointer-events: none;
    background-color: none;
  }
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
`;
