import React from "react";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStorage } from "../contexts/StorageContext";
import { TooltipArea } from "../../components/Tooltip";
import { ReactComponent as ViewingModeIcon } from "../../assets/icons/ViewingMode.svg";

const ViewingModePure = () => {
  const { video } = useStorage();
  const { t } = useTranslation();

  if (!video) return null;

  return (
    <TooltipArea
      text={t("video-editor.header.viewing-mode")}
      staticPosition
      offsetX={-30}
      offsetY={25}
      noArrow
    >
      <Link to={`/video-preview/${video.uuid}`}>
        <button
          css={css`
            width: 40px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f2f2f2;
            box-shadow: 0px 0px 13px #e5e5e5;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
              fill: #e95b2e !important;
            }

            path {
              stroke-width: 0 !important;
            }
          `}
        >
          <ViewingModeIcon />
        </button>
      </Link>
    </TooltipArea>
  );
};
export const ViewingMode = React.memo(ViewingModePure);
