import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { useWorkspace } from "../../contexts/WorkspaceContext";
// import { ReactComponent as NavSubscription } from "../../assets/icons/NavSubscription.svg";
import { UserWorkspace, WorkspaceUserRole } from "../../types/Workspace";
import { Avatar } from "../../VideoEditor/components/Avatar";

type WorkspaceDropdownProps = {
  workspace: UserWorkspace;
};

export function WorkspaceDropdown(props: WorkspaceDropdownProps) {
  const { t } = useTranslation();
  const { workspace } = useWorkspace();

  if (!workspace) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 450px;
        background-color: #fff;
        padding: 30px;
        border: 1px solid #eae8e8;
        border-radius: 9px;
        box-sizing: border-box;
        z-index: 2;
        cursor: default;

        a {
          text-decoration: none;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <Avatar
          url={props.workspace.workspace.logo?.image}
          name={`${props.workspace.workspace.name}`}
          border
          square
          size={50}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            margin-bottom: 20px;
          `}
        >
          <div
            css={css`
              font-weight: 600;
              font-size: 20px;
              line-height: 28px;
            `}
          >
            {props.workspace.workspace.name}
          </div>
          <div
            css={css`
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #505050;
            `}
          >
            {t("workspace-settings.title")}
          </div>
        </div>
      </div>
      <div
        css={css`
          width: 100%;
          border-bottom: 1px solid #eae8e8;
          margin-bottom: 20px;
        `}
      />
      <div
        css={css`
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
        `}
      >
        {t("workspace-settings.plan")}
      </div>
      <div
        css={css`
          width: 100%;
          border-bottom: 1px solid #eae8e8;
          margin-bottom: 20px;
        `}
      />
      {[WorkspaceUserRole.OWNER, WorkspaceUserRole.ADMIN].includes(
        workspace.role
      ) && (
        <div
          css={css`
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 12px;
            cursor: pointer;
          `}
        >
          <Link to="/workspace-settings">
            {t("workspace-settings.settings")}
          </Link>
        </div>
      )}
      {[WorkspaceUserRole.OWNER, WorkspaceUserRole.ADMIN].includes(
        workspace.role
      ) && (
        <div
          css={css`
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 20px;
            cursor: pointer;
          `}
        >
          <Link to={`/workspace/${workspace.workspace.id}/user-management`}>
            {t("workspace-settings.user-management")}
          </Link>
        </div>
      )}
      {/* 
      <div
        css={css`
          border-bottom: 1px solid #eae8e8;
          margin-bottom: 20px;
          width: 100%;
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: 20px;
          `}
        >
          <NavSubscription />
        </div>
        <div
          css={css`
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-left: 10px;
            cursor: pointer;
          `}
        >
          {t("workspace-settings.subscription")}
        </div>
      </div> */}
    </div>
  );
}
