import React, {
  useState,
  useRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { css } from "styled-components/macro";

import { ReactComponent as ChevronIcon } from "../assets/icons/Chevron2.svg";

export type ExpandableHandle = {
  close: () => void;
  open: () => void;
};

export function Expandable(props: {
  children: React.ReactNode | React.ReactNode[] | string | null;
  content: React.ReactNode | React.ReactNode[] | string | null;
  arrowControl?: boolean;
  transitionDuration?: number;
  ref?: React.ForwardedRef<ExpandableHandle>;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
}) {
  const { transitionDuration = 500 } = props;
  const [open, setOpen] = useState<boolean>(false);
  const wrapRef = useRef<HTMLDivElement | null>(null);

  useImperativeHandle(props.ref, () => ({
    close() {
      setOpen(false);
    },
    open() {
      setOpen(true);
    },
  }));

  const onOpen = useCallback(() => {
    setOpen(true);
    if (props.onOpen) props.onOpen();
  }, [props]);

  const onClose = useCallback(() => {
    setOpen(false);
    if (props.onClose) props.onClose();
  }, [props]);

  const onToggle = useCallback(() => {
    if (open) {
      onClose();
    } else {
      onOpen();
    }
  }, [onClose, onOpen, open]);

  return (
    <div className={props.className}>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
        `}
        onClick={() => {
          onToggle();
        }}
      >
        {props.children}

        {props.arrowControl && (
          <div
            onClick={() => {
              onToggle();
            }}
          >
            <ChevronIcon
              css={css`
                transition: transform ${transitionDuration}ms ease-in-out;
                transform: rotate(${open ? "-90deg" : "90deg"});
              `}
            />
          </div>
        )}
      </div>

      <div
        css={css`
          transition: height ${transitionDuration}ms ease-in-out;
          height: 0;
          overflow: hidden;
          width: 100%;
          ${open &&
          wrapRef.current &&
          css`
            height: ${wrapRef.current.clientHeight}px;
          `};
        `}
        className="expand-container"
      >
        <div ref={wrapRef}>{props.content}</div>
      </div>
    </div>
  );
}
