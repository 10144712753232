import { css } from "styled-components/macro";

export function ChoseButton(props: {
  icon: React.ReactNode;
  children: string;
  onClick?: () => void;
  className?: string;
  isActive: boolean;
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 6px 14px;
        border-radius: 46px;
        transition: background 0.4s ease, box-shadow 0.4s ease;
        color: #252424;
        cursor: pointer;
        :hover {
          background: #ffffff;
          box-shadow: 0px 8px 13px #e3e3e3;
        }
        ${props.isActive &&
        css`
          background: #ffffff;
          box-shadow: 0px 8px 13px #e3e3e3;
        `}
      `}
      onClick={props.onClick}
      className={props.className}
    >
      <span
        css={css`
          display: flex;
          margin-right: 10px;
        `}
      >
        {props.icon}
      </span>

      <span
        css={css`
          display: flex;
          vertical-align: middle;
        `}
      >
        {props.children}
      </span>
    </div>
  );
}
