import welcomeThemi from "./welcome.json";
import onboardingThemi from "./onboarding.json";
import howtoThemi from "./howto.json";
import { Video } from "../VideoEditor/types/Video";

function prepareInspirationThemis(themis: Video[]): Video[] {
  return themis.map((themi) => ({
    ...themi,
    preview_schema: themi.schema.schema,
    collaborators: [],
  }));
}

export const inspirationThemis = prepareInspirationThemis([
  welcomeThemi,
  onboardingThemi,
  howtoThemi,
] as any);
