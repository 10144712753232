import React from "react";
import { css } from "styled-components/macro";
import { ReactComponent as ShareLock } from "../../assets/icons/ShareLock.svg";
import { ReactComponent as ShareCollaborator } from "../../assets/icons/ShareCollaborator.svg";
import { ReactComponent as ShareViewers } from "../../assets/icons/ShareViewers.svg";
import { ReactComponent as SharePlus } from "../../assets/icons/SharePlus.svg";
import { ReactComponent as ShareTogle } from "../../assets/icons/ShareTogle.svg";
import { ReactComponent as LabelPro } from "../../assets/icons/LabelPro.svg";

import { ModalShareScreen, ShareProps } from "../../modals/ModalShare";
import { useSharing } from "../../VideoEditor/contexts/SharingContext";
import { Collaborators } from "../../VideoEditor/components/Collaborators";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/UserContext";

type ShareMenuProps = {
  disabled?: boolean;
  isPro?: boolean;
  icon: React.ReactNode;
  title: string;
  description: string | React.ReactNode;
  cta?: () => void;
  ctaIcon?: React.ReactNode;
  ctaComponent?: React.ReactNode;
};

const ShareMenu = (props: ShareMenuProps) => {
  return (
    <div
      onClick={props.cta}
      css={css`
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        border: 1px solid #eae8e8;
        border-radius: 9px;
        padding: 18px 30px 19px 18px;
        align-items: center;
        cursor: ${props.disabled ? "default" : "pointer"};

        color: ${props.disabled ? "#8E8E8E" : "#252424"};

        :first-child {
          margin-top: 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          width: 230px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {props.icon}
        </div>
        <div
          css={css`
            margin-left: 18px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          `}
        >
          {props.title}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        `}
      >
        {props.description}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin: ${props.disabled ? "0 0 0 auto" : "0 15px 0 auto"};
        `}
      >
        {props.disabled ? <LabelPro /> : props.ctaIcon}
      </div>
    </div>
  );
};

export function Share(props: ShareProps) {
  const { t } = useTranslation();
  const { collaborators, viewers } = useSharing();
  const { userprofile } = useAuth();

  let list: ShareMenuProps[] = [
    {
      disabled: true,
      isPro: true,
      icon: <ShareLock />,
      title: t("share.workspace"),
      description: t("share.workspace_description"),
      ctaIcon: <ShareTogle />,
    },
    {
      icon: <ShareCollaborator />,
      title: t("share.invite_collaborate"),
      description: collaborators.length ? (
        <Collaborators
          dynamicBorder
          users={collaborators.map((collaborator) => collaborator.user)}
          maxCollaborators={3}
        />
      ) : (
        t("share.invite_collaborate_description")
      ),
      cta: () => props.onScreenChange(ModalShareScreen.COLLABORATORS),
      ctaIcon: <SharePlus />,
    },
    {
      icon: <ShareViewers />,
      title: t("share.invite_watch"),
      description: viewers.length ? (
        <Collaborators
          dynamicBorder
          users={viewers.map((viewer) => viewer.user)}
          maxCollaborators={3}
        />
      ) : (
        t("share.invite_watch_description")
      ),

      cta: () => props.onScreenChange(ModalShareScreen.VIEWERS),
      ctaIcon: <SharePlus />,
    },
  ];

  if (userprofile?.is_pro) {
    list = list.filter((item) => !item.isPro);
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          width: 65%;
          margin-top: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #616161;
        `}
      >
        {t("share.description")}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 35px;
        `}
      >
        {list.map((item, index) => (
          <ShareMenu key={index} {...item} />
        ))}
      </div>
    </div>
  );
}
