import {
  VideoElementImage,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { css } from "styled-components/macro";

export function ElementImageRenderer(props: {
  element: VideoElementImage;
  state: VideoElementState;
}) {
  const { cta } = props.element;

  const hasCTA =
    cta &&
    ((cta.type === "link" && cta.url) || (cta.type === "file" && cta.file));

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        cursor: ${hasCTA ? "pointer" : "default"};

        img {
          width: 100%;
          height: auto;
          user-select: none;
        }
      `}
      onClick={() => {
        if (!hasCTA) return;

        if (cta.type === "link" && cta.url) {
          window.open(cta.url, "_blank");
        } else if (cta.type === "file" && cta.file) {
          window.open(cta.file.file, "_blank");
        }
      }}
    >
      <img
        src={props.element.image.image}
        alt={props.element.image.upload_name}
        draggable={false}
      />
    </div>
  );
}
