import React, { useState, useEffect, useCallback } from "react";
import { css } from "styled-components/macro";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { PageLayout } from "../components/PageLayout";
import { SEO } from "../components/SEO";
import { Loader2 } from "../components/Loader";
import { customToast } from "../components/customToast";
import { FeedbackWidget } from "../components/FeedbackWidget";
import { Button } from "../components/Button";
import { Card, CardModeType } from "../businessComponents/Workspace/Card";
import { serverErrorHandler } from "../helpers/serverErrorHandler";

import { getSearch, SearchResult } from "../actions/search/getSearch";
import { ReactComponent as HomePlus } from "../assets/icons/HomePlus.svg";
import { ReactComponent as SearchSvg } from "../assets/icons/Search.svg";
import { ReactComponent as LogoSvg } from "../assets/icons/Logo.svg";
import { ReactComponent as LogoSadIcon } from "../assets/icons/LogoSad.svg";
import { theme } from "../themes/variables";

export function SearchPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const searchQueryParam = new URLSearchParams(window.location.search).get(
    "query"
  );
  const [searchTerm, setSearchTerm] = useState(searchQueryParam || "");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const fetchSearchResults = useCallback(async () => {
    const timeout = setTimeout(() => {
      setLoading(true);
    }, 500);

    try {
      const { data } = await getSearch({
        text: debouncedSearchTerm,
      });

      setResults(data);
    } catch (e) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(e),
        })
      );
    } finally {
      clearTimeout(timeout);
      setLoading(false);
    }
  }, [debouncedSearchTerm, t]);

  useEffect(() => {
    if (debouncedSearchTerm.trim()) {
      fetchSearchResults();
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm, t, fetchSearchResults]);

  return (
    <PageLayout
      search={
        <div
          css={css`
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            max-width: 441px;
            border-bottom: 1px solid #252424;
          `}
        >
          <SearchSvg width={25} height="30" display="flex" color="#252424" />

          <input
            placeholder={t("video-editor.search.search-placeholder")}
            onChange={(e) => {
              setSearchTerm(e.target.value);

              if (e.target.value.trim().length > 0) {
                history.push(`/search?query=${e.target.value}`);
              } else {
                history.push(`/search`);
              }
            }}
            value={searchTerm}
            css={css`
              width: 100%;
              height: 42px;
              margin-left: 10px;
              box-sizing: border-box;
              background: none;
              border: none;
              font-size: 16px;
              outline: none;
              color: #616161;

              ::-webkit-input-placeholder {
                white-space: pre-line;
                position: relative;
              }
              ::-moz-placeholder {
                white-space: pre-line;
                position: relative;
              }
              :-ms-input-placeholder {
                white-space: pre-line;
                position: relative;
              }
              :-moz-placeholder {
                white-space: pre-line;
                position: relative;
              }
              ::placeholder {
                font-size: inherit;
                color: inherit;
              }
            `}
          />
        </div>
      }
    >
      <SEO></SEO>

      <div
        css={css`
          margin-right: 20px;
        `}
      >
        <div
          css={css`
            position: relative;
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            ${loading &&
            css`
              &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #fff;
                opacity: 0.5;
              }
            `}
          `}
        >
          {/* Clear input */}
          {debouncedSearchTerm.trim().length === 0 && !loading && (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-top: 160px;
              `}
            >
              <LogoSvg />
              <p
                css={css`
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 140%;
                  color: #252424;
                  margin-top: 25px;
                `}
              >
                {t("video-editor.search.no-text")}
              </p>
            </div>
          )}

          {/* Clear input */}
          {debouncedSearchTerm.trim().length > 0 &&
            searchTerm.trim().length > 0 &&
            !results.length && (
              <div
                css={css`
                  font-size: 18px;
                  line-height: 140%;
                  color: #252424;
                  margin-left: 8px;
                `}
              >
                <p
                  css={css`
                    margin-bottom: 25px;
                  `}
                >
                  {t("video-editor.search.search-result")}
                </p>
                <div
                  css={css`
                    display: flex;
                    color: #505050;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;
                  `}
                >
                  <div>
                    <p>
                      {t("video-editor.search.no-results-1")} "
                      {debouncedSearchTerm}"
                    </p>
                    <p>{t("video-editor.search.no-results-2")}</p>
                    <ul
                      css={css`
                        margin-left: 20px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                      `}
                    >
                      <li>{t("video-editor.search.no-results-3")}</li>
                      <li>{t("video-editor.search.no-results-4")}</li>
                    </ul>
                    <p>{t("video-editor.search.no-results-5")}</p>
                  </div>

                  <LogoSadIcon
                    css={css`
                      flex: 1 0 0;
                    `}
                  />
                </div>
                <Button
                  css={css`
                    background: #e95b2e;
                    color: #fff;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                    margin-right: 20px;
                  `}
                  icon={<HomePlus />}
                  onClick={() => {
                    history.push("/themi-builder");
                  }}
                  hoverStyles="none"
                >
                  {t("video-editor.search.create-new-themi")}
                </Button>
              </div>
            )}

          {/* Results */}
          {searchTerm.trim().length > 0 && !!results.length && (
            <div>
              <p
                css={css`
                  font-size: 18px;
                  line-height: 140%;
                  color: #252424;
                  margin-bottom: 25px;
                  margin-left: 8px;
                `}
              >
                {t("video-editor.search.search-result")}
              </p>
              <div
                css={css`
                  display: grid;
                  grid-template-columns: repeat(auto-fill, 330px);
                  grid-column-gap: 40px;
                `}
              >
                {results.map((video) => {
                  return (
                    <div css={css``}>
                      <Card
                        key={video.themi.uuid}
                        video={video.themi}
                        mode={CardModeType.VIEW}
                        refetch={fetchSearchResults}
                        onClick={() =>
                          history.push("video/" + video.themi.uuid)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/* Loading state */}
          {loading && (
            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <Loader2 color={theme.colors.gray4} size={40} />
            </div>
          )}
        </div>
      </div>

      <FeedbackWidget />
    </PageLayout>
  );
}
