import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { sendEvent } from "../helpers/tracking";
import { inspirationThemis } from "../inspiration/inspirations";
import { PlayerProvider } from "../VideoPlayer/contexts/PlayerContext";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { PlaybackProvider } from "./contexts/PlaybackContext";

export function ThemiPreview() {
  const params = useParams<{
    videoId: string;
  }>();
  const location = useLocation();
  const isInspiration = location.pathname.includes("inspiration");

  useEffect(() => {
    if (isInspiration) {
      const video = inspirationThemis.find(
        (themi) => themi.uuid === params.videoId
      );

      if (video) {
        sendEvent("Example Themi Viewed", {
          themi: video.uuid,
          name: video.name,
        });
      }
    }
  }, [params.videoId, isInspiration]);

  if (isInspiration) {
    const video = inspirationThemis.find(
      (themi) => themi.uuid === params.videoId
    );

    if (!video) {
      return null;
    }

    return (
      <PlayerProvider video={video} preview>
        {() => (
          <PlaybackProvider video={video} autoPause>
            <VideoPlayer preview />
          </PlaybackProvider>
        )}
      </PlayerProvider>
    );
  }

  return (
    <PlayerProvider videoId={params.videoId} preview>
      {(video) => (
        <PlaybackProvider video={video} autoPause>
          <VideoPlayer preview />
        </PlaybackProvider>
      )}
    </PlayerProvider>
  );
}
