import React, { useCallback, useState, useEffect } from "react";
import { DateTime } from "luxon";
import { debounce, cloneDeep } from "lodash";
import striptags from "striptags";
import { css } from "styled-components/macro";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";

import { canEdit } from "../helpers/collaborator";
import {
  calculateThemiAudioElements,
  calculateThemiVideoElements,
  calculateThemiDocumentElements,
} from "../helpers/video";
import { VideoScene, ReorderingScene } from "../types/Video";
import { Collaborators } from "./Collaborators";
import { ScenePreview } from "./ScenePreview";
import { slideHeight, slideWidth } from "./SceneNavigator";
import { DisableUI } from "./DisableUI";
import { Dropdown, DropdownItem } from "../../components/Dropdown";
import { Button } from "../../components/Button";
import { TooltipArea } from "../../components/Tooltip";

import { useCollaboration } from "../contexts/CollaborationContext";
import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";
import { useAuth } from "../../contexts/UserContext";
import { useEditor } from "../contexts/EditorContext";

import { ReactComponent as DeleteIcon } from "../../assets/icons/Trash.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/icons/Duplicate.svg";
import { ReactComponent as ThreeDotsIcon } from "../../assets/icons/ThreeDots.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/Settings.svg";

import { localAPI } from "../API";

export function SceneNavigatorItem(props: {
  scene: VideoScene;
  index: number;
  moveScene: (target: ReorderingScene, replaceScene: ReorderingScene) => void;
}) {
  const { scene, index, moveScene } = props;
  const { t } = useTranslation();
  const { setTextEditorInit, setActiveElementId } = useEditor();
  const { userPositions, members } = useCollaboration();
  const { api, video } = useStorage();
  const { setCurrentTime, activeScene, scenes, scrollToScene, videoDuration } =
    usePlayback();
  const { userprofile } = useAuth();
  const [name, setName] = useState(scene.name || "Untitled");

  useEffect(() => {
    setName(scene.name);
  }, [scene]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateScene = useCallback(
    debounce((sceneId: string, text: string) => {
      api.updateScene(sceneId, {
        name: text,
      });
    }, 1000),
    [api]
  );

  const handleContentEditable = (event: any) => {
    let name = event.target.value;
    name = striptags(name, [], "") as string;

    if (name.length > 22) {
      name = name.substring(0, 22);
    }

    setName(name);
    updateScene(scene.id, name);
  };

  const collaborators = userPositions.filter((userPosition) => {
    return (
      userPosition.time >= scene.start_time &&
      userPosition.time < scene.start_time + scene.duration
    );
  });

  const sceneCollaborators = members.filter((member) => {
    return collaborators.find(
      (collaborator) => collaborator.user.id === member.id
    );
  });

  const [, drag, dragPreview] = useDrag(() => {
    return {
      type: "scene",
      item: {
        id: scene.id,
        order: index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => canEdit(video?.current_user_role),
    };
  }, [scene]);

  const [, drop] = useDrop<
    VideoScene,
    void,
    {
      isOver: boolean;
    }
  >(
    () => ({
      accept: "scene",
      drop({ id: droppedId, order }) {
        if (droppedId !== scene.id) {
          moveScene(
            {
              id: droppedId,
              order: order,
            },
            {
              id: scene.id,
              order: scene.order,
            }
          );
        }
      },
      collect(monitor) {
        return {
          isOver: monitor.isOver(),
        };
      },
    }),
    [scene]
  );

  const isActive = activeScene && activeScene.id === scene.id;

  const openSettings = useCallback(() => {
    scrollToScene(index);
    setCurrentTime(scene.start_time);
    setTextEditorInit(false);
    setActiveElementId(null);
  }, [
    index,
    scene.start_time,
    scrollToScene,
    setCurrentTime,
    setActiveElementId,
    setTextEditorInit,
  ]);

  const duplicateScene = useCallback(async () => {
    if (!userprofile || !scene) return;

    const sceneClone = cloneDeep(scene);
    const elements = scene.elements;

    if (sceneClone && video) {
      const newScene = await api.createSceneWithContent(
        {
          ...sceneClone,
          order: sceneClone.order + 0.1,
          start_time: sceneClone.start_time + sceneClone.duration,
        },
        elements
      );

      if (newScene) {
        const newOrders = [...scenes, newScene]
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((scene, i) => ({
            ...scene,
            order: i,
          }));

        await localAPI.reorderScene(
          video.uuid,
          newOrders,
          {
            userId: userprofile.id,
          },
          null
        );

        setCurrentTime(scene.start_time + scene.duration);
      }
    }
  }, [api, setCurrentTime, scene, userprofile, video, scenes]);

  const getDisabledStateText = useCallback(() => {
    if (userprofile) {
      // get duration limit
      if (videoDuration + scene.duration >= userprofile.restrictions.duration) {
        return "video-editor.limits.video-length";
      }
      // get video records limit
      const currentSceneVideoElements = calculateThemiVideoElements(
        scene.elements
      );
      if (
        userprofile.restrictions.video &&
        currentSceneVideoElements > 0 &&
        currentSceneVideoElements +
          calculateThemiVideoElements(video?.schema.schema.elements) >
          userprofile.restrictions.video
      ) {
        return "video-editor.limits.video-limit";
      }
      // get audio records limit
      const currentSceneAudioElements = calculateThemiAudioElements(
        scene.elements
      );
      if (
        userprofile.restrictions.audio &&
        currentSceneAudioElements > 0 &&
        currentSceneAudioElements +
          calculateThemiAudioElements(video?.schema.schema.elements) >
          userprofile.restrictions.audio
      ) {
        return "video-editor.limits.audio-limit";
      }
      // get document limit
      const currentSceneDocumentElements = calculateThemiDocumentElements(
        scene.elements
      );
      if (
        userprofile.restrictions.uploaded_document &&
        currentSceneDocumentElements > 0 &&
        currentSceneDocumentElements +
          calculateThemiDocumentElements(video?.schema.schema.elements) >
          userprofile.restrictions.uploaded_document
      ) {
        return "video-editor.limits.document-limit";
      }
    }

    return "";
  }, [scene, userprofile, video?.schema.schema, videoDuration]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
      `}
      ref={(node) => drag(drop(node))}
    >
      <div
        css={css`
          position: relative;
          margin-left: 20px;
        `}
      >
        <div
          ref={dragPreview}
          css={css`
            position: relative;
            width: ${slideWidth}px;
            border: 1px solid #eae8e8;
            height: auto;
            cursor: pointer;
            box-sizing: border-box;
            border-radius: 9px;
            overflow: hidden;
            ${isActive &&
            css`
              border: 1px solid #e95b2e;
            `};
          `}
          onClick={() => {
            scrollToScene(index);
            setCurrentTime(scene.start_time);
          }}
        >
          <ScenePreview scene={scene} width={slideWidth * 2} />

          <div
            css={css`
              position: absolute;
              right: 10px;
              top: 10px;
            `}
          >
            <Collaborators
              users={sceneCollaborators}
              maxCollaborators={2}
              isHover={false}
              isValueClickable={false}
            />
          </div>

          <div
            css={css`
              position: absolute;
              top: ${slideHeight - 40}px;
              left: 10px;
              padding: 0 10px;
              background: #fae7d9;

              color: #e95b2e;
              border-radius: 29.5px;
              font-size: 12px;
            `}
          >
            {DateTime.fromSeconds(scene.duration / 1000).toFormat("mm:ss")}
          </div>
        </div>

        {isActive && (
          <div
            css={css`
              position: absolute;
              background: #e95b2e;
              border-radius: 2.5px;
              height: 100%;
              width: 5px;
              top: 0;
              left: -21.5px;
            `}
          ></div>
        )}

        {canEdit(video?.current_user_role) && (
          <div
            css={css`
              position: absolute;
              top: 3px;
              right: -37px;
            `}
          >
            <Dropdown
              options={
                <>
                  <DisableUI show={!canEdit(video?.current_user_role)}>
                    <DropdownItem
                      onClick={openSettings}
                      css={css`
                        align-items: center;
                      `}
                    >
                      <SettingsIcon
                        width={12}
                        height={13}
                        css={css`
                          margin-right: 10px;
                        `}
                      />
                      {t("video-editor.header.settings")}
                    </DropdownItem>
                  </DisableUI>
                  <DisableUI show={!canEdit(video?.current_user_role)}>
                    <DropdownItem
                      css={css`
                        align-items: center;
                        ${scenes.length === 1 &&
                        css`
                          cursor: default;
                          color: rgb(142, 142, 142);
                          background: transparent;
                          :hover {
                            color: rgb(142, 142, 142);
                            background: transparent;
                          }
                        `}
                      `}
                      onClick={() => {
                        if (scenes.length > 1 && userprofile && scene) {
                          const prevScene = scenes[index - 1];
                          scrollToScene(index - 1);
                          setCurrentTime(prevScene.start_time);
                          api.deleteScene(scene.id);
                        }
                      }}
                    >
                      <DeleteIcon
                        css={css`
                          margin-right: 10px;
                        `}
                      />
                      {t("actions.delete")}
                    </DropdownItem>
                  </DisableUI>
                  <TooltipArea
                    show={!!getDisabledStateText()}
                    text={t(getDisabledStateText())}
                    staticPosition
                    maxWidth={300}
                    offsetY={-12}
                    offsetX={4}
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <DisableUI
                      show={
                        !canEdit(video?.current_user_role) ||
                        !!getDisabledStateText()
                      }
                    >
                      <DropdownItem
                        disabled={!!getDisabledStateText()}
                        onClick={duplicateScene}
                        css={css`
                          align-items: center;
                        `}
                      >
                        <DuplicateIcon
                          css={css`
                            margin-right: 10px;
                          `}
                        />
                        {t("actions.duplicate")}
                      </DropdownItem>
                    </DisableUI>
                  </TooltipArea>
                </>
              }
            >
              <Button
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  min-width: 10px;
                  background-color: transparent;
                  color: #000;
                  padding: 0;
                `}
                hoverStyles="none"
                icon={<ThreeDotsIcon />}
              ></Button>
            </Dropdown>
          </div>
        )}
      </div>

      <div
        css={css`
          margin-top: 6px;
          padding-left: 20px;
          color: #252424;
          display: flex;
          font-size: 14px;
        `}
      >
        <span
          css={css`
            margin-right: 3px;
          `}
        >
          {index + 1}.
        </span>
        <input
          css={css`
            background: transparent;
            border: none;
            font-size: 14px;
            width: 100%;
            outline: none;
            padding: none;
            font-weight: 400;
          `}
          type="text"
          value={name}
          onChange={handleContentEditable}
        />
      </div>
    </div>
  );
}
