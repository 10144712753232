import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { ReactComponent as SearchSvg } from "../assets/icons/Search.svg";
import { debounce } from "lodash";

export function Search(props: {
  className?: string;
  hasPlaceholder?: boolean;
  hasDropDown?: boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  // @ts-ignore
  const onSearch = debounce((value: string) => {
    history.push(`/search?query=${value}`);
  }, 500);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        border-bottom: 1px solid #252424;
      `}
    >
      <SearchSvg width={25} height="30" display="flex" color="#252424" />
      <input
        placeholder={t("pagelayout.search-placeholder")}
        css={css`
          width: 100%;
          height: 42px;
          box-sizing: border-box;
          background: none;
          border: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          outline: none;
          color: #616161;
          margin-left: 9px;

          ::placeholder {
            font-size: inherit;
            color: inherit;
          }
        `}
        onChange={(e) => {
          onSearch(e.target.value);
        }}
      />
    </div>
  );
}
