import { ReactComponent as CancelIcon } from "../../VideoEditor/assets/icons/Cancel.svg";
import { css } from "styled-components/macro";

export const ModalCloseButton = (props: {
  onClose?: () => void;
  className?: string;
}) => {
  return (
    <button
      css={css`
        padding: 0;
        margin: 0;
        cursor: pointer;
        white-space: nowrap;
        border: 0;
        text-transform: none;
        -webkit-appearance: none;
        outline: none;
        background: #e95b2e;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        width: 20px;
        height: 20px;
        border-radius: 50%;

        :hover {
          opacity: 0.8;
        }
        :active {
          opacity: 0.95;
        }
      `}
      className={props.className}
      onClick={props.onClose}
    >
      <CancelIcon
        css={css`
          width: 7px;
          height: 7px;
        `}
      />
    </button>
  );
};
