import React from "react";
import { css } from "styled-components/macro";

export function Badge(props: {
  color?: string;
  background?: string;
  className?: string;
  children: string | React.ReactNode | React.ReactNode[] | null;
  onClick?: () => void;
}) {
  const {
    color = "#a47aff",
    background = "#a47aff",
    children,
    className,
    onClick,
  } = props;

  return (
    <div
      css={css`
        background: ${background};
        border-radius: 18px;
        padding: 0px 11px;
      `}
      className={className}
      onClick={onClick}
    >
      <span
        css={css`
          font-size: 12px;
          line-height: 28px;
          text-align: center;
          color: ${color};
          display: flex;
          align-items: center;
        `}
      >
        {children}
      </span>
    </div>
  );
}
