import {
  VideoElementPolygon,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import { css } from "styled-components/macro";
import { calculateProportionalSize } from "../../helpers/renderer";
import { usePlayer } from "../../contexts/PlayerContext";

export function ElementPolygonRenderer(props: {
  element: VideoElementPolygon;
  state: VideoElementState;
}) {
  const { config, cta } = props.element;
  const width = props.state.width - 10;
  const height = props.state.height - 10;
  const { canvasSize } = usePlayer();
  const strokeSize = calculateProportionalSize(
    config.strokeSize,
    canvasSize.width
  );

  const hasCTA =
    cta &&
    ((cta.type === "link" && cta.url) || (cta.type === "file" && cta.file));

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        cursor: ${hasCTA ? "pointer" : "default"};
      `}
      onClick={() => {
        if (!hasCTA) return;

        if (cta.type === "link" && cta.url) {
          window.open(cta.url, "_blank");
        } else if (cta.type === "file" && cta.file) {
          window.open(cta.file.file, "_blank");
        }
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill={config.fillColor}
        fillOpacity={config.fillOpacity / 100}
        stroke={config.strokeColor}
        strokeWidth={strokeSize}
        strokeOpacity={config.strokeOpacity / 100}
      >
        <polygon
          points={`${width / 2},${strokeSize} ${strokeSize},${
            height - strokeSize
          } ${width},${height - strokeSize}`}
        />
      </svg>
    </div>
  );
}
