import { css } from "styled-components/macro";
import { useHistory, useLocation } from "react-router";
import { SEO } from "../components/SEO";
import { PageLayout } from "../components/PageLayout";
import { FeedbackWidget } from "../components/FeedbackWidget";
import {
  Navigation,
  NavigationType,
} from "../businessComponents/Workspace/Navigation";
import { WorkspaceOverviewForm } from "../forms/workspace/WorkspaceOverviewForm";
import { useTranslation } from "react-i18next";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { WorkspaceOverview } from "../types/Workspace";

type MenuItemProps = {
  title?: string;
  form: React.ReactNode;
};

function MenuItem(props: MenuItemProps) {
  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        flex-direction: row;
        margin-top: 50px;
        margin-left: 40px;
        padding-bottom: 50px;
        border-bottom: 1px solid #eae8e8;

        &:last-child {
          border-bottom: none;
        }
      `}
    >
      <div
        css={css`
          width: 250px;
        `}
      >
        {props.title && (
          <div
            css={css`
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              margin-top: 20px;
              color: #252424;
            `}
          >
            {props.title}
          </div>
        )}
      </div>
      <div
        css={css`
          display: flex;
          width: 600px;
        `}
      >
        {props.form}
      </div>
    </div>
  );
}

export function WorkspaceSettingsPage() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { workspace, workspaceApi } = useWorkspace();

  if (!workspace) return null;

  const menuItems: NavigationType[] = [
    {
      title: t("workspace-settings.overview"),
      onClick: () => {
        history.push("/workspace-settings");
      },
      isActive: location.pathname === "/workspace-settings",
    },
    // {
    //   title: t("workspace-settings.feature-access"),
    //   onClick: () => {
    //     history.push("/workspace-settings/feature-access");
    //   },
    //   isActive: location.pathname === "/workspace-settings/feature-access",
    // },
  ];

  return (
    <PageLayout>
      <SEO>
        <title>{t("workspace-settings.settings")}</title>
      </SEO>

      <div
        css={css`
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
        `}
      >
        {t("workspace-settings.settings")}
      </div>

      <div
        css={css`
          margin-top: 60px;
          width: 100%;
          position: relative;
          display: flex;

          &:before {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #eae8e8;
          }
        `}
      >
        {menuItems.map((item, key) => {
          return <Navigation {...item} key={key} />;
        })}
      </div>
      <div>
        <MenuItem
          title={t("workspace-settings.company-overview")}
          form={
            <WorkspaceOverviewForm
              data={workspace.workspace}
              onSubmit={(values: WorkspaceOverview) =>
                workspaceApi.onUpdateWorkspace(values, workspace.workspace.id)
              }
            />
          }
        />
      </div>
      <FeedbackWidget />
    </PageLayout>
  );
}
