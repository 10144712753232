import React, { useRef, useState, useCallback, ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

import { AcceptedMimeTypes } from "../../types/File";

import { CircularProgressBar } from "../../components/CircularProgressBar";
import { customToast } from "../../components/customToast";

import { ReactComponent as DocumentsIcon } from "../../assets/icons/Documents.svg";

import { statusPercent, useAi } from "../contexts/AiContext";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";

import { theme } from "../../themes/variables";
import { UploadStatus } from "../actions/getAiFileStatus";
import { Headers } from "../helpers/headers";
import { Loader } from "./Loader";
import { useAuth } from "../../contexts/UserContext";

const supportedFormats = [
  AcceptedMimeTypes.PDF,
  AcceptedMimeTypes.DOC,
  AcceptedMimeTypes.DOCX,
  AcceptedMimeTypes.PPT,
  AcceptedMimeTypes.PPTX,
  AcceptedMimeTypes.JPG,
  AcceptedMimeTypes.JPEG,
  AcceptedMimeTypes.TXT,
  AcceptedMimeTypes.PNG,
];

const statusMessage: {
  [key in UploadStatus]: string;
} = {
  [UploadStatus.ERROR]: "ml.status.error",
  [UploadStatus.UPLOADING]: "ml.status.uploading",
  [UploadStatus.PARSING]: "ml.status.parsing",
  [UploadStatus.SUMMARIZING]: "ml.status.summarizing",
  [UploadStatus.GENERATING_LAYOUT]: "ml.status.generating-layout",
  [UploadStatus.COMPLETED]: "ml.status.completed",
};

export function AiSummarizer() {
  const { t } = useTranslation();
  const { status, uploadFiles } = useAi();
  const [loading, setLoading] = useState(false);
  const { userprofile } = useAuth();

  const maxAllowedDocuments = userprofile?.is_pro
    ? Infinity
    : userprofile?.restrictions.summarised_document || 1;

  const uploadRef = useRef<HTMLInputElement>(null);

  const onUpload = useCallback(
    async (files: File[]) => {
      setLoading(true);
      try {
        await uploadFiles(files);
      } catch (e: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(e),
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [t, uploadFiles]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const files = acceptedFiles;

      if (files) {
        onUpload(files);
      }
    },
    [onUpload]
  );

  const onFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        onUpload(Array.from(e.target.files));
      }
    },
    [onUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const processedDocuments = status.filter(
    (s) => s.status !== UploadStatus.ERROR
  );

  return (
    <div>
      <Headers.H3>
        {t("ai.summarize")}{" "}
        {!userprofile?.is_pro && (
          <span
            css={css`
              font-size: 12px;
              color: #616161;
              margin-left: 10px;
            `}
          >
            {processedDocuments.length}/{maxAllowedDocuments}
          </span>
        )}
      </Headers.H3>
      <p
        css={css`
          color: #616161;
          font-size: 14px;
        `}
      >
        {t("ai.description")}
      </p>
      {processedDocuments.length < maxAllowedDocuments && (
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
          `}
        >
          <div
            {...getRootProps()}
            css={css`
              width: 100%;
              padding: 38px;
              background: #f1f1f1;
              border: 1px solid #eae8e8;
              border-radius: 9px;
              margin-top: 40px;
              cursor: pointer;
              text-align: center;

              ${isDragActive &&
              css`
                border: 1px dashed ${theme.colors.primary};
                background: #f5cdb3;
              `}

              ${loading &&
              css`
                cursor: wait;
              `}
            `}
          >
            <input
              {...getInputProps()}
              ref={uploadRef}
              onChange={onFileUpload}
              multiple
              accept={Object.values(supportedFormats).join(",")}
            />
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              `}
            >
              <div
                css={css`
                  width: 35px;
                  height: 35px;
                  background: #ffffff;
                  box-shadow: 0px 3px 8px #e5e5e5;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 20px;
                `}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <DocumentsIcon width="15" height="16" fill="#E95B2E" />
                )}
              </div>
              <Headers.H3
                css={css`
                  font-size: 14px;
                  color: #252424;
                  margin-bottom: 5px;
                  line-height: normal;
                `}
              >
                {t("ai.uploader.description")}
              </Headers.H3>
              <Headers.H4
                css={css`
                  font-size: 12px;
                  color: #616161;
                  margin-top: 0;
                  line-height: normal;
                `}
              >
                {t("ai.uploader.requirements")}
              </Headers.H4>
            </div>
          </div>
        </div>
      )}
      {processedDocuments.map((job) => (
        <div
          key={job.file_id}
          css={css`
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 25px 21px;
            gap: 12px;
            color: ${theme.colors.white};
            background: linear-gradient(
              94.89deg,
              #a47aff 35.78%,
              #c3a9fc 99.32%
            );
            border-radius: 8px;
            margin-top: 30px;
            transition: all 0.3s ease-in-out;

            ${job.status === UploadStatus.UPLOADING &&
            css`
              background: linear-gradient(
                94.89deg,
                #6c49b8 35.78%,
                #b08cff 99.32%
              );
            `}
            ${job.status === UploadStatus.PARSING &&
            css`
              background: linear-gradient(
                94.89deg,
                #6c49b8 35.78%,
                #b08cff 99.32%
              );
            `}
            ${job.status === UploadStatus.SUMMARIZING &&
            css`
              background: linear-gradient(
                94.89deg,
                #f8b856 3.06%,
                #ffd1c2 88.82%
              );
            `}
            ${job.status === UploadStatus.GENERATING_LAYOUT &&
            css`
              background: linear-gradient(
                94.89deg,
                #f8b856 3.06%,
                #ffd1c2 88.82%
              );
            `}
            ${job.status === UploadStatus.COMPLETED &&
            css`
              background: linear-gradient(
                94.89deg,
                #6c49b8 35.78%,
                #b08cff 99.32%
              );
            `}
          `}
        >
          <div
            css={css`
              flex: 1 0 10%;
              min-width: 0;
            `}
          >
            <div
              css={css`
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
              `}
            >
              {t(statusMessage[job.status])}
            </div>
            <div
              css={css`
                font-weight: 300;
                font-size: 14px;
                line-height: 22px;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {job.filename}
            </div>
          </div>
          <div
            css={css`
              width: 106px;
              height: 106px;
              flex: 0 0 auto;
              font-size: 16px;
              font-weight: 700;
            `}
          >
            <CircularProgressBar
              percent={statusPercent[job.status]}
              size={106}
              strokeWidth={10}
              color={theme.colors.white}
              backgroundColor={"rgba(255, 255, 255, 0.63)"}
            >
              {statusPercent[job.status]}%
            </CircularProgressBar>
          </div>
        </div>
      ))}
    </div>
  );
}
