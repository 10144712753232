import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { css } from "styled-components/macro";

export function ThemiBuilderHeader(props: {
  currentStep: number;
  setStep: (step: number) => void;
}) {
  const { t } = useTranslation();

  const items = [
    {
      label: t("themi-builder.header.select-category"),
      key: "select-category",
    },
    {
      label: t("themi-builder.header.select-topics"),
      key: "select-topics",
    },
    {
      label: t("themi-builder.header.themi-creator"),
      key: "themi-creator",
    },
  ];

  return (
    <div
      css={css`
        position: relative;
        height: 78px;
        padding: 0 30px;
        flex: 0 0 auto;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 4px 19px rgba(217, 217, 217, 0.25);
        z-index: 2;
      `}
    >
      <div
        css={css`
          flex: 0 0 auto;
        `}
      >
        <Link to="/">
          <img
            css={css`
              cursor: pointer;
              width: 96px;
              vertical-align: middle;
            `}
            src={logo}
            alt={`Themi Logo`}
          />
        </Link>
      </div>
      <div
        css={css`
          flex: 1 1 10%;
          padding: 0 20px;
        `}
      >
        <ul
          css={css`
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-around;
          `}
        >
          {items.map((item, index) => {
            const isPassed = props.currentStep >= index;

            return (
              <li
                css={css`
                  display: flex;
                  flex-flow: row wrap;
                  align-items: center;

                  ${index < props.currentStep &&
                  css`
                    cursor: pointer;
                  `}
                `}
                onClick={() => {
                  if (index < props.currentStep) {
                    props.setStep(props.currentStep - 1);
                  }
                }}
                key={item.key}
              >
                <div
                  css={css`
                    flex: 0 0 auto;
                    width: 22px;
                    height: 22px;
                    border: 1px solid #252424;
                    border-radius: 4px;
                    line-height: 20px;
                    text-align: center;
                    font-weight: 500;
                    font-size: 14px;
                    margin-right: 10px;

                    ${isPassed &&
                    css`
                      background: #fae7d9;
                      border: 1px solid #e95b2e;
                      color: #e95b2e;
                    `}
                  `}
                >
                  {index + 1}
                </div>
                <h4
                  css={css`
                    font-weight: 500;
                    font-size: 14px;

                    ${isPassed &&
                    css`
                      color: #e95b2e;
                    `}
                  `}
                >
                  {item.label}
                </h4>
              </li>
            );
          })}
        </ul>
      </div>
      <div
        css={css`
          position: absolute;
          width: ${((props.currentStep + 1) / items.length) * 100}%;
          height: 5px;
          left: 0px;
          top: 100%;
          margin-top: -2.5px;
          transition: width 0.5s ease-in-out;

          /* main orange */

          background: #e95b2e;
          border-radius: 2.5px;
        `}
      ></div>
    </div>
  );
}
