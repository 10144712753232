import React from "react";
import { css } from "styled-components/macro";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../assets/img/AuthLogo.png";
import AuthWomanAvatar from "../assets/img/AuthWomanAvatar.jpg";
import AuthManAvatar from "../assets/img/AuthManAvatar.jpg";
import Bullet from "../assets/icons/Bullet.svg";
import { Button } from "./Button";
import { MovingElement } from "./MovingElement";
import { AvatarCursor } from "./AvatarCursor";

export function AuthSide(props: {}) {
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  const isLoginPage = location.pathname === "/login";

  return (
    <aside
      css={css`
        position: relative;
        flex: 0 0 10%;
        min-width: calc(100% - 740px);
        height: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      `}
    >
      <MovingElement
        positionBoundaries={{ x: [-25, 50], y: [-75, 0] }}
        timeout={2000}
        initialPositions={{ x: -25, y: 100 }}
      >
        <AvatarCursor
          color="#E95B2E"
          size={38}
          avatarURL={AuthManAvatar}
          css={css`
            .triangle {
              transform: translateY(39px) translateX(33px) rotate(-195deg)
                skewY(30deg) scaleX(0.866);
            }
            .avatar {
              box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
              border-width: 2px;
            }
          `}
        />
      </MovingElement>

      <MovingElement
        positionBoundaries={{ x: [0, 50], y: [-50, 0] }}
        timeout={3500}
        css={css`
          right: 0;
        `}
        initialPositions={{ x: -150, y: 450 }}
      >
        <AvatarCursor
          color="#FFC39C"
          size={38}
          avatarURL={AuthWomanAvatar}
          css={css`
            .avatar {
              box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
              border-width: 2px;
            }
          `}
        />
      </MovingElement>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          <Button
            onClick={() => {
              if (isLoginPage) {
                history.push("/register");
              } else {
                history.push("/login");
              }
            }}
            css={css`
              margin: 28px 34px 15px auto;
            `}
            hoverStyles="none"
          >
            {isLoginPage ? t("auth.login.signup") : t("auth.slider.login")}
          </Button>
        </div>

        <div
          css={css`
            margin-top: 87px;
            font-weight: 500;
            font-size: 30px;
            line-height: 140%;
            color: #252424;
          `}
        >
          {t("auth.side.header")}
        </div>

        <p
          css={css`
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #252424;
            margin-top: 19px;
            margin-bottom: 53px;
          `}
        >
          {t("auth.side.paragraph")}
        </p>
      </div>

      <ul
        css={css`
          list-style: none;
          padding-left: 0;
          li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 27px;
            :last-of-type {
              margin-bottom: 0;
            }
            :before {
              content: "";
              width: 10px;
              height: 10px;
              position: absolute;
              background-image: url(${Bullet});
              background-size: cover;
              background-position: center;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        `}
      >
        <li>{t("auth.side.list-1")}</li>
        <li>{t("auth.side.list-2")}</li>
        <li>{t("auth.side.list-3")}</li>
      </ul>

      <div
        css={css`
          width: 100%;
          height: 100%;
          background-image: url(${Logo});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `}
      ></div>
    </aside>
  );
}
