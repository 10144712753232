import React, { useCallback, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { THEMIS_LANGUAGE_KEY } from "../../i18n";
import { setLocale } from "yup";
import deLocale from "../../helpers/localization/de";
import enLocale from "../../helpers/localization/en";
import { Dropdown } from "../../components/Dropdown";
import { ReactComponent as ShareDropdown } from "../../assets/icons/ShareDropdown.svg";

type OptionType = { value: string; label: string };

export function LanguageForm(props: { className?: string }) {
  const { i18n, t } = useTranslation();
  const [opened, setOppened] = useState<boolean>(false);

  const languages: OptionType[] = [
    {
      value: "de",
      label: t("profile_page.de"),
    },
    {
      value: "en",
      label: t("profile_page.eng"),
    },
  ];

  const handleChange = useCallback(
    (lang: OptionType | null) => {
      if (lang) {
        i18n.changeLanguage(lang.value);
        localStorage.setItem(THEMIS_LANGUAGE_KEY, lang.value);
        if (lang.value === "en") {
          setLocale(enLocale);
        } else {
          setLocale(deLocale);
        }
      }
    },
    [i18n]
  );

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-top: 12px;
      `}
    >
      <div
        onClick={() => setOppened((opened) => !opened)}
        css={css`
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 10px 11px;
          background: #ffffff;
          border: 1px solid #cdcdcd;
          border-radius: 9px;
        `}
      >
        <div
          css={css`
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
          `}
        >
          {languages.map(
            (option) => option.value === i18n.language && option.label
          )}
        </div>
        <Dropdown
          opened={opened}
          options={languages.map((option, key) => (
            <div
              key={key}
              onClick={() => handleChange(option)}
              css={css`
                cursor: pointer;
              `}
            >
              {option.label}
            </div>
          ))}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              padding: 5px;
              margin-left: 15px;
            `}
          >
            <ShareDropdown />
          </div>
        </Dropdown>
      </div>
      <div
        css={css`
          margin-left: 30px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #505050;
        `}
      >
        {t("profile_page.lang_select")}
      </div>
    </div>
  );
}
