import { viewsAPI } from "../../request";

export type ViewStatsArgs = {
  themi_id: string;
  total_views: number;
  unique_views: number;
};

export async function getViewsStats(data: { themi_id: string }) {
  const API = viewsAPI;
  return await API.request<ViewStatsArgs>({
    method: "GET",
    url: `/views/stats/${data.themi_id}`,
  });
}
