import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { SEO } from "../../components/SEO";
import { theme } from "../../themes/variables";
import { AuthLayout } from "../../components/AuthLayout";
import { Button } from "../../components/Button";
import { useHistory } from "react-router";
import { useCheckMobile } from "../../hooks/useCheckMobile";

export function CheckEmailPage(props: {}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useCheckMobile();

  return (
    <AuthLayout>
      <SEO>
        {t("auth.check-email.title")}
        <title></title>
      </SEO>
      <div
        css={css`
          max-width: 700px;
          margin: auto;
          text-align: center;
          padding: 0 150px;
          height: 100%;

          ${isMobile &&
          css`
            display: flex;
            flex-direction: column;
            text-align: left;
            padding: 0;
          `}
        `}
      >
        <div
          css={css`
            font-weight: 500;
            font-size: 30px;
            line-height: 140%;
            margin-bottom: 10px;

            ${isMobile &&
            css`
              font-weight: 600;
              font-size: 25px;
              margin-bottom: 4px;
            `}
          `}
        >
          {t("auth.check-email.title")}
        </div>
        <div
          css={css`
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            color: #616161;

            ${isMobile &&
            css`
              font-weight: 400;
              font-size: 16px;
            `}
          `}
        >
          {t("auth.check-email.description")}
        </div>
        <Button
          onClick={() => history.push("/login")}
          background={theme.colors.primary}
          hoverStyles="none"
          css={css`
            margin: 34px auto;
            line-height: 28px;
            padding: 6px 26px;
            color: #fff;
            font-size: 16px;

            ${isMobile &&
            css`
              width: 100%;
              margin: auto 0 0 0;
              font-size: 20px;
              padding: 10px 0;
            `};
          `}
        >
          {t("auth.check-email.back")}
        </Button>
      </div>
    </AuthLayout>
  );
}
