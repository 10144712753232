import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { SEO } from "../../components/SEO";
import { AuthLayout } from "../../components/AuthLayout";
import { VerifyEmailForm } from "../../forms/auth/VerifyEmailForm";
import { useCheckMobile } from "../../hooks/useCheckMobile";

export function VerifyEmailPage(props: {}) {
  const { t } = useTranslation();
  const { isMobile } = useCheckMobile();

  return (
    <AuthLayout>
      <SEO>
        <title>{t("auth.verify.title")}</title>
      </SEO>
      <div
        css={css`
          max-width: 700px;
          margin: auto;
          text-align: center;
          padding: 0 150px;
          display: flex;
          flex-direction: column;
          height: 100%;

          ${isMobile &&
          css`
            text-align: left;
            padding: 0;
          `}
        `}
      >
        <div
          css={css`
            font-weight: 500;
            font-size: 30px;
            line-height: 140%;
            max-width: 300px;
            margin: 0 auto 10px auto;

            ${isMobile &&
            css`
              font-weight: 600;
              font-size: 25px;
              margin: 0 0 4px 0;
              width: 80%;
            `}
          `}
        >
          {t("auth.verify.title")}
        </div>
        <div
          css={css`
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            color: #616161;

            ${isMobile &&
            css`
              font-weight: 400;
              font-size: 16px;
            `}
          `}
        >
          {t("auth.verify.description")}
        </div>
        <VerifyEmailForm />
      </div>
    </AuthLayout>
  );
}
