import { useEditor } from "../contexts/EditorContext";
import { ImagesSidebar } from "./ImagesSidebar";
import { UploadSidebar } from "./UploadSidebar";

export function SidebarSelector() {
  const { activeNav } = useEditor();

  if (activeNav === "media") {
    return <ImagesSidebar />;
  }

  if (activeNav === "upload") {
    return <UploadSidebar />;
  }

  return null;
}
