import { authorised } from "../../request";
import { WorkspaceUserRole } from "../../types/Workspace";

export async function inviteUsers(data: {
  workspace_id: string;
  user_emails: string[];
  message: string;
  role: WorkspaceUserRole;
}) {
  const API = authorised();

  return await API.request({
    method: "POST",
    url: `/api/studio/themis/workspaces/${data.workspace_id}/invite/`,
    data: {
      user_emails: data.user_emails,
      role: data.role,
      message: data.message,
    },
  });
}
