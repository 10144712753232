import React from "react";
import { css } from "styled-components/macro";
import { createColumnHelper } from "@tanstack/react-table";

import { Table } from "../../components/Table";
import { Avatar } from "../../VideoEditor/components/Avatar";

/** TMP type untill backend will be done */
import { Image } from "../../types/Image";

type InviteUser = {
  email: string;
  message: string;
  requester: {
    id: string;
    last_name: string;
    first_name: string;
    email: string;
    avatar: Image | null;
  };
};
/** end */

export function AcceptRequestTable(props: {}) {
  const columnHelper = createColumnHelper<InviteUser>();

  const columns = [
    columnHelper.accessor("email", {
      header: () => <span>Email</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("requester", {
      header: () => <span>Name</span>,
      cell: (info) => {
        const user = info.getValue();
        return (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Avatar
              size={24}
              url={user.avatar?.image}
              name={`${user.first_name} ${user.last_name}`}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor("message", {
      header: () => <span>Message</span>,
      cell: (info) => (
        <div
          css={css`
            width: 400px;
            max-width: 400px;
          `}
        >
          {info.getValue()}
        </div>
      ),
    }),
    // columnHelper.accessor("user.teams", {
    //   header: () => <span>Team</span>,
    //   cell: () => {
    //     // const val = info.getValue();
    //     // return val.map((el) => {
    //     //   return <span>{el.title}</span>;
    //     // });
    //   },
    // }),
  ];

  const data: InviteUser[] = [
    {
      email: "example@gmail.com",
      message:
        "Hello, please add this new employee to our themi account, she will be part of the marketing team.",
      requester: {
        id: "123",
        last_name: "Vlad",
        first_name: "Vlad",
        email: "example2@gmail.com",
        avatar: {
          uuid: "uuid",
          image:
            "https://www.masslive.com/resizer/kNl3qvErgJ3B0Cu-WSBWFYc1B8Q=/arc-anglerfish-arc2-prod-advancelocal/public/W5HI6Y4DINDTNP76R6CLA5IWRU.jpeg",
          upload_name: "avatar",
          mime_type: "USER_UPLOADS",
          // type?: "USER_UPLOADS",
          // tags?: ImageTag[];
          width: 1014,
          height: 732,
        },
      },
    },
    {
      email: "example@gmail.com",
      message:
        "Hello, please add this new employee to our themi account, she will be part of the marketing team.",
      requester: {
        id: "123",
        last_name: "Vlad",
        first_name: "Vlad",
        email: "example2@gmail.com",
        avatar: {
          uuid: "uuid",
          image:
            "https://www.masslive.com/resizer/kNl3qvErgJ3B0Cu-WSBWFYc1B8Q=/arc-anglerfish-arc2-prod-advancelocal/public/W5HI6Y4DINDTNP76R6CLA5IWRU.jpeg",
          upload_name: "avatar",
          mime_type: "USER_UPLOADS",
          // type?: "USER_UPLOADS",
          // tags?: ImageTag[];
          width: 1014,
          height: 732,
        },
      },
    },
  ];

  return (
    <Table<InviteUser>
      data={data}
      columns={columns}
      css={css`
        table-layout: fixed;
      `}
    />
  );
}
