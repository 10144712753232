import { useTranslation } from "react-i18next";
import { BigMessage } from "../components/BigMessage";
import { ReactComponent as ErrorIcon } from "../VideoEditor/assets/icons/Error.svg";
import { useEditor } from "../VideoEditor/contexts/EditorContext";

export function ModalAiError(props: { isOpen: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const { setAIModalVisible } = useEditor();

  return (
    <BigMessage
      isOpen={props.isOpen}
      onClose={props.onClose}
      icon={<ErrorIcon width={22} height={22} />}
      title={t("ai.modal.error.title")}
      description={t("ai.modal.error.description")}
      button={{
        text: t("ai.modal.try-again"),
        onClick: () => {
          props.onClose();
          setAIModalVisible(true);
        },
      }}
    />
  );
}
