import { Modal, ModalBody, ModalFooter } from "../components/Modal";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Button";
import { CollaboratorRole } from "../VideoEditor/types/Collaborator";
import { useCallback, useState } from "react";
import { requestPermission } from "../VideoEditor/actions/requestPermission";
import { customToast } from "../components/customToast";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { ReactComponent as CloseIcon } from "../assets/icons/CloseIcon.svg";
import { ReactComponent as NoAccess } from "../assets/icons/NoAccess.svg";

type ModalRequestAccessProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function ModalRequestAccess(props: ModalRequestAccessProps) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const searchParams = new URLSearchParams(window.location.search);

  const themiId = searchParams.get("themi_id");
  const request = searchParams.get("request");

  const { isOpen, onClose } = props;

  const onRequestPermision = useCallback(
    async (role: CollaboratorRole) => {
      if (!themiId) {
        return;
      }

      setIsSubmitting(true);
      try {
        await requestPermission({
          videoId: themiId,
          role: role,
        });
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      } finally {
        setIsSubmitting(false);
        onClose();
      }
    },
    [themiId, t, onClose]
  );

  return (
    <Modal
      modalIsOpen={isOpen}
      onClose={onClose}
      contentStyles={{
        maxWidth: "500px",
        padding: "30px",
      }}
    >
      <ModalBody
        css={css`
          justify-content: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 5px 0;
        `}
      >
        <div
          onClick={onClose}
          css={css`
            display: flex;
            margin: 0 0 0 auto;
            cursor: pointer;
          `}
        >
          <CloseIcon />
        </div>
        <div
          css={css`
            display: flex;
            margin: 0 auto;
          `}
        >
          <NoAccess />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            align-items: center;
            text-align: center;
          `}
        >
          <div
            css={css`
              font-weight: 500;
              font-size: 20px;
              line-height: 28px;
              margin-bottom: 10px;
            `}
          >
            {t("no-access.title")}
          </div>
          <div
            css={css`
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #505050;
            `}
          >
            {t("no-access.description")}
          </div>
        </div>
      </ModalBody>
      {themiId &&
        request &&
        (request === CollaboratorRole.VIEWER ||
          request === CollaboratorRole.EDITOR) && (
          <ModalFooter
            css={css`
              justify-content: center;
              margin-top: 40px;
            `}
          >
            <Button
              css={css`
                background-color: #e95b2e;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
              `}
              isSubmitting={isSubmitting}
              hoverStyles={`background:#FE521C`}
              onClick={() => onRequestPermision(request)}
            >
              {t("no-access.request")}
            </Button>
          </ModalFooter>
        )}
    </Modal>
  );
}
