import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  VideoContact,
  VideoElementState,
} from "../../../VideoEditor/types/Video";
import styled, { css } from "styled-components/macro";
import { usePlayer } from "../../contexts/PlayerContext";
import { Avatar } from "../../../VideoEditor/components/Avatar";
import { ReactComponent as MinusIcon } from "../../../assets/icons/Minus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/PlusIcon.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/CopyIcon.svg";
import { sendEvent } from "../../../helpers/tracking";
import { usePlayback } from "../../../VideoEditor/contexts/PlaybackContext";
import { clipboardCopy } from "../../../helpers/clipboardCopy";

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 9px;
  background: #ffffff;
  border: 1px solid #eae8e8;
  border-radius: 6px;
  cursor: pointer;
`;

export function Section(props: {
  title: string;
  copy?: boolean;
  getInPixel: (size: number, coef?: number | undefined) => number;
  children: React.ReactNode | React.ReactNode[] | string | null;
}) {
  return (
    <div
      css={css`
        margin-top: ${props.getInPixel(25, 0.65)}px;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          font-weight: 400;
          font-size: ${props.getInPixel(14, 0.65)}px;
          line-height: 120%;
          color: #616161;
          display: flex;
          align-items: center;
        `}
      >
        {props.title}
      </div>

      <div
        css={css`
          margin-top: ${props.getInPixel(3, 0.65)}px;
          font-weight: 400;
          font-size: ${props.getInPixel(14, 0.65)}px;
          line-height: 120%;
          color: #252424;
        `}
      >
        {props.children}
      </div>
    </div>
  );
}

export function ElementContactRenderer(props: {
  element: VideoContact;
  state: VideoElementState;
}) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const wrapRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const { getInPixel, canvasSize, video } = usePlayer();
  const { pause } = usePlayback();

  useEffect(() => {
    if (wrapRef.current) {
      const hiddenHeight = wrapRef.current.clientHeight + getInPixel(25, 0.65);
      const isNeedToChangePosition =
        canvasSize.height <=
        hiddenHeight + props.state.top + props.state.height;

      // Upper card expand + animation
      if (wrapperRef.current && wrapperRef.current?.parentElement) {
        if (open && isNeedToChangePosition) {
          const height = wrapRef.current.clientHeight + getInPixel(25, 0.65);
          wrapperRef.current.parentElement.style.transition =
            "transform 0.5s ease";
          wrapperRef.current.parentElement.style.transform = `translate(0px, -${height}px)`;
        } else {
          wrapperRef.current.parentElement.style.transform = "";
        }

        // Make expanded item be upper on everything
        if (wrapperRef.current.parentElement.parentElement) {
          if (open) {
            const oldZIndex =
              wrapperRef.current.parentElement.parentElement.style.zIndex;
            wrapperRef.current.parentElement.parentElement.setAttribute(
              "data-index",
              oldZIndex
            );
            wrapperRef.current.parentElement.parentElement.style.zIndex =
              "1001";
          } else {
            const oldIndex =
              wrapperRef.current.parentElement.parentElement.getAttribute(
                "data-index"
              );
            wrapperRef.current.parentElement.parentElement.removeAttribute(
              "data-index"
            );
            if (oldIndex)
              wrapperRef.current.parentElement.parentElement.style.zIndex =
                oldIndex;
          }
        }
      }
    }
  }, [
    open,
    canvasSize.height,
    getInPixel,
    props.state.height,
    props.state.top,
  ]);

  return (
    <div
      css={css`
        background: #ffffff;
        border: 1px solid #eae8e8;
        border-radius: 9px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: ${getInPixel(19, 0.65)}px ${getInPixel(20, 0.65)}px;
        font-weight: 500;
        line-height: 120%;
        position: relative;
        :hover {
          box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
        }
      `}
      ref={wrapperRef}
      onClick={(e) => {
        e.stopPropagation();
        pause();
      }}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          width: 100%;
        `}
      >
        <Avatar
          size={getInPixel(61, 0.65)}
          name={`${props.element.contact.name}`}
          url={props.element.contact.avatar?.file}
          filler
          css={css`
            margin-right: ${getInPixel(25, 0.65)}px;
          `}
        />

        <div
          css={css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          <div
            css={css`
              display: inline-block;
              background: #c9b1ff;
              border-radius: 16px;
              padding: ${getInPixel(7, 0.65)}px ${getInPixel(20, 0.65)}px;
            `}
          >
            <span
              css={css`
                font-size: ${getInPixel(14, 0.65)}px;
                line-height: 1;
                text-align: center;
                color: #fff;
                display: flex;
                align-items: center;
              `}
            >
              {props.element.contact.job_title}
            </span>
          </div>

          {props.element.contact.name && (
            <div
              css={css`
                font-size: ${getInPixel(20, 0.65)}px;
                color: #000000;
                margin-top: ${getInPixel(9, 0.65)}px;
                line-height: 1.3;
                text-overflow: ellipsis;
                overflow: hidden;
              `}
            >
              {props.element.contact.name}
            </div>
          )}
        </div>
      </div>

      <div
        css={css`
          -webkit-transition: height 0.5s;
          transition: height 0.5s;
          height: 0;
          overflow: hidden;
          width: 100%;
          ${open &&
          wrapRef.current &&
          css`
            height: ${wrapRef.current.clientHeight + getInPixel(25, 0.65)}px;
          `};
        `}
      >
        <div ref={wrapRef}>
          <Section getInPixel={getInPixel} title={t("account.email")} copy>
            <div
              css={css`
                display: flex;
                width: 100%;
                align-items: center;
              `}
            >
              <Link
                to={{ pathname: `mailto:${props.element.contact.email}` }}
                target="_blank"
                onClick={() => {
                  sendEvent("Interact with Themi", {
                    themiId: video?.uuid,
                    feature: "contact-card",
                    action: "clicked email",
                  });
                }}
              >
                {props.element.contact.email}
              </Link>
              {props.element.contact.email && (
                <div
                  onClick={async () => {
                    if (props.element.contact.email) {
                      await clipboardCopy(props.element.contact.email);

                      sendEvent("Interact with Themi", {
                        themiId: video?.uuid,
                        feature: "contact-card",
                        action: "copied email",
                      });
                    }
                  }}
                  css={css`
                    display: flex;
                    align-items: center;
                    margin: 0 0 0 auto;
                    cursor: pointer;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: ${getInPixel(11, 0.65)}px;
                      height: ${getInPixel(11, 0.65)}px;
                    `}
                  >
                    <CopyIcon
                      color="#252424"
                      width={getInPixel(11, 0.65)}
                      height={getInPixel(11, 0.65)}
                    />
                  </div>
                  <div
                    css={css`
                      margin-left: ${getInPixel(5, 0.65)}px;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 17px;
                    `}
                  >
                    {t("contact-card.create.copy")}
                  </div>
                </div>
              )}
            </div>
          </Section>
          <Section
            getInPixel={getInPixel}
            title={t("interactivity_settings.link_label")}
          >
            <Link
              to={{ pathname: `${props.element.contact.link}` }}
              target="_blank"
              onClick={() => {
                sendEvent("Interact with Themi", {
                  themiId: video?.uuid,
                  feature: "contact-card",
                  action: "clicked link",
                });
              }}
            >
              {props.element.contact.link}
            </Link>
          </Section>
          <Section
            getInPixel={getInPixel}
            title={t("contact-card.create.notes")}
          >
            {props.element.contact.notes}
          </Section>
        </div>
      </div>

      <div
        onClick={() => setOpen(!open)}
        css={css`
          position: absolute;
          top: ${getInPixel(8, 0.65)}px;
          right: ${getInPixel(12, 0.65)}px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: ${getInPixel(20, 0.65)}px;
          height: ${getInPixel(20, 0.65)}px;
          background: #ffffff;
          border: 1px solid #eae8e8;
          border-radius: 50%;
          cursor: pointer;
        `}
      >
        {open ? (
          <MinusIcon
            width={getInPixel(10, 0.65)}
            height={getInPixel(10, 0.65)}
          />
        ) : (
          <PlusIcon
            width={getInPixel(10, 0.65)}
            height={getInPixel(10, 0.65)}
          />
        )}
      </div>
    </div>
  );
}
