import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { useAuth } from "../contexts/UserContext";

import { Button } from "../components/Button";
import { Badge } from "../components/Badge";

import { sendEvent } from "../helpers/tracking";

import { ReactComponent as ThemisSvg } from "../assets/icons/Themis.svg";

export function UpgradePro(props: {
  title?: string;
  description?: string;
  location: string;
}) {
  const { t } = useTranslation();
  const { userprofile } = useAuth();
  const {
    title = t("upgrade-ad.button"),
    description = t("upgrade-ad.upgrade-to-share"),
  } = props;
  if (!userprofile?.restrictions.max_themis_count || userprofile?.is_pro)
    return null;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ThemisSvg />

          <span
            css={css`
              margin-left: 13px;
              font-weight: 500;
              font-size: 20px;
              line-height: 120%;
              color: #252424;
            `}
          >
            {title}
          </span>
        </div>

        <Badge>PRO</Badge>
      </div>

      <div
        css={css`
          margin-top: 5px;
          margin-bottom: 7px;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          color: #505050;
        `}
      >
        {userprofile.themis_count}/{userprofile?.restrictions.max_themis_count}{" "}
        {t("upgrade-ad.themi-videos")}
      </div>

      <div
        css={css`
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #252424;
          margin-bottom: 24px;
        `}
      >
        {description}
      </div>

      <div>
        <Button
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 32px;
            padding: 5px 20px;
          `}
          hoverStyles="none"
          onClick={() => {
            sendEvent("Upgrade button clicked", {
              type: "Upgrade Banner",
              location: props.location,
            });

            window.open(
              "https://calendly.com/themis-sales/demo",
              "_blank"
            );
          }}
        >
          {t("upgrade-ad.button-text")}
        </Button>
      </div>
    </div>
  );
}
