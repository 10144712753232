import { css } from "styled-components/macro";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { IGif } from "@giphy/js-types";
import { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { useDebounce } from "use-debounce";
import { v4 as uuid } from "uuid";
import { InputComponent } from "./InputComponent";
import { Headers } from "../helpers/headers";
import { ReactComponent as SearchIcon } from "../assets/icons/Search.svg";
import { useTranslation } from "react-i18next";
import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";
import { useAuth } from "../../contexts/UserContext";

import { defaultSequenceDuration } from "../../constants";

const gf = new GiphyFetch("tPHrMYJdjl1xb3sHJVXOBvJ6JrPSSunO");

const GifElement = (props: { image: IGif }) => {
  const { image } = props;
  const { activeScene } = usePlayback();
  const { api } = useStorage();
  const { userprofile } = useAuth();

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: "element",
    item: {
      type: "gif",
      image: {
        id: image.id,
        url: image.url,
        src: {
          url: image.images.original.url,
          width: image.images.original.width,
        },
        title: image.title,
        tags: image.tags,
      },
      states: [
        {
          id: uuid(),
          width: image.images.original.width,
          height: image.images.original.height,
        },
      ],
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const createElement = () => {
    if (!activeScene || !userprofile) return;

    api.createElement({
      id: uuid(),
      scene_id: activeScene.id,
      order: activeScene.elements.length,
      type: "gif",
      image: {
        id: image.id,
        url: image.url,
        src: {
          url: image.images.original.url,
          width: image.images.original.width,
        },
        title: image.title,
        tags: image.tags,
      },
      states: [
        {
          id: uuid(),
          left: 100,
          top: 100,
          width: 100,
          height:
            image.images.original.height / (image.images.original.width / 100),
          scale: 1,
          rotation: 0,
          start_time: 0,
          duration: defaultSequenceDuration,
        },
      ],
      config: {},
    });
  };

  return (
    <div
      ref={dragPreview}
      onClick={createElement}
      style={{
        position: "relative",
        width: "100%",
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
    >
      <div
        css={css`
          margin-bottom: 0px;

          img {
            width: 100%;
            height: 74px;
            border-radius: 9px;
            object-fit: cover;
            display: block;
          }
        `}
      >
        <img src={image.images.fixed_width.url} alt={image.title} />
      </div>
      <div
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        `}
        ref={drag}
      ></div>
    </div>
  );
};

export function Giphy(props: {
  enableSearch?: boolean;
  limit?: number;
  className?: string;
}) {
  const [query, setQuery] = useState("");
  const [images, setImages] = useState<IGif[]>([]);
  const limit = props.limit || 20;
  const [debouncedQuery] = useDebounce(query, 500);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const q = debouncedQuery.trim() || "greeting";

    setLoading(true);
    gf.search(q, { limit: limit, rating: "y" }).then((gifs) => {
      setImages(gifs.data);
      setLoading(false);
    });
  }, [debouncedQuery, limit]);

  return (
    <div className={props.className}>
      {props.enableSearch && (
        <div>
          <InputComponent
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            icon={<SearchIcon />}
            placeholder={t("media.sidebar.giphy.search.placeholder")}
            css={css`
              height: 40px;
              padding-left: 40px;
            `}
          />
          {!loading && debouncedQuery && (
            <Headers.H4
              css={css`
                font-size: 16px;
                margin-top: 20px;
              `}
            >
              {images.length > 0
                ? `${t("media.sidebar.search.results")}${debouncedQuery}`
                : t("media.sidebar.search.giphy.no-results")}
            </Headers.H4>
          )}
        </div>
      )}
      <div
        css={css`
          position: relative;
          display: flex;
          flex-wrap: wrap;
          margin-top: 12px;
        `}
      >
        {images.map((image) => {
          return (
            <div
              key={image.id}
              css={css`
                width: 48%;
                margin-bottom: 4%;

                &:nth-child(odd) {
                  margin-right: 4%;
                }
              `}
            >
              <GifElement image={image} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
