import { useCallback, useEffect, useState } from "react";
import { css } from "styled-components/macro";
import { useLocation } from "react-router";
import { usePlayback } from "../VideoEditor/contexts/PlaybackContext";
import { calculateCanvasSize } from "../VideoEditor/helpers/video";
import { FrameRenderer } from "./renderers/FrameRenderer";
import { ControlsBar } from "./components/ControlsBar";
import { usePlayer } from "./contexts/PlayerContext";
import { useAuth } from "../contexts/UserContext";
import { PlayerHeader } from "./components/PlayerHeader";
import { useViewerComments } from "./contexts/ViewerCommentsContext";

import { registerView } from "../actions/views/registerView";

import { defaultSequenceDuration } from "../constants";

type VideoPlayerProps = {
  preview?: boolean;
  commentMode?: boolean;
};

export function VideoPlayer(props: VideoPlayerProps) {
  const {
    currentFrame,
    activeScene,
    play,
    playing,
    pause,
    currentTime,
    watchTimeRef,
    videoDuration,
  } = usePlayback();
  const { canvasSize, setCanvasSize, showControls, setShowControls, video } =
    usePlayer();
  const { userprofile } = useAuth();
  const [videoRegistered, setVideoRegistered] = useState(false);
  const location = useLocation();
  const isEmbed = location.pathname.includes("/embed/");
  const isWatching = location.pathname.includes("/video/");
  const { commentsThreadOpen } = useViewerComments();

  useEffect(() => {
    if (isWatching) {
      const completePercent = (watchTimeRef.current / videoDuration) * 100;

      if (
        watchTimeRef.current > defaultSequenceDuration ||
        completePercent > 75 ||
        currentTime >= videoDuration - 100
      ) {
        if (video?.uuid && userprofile?.id && !videoRegistered) {
          setVideoRegistered(true);
          registerView({
            themi_id: video?.uuid,
            user_id: userprofile?.id,
          });
        }
      }
    }
  }, [
    currentTime,
    isWatching,
    userprofile?.id,
    video?.uuid,
    videoDuration,
    videoRegistered,
    watchTimeRef,
  ]);

  useEffect(() => {
    function updateSize() {
      const { width, height } = calculateCanvasSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      setCanvasSize({
        width,
        height,
      });
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [setCanvasSize]);

  // pause/play on spacebar
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (!commentsThreadOpen && event.key === " ") {
        event.preventDefault();

        if (playing) {
          pause();
        } else {
          play();
        }
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [playing, play, pause, commentsThreadOpen]);

  const onMouseMove = useCallback(
    (e: React.MouseEvent) => {
      setShowControls(true);
    },
    [setShowControls]
  );

  useEffect(() => {
    // autohide controls after 10s
    const timeout = setTimeout(() => {
      if (showControls && !commentsThreadOpen) {
        setShowControls(false);
      }
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [showControls, setShowControls, commentsThreadOpen]);

  return (
    <div
      css={
        !isEmbed &&
        css`
          width: 100vw;
          height: 100vh;
          background: #000;
          display: flex;
          justify-content: center;
          align-items: center;
        `
      }
    >
      <div
        css={css`
          position: relative;
          width: ${canvasSize.width}px;
          height: ${canvasSize.height}px;
          background: ${activeScene ? activeScene.backgroundColor : "#fff"};
          overflow: hidden;
          container-type: inline-size;
          transition: background 0.5s;
        `}
        onMouseMove={onMouseMove}
      >
        <FrameRenderer key={activeScene?.id} elements={currentFrame} />
        <PlayerHeader preview={props.preview} disableActions={props.preview} />
        <ControlsBar />
      </div>
    </div>
  );
}
