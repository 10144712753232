// import { getProportionalSize } from "../ScenePreview";
import { curry } from "lodash";
export * from "./ContactRenderer";
export * from "./DocumentRenderer";

const canvasWidth = 707;

export function getProportionalSize(value: number, currentWidth: number) {
  const ratio = currentWidth / canvasWidth;

  return Number(Number(value * ratio).toFixed(2));
}

export const getInPixel = (koef: number, width: number, num: number) => {
  return getProportionalSize(num * koef, width);
};

export const getInPixelCurry = curry(getInPixel);
