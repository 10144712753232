import { VideoElement } from "../../../VideoEditor/types/Video";
import { v4 as uuid } from "uuid";
import { defaultSequenceDuration } from "../../../constants";

export function generateDocumentCardsGrid(
  sceneId: string,
  orderFrom: number,
  t: any
): VideoElement[] {
  const documentCardInitialState = {
    title: t("themi-builder.template.common.document-title"),
    description: t("themi-builder.template.common.document-description"),
  };

  return [
    {
      id: uuid(),
      scene_id: sceneId,
      type: "document",
      document: {
        title: documentCardInitialState.title,
        description: documentCardInitialState.description,
      },
      order: orderFrom,
      states: [
        {
          id: uuid(),
          top: 161,
          left: 40,
          scale: 1,
          width: 280,
          height: 77.35,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      config: {},
    },
    {
      id: uuid(),
      scene_id: sceneId,
      type: "document",
      document: {
        title: documentCardInitialState.title,
        description: documentCardInitialState.description,
      },
      order: orderFrom + 1,
      states: [
        {
          id: uuid(),
          top: 269,
          left: 40,
          scale: 1,
          width: 280,
          height: 77.35,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      config: {},
    },
    {
      id: uuid(),
      scene_id: sceneId,
      type: "document",
      document: {
        title: documentCardInitialState.title,
        description: documentCardInitialState.description,
      },
      order: orderFrom + 2,
      states: [
        {
          id: uuid(),
          top: 161,
          left: 372,
          scale: 1,
          width: 280,
          height: 77.35,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      config: {},
    },
    {
      id: uuid(),
      scene_id: sceneId,
      type: "document",
      document: {
        title: documentCardInitialState.title,
        description: documentCardInitialState.description,
      },
      order: orderFrom + 3,
      states: [
        {
          id: uuid(),
          top: 269,
          left: 372,
          scale: 1,
          width: 280,
          height: 77.35,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      config: {},
    },
  ];
}
