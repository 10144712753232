import React, { useState, useCallback } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import {
  WorkspaceUserRole,
  WorkspaceUserWithRole,
} from "../../types/Workspace";

import { Button } from "../../components/Button";
import { Modal, ModalBody, ModalHeader } from "../../components/Modal";
import { Divider } from "../../components/Divider";
import { Select } from "../../components/Select";
import { Expandable } from "../../components/Expandable";
import { ModalDelete } from "../../modals/ModalDelete";
import { UserRole } from "./UserRole";

import { ReactComponent as PixelSvg } from "../../assets/icons/Pixel.svg";
import { ReactComponent as HandSvg } from "../../assets/icons/Hand.svg";
import { deleteUserFromWorkspace } from "../../actions/workspace/deleteUserFromWorkspace";
import { useAuth } from "../../contexts/UserContext";
import { updateUserRole } from "../../actions/workspace/updateUserRole";
import { customToast } from "../../components/customToast";

export function EditUserModal(props: {
  onDelete: () => Promise<void>;
  onEdit: () => Promise<void>;
  workspaceId: string;
  user: WorkspaceUserWithRole;
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { userprofile } = useAuth();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const workspaceRoleOptions = [
    {
      label: "Owner",
      value: WorkspaceUserRole.OWNER,
    },
    {
      label: "Admin",
      value: WorkspaceUserRole.ADMIN,
    },
    {
      label: "Member",
      value: WorkspaceUserRole.MEMBER,
    },
    {
      label: "Guest",
      value: WorkspaceUserRole.GUEST,
    },
  ];

  const isCurrentUser = userprofile
    ? props.user.user.id === userprofile.id
    : true;

  return (
    <>
      <Button
        icon={<PixelSvg width={13} height={13} color="#fff" />}
        onClick={() => setIsOpen(true)}
      >
        Edit
      </Button>
      <Modal
        modalIsOpen={isOpen}
        onClose={onClose}
        contentLabel={"Invite new users"}
        contentStyles={{ maxWidth: "654px", padding: "30px 30px 30px 30px" }}
      >
        <ModalHeader
          css={css`
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            color: #252424;
            .wrapper {
              padding-right: 0;
            }
          `}
          closeIcon
          onClose={onClose}
        >
          User Details
        </ModalHeader>

        <ModalBody
          css={css`
            margin-top: 10px;
            padding: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #505050;
          `}
        >
          <span
            css={css`
              font-size: 16px;
            `}
          >
            Edit access and remove users from your Themis company workspace.
          </span>

          <div
            css={css`
              margin-top: 33px;
              display: flex;
              justify-content: space-between;
            `}
          >
            <UserRole user={props.user} />

            <Select
              options={workspaceRoleOptions}
              value={workspaceRoleOptions.find(
                (r) => r.value === props.user.role
              )}
              disabled={isCurrentUser}
              onChange={async (value) => {
                if (!value) return;
                console.log(value);

                await updateUserRole({
                  workspaceId: props.workspaceId,
                  userEmail: props.user.user.email,
                  role: value.value as WorkspaceUserRole,
                });

                await props.onEdit();

                customToast.success(t("status.success"));
              }}
            />
          </div>

          <Divider
            css={css`
              margin-top: 34px;
            `}
          />

          <Expandable
            arrowControl
            content={
              <div>
                <div
                  css={css`
                    font-weight: 400;
                    font-size: 14px;
                    color: #000000;
                  `}
                >
                  If you delete this user, all their shared Themi videos that do
                  not have another owner will be transferred to the workspace
                  admin.
                </div>
                <Button
                  css={css`
                    color: #fff;
                    padding: 10px 33px;
                    font-size: 20px;
                    margin-top: 30px;
                  `}
                  disabled={isCurrentUser}
                  hoverStyles={`color:#fff; border:none;background-color:#FE521C`}
                  onClick={() => setDeleteModal(true)}
                >
                  Delete User
                </Button>
              </div>
            }
            css={css`
              .expand-container {
                margin-top: 30px;
              }
            `}
          >
            <div
              css={css`
                margin-top: 34px;
                display: flex;
                align-items: center;
              `}
            >
              <HandSvg />

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-left: 18px;
                `}
              >
                <div
                  css={css`
                    font-weight: 500;
                    font-size: 16px;
                    color: #252424;
                  `}
                >
                  Delete user from this workspace
                </div>

                <div
                  css={css`
                    margin-top: 4px;
                    font-size: 16px;
                    color: #505050;
                  `}
                >
                  Find out how you can delete this user
                </div>
              </div>
            </div>
          </Expandable>
        </ModalBody>

        <ModalDelete
          isOpen={deleteModal}
          onClose={() => setDeleteModal(false)}
          onDelete={async () => {
            await deleteUserFromWorkspace({
              workspaceId: props.workspaceId,
              userId: props.user.user.id,
            });
            await props.onDelete();

            setDeleteModal(false);
          }}
          title={"Are you sure you want to delete this user? "}
          description={
            "If you delete this user, any Themi videos they have not shared with others will be permanently deleted. Shared Themi videos will remain in the workspace."
          }
        />
      </Modal>
    </>
  );
}
