import { Image } from "./Image";

export enum WorkspaceUserRole {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  GUEST = "GUEST",
}

export type WorkspaceUser = {
  id: number;
  last_name: string;
  first_name: string;
  email: string;
  avatar: Image | null;
  themi_videos: number;
  teams: [

  ] /* TODO: when teams functionality will be ready, create types for them */;
};

export type WorkspaceUserWithRole = {
  role: WorkspaceUserRole;
  user: WorkspaceUser;
};
export interface Workspace {
  id: string;
  logo: Image | null;
  name: string;
  admin_email: string;
  industry: string;
  billing_override: string;
}

export type UserWorkspace = {
  workspace: Pick<Workspace, "id" | "name" | "logo" | "industry">;
  role: WorkspaceUserRole;
};

export type WorkspaceOverview = Pick<Workspace, "name" | "logo" | "industry">;
