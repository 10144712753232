import { VideoElement, VideoScene } from "../../../VideoEditor/types/Video";
import { v4 as uuid } from "uuid";
import { Template } from "../types";
import { defaultSequenceDuration } from "../../../constants";

export function generateHandoverProcessIntroductionTemplate(
  data: Template,
  order: number,
  t: any
) {
  const sceneId = uuid();
  const scene: VideoScene = {
    id: sceneId,
    backgroundColor: "#fff",
    duration: defaultSequenceDuration,
    order,
    name: t("themi-builder.template.process-intro.scene-name"),
    enableCollaboration: true,
    start_time: 0,
    elements: [],
  };

  const firstElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    text: `<p style="font-family: Arial; font-size: 4.57cqw;"><strong>${t(
      "themi-builder.template.process-intro.title"
    )}</strong></p>`,
    type: "text",
    order: 0,
    states: [
      {
        id: uuid(),
        top: 21.21,
        left: 35.35,
        scale: 1,
        width: 636.3000000000001,
        height: 57.834724540901504,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const secondElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // Give context to what this process is, why it is important, and what it's trying to achieve. It's helpful to give a summary of what part of the process you are handing over.
    text: `<p style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.process-intro.description"
    )}</p>`,
    type: "text",
    order: 1,
    states: [
      {
        id: uuid(),
        top: 84.84,
        left: 35.35,
        scale: 1,
        width: 636.3000000000001,
        height: 44.85141903171953,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const thirdElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // Add an overview of the process.
    text: `<p style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.process-intro.second-description"
    )}</p>`,
    type: "text",
    order: 2,
    states: [
      {
        id: uuid(),
        top: 144.49312500000002,
        left: 35.35,
        scale: 1,
        width: 307.545,
        height: 178.959375,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const fourthElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    type: "record-prompt",
    order: 3,
    states: [
      {
        id: uuid(),
        top: 144.49312500000002,
        left: 367.64,
        scale: 1,
        width: 307.545,
        height: 178.959375,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
    touched: true,
    instruction: t("themi-builder.template.record-prompt.general-description"),
  };

  const scenes: VideoScene[] = [scene];

  const elements: VideoElement[] = [
    firstElement,
    secondElement,
    thirdElement,
    fourthElement,
  ];

  return { scenes, elements };
}
