import React from "react";
import { useHistory } from "react-router";
import { css } from "styled-components/macro";
import { Card, CardModeType } from "../businessComponents/Workspace/Card";
import { Video } from "../VideoEditor/types/Video";

type ThemiListProps = {
  title: string;
  description: string;
  video: Video[];
  cardsMode: CardModeType;
  refetch: () => Promise<void>;
};

export function DashboardThemiList(props: ThemiListProps) {
  const history = useHistory();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        `}
      >
        <div
          css={css`
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #252424;
            margin-bottom: 8px;
          `}
        >
          {props.title}
        </div>
        <div
          css={css`
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #252424;
          `}
        >
          {props.description}
        </div>
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(auto-fill, 330px);
          grid-column-gap: 40px;
        `}
      >
        {props.video.map((video) => (
          <Card
            key={video.uuid}
            video={video}
            mode={props.cardsMode}
            refetch={props.refetch}
            onClick={() =>
              props.cardsMode === CardModeType.EDIT
                ? history.push("video-editor/" + video.uuid)
                : props.cardsMode === CardModeType.INSPIRATION
                ? history.push("inspiration/" + video.uuid)
                : history.push("video/" + video.uuid)
            }
          />
        ))}
      </div>
    </div>
  );
}
