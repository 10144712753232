import { VideoElement, VideoScene } from "../../../VideoEditor/types/Video";
import { v4 as uuid } from "uuid";
import { Template } from "../types";
import { defaultSequenceDuration } from "../../../constants";

export function generateOnboardingOverviewTemplate(
  data: Template,
  order: number,
  t: any
) {
  const sceneId = uuid();
  const scene: VideoScene = {
    id: sceneId,
    backgroundColor: "#fff",
    duration: defaultSequenceDuration,
    order,
    name: t("themi-builder.template.overview-of-the-role.scene-name"),
    enableCollaboration: true,
    start_time: 0,
    elements: [],
  };

  const firstElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    text: `<p style="font-family: Arial; font-size: 4.57cqw;"><strong>${t(
      "themi-builder.template.overview-of-the-role.title"
    )}</strong></p>`,
    type: "text",
    order: 0,
    states: [
      {
        id: uuid(),
        top: 21.21,
        left: 35.35,
        scale: 1,
        width: 451.5239565943239,
        height: 57.834724540901504,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const secondElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // What are the main responsibilities of the role? Give a high-level summary here, and go into more detail later on.
    text: `<p style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.overview-of-the-role.description"
    )}</p>`,
    type: "text",
    order: 1,
    states: [
      {
        id: uuid(),
        top: 84.84,
        left: 35.35,
        scale: 1,
        width: 450.3436560934892,
        height: 44.85141903171953,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const thirdElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // List out key responsibilities here:
    // What are their main responsibilities?
    // What software is critical to their role?
    // What important process or system are they responsible for, or part of?
    text: `<ul>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.overview-of-the-role.points.one"
    )}</li>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.overview-of-the-role.points.two"
    )}</li>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.overview-of-the-role.points.three"
    )}</li>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.overview-of-the-role.points.four"
    )}</li>
    </ul>`,
    type: "text",
    order: 2,
    states: [
      {
        id: uuid(),
        top: 194.41762312186978,
        left: 36.530300500834706,
        scale: 1,
        width: 467.4580133555927,
        height: 112.71869782971619,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const fourthElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    type: "record-prompt",
    order: 3,
    states: [
      {
        id: uuid(),
        top: 42.98728192821372,
        left: 522.2829716193656,
        scale: 1,
        width: 145.2300751252091,
        height: 44.7002139338096,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
    instruction: t("themi-builder.template.record-prompt.general-description"),
  };

  const scenes: VideoScene[] = [scene];

  const elements: VideoElement[] = [
    firstElement,
    secondElement,
    thirdElement,
    fourthElement,
  ];

  return { scenes, elements };
}
