import { VideoElement, VideoScene } from "../../../VideoEditor/types/Video";
import { Template } from "../types";
import { generateHandoverClientContactsTemplate } from "./client_contacts";
import { generateHandoverClientDocumentsTemplate } from "./client_documents";
import { generateHandoverClientHistoryTemplate } from "./client_history";
import { generateHandoverClientIntroductionTemplate } from "./client_introduction";
import { generateHandoverClientProjectTemplate } from "./client_project";
import { generateHandoverCompanyExpertsTemplate } from "./company_experts";
import { generateHandoverImportantDocumentsTemplate } from "./important_documents";
import { generateHandoverIntroductionTemplate } from "./introduction";
import { generateHandoverLessonsLearnedTemplate } from "./lessons_learned";
import { generateHandoverOverviewTemplate } from "./overview";
import { generateHandoverProcessIntroductionTemplate } from "./process_introduction";
import { generateHandoverProcessStepsTemplate } from "./process_steps";
import { generateHandoverProcessTipsTemplate } from "./process_tips";
import { generateHandoverProjectDocumentsTemplate } from "./project_documents";
import { generateHandoverProjectIntroductionTemplate } from "./project_introduction";
import { generateHandoverProjectNextStepsTemplate } from "./project_next_steps";
import { generateHandoverProjectStatusTemplate } from "./project_status";
import { generateHandoverProjectTeamTemplate } from "./project_team";
import { generateHandoverProjectWaysTemplate } from "./project_ways";
import { generateHandoverStakeholdersTemplate } from "./stakeholders";
import { generateHandoverTeamTemplate } from "./team";
import { generateHandoverTipsAndTricksTemplate } from "./tips_and_tricks";
import { generateHandoverToolIntroductionTemplate } from "./tool_introduction";
import { generateHandoverToolStepsTemplate } from "./tool_steps";
import { generateHandoverToolTipsTemplate } from "./tool_tips";

export function generateHandoverTemplate(
  data: Template,
  t: any
): {
  scenes: VideoScene[];
  elements: VideoElement[];
} {
  let scenes: VideoScene[] = [];
  let elements: VideoElement[] = [];
  let count = 0;

  if ("handover-introduction" in data.selectedTopics) {
    const topics = data.selectedTopics["handover-introduction"];

    if (topics.includes("introduction")) {
      const template = generateHandoverIntroductionTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("overview-of-the-handover")) {
      const template = generateHandoverOverviewTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }
  }

  if ("who-do-you-work-with" in data.selectedTopics) {
    const topics = data.selectedTopics["who-do-you-work-with"];

    if (topics.includes("team")) {
      const template = generateHandoverTeamTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("stakeholders")) {
      const template = generateHandoverStakeholdersTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }

    if (topics.includes("company-experts")) {
      const template = generateHandoverCompanyExpertsTemplate(data, count++, t);

      scenes = scenes.concat(template.scenes);
      elements = elements.concat(template.elements);
    }
  }
  if ("handover-responsibilities" in data.selectedTopics) {
    const topics = data.selectedTopics["handover-responsibilities"];

    if (topics.includes("client")) {
      const template1 = generateHandoverClientIntroductionTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);

      const template2 = generateHandoverClientContactsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template2.scenes);
      elements = elements.concat(template2.elements);

      const template3 = generateHandoverClientHistoryTemplate(data, count++, t);

      scenes = scenes.concat(template3.scenes);
      elements = elements.concat(template3.elements);

      const template4 = generateHandoverClientProjectTemplate(data, count++, t);

      scenes = scenes.concat(template4.scenes);
      elements = elements.concat(template4.elements);

      const template5 = generateHandoverClientDocumentsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template5.scenes);
      elements = elements.concat(template5.elements);
    }

    if (topics.includes("project")) {
      const template1 = generateHandoverProjectIntroductionTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);

      const template2 = generateHandoverProjectTeamTemplate(data, count++, t);

      scenes = scenes.concat(template2.scenes);
      elements = elements.concat(template2.elements);

      const template3 = generateHandoverProjectStatusTemplate(data, count++, t);

      scenes = scenes.concat(template3.scenes);
      elements = elements.concat(template3.elements);

      const template4 = generateHandoverProjectWaysTemplate(data, count++, t);

      scenes = scenes.concat(template4.scenes);
      elements = elements.concat(template4.elements);

      const template5 = generateHandoverProjectDocumentsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template5.scenes);
      elements = elements.concat(template5.elements);

      const template6 = generateHandoverProjectNextStepsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template6.scenes);
      elements = elements.concat(template6.elements);
    }

    if (topics.includes("process")) {
      const template1 = generateHandoverProcessIntroductionTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);

      const template2 = generateHandoverProcessStepsTemplate(data, count++, t);

      scenes = scenes.concat(template2.scenes);
      elements = elements.concat(template2.elements);

      const template3 = generateHandoverProcessTipsTemplate(data, count++, t);

      scenes = scenes.concat(template3.scenes);
      elements = elements.concat(template3.elements);
    }

    if (topics.includes("tool-walk-through")) {
      const template1 = generateHandoverToolIntroductionTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);

      const template2 = generateHandoverToolStepsTemplate(data, count++, t);

      scenes = scenes.concat(template2.scenes);
      elements = elements.concat(template2.elements);

      const template3 = generateHandoverToolTipsTemplate(data, count++, t);

      scenes = scenes.concat(template3.scenes);
      elements = elements.concat(template3.elements);
    }

    if (topics.includes("important-documents")) {
      const template1 = generateHandoverImportantDocumentsTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);
    }
  }

  if ("role-succession" in data.selectedTopics) {
    const topics = data.selectedTopics["role-succession"];

    if (topics.includes("tips-and-tricks")) {
      const template1 = generateHandoverTipsAndTricksTemplate(data, count++, t);

      scenes = scenes.concat(template1.scenes);
      elements = elements.concat(template1.elements);
    }

    if (topics.includes("lessons-learned")) {
      const template2 = generateHandoverLessonsLearnedTemplate(
        data,
        count++,
        t
      );

      scenes = scenes.concat(template2.scenes);
      elements = elements.concat(template2.elements);
    }
  }

  console.log("Template", data, scenes, elements);

  return {
    scenes: scenes,
    elements: elements,
  };
}
