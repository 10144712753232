import React, { useState, useCallback } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { Button } from "../../components/Button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../components/Modal";
import { InputComponent } from "../../components/InputComponent";
import { TextareaComponent } from "../../components/TextareaComponent";

import { ReactComponent as AttentionSvg } from "../../assets/icons/Attention.svg";
import { emailIsValid } from "../../helpers/email";
import { inviteUsers } from "../../actions/workspace";
import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { WorkspaceUserRole } from "../../types/Workspace";

function parseEmails(emailsString: string): string[] {
  return emailsString.split(",").map((email) => email.trim());
}

function validateEmails(emails: string[]): boolean {
  return emails.every((email) => {
    return emailIsValid(email);
  });
}

export function InviteUsersModal(props: {
  workspaceId: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const [emailsString, setEmailsString] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isTouched, setIsTouched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClose = useCallback(() => {
    props.onClose();
    setEmailsString("");
    setMessage("");
  }, [props]);

  const emails = parseEmails(emailsString);
  const isValid = validateEmails(emails);

  const submitForm = useCallback(async () => {
    if (!isValid) {
      return;
    }

    setIsSubmitting(true);

    try {
      await inviteUsers({
        workspace_id: props.workspaceId,
        user_emails: emails,
        message,
        role: WorkspaceUserRole.MEMBER,
      });

      customToast.success(t("status.success"));

      props.onClose();
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  }, [emails, isValid, message, t, props]);

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={onClose}
      contentLabel={"Invite new users"}
      contentStyles={{ maxWidth: "654px", padding: "30px 30px 30px 30px" }}
    >
      <ModalHeader
        css={css`
          font-weight: 500;
          font-size: 20px;
          line-height: 140%;
          color: #252424;
          .wrapper {
            padding-right: 0;
          }
        `}
        closeIcon
        onClose={onClose}
      >
        Invite new users
      </ModalHeader>

      <ModalBody
        css={css`
          margin-top: 10px;
          padding: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #505050;
        `}
      >
        <span
          css={css`
            font-size: 16px;
          `}
        >
          An invitation will be sent to new users. All users will be added as
          creators. You can change their access on the user management page.
        </span>

        <div
          css={css`
            margin-top: 33px;
          `}
        >
          <InputComponent
            value={emailsString}
            onChange={(e) => {
              setEmailsString(e.target.value);
              setIsTouched(true);
            }}
            label={"Email"}
            placeholder="Separate emails by a comma"
          />

          {isTouched && (
            <div
              css={css`
                margin-top: 8px;
                display: flex;
                background: #fffbf8;
                border: 1px solid #e95b2e;
                border-radius: 8px;
                padding: 14px;
              `}
            >
              <div>
                <AttentionSvg />
              </div>

              <div
                css={css`
                  margin-left: 17px;
                  color: #252424;
                `}
              >
                New members will be billed as full workspace seats, and will
                show on your next invoice.
              </div>
            </div>
          )}
        </div>

        <div
          css={css`
            margin-top: 24px;
          `}
        >
          <TextareaComponent
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            label="Message"
            placeholder="Add a personalised welcome message along with your invitation"
            css={css`
              min-height: 100px;
            `}
          />
        </div>
      </ModalBody>

      <ModalFooter
        css={css`
          margin-top: 54px;
        `}
      >
        <Button
          onClick={submitForm}
          css={css`
            background-color: #e95b2e;
            color: #fff;
            padding: 10px 33px;
          `}
          disabled={!isValid}
          isSubmitting={isSubmitting}
          hoverStyles={`color:#fff; border:none;background-color:#FE521C`}
        >
          Send invitation
        </Button>
      </ModalFooter>
    </Modal>
  );
}
