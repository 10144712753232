import { UserMeta } from "../../types/UserProfile";
import { Video } from "./Video";

export enum CollaboratorRole {
  OWNER = "OWNER",
  EDITOR = "EDITOR",
  COMMENTER = "COMMENTER",
  VIEWER = "VIEWER",
}

export type Collaborator = {
  user: UserMeta;
  role: CollaboratorRole;
};

export enum CollaborationMode {
  EDIT = "EDIT",
  VIEW = "VIEW",
}

export type PermissionRequest = {
  themi: Video;
  user: UserMeta;
  role: CollaboratorRole;
};

export type CollaboratorMeta = {
  email: string;
  role: CollaboratorRole;
};

export type CollaboratorInvite = {
  email: string;
  role: CollaboratorRole;
  message: string;
};
