import React, { useState, useCallback } from "react";
import { css } from "styled-components/macro";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { File as FileType } from "../../types/File";
import { Loader } from "./Loader";

import AvatarPlaceholder from "../../assets/img/AvatarPlaceholder.png";
import { uploadFile } from "../../actions/file";

export function InteractiveContactCardAvatarUpload(props: {
  avatar?: FileType | null;
  onUpload?: (file: FileType) => void;
}) {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState<FileType | null | undefined>(
    props.avatar
  );
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (file) {
        setUploading(true);

        const response = await uploadFile({
          file,
          title: file.name,
        });

        setUploadedFile(response);
        props.onUpload && props.onUpload(response);

        setUploading(false);
      }
    },
    [props]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <div
        css={css`
          margin-right: 31px;
          width: 90px;
          height: 90px;
          position: relative;
          background: #252424;
          border-radius: 7px;
        `}
      >
        {uploadedFile?.file ? (
          <img
            src={uploadedFile.file}
            alt={uploadedFile.title}
            css={css`
              max-width: 100%;
              max-height: 100%;
              width: 100%;
              height: 100%;
              position: relative;

              background: #252424;
              border-radius: 7px;
            `}
          />
        ) : (
          <img
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
            src={AvatarPlaceholder}
            alt={t("account.avatar")}
          />
        )}
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <span
          css={css`
            font-weight: 500;
            font-size: 16px;
          `}
        >
          {t("contact-card.create.upload-profile-image")}
        </span>

        <span
          css={css`
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #616161;
          `}
        >
          PNG, JPG (max 300x300px){" "}
        </span>

        <div
          {...getRootProps()}
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6px 26px;
            margin-top: 10px;
            background: #252424;
            border-radius: 32px;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
          `}
        >
          <input {...getInputProps()} />
          {uploading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div>{t("contact-card.create.upload-image")}</div>
          )}
        </div>
      </div>
    </div>
  );
}
