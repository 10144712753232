import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { Video, VideoElement, VideoScene } from "../types/Video";
import useInterval from "@use-it/interval";
import {
  calculateVideoDuration,
  getVideoScenes,
  getMaxSceneOrder,
} from "../helpers/video";
import { slideHeight } from "../components/SceneNavigator";

const PlaybackContext = React.createContext<{
  scrollRef: React.RefObject<HTMLDivElement>;
  currentTime: number;
  watchTimeRef: React.MutableRefObject<number>;
  videoDuration: number;
  playing: boolean;
  play: () => void;
  pause: () => void;
  setCurrentTime: (time: number) => void;
  scrollToScene: (index: number) => void;
  findScene: (id: string) => {
    scene: VideoScene;
    index: number;
  } | null;
  currentFrame: VideoElement[];
  scenes: VideoScene[];
  activeScene: VideoScene | null;
  maxSceneOrder: number;
}>({
  scrollRef: React.createRef(),
  currentTime: 0,
  watchTimeRef: { current: 0 },
  videoDuration: 0,
  maxSceneOrder: 0,
  activeScene: null,
  playing: false,
  play: () => {},
  pause: () => {},
  setCurrentTime: (time: number) => {},
  scrollToScene: (index: number) => {},
  findScene: (id: string) => null,
  currentFrame: [],
  scenes: [],
});

const interval = 15;

// Normalize the order property so it's always bigger than zero
export function normalizeOrder(elements: VideoElement[]) {
  const minOrder = Math.min(...elements.map((e, i) => e.order || i));

  return elements.map((e, i) => {
    const order = e.order || i;

    return {
      ...e,
      order: order - minOrder,
    };
  });
}

export function PlaybackProvider(props: {
  children: React.ReactNode;
  video: Video;
  autoPause?: boolean;
}) {
  const { video } = props;
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const currentTimeRef = useRef(0);
  const watchTimeRef = useRef(0);
  const videoDuration = useMemo(
    () => (video ? calculateVideoDuration(video.schema.schema) : 0),
    [video]
  );
  const scenes = useMemo(
    () => (video ? getVideoScenes(video.schema.schema) : []),
    [video]
  );
  const [activeScene, setActiveScene] = useState<VideoScene | null>(null);
  let currentFrame = useMemo(
    () => (activeScene && activeScene.elements ? activeScene.elements : []),
    [activeScene]
  );
  const stopIds = useRef<string[]>([]);
  const maxSceneOrder = useMemo(() => getMaxSceneOrder(scenes), [scenes]);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  currentFrame = normalizeOrder(currentFrame);

  useEffect(() => {
    currentTimeRef.current = currentTime;
  }, [currentTime]);

  useInterval(() => {
    if (playing) {
      setCurrentTime((time) => {
        if (time <= videoDuration) {
          return time + interval;
        }

        return time;
      });
      watchTimeRef.current = watchTimeRef.current + interval;
    }
  }, interval);

  useEffect(() => {
    if (currentTime >= videoDuration) {
      setPlaying(false);
      watchTimeRef.current = 0;
      stopIds.current = [];

      return;
    }

    if (
      props.autoPause &&
      activeScene &&
      stopIds.current.indexOf(activeScene.id) === -1
    ) {
      const hasInteraciveElement = activeScene.elements.some((element) =>
        ["open-question", "poll", "user-tagging"].includes(element.type)
      );

      if (hasInteraciveElement) {
        setPlaying(false);
        stopIds.current.push(activeScene.id);
      }
    }
  }, [currentTime, videoDuration, activeScene, props.autoPause]);

  useEffect(() => {
    const firstScene = scenes[0];
    const lastScene = scenes[scenes.length - 1];

    if (!lastScene) return;

    const lastSceneEndTime = lastScene.start_time + lastScene.duration;

    if (
      currentTime >= firstScene.start_time &&
      Math.floor(currentTime / 1000) <= Math.floor(lastSceneEndTime / 1000)
    ) {
      const scene = scenes.find((scene) => {
        return (
          currentTime >= scene.start_time &&
          currentTime < scene.start_time + scene.duration
        );
      });
      if (scene) {
        setActiveScene(scene);
      }
    } else {
      setActiveScene(firstScene);
    }
  }, [scenes, currentTime]);

  const scrollToScene = useCallback(
    (index: number) => {
      if (!scrollRef.current) return;

      const carouselHeight = scenes.length * slideHeight;
      const elPosition = index * (slideHeight + 30);
      const containerHeight = scrollRef.current.clientHeight;

      let scrollPosition = elPosition - containerHeight / 2 + slideHeight / 2;
      scrollPosition = Math.max(0, scrollPosition);
      scrollPosition = Math.min(scrollPosition, carouselHeight);

      scrollRef.current.scroll({
        top: scrollPosition,
        behavior: "smooth",
      });
    },
    [scenes.length]
  );

  const findScene = useCallback(
    (id: string) => {
      const scene = scenes.find((s) => s.id === id);
      if (!scene) return null;

      return {
        scene,
        index: scenes.indexOf(scene),
      };
    },
    [scenes]
  );

  const play = useCallback(() => {
    setPlaying(true);

    if (currentTimeRef.current >= videoDuration) {
      setCurrentTime(0);
      watchTimeRef.current = 0;
    }
  }, [videoDuration]);

  const pause = useCallback(() => {
    setPlaying(false);
  }, []);

  const setCurrentTimeFunc = useCallback((time: number) => {
    setCurrentTime(time);
    setPlaying(false);
  }, []);

  return (
    <PlaybackContext.Provider
      value={{
        scrollRef,
        scenes,
        currentTime,
        videoDuration,
        currentFrame,
        playing,
        activeScene,
        maxSceneOrder,
        watchTimeRef,
        play,
        pause,
        setCurrentTime: setCurrentTimeFunc,
        scrollToScene,
        findScene,
      }}
    >
      {props.children}
    </PlaybackContext.Provider>
  );
}

export function usePlayback() {
  const context = React.useContext(PlaybackContext);

  if (context === undefined) {
    throw new Error("usePlayback must be used within a PlaybackProvider");
  }

  return context;
}
