import { ChangeActionType, db } from "./db";
import { VideoElement, VideoScene, VideoPublishStatus } from "./types/Video";
import { v4 as uuid } from "uuid";

export type LocalAPIMeta = {
  userId: number;
  updateGroupId?: string | null;
  fake?: boolean;
};

export async function commitLocalChange(input: {
  videoId: string;
  elementId?: string | null;
  content?: any;
  action: ChangeActionType;
  meta: LocalAPIMeta;
}) {
  const eventId = uuid();
  const changeId = await db.video_state_updates.add({
    event_id: eventId,
    update_group_id: input.meta.updateGroupId || null,
    video_id: input.videoId,
    timestamp: Date.now(),
    user_id: input.meta.userId,
    action: input.action,
    element_id: input.elementId,
    content: input.content,
    remote_id: null,
    fake: input.meta.fake,
  });

  const change = await db.video_state_updates.get(changeId);

  return {
    changeId,
    change: change!,
  };
}

export async function deleteLocalChange(input: { key: number }): Promise<void> {
  await db.video_state_updates.delete(input.key);
}

export const localAPI = {
  getVideo: async (videoId: string) => {
    const video = await db.videos.get({
      uuid: videoId,
    });

    if (video) {
      return video.content;
    }

    return null;
  },

  saveVideo: async (videoId: string, content: any) => {
    const video = await db.videos.get({
      uuid: videoId,
    });

    if (video) {
      await db.videos.update(video.id!, {
        content: content,
      });
    } else {
      await db.videos.put({
        uuid: videoId,
        content,
      });
    }
  },

  getChangeById: async (changeId: string) => {
    return await db.video_state_updates.get(changeId);
  },

  createScene: async (
    videoId: string,
    name: string,
    meta: LocalAPIMeta,
    order?: number
  ) => {
    const elementId = uuid();

    return await commitLocalChange({
      action: "create-scene",
      videoId,
      elementId,
      content: {
        id: elementId,
        name: name,
        order: order,
        backgroundColor: "#fff",
      },
      meta,
    });
  },

  reorderScene: async (
    videoId: string,
    orders: VideoScene[],
    meta: LocalAPIMeta,
    elementId?: string | null
  ) => {
    return await commitLocalChange({
      action: "reorder-scene",
      videoId,
      elementId: elementId,
      content: {
        orders: orders.map((el) => {
          return { id: el.id, order: el.order };
        }),
      },
      meta,
    });
  },

  updateScene: async (
    videoId: string,
    elementId: string,
    element: VideoScene,
    meta: LocalAPIMeta
  ) => {
    return await commitLocalChange({
      action: "update-scene",
      videoId,
      elementId: elementId,
      content: element,
      meta,
    });
  },

  deleteScene: async (
    videoId: string,
    elementId: string,
    meta: LocalAPIMeta
  ) => {
    return await commitLocalChange({
      action: "delete-scene",
      videoId,
      elementId: elementId,
      meta,
    });
  },

  createSceneWithContent: async (
    videoId: string,
    scene: Pick<VideoScene, "name" | "order" | "start_time">,
    elements: VideoElement[],
    meta: LocalAPIMeta
  ) => {
    const sceneId = uuid();
    const newScene: VideoScene = {
      backgroundColor: "#fff",
      duration: 5 * 1000,
      enableCollaboration: true,
      ...scene,
      elements: [],
      id: sceneId,
    };

    const response = await commitLocalChange({
      action: "create-scene",
      videoId,
      elementId: sceneId,
      content: newScene,
      meta,
    });

    await commitLocalChange({
      action: "create-elements",
      videoId,
      content: elements.map((el) => ({
        ...el,
        id: uuid(),
        scene_id: sceneId,
      })),
      meta,
    });

    return {
      ...response,
      newScene,
    };
  },

  createElement: async (
    videoId: string,
    element: VideoElement,
    meta: LocalAPIMeta
  ) => {
    const elementId = element.id || uuid();

    return await commitLocalChange({
      action: "create-element",
      videoId,
      elementId,
      content: {
        ...element,
        id: elementId,
      },
      meta,
    });
  },

  createElements: async (
    videoId: string,
    elements: VideoElement[],
    meta: LocalAPIMeta
  ) => {
    return await commitLocalChange({
      action: "create-elements",
      videoId,
      content: elements.map((el) => ({
        ...el,
        id: uuid(),
      })),
      meta,
    });
  },

  updateElement: async (
    videoId: string,
    elementId: string,
    element: VideoElement,
    meta: LocalAPIMeta
  ) => {
    return await commitLocalChange({
      action: "update-element",
      videoId,
      elementId: elementId,
      content: {
        ...element,
        touched: true,
      },
      meta,
    });
  },

  updateElements: async (
    videoId: string,
    elements: VideoElement[],
    meta: LocalAPIMeta
  ) => {
    return await commitLocalChange({
      action: "update-elements",
      videoId,
      content: elements,
      meta,
    });
  },

  deleteElement: async (
    videoId: string,
    elementId: string,
    meta: LocalAPIMeta
  ) => {
    return await commitLocalChange({
      action: "delete-element",
      videoId,
      elementId: elementId,
      meta,
    });
  },

  deleteElements: async (
    videoId: string,
    elements: VideoElement[],
    meta: LocalAPIMeta
  ) => {
    return await commitLocalChange({
      action: "delete-elements",
      videoId,
      content: elements,
      meta,
    });
  },

  deleteEvent: async (key: number): Promise<void> => {
    return await deleteLocalChange({ key });
  },

  findFakeEvents: async (videoId: string) => {
    return await db.video_state_updates
      .where("video_id")
      .equals(videoId)
      .and((update) => {
        return update.fake || false;
      })
      .toArray();
  },

  getLastEvent: async (videoId: string) => {
    return await db.video_state_updates
      .where("video_id")
      .equals(videoId)
      .last();
  },

  updateVideoPublishStatus: async (
    videoId: string,
    newStatus: VideoPublishStatus
  ) => {
    return await db.videos.update(videoId, {
      publish_status: newStatus,
    });
  },
};
