import { authorised } from "../../request";
import { WorkspaceUserWithRole } from "../../types/Workspace";

export async function getUsers(data: { workspace_id: string }) {
  const API = authorised();

  return await API.request<WorkspaceUserWithRole[]>({
    method: "GET",
    url: `/api/studio/themis/workspaces/${data.workspace_id}/view-users/`,
  });
}
