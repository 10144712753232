import { VideoElement, VideoScene } from "../../VideoEditor/types/Video";
import { generateHandoverTemplate } from "./handover";
import { generateKnowledgeSharingTemplate } from "./knowledge-sharing";
import { generateOnboardingTemplate } from "./onboarding";
import { Template } from "./types";

export function generateTemplate(
  data: Template,
  t: any
): {
  scenes: VideoScene[];
  elements: VideoElement[];
} {
  let template;

  if (data.category === "handover") {
    template = generateHandoverTemplate(data, t);
  }

  if (data.category === "onboarding") {
    template = generateOnboardingTemplate(data, t);
  }

  if (data.category === "knowledge-sharing") {
    template = generateKnowledgeSharingTemplate(data, t);
  }

  if (!template) throw new Error("Template not found");

  return {
    scenes: template.scenes,
    elements: template.elements,
  };
}
