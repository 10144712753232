import React from "react";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { Column, Row, Spacing } from "../../helpers/layout";
import { InputField } from "../fields/InputField";
import { ImageUploadField } from "../fields/ImageUploadField";
import { Button } from "../../components/Button";

import { UserProfile } from "../../types/UserProfile";
import { Image, ImageType } from "../../types/Image";
import { InputType } from "../../components/InputComponent";
import { uploadImage } from "../../actions/image";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { updateMe } from "../../actions/account";

export type FormValues = {
  first_name: string;
  last_name: string;
  avatar: Image | null;
  email: string;
};

export function ProfileOverviewForm(props: {
  data: UserProfile;
  refetch: () => void;
}) {
  const { t } = useTranslation();

  const re = new RegExp("[a-zA-Z-]");

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().matches(re).required(),
    last_name: Yup.string().matches(re).required(),
    introduction: Yup.string().max(500),
  });

  const initialValues: FormValues = {
    first_name: props.data.first_name,
    last_name: props.data.last_name,
    avatar: props.data.avatar || null,
    email: props.data.email,
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (
        values: FormValues,
        { setSubmitting }: FormikHelpers<FormValues>
      ) => {
        try {
          setSubmitting(true);

          await updateMe(values);
          props.refetch();
          customToast.success(t("status.success"));
        } catch (error: any) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, dirty, isValid }) => {
        return (
          <Form
            noValidate
            css={css`
              width: 100%;
            `}
          >
            <Column gutter={Spacing.xl}>
              <ImageUploadField
                label={`${t("account.avatar")}:`}
                name={"avatar"}
                type={ImageType.HIDDEN}
                disableLibrary
                uploadFn={uploadImage}
              />

              <Row
                gutter={Spacing.xl}
                css={css`
                  width: 100%;
                `}
              >
                <InputField
                  name={"first_name"}
                  type={InputType.TEXT}
                  placeholder={t("account.first-name")}
                  css={css`
                    width: 100%;
                  `}
                />

                <InputField
                  name={"last_name"}
                  type={InputType.TEXT}
                  placeholder={t("account.last-name")}
                  css={css`
                    width: 100%;
                  `}
                />
              </Row>

              <InputField
                name={"email"}
                type={InputType.TEXT}
                placeholder={t("account.email")}
                disabled
                css={css`
                  width: calc(50% - ${Spacing.xl} / 2);
                  input {
                    border: none;
                    background-color: ${theme.colors.gray2};
                  }
                `}
              />

              <div>
                <Button
                  isSubmitting={isSubmitting}
                  type="submit"
                  disabled={!dirty || !isValid}
                >
                  {t("profile_page.update")}
                </Button>
              </div>
            </Column>
          </Form>
        );
      }}
    </Formik>
  );
}
