import React from "react";
import { css } from "styled-components/macro";
import { Footer } from "./Footer";
import { Wrapper } from "./Wrapper";
import { Navigation } from "./Navigation/Navigation";
import { Header } from "./Navigation/Header";

export function PageLayout(props: {
  children: React.ReactNode | React.ReactNode[];
  search?: React.ReactNode;
}) {
  return (
    <>
      <Wrapper
        css={css`
          padding: 0;
          box-sizing: border-box;
        `}
      >
        <div
          css={css`
            position: relative;
            display: flex;
            flex-flow: row wrap;
            justify-content: stretch;
          `}
        >
          <Navigation />
          <main
            css={css`
              flex: 1 1 10%;
              padding: 0 60px;
            `}
          >
            <Header search={props.search} />
            <section>{props.children}</section>
          </main>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
}
