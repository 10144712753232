import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as HomeIcon } from "../../assets/icons/Home.svg";
import { Collaborators } from "./Collaborators";
import { CommentNotification } from "./Comments";
import { HistoryButtons } from "./HistoryButtons";
import { Title } from "./Title";
import { ShareButton } from "./ShareButton";
import { ConnectionCheck } from "./ConnectionCheck";
import { ThemiControlsDropdown } from "./ThemiControlsDropdown";
import { FeedbackButton } from "../../components/FeedbackButton";

import { useCollaboration } from "../contexts/CollaborationContext";
import { theme } from "../../themes/variables";

const Section = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  border-right: 1px solid #eae8e8;
`;

export function StatusBar() {
  const { members } = useCollaboration();
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        flex-flow: row wrap;
        justify-content: space-between;
      `}
    >
      <Section
        css={css`
          min-width: 225px;
          position: relative;
        `}
      >
        <Link
          to="/"
          css={css`
            display: flex;
            align-items: center;
            text-decoration: underline;
            font-weight: 600;
            margin-right: 40px;
            :active {
              color: ${theme.colors.primary};
            }
          `}
          title={t("myThemi.dashboard.button.back")}
        >
          <HomeIcon />
        </Link>

        <div
          css={css`
            position: absolute;
            top: 50%;
            left: 40px;
            transform: translate(-50%, -50%);
          `}
        >
          <ConnectionCheck />
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
          `}
        >
          <div
            css={css`
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              color: #616161;
              margin-bottom: 3px;
              cursor: default;
            `}
          >
            {t("video-editor.header.title")}
          </div>

          <div
            css={css`
              display: flex;
              align-items: center;
              margin-right: 16px;
            `}
          >
            <Title />

            <ThemiControlsDropdown users={members} />
          </div>
        </div>
      </Section>

      <div
        css={css`
          display: flex;
          flex: 1 0 10%;
          height: 100%;
          flex-flow: row wrap;
          justify-content: space-between;
          border-right: 1px solid #d0d0d0;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: 15px;
          `}
        >
          <HistoryButtons />
        </div>

        <CommentNotification />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            padding: 0 30px;
          `}
        >
          <Collaborators
            users={members}
            maxCollaborators={3}
            isHover
            dynamicBorder
            isValueClickable
          />
        </div>
        <div
          css={css`
            flex: 0 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 20px 0px 0;
          `}
        >
          <div id="themis-collaborators">
            <ShareButton />
          </div>
          <FeedbackButton />
        </div>
      </div>
    </div>
  );
}
