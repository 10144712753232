import { authorised } from "../../request";
import { UserWorkspace, WorkspaceOverview } from "../../types/Workspace";

export async function createWorkspace(values: WorkspaceOverview) {
  const API = authorised();

  const response = await API.request<UserWorkspace>({
    method: "POST",
    url: `/api/studio/themis/workspaces/create/`,
    data: values,
  });

  return response.data;
}
