import React, { useMemo, useState, useCallback } from "react";
import { css } from "styled-components/macro";
import { useHistory } from "react-router";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Headers } from "../../helpers/layout";
import { Badge } from "./Badge";
import { ModalDelete } from "../../modals/ModalDelete";
import { Button } from "../../components/Button";
import { Dropdown, DropdownItem } from "../../components/Dropdown";
import { ReactComponent as ThreeDotsIcon } from "../../assets/icons/ThreeDots.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/Play.svg";
import { ReactComponent as ViewsIcon } from "../../assets/icons/Views.svg";
import { ReactComponent as FavouriteIcon } from "../../assets/icons/Favourite.svg";
import { ReactComponent as SmileIcon } from "../../assets/icons/Smile.svg";
import { ReactComponent as PixelIcon } from "../../assets/icons/Pixel.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/Eye.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/Trash.svg";
import {
  ScenePreview,
  aspectRatio,
} from "../../VideoEditor/components/ScenePreview";
import { getDuration, getVideoScenes } from "../../VideoEditor/helpers/video";
import { Video, VideoPublishStatus } from "../../VideoEditor/types/Video";
import { deleteVideo } from "../../VideoEditor/actions/deleteVideo";
import { Collaborators } from "../../VideoEditor/components/Collaborators";
import { CollaboratorRole } from "../../VideoEditor/types/Collaborator";
import { useAuth } from "../../contexts/UserContext";
import { ReactComponent as ArchiveIcon } from "../../assets/icons/DropDown-Archive.svg";
import { handleArchiveRecover } from "../../helpers/handleArchiveRecover";
import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { UserMeta } from "../../types/UserProfile";
import { ReactComponent as DuplicateIcon } from "../../assets/icons/Duplicate.svg";
import { duplicateVideo as duplicateVideoRequest } from "../../VideoEditor/actions/duplicateVideo";
import { sendEvent } from "../../helpers/tracking";

export enum CardModeType {
  EDIT = "EDIT",
  VIEW = "VIEW",
  INSPIRATION = "INSPIRATION",
}

export function Card(props: {
  video: Video;
  videoStatus?: string;
  mode?: CardModeType;
  refetch: () => Promise<void>;
  onClick: () => void;
}) {
  const { video, mode = CardModeType.EDIT, refetch } = props;
  const { userprofile } = useAuth();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const isArchived = video.archived_at !== null;

  const onConfirmDelete = useCallback(async () => {
    if (video) {
      try {
        await deleteVideo(video.uuid);
      } catch (error: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      } finally {
        refetch();
      }
    }
  }, [t, video, refetch]);

  const duplicateVideo = useCallback(async () => {
    if (video) {
      try {
        const response = await duplicateVideoRequest({
          uuid: video.uuid,
        });

        customToast.success(
          t("video-editor.header.dropdown.duplicate-success")
        );

        sendEvent("Duplicated Themi", {
          themiId: response.data.uuid,
        });

        history.push(`/video-editor/${response.data.uuid}`);
      } catch (error: any) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    }
  }, [t, video, history]);

  const date1 = DateTime.now();
  const date2 = video.publish_date
    ? DateTime.fromISO(video.publish_date)
    : null;
  const diff = date2 ? date1.diff(date2, ["months", "days", "hours"]) : null;
  const days = diff ? diff.days : null;
  const hours = diff ? diff.hours : null;

  const userRole = useMemo(() => {
    const user = video.collaborators.find(
      (user) => user.user.id === userprofile?.id
    );

    return user?.role;
  }, [userprofile?.id, video.collaborators]);

  return (
    <div
      css={css`
        width: 330px;
        margin-bottom: 20px;
      `}
      key={video.uuid}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          border-radius: 9px;
          padding: 8px;
          transition: transform 0.3s ease, box-shadow 0.3s ease,
            padding 0.3s ease;
          cursor: pointer;

          :hover {
            transform: translate3d(0px, -4px, 0px);
            transform: scale(1.03);
            box-shadow: 0px 13px 25px #e8e8e8;
          }
        `}
        onClick={props.onClick}
      >
        <div
          css={css`
            width: 100%;
            border: 1px solid #e2e2e2;
            border-radius: 11px;
            display: flex;
            position: relative;
          `}
        >
          <div
            css={css`
              position: relative;
              width: 100%;
              aspect-ratio: ${aspectRatio};
              border: 1px solid #cdcdcd;
              border-radius: 9px;
              overflow: hidden;
            `}
          >
            {video.preview_schema && (
              <ScenePreview
                scene={getVideoScenes(video.preview_schema)[0]}
                width={400}
                css={css`
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  object-fit: cover;
                  border-radius: 9px;
                `}
              />
            )}
            <div
              css={css`
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 9px;
                background: linear-gradient(
                  180deg,
                  rgba(37, 36, 36, 0) -25%,
                  #252424 144.25%
                );
              `}
            ></div>
          </div>

          {/* Collaborators */}
          <div
            css={css`
              position: absolute;
              top: 10px;
              left: 10px;
            `}
          >
            <Collaborators
              users={video.collaborators.reduce<UserMeta[]>(
                (acc, collaborator) => {
                  if (collaborator.role !== CollaboratorRole.OWNER) return acc;
                  return [...acc, collaborator.user];
                },
                []
              )}
              maxCollaborators={4}
              isHover={false}
            />
          </div>

          {/* Three dots dropdown */}
          {mode !== CardModeType.INSPIRATION && (
            <div
              css={css`
                position: absolute;
                top: 10px;
                right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <Dropdown
                options={
                  video.publish_status === VideoPublishStatus.PUBLISHED ||
                  video.publish_status === VideoPublishStatus.ARCHIVED ? (
                    <>
                      <DropdownItem
                        onClick={async () =>
                          await handleArchiveRecover(video, refetch, t)
                        }
                      >
                        <ArchiveIcon
                          css={css`
                            align-self: center;
                            margin-right: 10px;
                          `}
                        />
                        {isArchived
                          ? t("drop-down.recover")
                          : t("drop-down.archive")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          history.push(`/video-editor/${video.uuid}`)
                        }
                      >
                        <PixelIcon
                          css={css`
                            align-self: center;
                            margin-right: 10px;
                          `}
                        />
                        {t("video-player.edit")}
                      </DropdownItem>
                      <DropdownItem onClick={duplicateVideo}>
                        <DuplicateIcon
                          width={15}
                          height={15}
                          css={css`
                            align-self: center;
                            margin-right: 10px;
                          `}
                        />
                        {t("actions.duplicate")}
                      </DropdownItem>
                    </>
                  ) : (
                    <>
                      <DropdownItem onClick={duplicateVideo}>
                        <DuplicateIcon
                          width={15}
                          height={15}
                          css={css`
                            align-self: center;
                            margin-right: 10px;
                          `}
                        />
                        {t("actions.duplicate")}
                      </DropdownItem>
                      <DropdownItem onClick={() => setDeleteModal(true)}>
                        <TrashIcon
                          width={15}
                          height={15}
                          css={css`
                            align-self: center;
                            margin-right: 10px;
                          `}
                        />
                        {t("actions.delete")}
                      </DropdownItem>
                    </>
                  )
                }
              >
                <Button
                  css={css`
                    width: 28px;
                    height: 28px;
                    background-color: #000;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    min-width: 0;
                    color: #fff;
                    border-radius: 50%;
                  `}
                  hoverStyles="none"
                >
                  <ThreeDotsIcon />
                </Button>
              </Dropdown>
            </div>
          )}

          {/* Play icon */}
          {(mode === CardModeType.VIEW ||
            mode === CardModeType.INSPIRATION) && (
            <div
              css={css`
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              `}
            >
              <PlayIcon width={37} height={37} />
            </div>
          )}

          {/* Duration */}
          {(mode === CardModeType.VIEW ||
            mode === CardModeType.INSPIRATION) && (
            <div
              css={css`
                position: absolute;
                bottom: 11px;
                right: 13px;
              `}
            >
              <Badge
                background="#e5e1ee"
                color="#a47aff"
                css={css`
                  cursor: default;
                `}
              >
                {getDuration(video.duration || 0, i18n.language, {
                  round: true,
                  units:
                    video.duration && video.duration > 60000
                      ? ["m"]
                      : ["m", "s"],
                })}
              </Badge>
            </div>
          )}

          {mode === CardModeType.EDIT && (
            <div
              css={css`
                position: absolute;
                bottom: 10px;
                left: 10px;
              `}
            >
              <Badge background="#252424" color="#FFFFFF">
                {userRole === CollaboratorRole.COMMENTER ? (
                  <>
                    <EyeIcon width={13} height={13} />

                    <span
                      css={css`
                        margin-left: 7px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 28px;
                      `}
                    >
                      {t("video-player.view")}
                    </span>
                  </>
                ) : (
                  <>
                    <PixelIcon width={13} height={13} />

                    <span
                      css={css`
                        margin-left: 7px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 28px;
                      `}
                    >
                      {t("video-player.edit")}
                    </span>
                  </>
                )}
              </Badge>
            </div>
          )}
        </div>

        <div
          css={css`
            margin-top: 12px;
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            `}
          >
            <Headers.H4
              css={css`
                flex: 1 0 10%;
                margin-right: 20px;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {video.name}
            </Headers.H4>

            {video.publish_date && (
              <div
                css={css`
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 120%;
                  flex: 0 0 auto;
                `}
              >
                {days
                  ? `${days} ${t("video-editor.search.days")}`
                  : `${hours && hours.toFixed(0)} ${t(
                      "video-editor.search.hours"
                    )}`}{" "}
                {t("video-editor.search.ago")}
              </div>
            )}
          </div>

          {mode === CardModeType.EDIT && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                margin-top: 6px;
                margin-bottom: 15px;
              `}
            >
              <div
                css={css`
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 120%;
                  color: #616161;
                `}
              >
                {t("collaborators.reactions")}
              </div>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                  margin-top: 12px;

                  font-weight: 400;
                  font-size: 12px;
                  line-height: 120%;
                  > div {
                    margin-right: 12px;
                  }
                `}
              >
                {false && (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <ViewsIcon />
                    <span
                      css={css`
                        margin-left: 8px;
                      `}
                    >
                      0
                    </span>
                  </div>
                )}
                {false && (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <SmileIcon />
                    <span
                      css={css`
                        margin-left: 8px;
                      `}
                    >
                      0
                    </span>
                  </div>
                )}

                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <FavouriteIcon />
                  <span
                    css={css`
                      margin-left: 8px;
                    `}
                  >
                    {video.comments_count || 0}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={onConfirmDelete}
        title={t("modal.delete.title")}
        description={t("video-editor.header.dropdown.delete-description")}
      />
    </div>
  );
}
