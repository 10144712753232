import React from "react";
import { Field, FieldProps } from "formik";
import { css } from "styled-components/macro";
import { CheckboxComponent } from "../../components/CheckboxComponent";
import { InputErrors } from "./InputErrors";
import { theme } from "../../themes/variables";

export function CheckboxField(props: {
  name: string;
  id?: string;
  label?: string;
  labelRight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  value?: boolean;
  className?: string;
  errorBottom?: boolean;
  hideTooltip?: boolean;
  validate?: (value: string) => Promise<void | string>;
  children?: React.ReactNode;
}) {
  const { validate, ...other } = props;
  return (
    <Field type="checkbox" name={props.name} validate={validate}>
      {({ field, meta }: FieldProps) => {
        return (
          <CheckboxComponent {...other} field={field}>
            {props.children}
            {!props.hideTooltip && (
              <InputErrors
                currentLength={String(field.value).length}
                error={meta.error}
                touched={meta.touched}
              />
            )}
            {props.errorBottom && meta.error && meta.touched && (
              <div
                css={css`
                  position: absolute;
                  color: ${theme.colors.error};
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 14px;
                  text-align: left;
                  margin-top: 25px;
                `}
              >
                {meta.error}
              </div>
            )}
          </CheckboxComponent>
        );
      }}
    </Field>
  );
}
