import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "../../components/Button";

import { ReactComponent as OopsIcon } from "../../assets/icons/Oops.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/Plus.svg";

import { theme } from "../../themes/variables";

export function NoResult() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
        margin-top: 140px;
        margin-bottom: 100px;
      `}
    >
      <OopsIcon />

      <div
        css={css`
          margin-top: 8px;
          font-weight: 500;
          font-size: 30px;
          line-height: 140%;
          color: #252424;
        `}
      >
        {t("video-editor.workspace.nothing-to-watch")}
      </div>

      <div
        css={css`
          margin-top: 6px;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #616161;
          text-align: center;
        `}
      >
        {t("video-editor.workspace.nothing-to-watch-description")}
      </div>

      <Button
        css={css`
          margin-top: 50px;
        `}
        color={theme.colors.white}
        background={theme.colors.primary}
        icon={<PlusIcon />}
        onClick={() => {
          history.push("/themi-builder");
        }}
      >
        {t("video-editor.workspace.nothing-to-watch-new-themi")}
      </Button>
    </div>
  );
}
