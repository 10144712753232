import React, { useCallback, useMemo, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useEditor } from "../../contexts/EditorContext";
import { ColorPicker } from "../ColorAndVisibility";
import { Dropdown, DropdownItem } from "../../../components/Dropdown";
import { ModalHyperlink } from "../../../modals/ModalHyperlink";
import { Divider } from "./components/Divider";
import { Section } from "./components/Section";

import { ReactComponent as StrokeSizeIcon } from "../../assets/icons/StrokeSize.svg";
import { ReactComponent as HyperlinkIcon } from "../../assets/icons/Hyperlink.svg";
import { Button } from "../Button";
import { theme } from "../../../themes/variables";

export function ShapeToolbar() {
  const { shapeConfig, setShapeConfig, currentElement } = useEditor();
  const { t } = useTranslation();
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [hyperlinkModalIsOpen, setHyperlinkModalIsOpen] = useState(false);

  const onSetShapeConfigSize = useCallback(
    (value: number) => {
      setShapeConfig("strokeSize", value);
    },
    [setShapeConfig]
  );

  const optionsForStroke: number[] = useMemo(() => {
    const elements = Array.from(Array(6).keys());
    if (
      currentElement?.type === "ellipse" ||
      currentElement?.type === "polygon" ||
      currentElement?.type === "rectangle"
    ) {
      return elements;
    }

    return elements.filter((el) => !!el);
  }, [currentElement?.type]);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        background: #fff;
        height: 100%;
        border: 1px solid #f2f2f2;
        box-shadow: 0px 0px 13px #e5e5e5;
        border-radius: 21.5px;
      `}
    >
      <Section>
        <ColorPicker
          title={t("shapes.sidebar.title.fill")}
          color={shapeConfig.fillColor}
          colorChangeHandler={(value: string) => {
            setShapeConfig("fillColor", value);
          }}
        />
        <Divider />
      </Section>

      <Section>
        <ColorPicker
          type={"ellipse"}
          title={t("shapes.sidebar.title.stroke")}
          color={shapeConfig.strokeColor}
          colorChangeHandler={(value: string) => {
            setShapeConfig("strokeColor", value);
          }}
        />

        <Dropdown
          opened={openedDropdown}
          onOpen={() => setOpenedDropdown(true)}
          onClose={() => setOpenedDropdown(false)}
          options={optionsForStroke.map((el) => {
            return (
              <DropdownItem onClick={() => onSetShapeConfigSize(el)} key={el}>
                {el}
              </DropdownItem>
            );
          })}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-left: 19px;
              cursor: pointer;
            `}
          >
            <StrokeSizeIcon width={19} height={16} />
            <div
              css={css`
                margin-left: 7px;
                padding: 2px 5px;
                border-radius: 2px;
                font-weight: 400;
                font-size: 14px;
                line-height: 1;
                ${openedDropdown &&
                css`
                  background: #f2f2f2;
                `}
              `}
            >
              {shapeConfig.strokeSize}
            </div>
          </div>
        </Dropdown>
        <Divider />
      </Section>

      <Section>
        <Button
          css={css`
            padding: 3px 6px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            background: ${theme.colors.white};
            color: ${theme.colors.primary};
          `}
          icon={<HyperlinkIcon />}
          onClick={() => setHyperlinkModalIsOpen(true)}
        >
          {t("video-editor.side-bar.hyperlink")}
        </Button>
        <ModalHyperlink
          isOpen={hyperlinkModalIsOpen}
          onClose={() => setHyperlinkModalIsOpen(false)}
        />
      </Section>
    </div>
  );
}
