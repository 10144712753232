import React from "react";
import { css } from "styled-components/macro";

import { Avatar } from "../VideoEditor/components/Avatar";
import { Triangle } from "../VideoEditor/components/Triangle";

export function AvatarCursor(props: {
  color: string;
  name?: string;
  size?: number;
  avatarURL?: string;
  className?: string;
}) {
  return (
    <div
      css={css`
        width: auto;
        z-index: 100;
      `}
      className={props.className}
    >
      <Triangle color={props.color} />
      <Avatar
        url={props.avatarURL}
        size={props.size}
        name={props.name}
        border
        color={props.color}
        css={css`
          margin-left: 4.5px;
          margin-top: -3px;
          border-width: 1.5px;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
        `}
      />
    </div>
  );
}
