import { authorised } from "../../request";
import { Video } from "../types/Video";

export async function duplicateVideo(input: { uuid: string }) {
  const API = authorised();

  return API.request<Video>({
    method: "POST",
    url: `/api/studio/themis/${input.uuid}/duplicate/`,
    data: input,
  });
}
