import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { File } from "../types/File";
import { DropFile } from "./DropFile";
import { Headers } from "../helpers/headers";

import { ReactComponent as DocumentIcon } from "../assets/icons/Document.svg";
import { ReactComponent as LinkIcon } from "../assets/icons/Link.svg";
import { ReactComponent as PageLinkIcon } from "../assets/icons/PageLink.svg";
import { ReactComponent as FolderIcon } from "../assets/icons/Folder.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/ControlsDelete.svg";

const SquareButton = styled.button<{
  isActive: boolean;
}>`
  width: 108px;
  height: 98px;
  background: #ffffff;
  /* outline */

  border: 1px solid #8e8e8e;
  border-radius: 7px;
  color: #8e8e8e;
  font-size: 16px;
  cursor: pointer;

  svg {
    fill: #8e8e8e;
  }

  h4 {
    font-size: 14px;
  }

  ${(props) => {
    if (props.isActive) {
      return css`
        border: 1px solid #e95b2e;
        color: #e95b2e;

        svg {
          fill: #e95b2e;
        }
      `;
    }
  }}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 10px;
  }
`;

export function LinkDocumentAttach(props: {
  url?: string;
  file?: File | null | undefined;
  onSetUrl: (text: string) => void;
  onUpload: (file: File) => void;
  onRemoveAttachedLink: () => void;
  onRemoveAttachedFile: () => void;
  showDescription?: boolean;
}) {
  const [mode, setMode] = useState<"link" | "document">(
    props.url ? "link" : "document"
  );
  const [url, setUrl] = useState(props.url || "");
  const { t } = useTranslation();

  useEffect(() => {
    if (props.file) {
      setMode("document");
    } else if (props.url) {
      setUrl(props.url);
      setMode("link");
    }
  }, [props.file, props.url]);

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          flex-wrap: nowrap;
          margin-top: 20px;
          border-bottom: 1px solid #8e8e8e;
          padding-bottom: 30px;

          p {
            text-align: center;
            line-height: 1;
          }
        `}
      >
        <ButtonContainer
          css={css`
            flex: 1 0 50%;
          `}
        >
          <SquareButton
            onClick={() => {
              setMode("link");
            }}
            isActive={mode === "link"}
          >
            <PageLinkIcon />
            <h4>{t("interactivity_settings.link_label")}</h4>
          </SquareButton>
          <p
            css={
              props.showDescription &&
              css`
                font-weight: 500;
                font-size: 20px;
              `
            }
          >
            {t("video-editor.tooltip.attach-link")}
          </p>
          {props.showDescription && (
            <p
              css={css`
                color: #7f7f7f;
                font-weight: 400;
                font-size: 14px;
              `}
            >
              {t("video-editor.tooltip.attach-link-hint")}
            </p>
          )}
        </ButtonContainer>
        <ButtonContainer
          css={css`
            flex: 1 0 50%;
          `}
        >
          <SquareButton
            onClick={() => {
              setMode("document");
            }}
            isActive={mode === "document"}
          >
            <DocumentIcon />

            <h4>{t("interactivity_settings.document_label")}</h4>
          </SquareButton>
          <p
            css={
              props.showDescription &&
              css`
                font-weight: 500;
                font-size: 20px;
              `
            }
          >
            {t("video-editor.tooltip.attach-document")}
          </p>
          {props.showDescription && (
            <p
              css={css`
                color: #7f7f7f;
                font-weight: 400;
                font-size: 14px;
              `}
            >
              {t("video-editor.tooltip.attach-document-hint")}
            </p>
          )}
        </ButtonContainer>
      </div>
      <div
        css={css`
          padding-top: 20px;
        `}
      >
        <Headers.H4
          css={css`
            margin-bottom: 10px;
            font-weight: 500;
          `}
        >
          {t("interactivity_settings.attached_material")}
        </Headers.H4>

        {mode === "link" && (
          <div
            css={css`
              position: relative;
              display: flex;
              flex-flow: row nowrap;
            `}
          >
            <div
              css={css`
                position: relative;
                flex: 1 0 10%;
              `}
            >
              <input
                type="text"
                css={css`
                  height: 40px;
                  width: 100%;
                  padding: 0 20px;
                  padding-left: 50px;
                  border: 1px solid #8e8e8e;
                  border-radius: 7px;

                  &:focus {
                    border-color: #e95b2e;
                    outline: none;

                    & + .icon svg {
                      fill: #e95b2e;
                    }
                  }
                `}
                placeholder={t("video-editor.tooltip.paste-link")}
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                  props.onSetUrl(e.target.value);
                }}
              />
              <div
                className="icon"
                css={css`
                  width: 18px;
                  height: 18px;
                  top: 0;
                  bottom: 0;
                  left: 10px;
                  margin: auto;
                  position: absolute;

                  svg {
                    width: 100%;
                    height: 100%;
                  }
                `}
              >
                <LinkIcon />
              </div>
            </div>
            <button
              css={css`
                flex: 0 0 auto;
                min-width: 0;
                background: none;
                border: none;
                cursor: pointer;
                margin-left: 15px;

                svg {
                  width: 16px;
                }
                :active svg {
                  fill: #e95b2e;
                }
              `}
              onClick={() => {
                props.onRemoveAttachedLink();
              }}
            >
              <TrashIcon />
            </button>
          </div>
        )}
        {mode === "document" && (
          <div>
            {props.file ? (
              <div
                css={css`
                  position: relative;
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <div
                  data-type={props.file.mime_type}
                  css={css`
                    flex: 0 0 auto;
                    min-width: 0;
                    margin-right: 10px;
                    font-family: Arial, Tahoma, sans-serif;
                    font-weight: 300;
                    display: inline-block;
                    width: 20px;
                    height: 26px;
                    background: #018fef;
                    position: relative;
                    border-radius: 2px;
                    text-align: left;
                    -webkit-font-smoothing: antialiased;

                    &:before {
                      display: block;
                      content: "";
                      position: absolute;
                      top: 0;
                      right: 0;
                      width: 0;
                      height: 0;
                      border-bottom-left-radius: 2px;
                      border-width: 4px;
                      border-style: solid;
                      border-color: #fff #fff rgba(255, 255, 255, 0.35)
                        rgba(255, 255, 255, 0.35);
                    }

                    &[data-type="zip"],
                    &[data-type="rar"] {
                      background: #acacac;
                    }
                    &[data-type^="doc"] {
                      background: #307cf1;
                    }
                    &[data-type^="xls"] {
                      background: #0f9d58;
                    }
                    &[data-type^="ppt"] {
                      background: #d24726;
                    }
                    &[data-type="pdf"] {
                      background: #e13d34;
                    }
                    &[data-type="txt"] {
                      background: #5eb533;
                    }
                    &[data-type="mp3"],
                    &[data-type="wma"],
                    &[data-type="m4a"],
                    &[data-type="flac"] {
                      background: #8e44ad;
                    }
                    &[data-type="mp4"],
                    &[data-type="wmv"],
                    &[data-type="mov"],
                    &[data-type="avi"],
                    &[data-type="mkv"] {
                      background: #7a3ce7;
                    }
                    &[data-type="bmp"],
                    &[data-type="jpg"],
                    &[data-type="jpeg"],
                    &[data-type="gif"],
                    &[data-type="png"] {
                      background: #f4b400;
                    }
                  `}
                ></div>
                <div
                  css={css`
                    flex: 1 0 10%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  `}
                >
                  {props.file.title}
                </div>
                <button
                  css={css`
                    flex: 0 0 auto;
                    min-width: 0;
                    background: none;
                    border: none;
                    cursor: pointer;
                    margin-left: 15px;

                    svg {
                      width: 16px;
                    }
                  `}
                  onClick={() => {
                    props.onRemoveAttachedFile();
                  }}
                >
                  <TrashIcon />
                </button>
              </div>
            ) : (
              <DropFile
                accept={{
                  "image/*": [".jpg", ".png", ".svg", ".jpeg"],
                  "application/*": [".doc", ".docx", ".pdf"],
                }}
                initialMessage={
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      padding: 7px 10px;
                    `}
                  >
                    <FolderIcon
                      css={css`
                        align-self: flex-start;
                        width: 42px;
                        margin-top: 3px;
                      `}
                    />
                    <Headers.H5
                      css={css`
                        margin-left: 7px;
                      `}
                    >
                      {t("media.sidebar.dragDrop")}
                    </Headers.H5>
                  </div>
                }
                onUpload={(file) => {
                  if (!file) return;
                  props.onUpload(file);
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
