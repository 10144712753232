import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDrag } from "react-dnd";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { useClickOutside } from "../VideoEditor/hooks/useClickOutside";

export function DropdownDragItem(props: {
  className?: string;
  children: React.ReactNode | React.ReactNode[] | string | null;
  drag: {
    type?: string;
    item: {
      type: string;
      subtype?: string;
      config?: any;
    };
  };
  onClick?: () => void;
}) {
  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: "element",
      item: props.drag.item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  return (
    <DropdownItem
      dropdownRef={(inst) => {
        if (inst) {
          dragPreview(inst);
          drag(inst);
        }
      }}
      styles={{ opacity: isDragging ? 0.5 : 1, cursor: "grab" }}
      onClick={props.onClick}
      className={props.className}
    >
      {props.children}
    </DropdownItem>
  );
}

export function DropdownItem(props: {
  className?: string;
  children: React.ReactNode | React.ReactNode[] | string | null;
  onClick?: () => void;
  dropdownRef?: React.ForwardedRef<HTMLDivElement>;
  styles?: React.CSSProperties;
  disabled?: boolean;
}) {
  return (
    <div
      ref={props.dropdownRef}
      style={props.styles}
      onClick={() => {
        if (props.onClick && !props.disabled) props.onClick();
      }}
      css={css`
        display: flex;
        position: relative;
        align-items: left;
        padding: 2px 50px 2px 6px;
        background: ${theme.colors.white};
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        border-radius: 4px;
        cursor: pointer;
        :hover {
          color: ${theme.colors.white};
          background: ${theme.colors.primary};
        }
        ${props.disabled &&
        css`
          color: #8e8e8e;
          cursor: default;
          pointer-events: none;
        `}
      `}
      className={props.className}
    >
      {props.children}
    </div>
  );
}

export type DropdownHandle = {
  close: () => void;
  open: () => void;
};

export const Dropdown = forwardRef(
  (
    props: {
      id?: string;
      opened?: boolean;
      disabled?: boolean;
      className?: string;
      children?: React.ReactNode | React.ReactNode[] | string | null;
      options?: React.ReactNode | React.ReactNode[] | string | null;
      settings?: {
        disableOutsideClick?: boolean;
      };
      onOpen?: () => void;
      onClose?: () => void;
      outsideRef?: React.MutableRefObject<HTMLElement | null>;
      containerStyle?: React.CSSProperties;
    },
    ref: React.ForwardedRef<DropdownHandle>
  ) => {
    const { opened: intialOpened = false, settings } = props;
    const [opened, setOpened] = useState(intialOpened);
    const dropdownRef = useRef(null);

    useEffect(() => {
      setOpened(intialOpened);
    }, [intialOpened]);

    useClickOutside(props.outsideRef || dropdownRef, () => {
      if (settings?.disableOutsideClick) return;
      setOpened(false);
      props.onClose && props.onClose();
    });

    useImperativeHandle(ref, () => ({
      close() {
        setOpened(false);
      },
      open() {
        setOpened(true);
      },
    }));

    const onOpen = useCallback(() => {
      setOpened(true);
      if (props.onOpen) props.onOpen();
    }, [props]);

    const onClose = useCallback(() => {
      setOpened(false);
      if (props.onClose) props.onClose();
    }, [props]);

    return (
      <div
        id={props.id}
        ref={dropdownRef}
        css={css`
          position: relative;
          display: flex;
          overflow: visible;
        `}
        style={props.containerStyle}
        onClick={(e) => {
          e.stopPropagation();
          if (!props.disabled) {
            if (opened) onClose();
            else onOpen();
          }
        }}
      >
        {props.children}
        <div
          css={css`
            position: absolute;
            background: #fff;
            color: ${theme.colors.black};
            border: 1px solid #e2e2e2;
            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
            border-radius: 7px;
            top: calc(100% + 10px);
            right: 0;
            padding: 10px 8px;
            box-sizing: border-box;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0.3s linear, opacity 0.3s linear;
            z-index: 2;
            ${opened &&
            css`
              visibility: visible;
              opacity: 1;
            `}
            >div {
              margin-bottom: 15px;
              :last-of-type {
                margin-bottom: 0;
              }
            }
          `}
          className={props.className}
        >
          {props.options}
        </div>
      </div>
    );
  }
);
