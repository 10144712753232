import React from "react";
import { css } from "styled-components/macro";

type ModalFooterProps = {
  children?: React.ReactNode | React.ReactNode[] | string;
  className?: string;
};

export function ModalSides(props: ModalFooterProps) {
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-flow: row wrap;
      `}
      className={props.className}
    >
      {props.children}
    </div>
  );
}
