import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import styled, { css } from "styled-components/macro";
import { v4 as uuid } from "uuid";
import {
  Dropdown,
  DropdownItem,
  DropdownDragItem,
  DropdownHandle,
} from "../../components/Dropdown";
import { DisableUI } from "../components/DisableUI";
import { TooltipArea } from "../../components/Tooltip";
import { InteractiveModal } from "./InteractiveModal";
import { useEditor, navItems, NavItemBlank } from "../contexts/EditorContext";
import { useComments } from "../contexts/CommentsContext";
import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";
import { useAuth } from "../../contexts/UserContext";
import { canEdit } from "../helpers/collaborator";

import { ReactComponent as RecordIcon } from "../../assets/icons/Record.svg";
import { ReactComponent as ThemiAiIcon } from "../../assets/icons/ThemiAi.svg";
import { ReactComponent as TextIcon } from "../../assets/icons/Text.svg";
import { ReactComponent as ToolsIcon } from "../../assets/icons/Tools.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/Comment.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/Chevron2.svg";
import { ReactComponent as SquareIcon } from "../../assets/icons/Square.svg";
import { ReactComponent as PolygonIcon } from "../../assets/icons/Polygon.svg";
import { ReactComponent as CircleIcon } from "../../assets/icons/Circle.svg";
import { ReactComponent as LineIcon } from "../../assets/icons/Line.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/Arrow1.svg";
import { ReactComponent as ArmIcon } from "../../assets/icons/Arm.svg";
import { ReactComponent as LettetTBigIcon } from "../assets/icons/LettetTBig.svg";
import { ReactComponent as LetterTSmallIcon } from "../assets/icons/LettetTSmall.svg";
import { ReactComponent as BulletIcon } from "../assets/icons/Bullet.svg";
import { calculateRelativeSize } from "../helpers/renderer";

import { defaultSequenceDuration } from "../../constants";

export enum NavType {
  Dropdown = "dropdown",
  Select = "select",
}

export type NavItem = NavItemBlank & {
  label: string;
  icon: React.ReactNode;
  options?: React.ReactNode | React.ReactNode[];
};

const NavigationItemContainer = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

function NavigationItem(props: {
  item: NavItem;
  isActive: boolean;
  className?: string;
  handleOpen: (item: NavItem) => void;
}) {
  const { item, isActive } = props;

  return (
    <div
      onClick={() => {
        props.handleOpen(item);
      }}
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        &:hover {
          color: #e95b2e;
        }

        ${isActive &&
        css`
          color: #e95b2e;
        `}
      `}
      className={props.className}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 45px;
          background: #ffffff;
          box-shadow: 0px 3px 7px #e5e5e5;
          border-radius: 26.5px;
        `}
      >
        {item.icon}
      </div>

      <span
        css={css`
          align-self: center;
          text-align: center;
          margin-top: 1px;
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: #616161;
          white-space: nowrap;
        `}
      >
        {item.label}
      </span>
    </div>
  );
}

function DropdownItemOption(props: {
  icon?: React.ReactNode;
  text: string;
  isDropdown?: boolean;
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {props.isDropdown && (
          <div>
            <ChevronIcon
              css={css`
                margin-right: 15px;
              `}
            />
          </div>
        )}
        {props.icon && (
          <div
            css={css`
              width: 22px;
            `}
          >
            {props.icon}
          </div>
        )}
        <div
          css={css`
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
          `}
        >
          {props.text}
        </div>
      </div>
    </div>
  );
}

function NavigationPure() {
  const { toggleComments, closeComments } = useComments();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [interactiveModal, setInteractiveModal] = useState<boolean>(false);
  const { activeNav, setActiveNav, setRecordModalVisible, setAIModalVisible } =
    useEditor();
  const { userprofile } = useAuth();
  const { activeScene, pause } = usePlayback();
  const { shapeConfig, canvasSize } = useEditor();
  const { video, api } = useStorage();
  const { t } = useTranslation();

  let config = cloneDeep(shapeConfig);

  const shapeRef = useRef<DropdownHandle>(null);
  const interactiveContentRef = useRef<DropdownHandle>(null);

  const closeChildDropdown = () => {
    if (shapeRef.current && interactiveContentRef.current) {
      shapeRef.current.close();
      interactiveContentRef.current.close();
    }
  };

  const closeNav = useCallback(() => {
    closeChildDropdown();
    setActiveNav(null);
  }, [setActiveNav]);

  const items: Array<NavItem> = navItems.map((item) => {
    if (item.key === "ai") {
      return {
        ...item,
        icon: <ThemiAiIcon width={25} height={25} />,
        label: t("ai.themi-ai"),
      };
    } else if (item.key === "record") {
      return {
        ...item,
        icon: <RecordIcon width={23} height={23} />,
        label: t("video-editor.side-bar.record"),
      };
    } else if (item.key === "text") {
      return {
        type: item.type,
        key: item.key,
        icon: <TextIcon width={17} height={17} />,
        label: t("video-editor.side-bar.text"),
        options: (
          <>
            <DropdownItem
              css={css`
                padding: 2px 15px 2px 6px;
              `}
              onClick={() => {
                if (!activeScene || !userprofile) return;

                api.createElement({
                  id: uuid(),
                  scene_id: activeScene.id,
                  order: activeScene.elements.length,
                  type: "text",
                  text: `<p style="font-family: Arial;font-size: ${calculateRelativeSize(
                    32
                  )};">${t("text.sidebar.typeSomething")}</p>`,
                  states: [
                    {
                      id: uuid(),
                      left: 100,
                      top: 100,
                      width: 220,
                      height: 150,
                      scale: 1,
                      rotation: 0,
                      start_time: 0,
                      duration: defaultSequenceDuration,
                    },
                  ],
                });

                closeNav();
              }}
            >
              <DropdownItemOption
                icon={<LettetTBigIcon />}
                text={t("navigation.headline")}
              />
            </DropdownItem>

            <DropdownItem
              css={css`
                padding: 2px 15px 2px 6px;
              `}
              onClick={() => {
                if (!activeScene || !userprofile) return;

                api.createElement({
                  id: uuid(),
                  scene_id: activeScene.id,
                  order: activeScene.elements.length,
                  type: "text",
                  text: `<p style="font-family: Arial;font-size: ${calculateRelativeSize(
                    16
                  )};">${t("text.sidebar.typeSomething")}</p>`,
                  states: [
                    {
                      id: uuid(),
                      left: 100,
                      top: 100,
                      width: 200,
                      height: 100,
                      scale: 1,
                      rotation: 0,
                      start_time: 0,
                      duration: defaultSequenceDuration,
                    },
                  ],
                });

                closeNav();
              }}
            >
              <DropdownItemOption
                icon={<LetterTSmallIcon />}
                text={t("navigation.body-text")}
              />
            </DropdownItem>
            <DropdownItem
              css={css`
                padding: 2px 15px 2px 6px;
              `}
              onClick={() => {
                if (!activeScene || !userprofile) return;

                api.createElement({
                  id: uuid(),
                  scene_id: activeScene.id,
                  order: activeScene.elements.length,
                  type: "text",
                  text: `<ul style="font-family: Arial;font-size: ${calculateRelativeSize(
                    16
                  )};">
                    <li>${t("text.sidebar.typeSomething")}</li>
                  </ul>`,
                  states: [
                    {
                      id: uuid(),
                      left: 100,
                      top: 100,
                      width: 200,
                      height: 100,
                      scale: 1,
                      rotation: 0,
                      start_time: 0,
                      duration: defaultSequenceDuration,
                    },
                  ],
                });

                closeNav();
              }}
            >
              <DropdownItemOption
                icon={<BulletIcon />}
                text={t("navigation.bullet-points")}
              />
            </DropdownItem>
          </>
        ),
      };
    } else if (item.key === "comment") {
      return {
        ...item,
        icon: <CommentIcon />,
        label: t("video-editor.side-bar.comment"),
      };
    } else {
      return {
        ...item,
        icon: <ToolsIcon width={23} height={23} />,
        label: t("video-editor.side-bar.tools"),
        options: (
          <>
            <Dropdown
              css={css`
                right: unset;
                right: calc(100% + 8px);
                top: -10px;
                white-space: nowrap;
                width: 100%;
              `}
              outsideRef={wrapperRef}
              onClose={() => {
                if (activeNav === item.key) closeNav();
              }}
              onOpen={() => {
                interactiveContentRef.current?.close();
              }}
              ref={shapeRef}
              options={
                <>
                  <DropdownDragItem
                    css={css`
                      padding: 2px 15px 2px 6px;
                      width: 100%;
                    `}
                    drag={{
                      type: "element",
                      item: {
                        type: "rectangle",
                        config: config,
                      },
                    }}
                    onClick={() => {
                      if (!activeScene || !userprofile) return;

                      api.createElement({
                        id: uuid(),
                        scene_id: activeScene.id,
                        order: activeScene.elements.length,
                        type: "rectangle",
                        states: [
                          {
                            id: uuid(),
                            left: 100,
                            top: 100,
                            width: 100,
                            height: 100,
                            scale: 1,
                            rotation: 0,
                            start_time: 0,
                            duration: defaultSequenceDuration,
                          },
                        ],
                        config: config,
                      });

                      closeNav();
                    }}
                  >
                    <DropdownItemOption
                      icon={<SquareIcon />}
                      text={t("video-editor.side-bar.square")}
                    />
                  </DropdownDragItem>

                  <DropdownDragItem
                    css={css`
                      padding: 2px 15px 2px 6px;
                      width: 100%;
                    `}
                    drag={{
                      type: "element",
                      item: {
                        type: "ellipse",
                        config: config,
                      },
                    }}
                    onClick={() => {
                      if (!activeScene || !userprofile) return;

                      api.createElement({
                        id: uuid(),
                        scene_id: activeScene.id,
                        order: activeScene.elements.length,
                        type: "ellipse",
                        states: [
                          {
                            id: uuid(),
                            left: 100,
                            top: 100,
                            width: 100,
                            height: 100,
                            scale: 1,
                            rotation: 0,
                            start_time: 0,
                            duration: defaultSequenceDuration,
                          },
                        ],
                        config: config,
                      });

                      closeNav();
                    }}
                  >
                    <DropdownItemOption
                      icon={<CircleIcon />}
                      text={t("video-editor.side-bar.circle")}
                    />
                  </DropdownDragItem>

                  <DropdownDragItem
                    css={css`
                      padding: 2px 15px 2px 6px;
                      width: 100%;
                    `}
                    drag={{
                      type: "element",
                      item: {
                        type: "polygon",
                        config: config,
                      },
                    }}
                    onClick={() => {
                      if (!activeScene || !userprofile) return;

                      api.createElement({
                        id: uuid(),
                        scene_id: activeScene.id,
                        order: activeScene.elements.length,
                        type: "polygon",
                        states: [
                          {
                            id: uuid(),
                            left: 100,
                            top: 100,
                            width: 100,
                            height: 100,
                            scale: 1,
                            rotation: 0,
                            start_time: 0,
                            duration: defaultSequenceDuration,
                          },
                        ],
                        config: config,
                      });

                      closeNav();
                    }}
                  >
                    <DropdownItemOption
                      icon={<PolygonIcon />}
                      text={t("video-editor.side-bar.triangle")}
                    />
                  </DropdownDragItem>

                  <hr
                    css={css`
                      margin: 0 !important;
                      border-top: 1px solid #eae8e8;
                      margin-bottom: 15px !important;
                    `}
                  />

                  <DropdownDragItem
                    css={css`
                      padding: 2px 15px 2px 6px;
                      width: 100%;
                    `}
                    drag={{
                      type: "element",
                      item: {
                        type: "line",
                        config: config,
                      },
                    }}
                    onClick={() => {
                      if (!activeScene || !userprofile) return;

                      api.createElement({
                        id: uuid(),
                        scene_id: activeScene.id,
                        order: activeScene.elements.length,
                        type: "line",
                        states: [
                          {
                            id: uuid(),
                            left: 100,
                            top: 100,
                            width: 100,
                            height: 100,
                            scale: 1,
                            rotation: 0,
                            start_time: 0,
                            duration: defaultSequenceDuration,
                          },
                        ],
                        config: {
                          ...config,
                          fillColor: config.strokeColor,
                        },
                      });

                      closeNav();
                    }}
                  >
                    <DropdownItemOption
                      icon={<LineIcon />}
                      text={t("video-editor.side-bar.line")}
                    />
                  </DropdownDragItem>

                  <DropdownDragItem
                    css={css`
                      padding: 2px 15px 2px 6px;
                      width: 100%;
                    `}
                    drag={{
                      type: "element",
                      item: {
                        type: "arrow-1",
                        config: config,
                      },
                    }}
                    onClick={() => {
                      if (!activeScene || !userprofile) return;

                      api.createElement({
                        id: uuid(),
                        scene_id: activeScene.id,
                        order: activeScene.elements.length,
                        type: "arrow-1",
                        states: [
                          {
                            id: uuid(),
                            left: 100,
                            top: 100,
                            width: 100,
                            height: 100,
                            scale: 1,
                            rotation: 0,
                            start_time: 0,
                            duration: defaultSequenceDuration,
                          },
                        ],
                        config: {
                          ...config,
                          fillColor: config.strokeColor,
                        },
                      });

                      closeNav();
                    }}
                  >
                    <DropdownItemOption
                      icon={<ArrowIcon />}
                      text={t("video-editor.side-bar.arrow")}
                    />
                  </DropdownDragItem>
                </>
              }
            >
              <DropdownItem
                css={css`
                  padding: 2px 15px 2px 6px;
                  width: 100%;
                `}
              >
                <DropdownItemOption
                  icon={<ChevronIcon />}
                  text={t("video-editor.side-bar.shapes")}
                />
              </DropdownItem>
            </Dropdown>
            {/* <DropdownItem
              css={css`
                padding: 2px 15px 2px 6px;
              `}
            >
              <DropdownItemOption
                icon={<PictureIcon />}
                text={t("video-editor.side-bar.image")}
              />
            </DropdownItem> */}
            <DropdownItem
              css={css`
                padding: 2px 15px 2px 6px;
              `}
              onClick={() => {
                if (!activeScene || !userprofile) return;

                setInteractiveModal(true);

                closeNav();
              }}
            >
              <DropdownItemOption
                icon={<ArmIcon />}
                text={t("video-editor.side-bar.interactive-content")}
              />
            </DropdownItem>
          </>
        ),
      };
    }
  });

  const handleOpen = useCallback(
    (item: NavItem) => {
      if (item.key === "comment") toggleComments();
      else closeComments();

      if (!canEdit(video?.current_user_role)) return;

      if (item.key === "record") {
        setRecordModalVisible(true);
      } else if (item.key === "ai") {
        setAIModalVisible(true);
      } else if (activeNav !== item.key) {
        setActiveNav(item.key);
      } else {
        closeNav();
      }
      pause();
    },
    [
      video,
      activeNav,
      setActiveNav,
      closeNav,
      setRecordModalVisible,
      setAIModalVisible,
      closeComments,
      toggleComments,
      pause,
    ]
  );

  return (
    <div
      css={css`
        height: 100%;
        color: #000;
        z-index: 999;
        padding-top: 90px;
      `}
      ref={wrapperRef}
    >
      <div
        id="themis-tools"
        css={css`
          position: relative;
          height: 100%;
          max-height: ${canvasSize.height}px;
        `}
      >
        {items.map((item) => {
          if (process.env.REACT_APP_ENABLE_AI !== "true" && item.key === "ai") {
            return null;
          }

          if (item.type === NavType.Dropdown) {
            return (
              <NavigationItemContainer key={item.key}>
                <TooltipArea
                  show={
                    item.key !== "comment" && !canEdit(video?.current_user_role)
                  }
                  text={t("tooltip.access")}
                  css={css`
                    height: 100%;
                  `}
                  maxWidth={300}
                  offsetX={-300}
                  offsetY={17}
                  staticPosition
                  noArrow
                >
                  <DisableUI
                    show={
                      item.key !== "comment" &&
                      !canEdit(video?.current_user_role)
                    }
                    css={css`
                      height: 100%;
                    `}
                  >
                    <Dropdown
                      id={`nav--${item.key}`}
                      opened={activeNav === item.key}
                      css={css`
                        top: 0;
                        right: 100%;
                      `}
                      outsideRef={wrapperRef}
                      onClose={() => {
                        if (activeNav === item.key) closeNav();
                      }}
                      options={item.options}
                    >
                      <NavigationItem
                        item={item}
                        isActive={activeNav === item.key}
                        handleOpen={handleOpen}
                      />
                    </Dropdown>
                  </DisableUI>
                </TooltipArea>
              </NavigationItemContainer>
            );
          }

          return (
            <NavigationItemContainer
              css={
                item.key === "comment" &&
                css`
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;
                `
              }
              key={item.key}
              id={"nav--" + item.key}
            >
              <TooltipArea
                show={
                  item.key !== "comment" && !canEdit(video?.current_user_role)
                }
                text={t("tooltip.access")}
                css={css`
                  height: 100%;
                `}
                maxWidth={300}
                offsetX={-300}
                offsetY={17}
                staticPosition
                noArrow
              >
                <DisableUI
                  show={
                    item.key !== "comment" && !canEdit(video?.current_user_role)
                  }
                  css={css`
                    height: 100%;
                  `}
                >
                  <NavigationItem
                    item={item}
                    isActive={activeNav === item.key}
                    handleOpen={handleOpen}
                  />
                </DisableUI>
              </TooltipArea>
            </NavigationItemContainer>
          );
        })}
      </div>

      <InteractiveModal
        isOpen={interactiveModal}
        onClose={() => setInteractiveModal(false)}
      />
    </div>
  );
}

export const Navigation = React.memo(NavigationPure);
