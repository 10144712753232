import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import styled, { css } from "styled-components/macro";
import { useRecording } from "../../contexts/RecordingContext";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Trash.svg";
import { ReactComponent as RetryIcon } from "../../assets/icons/Retry.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/Play.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/Pause.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/Checkmark.svg";
import { Button } from "../Button";
import { ModalDelete } from "../../../modals/ModalDelete";

const RoundedButton = styled.button`
  border: none;
  align-self: center;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 2px 13px #e3e3e3;
  width: 41px;
  height: 41px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

export function RecordingToolbar() {
  const {
    recording,
    recordingTime,
    cancelRecording,
    finishRecording,
    pauseRecording,
    retry,
    resumeRecording,
  } = useRecording();
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [retryModal, setRetryModal] = useState<boolean>(false);

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          background: #fff;
          width: auto;
          height: calc(100% + 15px);
          top: -7.5px;
          padding: 7.5px 15px;
          border: 1px solid #f2f2f2;
          box-shadow: 0px 0px 13px #e5e5e5;
          border-radius: 29px;
        `}
      >
        <RoundedButton
          onClick={() => {
            pauseRecording();
            setDeleteModal(true);
          }}
        >
          <DeleteIcon
            css={css`
              width: 17px;
              height: 18px;
            `}
          />
        </RoundedButton>
        <RoundedButton
          onClick={() => {
            pauseRecording();
            setRetryModal(true);
          }}
        >
          <RetryIcon
            css={css`
              width: 24px;
              height: 27px;
            `}
          />
        </RoundedButton>
        <RoundedButton
          onClick={() => {
            if (recording === "paused") {
              resumeRecording();
            } else {
              pauseRecording();
            }
          }}
          css={css`
            width: auto;
            padding: 0 13px;
            flex-flow: row wrap;
          `}
        >
          <div
            css={css`
              display: flex;
              min-width: 17px;
            `}
          >
            {recording === "paused" ? (
              <PlayIcon
                fill="#000"
                css={css`
                  width: 17px;
                  height: 17px;
                `}
              />
            ) : (
              <PauseIcon
                css={css`
                  width: 13px;
                  height: 19px;
                `}
              />
            )}
          </div>
          <div
            css={css`
              margin-left: 10px;
              font-size: 16px;
            `}
          >
            {DateTime.fromSeconds(recordingTime).toFormat("mm:ss")}
          </div>
        </RoundedButton>
        <Button
          disabled={
            !(
              (recording === "active" || recording === "paused") &&
              recordingTime > 0
            )
          }
          icon={<CheckIcon />}
          css={css`
            color: #fff;

            &:hover {
              background: #fe521c;
            }
          `}
          onClick={() => {
            finishRecording();
          }}
        >
          {t("recording.done")}
        </Button>
      </div>
      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={() => cancelRecording()}
        title={t("modal.record.delete.title")}
        description={t("modal.record.delete.description")}
        css={css`
          button.cancel-button {
            padding: 10px 27px;
            margin-right: 80px;
          }
          .action-button {
            padding: 10px 27px;
          }
        `}
      />

      <ModalDelete
        isOpen={retryModal}
        onClose={() => setRetryModal(false)}
        onDelete={() => retry()}
        title={t("modal.record.retry.title")}
        description={t("modal.record.retry.description")}
        css={css`
          button.cancel-button {
            padding: 10px 27px;
            margin-right: 80px;
          }
          .action-button {
            padding: 10px 27px;
          }
        `}
        actionButtonText={t("modal.record.retry.button-text")}
      />
    </div>
  );
}
