import React, { useState } from "react";
import { css } from "styled-components/macro";
import { VideoElement } from "../../types/Video";
import { ReactComponent as DeleteIcon } from "../../assets/icons/ElementDelete.svg";
import { ReactComponent as InteractivityIcon } from "../../assets/icons/Interactivity.svg";
import { ReactComponent as PixelIcon } from "../../../assets/icons/Pixel.svg";
import { useTranslation } from "react-i18next";
import { useEditor } from "../../contexts/EditorContext";
import { useStorage } from "../../contexts/StorageContext";
import { ElementDropdown } from "./ElementDropdown";
import { ElementButton } from "./ElementButton";
import { InteractiveModal, InteractiveType } from "../InteractiveModal";
import { ModalDelete } from "../../../modals/ModalDelete";

type ElementControlsProps = {
  element: VideoElement;
};

export function ElementControls(props: ElementControlsProps) {
  const { t } = useTranslation();
  const { interactivityEditing, setInteractivityEditing } = useEditor();
  const [interactiveModal, setInteractiveModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const { api } = useStorage();

  const orderDropDown: { value: string; label: string }[] = [
    {
      value: "bringToFront",
      label: t("canvas.elements.dropdown.option1"),
    },
    {
      value: "bringToBack",
      label: t("canvas.elements.dropdown.option2"),
    },
  ];

  return (
    <>
      <div
        css={css`
          position: absolute;
          top: -50px;
          right: 0;
          display: flex;
          justify-content: center;
        `}
      >
        {props.element.type === "image" && (
          <ElementButton
            onClick={() => {
              setInteractivityEditing(true);
            }}
            css={
              interactivityEditing &&
              css`
                border: 1px solid #e95b2e !important;
                color: #e95b2e !important;
              `
            }
          >
            <InteractivityIcon />
          </ElementButton>
        )}
        {(props.element.type === "document" ||
          props.element.type === "contact") && (
          <ElementButton
            onClick={() => {
              setInteractiveModal(true);
            }}
            icon={<PixelIcon width={12} height={12} />}
            css={css`
              width: unset;
              position: relative;
              height: 30px;
              border-radius: 15px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
              padding: 4px 6px;
              :hover {
                color: unset;
              }
            `}
          >
            {t("video-player.edit")}
          </ElementButton>
        )}
        {(props.element.type === "document" ||
          props.element.type === "contact") && (
          <InteractiveModal
            initialValues={
              props.element.type === "document"
                ? props.element.document
                : props.element.contact
            }
            interactiveType={
              props.element.type === "document"
                ? InteractiveType.Document
                : InteractiveType.Contact
            }
            isOpen={interactiveModal}
            onClose={() => setInteractiveModal(false)}
          />
        )}
        <ElementDropdown
          options={orderDropDown}
          css={css`
            margin: 0 3px;
          `}
          onSelect={(option) => {
            if (option === "bringToFront") {
              api.bringToFront(props.element.id);
            } else if (option === "bringToBack") {
              api.bringToBack(props.element.id);
            }
          }}
        >
          {t("canvas.elements.dropdown.title")}
        </ElementDropdown>
        <ElementButton
          onClick={() => {
            if (
              props.element.type === "video" ||
              props.element.type === "audio"
            ) {
              setDeleteModal(true);
            } else {
              api.deleteElement(props.element.id);
            }
          }}
        >
          <DeleteIcon />
        </ElementButton>

        <ModalDelete
          isOpen={deleteModal}
          onClose={() => setDeleteModal(false)}
          onDelete={() => api.deleteElement(props.element.id)}
          title={t("modal.record.delete.title")}
          description={t("modal.record.delete.description")}
          css={css`
            button.cancel-button {
              padding: 10px 27px;
              margin-right: 80px;
            }
            .action-button {
              padding: 10px 27px;
            }
          `}
        />
      </div>
    </>
  );
}
