import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { useAuth } from "../contexts/UserContext";
import { Permission } from "../types/UserProfile";

export const PrivateRoute: React.FC<
  {
    component: React.FC<RouteComponentProps<any>>;
    path: string;
    exact?: boolean;
    permission?: Permission;
    organisationPermission?: Permission;
  } & RouteProps
> = ({ component, permission, organisationPermission, ...rest }) => {
  const { isAuthenticated, hasPermission, hasOrganisationPermission } =
    useAuth();

  const token = new URLSearchParams(window.location.search).get("token");

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<any>) =>
        isAuthenticated === null ? null : !isAuthenticated ? (
          token ? (
            <Redirect
              to={{
                pathname: `/register`,
                search: window.location.search,
                state: {
                  from: props.location,
                },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: `/login`,
                search: window.location.search,
                state: {
                  from: props.location,
                },
              }}
            />
          )
        ) : !permission ||
          hasPermission(permission) ||
          !organisationPermission ||
          hasOrganisationPermission(organisationPermission) ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/no-access",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export const PublicRoute: React.FC<
  {
    component: React.FC<RouteComponentProps<any>>;
    path: string;
    exact?: boolean;
  } & RouteProps
> = ({ component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  const invitationToken = new URLSearchParams(window.location.search).get(
    "invitation_token"
  );

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<any>) =>
        isAuthenticated === null ? null : isAuthenticated ? (
          invitationToken ? (
            <Redirect
              to={{
                pathname: "/organisation/invite",
                search: rest.location?.search,
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                search: rest.location?.search,
              }}
            />
          )
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};
