import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { ReactComponent as DashboardTip } from "../assets/icons/DashboardTip.svg";

type TipsProps = {
  id: number;
  tip: string;
};

export function DashboardTips() {
  const { t } = useTranslation();

  const tips: TipsProps[] = [
    {
      id: 1,
      tip: t("dashboard-page.tips.1"),
    },
    {
      id: 2,
      tip: t("dashboard-page.tips.2"),
    },
    {
      id: 3,
      tip: t("dashboard-page.tips.3"),
    },
    {
      id: 4,
      tip: t("dashboard-page.tips.4"),
    },
  ];

  const tipToShow = tips[Math.floor(Math.random() * tips.length)];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 25px;
        background-color: #fffbf8;
        border: 1px solid #e95b2e;
        border-radius: 10px;
        margin-bottom: 100px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          margin-bottom: 16px;
          align-items: center;
        `}
      >
        <div
          css={css`
            margin-right: 12px;
            align-items: center;
          `}
        >
          <DashboardTip />
        </div>
        <div
          css={css`
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #252424;
          `}
        >
          {t("dashboard-page.tips.title")}
        </div>
      </div>
      <div
        css={css`
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #616161;
        `}
      >
        {tipToShow.tip}
      </div>
    </div>
  );
}
