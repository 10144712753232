import { buildQueryString } from "../../helpers/buildQueryString";
import { authorised } from "../../request";
import { Video } from "../../VideoEditor/types/Video";

export type QueryVideoStatus =
  | "SHARED"
  | "PUBLISHED"
  | "MY_DRAFTS"
  | "ARCHIVED"
  | "ALL"
  | "MY_THEMIS"
  | "SHARED_DRAFTS"
  | "DRAFTS";

export type QueryVideoSort = "NEW" | "OLD";

export async function getVideos({
  searchText,
  status,
  sort,
  limit,
  offset,
}: {
  limit?: number;
  offset?: number;
  searchText?: string;
  sort?: QueryVideoSort;
  status?: QueryVideoStatus;
} = {}) {
  const API = authorised();

  return API.request<{
    count: number;
    next: string | null;
    previous: string | null;
    results: Video[];
  }>({
    method: "GET",
    url: `/api/studio/themis/?${buildQueryString({
      name: searchText,
      status: status,
      limit: limit,
      offset: offset,
      sort: sort,
    })}`,
  });
}
