import { css } from "styled-components/macro";
import { Headers } from "../helpers/layout";
import { useTranslation } from "react-i18next";
import { InputComponent } from "../components/InputComponent";
import { LifetimeTooltip } from "../components/Tooltip";
import { useRef, useState } from "react";
import { sendEvent } from "../helpers/tracking";

type Topic = {
  key: string;
  name: string;
  options: Array<{
    key: string;
    name: string;
    description: string;
  }>;
};

function TopicOption(props: {
  option: any;
  isSelected: boolean;
  onSelect: (key: string) => void;
}) {
  const { option, isSelected } = props;
  const [hovered, setHovered] = useState(false);
  const timeoutRef = useRef<any>(null);

  return (
    <LifetimeTooltip
      isActive={hovered}
      position="center"
      verticalPosition="top"
      text={option.description}
      lifeTime={5000}
      css={
        hovered &&
        css`
          z-index: 2;
        `
      }
      width={265}
    >
      <button
        css={css`
          height: 46px;
          padding: 0px 30px;
          font-weight: 600;
          font-size: 20px;
          line-height: 44px;
          border: 1.5px solid #8e8e8e;
          border-radius: 24px;
          background: none;
          color: #616161;
          cursor: pointer;

          &:hover {
            color: #e95b2e;
            border-color: #e95b2e;
          }

          ${isSelected &&
          css`
            color: #e95b2e;
            border-color: #e95b2e;
          `}
        `}
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            setHovered(true);
          }, 300);
        }}
        onMouseLeave={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          setHovered(false);
        }}
        onClick={() => props.onSelect(option.key)}
      >
        {option.name}
      </button>
    </LifetimeTooltip>
  );
}

function TopicSelector(props: {
  topic: Topic;
  onSelect: (key: string) => void;
  selected: string[];
}) {
  const { topic } = props;

  return (
    <div
      css={css`
        margin-bottom: 35px;
      `}
    >
      <Headers.H4
        css={css`
          margin: 0;
        `}
      >
        {topic.name}
      </Headers.H4>
      <ul
        css={css`
          list-style: none;
          padding: 0;
          margin: 15px 0 0 0;
        `}
      >
        {topic.options.map((option) => {
          const isSelected = props.selected.includes(option.key);

          return (
            <li
              css={css`
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 20px;
              `}
              key={option.key}
            >
              <TopicOption
                option={option}
                isSelected={isSelected}
                onSelect={props.onSelect}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export function ThemiBuilderTopicsSelector(props: {
  category: string | null;
  title: string;
  onTitleChange: (title: string) => void;
  selectedTopics: { [key: string]: string[] };
  onTopicSelect: (topic: string, option: string) => void;
}) {
  const { t } = useTranslation();

  const handoverTopics: Array<Topic> = [
    {
      key: "handover-introduction",
      name: t("themi-builder.topics-selector.handover-introduction.title"),
      options: [
        {
          key: "introduction",
          name: t(
            "themi-builder.topics-selector.handover-introduction.options.introduction.title"
          ),
          description: t(
            "themi-builder.topics-selector.handover-introduction.options.introduction.description"
          ),
        },
        {
          key: "overview-of-the-handover",
          name: t(
            "themi-builder.topics-selector.handover-introduction.options.overview-of-the-handover.title"
          ),
          description: t(
            "themi-builder.topics-selector.handover-introduction.options.overview-of-the-handover.description"
          ),
        },
      ],
    },
    {
      key: "who-do-you-work-with",
      name: t("themi-builder.topics-selector.who-do-you-work-with.title"),
      options: [
        {
          key: "team",
          name: t(
            "themi-builder.topics-selector.who-do-you-work-with.options.team.title"
          ),
          description: t(
            "themi-builder.topics-selector.who-do-you-work-with.options.team.description"
          ),
        },
        {
          key: "stakeholders",
          name: t(
            "themi-builder.topics-selector.who-do-you-work-with.options.stakeholders.title"
          ),

          description: t(
            "themi-builder.topics-selector.who-do-you-work-with.options.stakeholders.description"
          ),
        },
        {
          key: "company-experts",
          name: t(
            "themi-builder.topics-selector.who-do-you-work-with.options.company-experts.title"
          ),
          description: t(
            "themi-builder.topics-selector.who-do-you-work-with.options.company-experts.description"
          ),
        },
      ],
    },
    {
      key: "handover-responsibilities",
      name: t("themi-builder.topics-selector.handover-responsibilities.title"),
      options: [
        {
          key: "client",
          name: t(
            "themi-builder.topics-selector.handover-responsibilities.options.client.title"
          ),
          description: t(
            "themi-builder.topics-selector.handover-responsibilities.options.client.description"
          ),
        },
        {
          key: "project",
          name: t(
            "themi-builder.topics-selector.handover-responsibilities.options.project.title"
          ),
          description: t(
            "themi-builder.topics-selector.handover-responsibilities.options.project.description"
          ),
        },
        {
          key: "process",
          name: t(
            "themi-builder.topics-selector.handover-responsibilities.options.process.title"
          ),
          description: t(
            "themi-builder.topics-selector.handover-responsibilities.options.process.description"
          ),
        },
        {
          key: "tool-walk-through",
          name: t(
            "themi-builder.topics-selector.handover-responsibilities.options.tool-walk-through.title"
          ),
          description: t(
            "themi-builder.topics-selector.handover-responsibilities.options.tool-walk-through.description"
          ),
        },
        {
          key: "important-documents",
          name: t(
            "themi-builder.topics-selector.handover-responsibilities.options.important-documents.title"
          ),
          description: t(
            "themi-builder.topics-selector.handover-responsibilities.options.important-documents.description"
          ),
        },
      ],
    },
    {
      name: t("themi-builder.topics-selector.role-succession.title"),
      key: "role-succession",
      options: [
        {
          name: t(
            "themi-builder.topics-selector.role-succession.options.tips-and-tricks.title"
          ),
          description: t(
            "themi-builder.topics-selector.role-succession.options.tips-and-tricks.description"
          ),
          key: "tips-and-tricks",
        },
        {
          name: t(
            "themi-builder.topics-selector.role-succession.options.lessons-learned.title"
          ),
          description: t(
            "themi-builder.topics-selector.role-succession.options.lessons-learned.description"
          ),
          key: "lessons-learned",
        },
      ],
    },
  ];

  const onboardingTopics: Array<Topic> = [
    {
      key: "onboarding-introduction",
      name: t("themi-builder.topics-selector.onboarding-introduction.title"),
      options: [
        {
          key: "welcome",
          name: t(
            "themi-builder.topics-selector.onboarding-introduction.options.welcome.title"
          ),
          description: t(
            "themi-builder.topics-selector.onboarding-introduction.options.welcome.description"
          ),
        },
        {
          key: "about-the-company",
          name: t(
            "themi-builder.topics-selector.onboarding-introduction.options.about-the-company.title"
          ),
          description: t(
            "themi-builder.topics-selector.onboarding-introduction.options.about-the-company.description"
          ),
        },
        {
          key: "overview-of-the-role",
          name: t(
            "themi-builder.topics-selector.onboarding-introduction.options.overview-of-the-role.title"
          ),
          description: t(
            "themi-builder.topics-selector.onboarding-introduction.options.overview-of-the-role.description"
          ),
        },
        {
          key: "goals-and-priorities",
          name: t(
            "themi-builder.topics-selector.onboarding-introduction.options.goals-and-priorities.title"
          ),
          description: t(
            "themi-builder.topics-selector.onboarding-introduction.options.goals-and-priorities.description"
          ),
        },
      ],
    },
    {
      key: "onboarding-topic",
      name: t("themi-builder.topics-selector.onboarding-topic.title"),
      options: [
        {
          key: "client",
          name: t(
            "themi-builder.topics-selector.onboarding-topic.options.client.title"
          ),
          description: t(
            "themi-builder.topics-selector.onboarding-topic.options.client.description"
          ),
        },
        {
          key: "project",
          name: t(
            "themi-builder.topics-selector.onboarding-topic.options.project.title"
          ),
          description: t(
            "themi-builder.topics-selector.onboarding-topic.options.project.description"
          ),
        },
        {
          key: "tool-walk-through",
          name: t(
            "themi-builder.topics-selector.onboarding-topic.options.tool-walk-through.title"
          ),
          description: t(
            "themi-builder.topics-selector.onboarding-topic.options.tool-walk-through.description"
          ),
        },
        {
          key: "process",
          name: t(
            "themi-builder.topics-selector.onboarding-topic.options.process.title"
          ),
          description: t(
            "themi-builder.topics-selector.onboarding-topic.options.process.description"
          ),
        },
      ],
    },
    {
      key: "newstarter-work",
      name: t("themi-builder.topics-selector.newstarter-work.title"),
      options: [
        {
          key: "team",
          name: t(
            "themi-builder.topics-selector.newstarter-work.options.meet-the-team.title"
          ),
          description: t(
            "themi-builder.topics-selector.newstarter-work.options.meet-the-team.description"
          ),
        },
        {
          key: "stakeholders",
          name: t(
            "themi-builder.topics-selector.newstarter-work.options.stakeholders.title"
          ),
          description: t(
            "themi-builder.topics-selector.newstarter-work.options.stakeholders.description"
          ),
        },
      ],
    },
    {
      key: "anything-else",
      name: t("themi-builder.topics-selector.anything-else.title"),
      options: [
        {
          key: "useful-resources",
          name: t(
            "themi-builder.topics-selector.anything-else.options.useful-resources.title"
          ),
          description: t(
            "themi-builder.topics-selector.anything-else.options.useful-resources.description"
          ),
        },
        {
          key: "next-steps",
          name: t(
            "themi-builder.topics-selector.anything-else.options.next-steps.title"
          ),
          description: t(
            "themi-builder.topics-selector.anything-else.options.next-steps.description"
          ),
        },
      ],
    },
  ];

  const knowledgeSharingTopics: Array<Topic> = [
    {
      key: "topic-introduction",
      name: t("themi-builder.topics-selector.topic-introduction.title"),
      options: [
        {
          key: "introduction",
          name: t(
            "themi-builder.topics-selector.topic-introduction.options.introduction.title"
          ),
          description: t(
            "themi-builder.topics-selector.topic-introduction.options.introduction.description"
          ),
        },
        {
          key: "overview-of-the-topic",
          name: t(
            "themi-builder.topics-selector.topic-introduction.options.overview-of-the-topic.title"
          ),
          description: t(
            "themi-builder.topics-selector.topic-introduction.options.overview-of-the-topic.description"
          ),
        },
        {
          key: "learning-objectives",
          name: t(
            "themi-builder.topics-selector.topic-introduction.options.learning-objectives.title"
          ),
          description: t(
            "themi-builder.topics-selector.topic-introduction.options.learning-objectives.description"
          ),
        },
      ],
    },
    {
      key: "what-to-share",
      name: t("themi-builder.topics-selector.what-to-share.title"),
      options: [
        {
          key: "core-concepts",
          name: t(
            "themi-builder.topics-selector.what-to-share.options.core-concepts.title"
          ),
          description: t(
            "themi-builder.topics-selector.what-to-share.options.core-concepts.description"
          ),
        },
        {
          key: "step-by-step-guide",
          name: t(
            "themi-builder.topics-selector.what-to-share.options.step-by-step-guide.title"
          ),
          description: t(
            "themi-builder.topics-selector.what-to-share.options.step-by-step-guide.description"
          ),
        },
        {
          key: "tool-walk-through",
          name: t(
            "themi-builder.topics-selector.what-to-share.options.tool-walk-through.title"
          ),
          description: t(
            "themi-builder.topics-selector.what-to-share.options.tool-walk-through.description"
          ),
        },
      ],
    },
    {
      key: "additional-information",
      name: t("themi-builder.topics-selector.additional-information.title"),
      options: [
        {
          key: "examples",
          name: t(
            "themi-builder.topics-selector.additional-information.options.examples.title"
          ),
          description: t(
            "themi-builder.topics-selector.additional-information.options.examples.description"
          ),
        },
        {
          key: "tips-and-tricks",
          name: t(
            "themi-builder.topics-selector.additional-information.options.tips-and-tricks.title"
          ),
          description: t(
            "themi-builder.topics-selector.additional-information.options.tips-and-tricks.description"
          ),
        },

        {
          key: "extra-notes",
          name: t(
            "themi-builder.topics-selector.additional-information.options.extra-notes.title"
          ),
          description: t(
            "themi-builder.topics-selector.additional-information.options.extra-notes.description"
          ),
        },
        {
          key: "feedback",
          name: t(
            "themi-builder.topics-selector.additional-information.options.feedback.title"
          ),
          description: t(
            "themi-builder.topics-selector.additional-information.options.feedback.description"
          ),
        },
        {
          key: "additional-resources",
          name: t(
            "themi-builder.topics-selector.additional-information.options.additional-resources.title"
          ),
          description: t(
            "themi-builder.topics-selector.additional-information.options.additional-resources.description"
          ),
        },
        // Depreoritized for now:

        // {
        //   key: "check-knowledge",
        //   name: t(
        //     "themi-builder.topics-selector.additional-information.options.check-knowledge.title"
        //   ),
        //   description: t(
        //     "themi-builder.topics-selector.additional-information.options.check-knowledge.description"
        //   ),
        // },
      ],
    },
  ];

  const topics =
    props.category === "handover"
      ? handoverTopics
      : props.category === "onboarding"
      ? onboardingTopics
      : props.category === "knowledge-sharing"
      ? knowledgeSharingTopics
      : [];

  return (
    <div>
      <div
        css={css`
          margin-bottom: 85px;
        `}
      >
        <Headers.H2
          css={css`
            margin-bottom: 20px;
          `}
        >
          2. {t("themi-builder.topics-selector.name.title")}
        </Headers.H2>
        <Headers.H4 css={css``}>
          {t("themi-builder.topics-selector.name.subtitle")}
        </Headers.H4>
        <InputComponent
          css={css`
            height: 45px;
          `}
          value={props.title}
          onChange={(e) => {
            const title = e.target.value.slice(0, 60);
            props.onTitleChange(title);
          }}
          placeholder={t("themi-builder.topics-selector.name.placeholder")}
        />
      </div>
      <div>
        <Headers.H2
          css={css`
            margin-bottom: 20px;
          `}
        >
          3. {t("themi-builder.topics-selector.title")}
        </Headers.H2>
        <Headers.H4
          css={css`
            font-weight: 400;
            font-size: 20px;
            color: #616161;
          `}
        >
          {t("themi-builder.topics-selector.description")}
        </Headers.H4>
        <div
          css={css`
            margin-top: 55px;
          `}
        >
          {topics.map((topic) => {
            return (
              <TopicSelector
                topic={topic}
                key={topic.key}
                onSelect={(option) => {
                  sendEvent("Themi Builder: Selected Topic", {
                    topic: topic.key,
                    option: option,
                  });

                  props.onTopicSelect(topic.key, option);
                }}
                selected={props.selectedTopics[topic.key] || []}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
