import { css } from "styled-components/macro";
import { useEditor } from "../contexts/EditorContext";
import { useClickOutside } from "../../VideoEditor/hooks/useClickOutside";
import { useCallback, useEffect, useRef, useState } from "react";
import { Headers } from "../helpers/headers";
import { useTranslation } from "react-i18next";
import { useStorage } from "../contexts/StorageContext";
import { debounce } from "lodash";
import { LinkDocumentAttach } from "./LinkDocumentAttach";

export function InteractivitySettings() {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { api } = useStorage();
  const [url, setUrl] = useState("");

  const { interactivityEditing, setInteractivityEditing, activeElementId } =
    useEditor();

  const { video } = useStorage();

  const activeElement = video?.schema.schema.elements.find(
    (element) => element.id === activeElementId
  );

  useEffect(() => {
    if (
      !activeElement ||
      !(activeElement.type === "text" || activeElement.type === "image")
    )
      return;

    setUrl(activeElement.cta ? activeElement.cta.url : "");
  }, [activeElement]);

  useClickOutside(ref, () => {
    setInteractivityEditing(false);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateUrl = useCallback(
    debounce(async (url: string) => {
      if (!activeElementId) return;

      api.updateElement(activeElementId, {
        cta: {
          type: "link",
          url: url,
          file: null,
        },
      });
    }, 1000),
    [activeElementId]
  );

  if (
    !activeElement ||
    !(activeElement.type === "text" || activeElement.type === "image")
  )
    return null;

  return (
    <div
      ref={ref}
      css={css`
        position: absolute;
        width: 290px;
        right: -290px;
        top: 68px;
        height: 100%;
        padding: 24px;
        transition: right 0.2s ease-in-out;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
        z-index: 2000;
        background: #fff;

        ${interactivityEditing &&
        css`
          right: 0;
        `};
      `}
    >
      <Headers.H3>{t("interactivity_settings.title")}</Headers.H3>
      <LinkDocumentAttach
        url={url}
        file={activeElement.cta?.file}
        onSetUrl={(text) => {
          setUrl(text);
          updateUrl(text);
        }}
        onUpload={(file) => {
          if (!activeElementId) return;

          api.updateElement(activeElementId, {
            cta: {
              type: "file",
              url: "",
              file,
            },
          });
        }}
        onRemoveAttachedLink={() => {
          if (!activeElementId) return;

          api.updateElement(activeElementId, {
            cta: {
              type: "link",
              url: "",
              file: null,
            },
          });
        }}
        onRemoveAttachedFile={() => {
          if (!activeElementId) return;

          api.updateElement(activeElementId, {
            cta: {
              type: "file",
              url: "",
              file: null,
            },
          });
        }}
      />
    </div>
  );
}
