import { authorised } from "../../request";

export async function readComment(themi_id: string, commentId: number) {
  const API = authorised();
  const response = await API.request({
    method: "POST",
    url: `/api/studio/themis/${themi_id}/comments/${commentId}/read/`,
  });

  return response.data;
}
