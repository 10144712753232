import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { debounce } from "lodash";

import { Modal, ModalBody, ModalHeader } from "../components/Modal";
import { LinkDocumentAttach } from "../VideoEditor/components/LinkDocumentAttach";

import { useStorage } from "../VideoEditor/contexts/StorageContext";
import { useEditor } from "../VideoEditor/contexts/EditorContext";

export function ModalHyperlink(props: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { api, video } = useStorage();
  const { activeElementId } = useEditor();
  const [url, setUrl] = useState("");

  const activeElement = video?.schema.schema.elements.find(
    (element) => element.id === activeElementId
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateUrl = useCallback(
    debounce(async (url: string) => {
      if (!activeElementId) return;

      api.updateElement(activeElementId, {
        cta: {
          type: "link",
          url: url,
          file: null,
        },
      });
    }, 1000),
    [activeElementId]
  );
  useEffect(() => {
    if (
      !activeElement ||
      !(
        activeElement.type === "ellipse" ||
        activeElement.type === "polygon" ||
        activeElement.type === "arrow-1" ||
        activeElement.type === "arrow-2" ||
        activeElement.type === "arrow-3" ||
        activeElement.type === "line" ||
        activeElement.type === "rectangle"
      )
    )
      return;

    setUrl(activeElement.cta ? activeElement.cta.url : "");
  }, [activeElement]);

  if (
    !activeElement ||
    !(
      activeElement.type === "ellipse" ||
      activeElement.type === "polygon" ||
      activeElement.type === "arrow-1" ||
      activeElement.type === "arrow-2" ||
      activeElement.type === "arrow-3" ||
      activeElement.type === "line" ||
      activeElement.type === "rectangle"
    )
  )
    return null;

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{ maxWidth: "584px", padding: 0 }}
    >
      <ModalHeader
        closeIcon
        onClose={props.onClose}
        css={css`
          padding: 14px 21px 13px 32px;
          border-bottom: 1px solid #f2f2f2;
        `}
      >
        {t("video-editor.tooltip.hyperlink-question")}
      </ModalHeader>

      <ModalBody
        css={css`
          padding: 20px;
        `}
      >
        <LinkDocumentAttach
          url={url}
          file={activeElement.cta?.file}
          showDescription
          onSetUrl={(text) => {
            setUrl(text);
            updateUrl(text);
          }}
          onUpload={(file) => {
            if (!activeElementId) return;

            api.updateElement(activeElementId, {
              cta: {
                type: "file",
                url: "",
                file,
              },
            });
          }}
          onRemoveAttachedLink={() => {
            if (!activeElementId) return;

            api.updateElement(activeElementId, {
              cta: {
                type: "link",
                url: "",
                file: null,
              },
            });
          }}
          onRemoveAttachedFile={() => {
            if (!activeElementId) return;

            api.updateElement(activeElementId, {
              cta: {
                type: "file",
                url: "",
                file: null,
              },
            });
          }}
        />
      </ModalBody>
    </Modal>
  );
}
