import React, { useCallback } from "react";
import styled, { css } from "styled-components/macro";
import { useComments } from "../../contexts/CommentsContext";
import { usePlayback } from "../../contexts/PlaybackContext";
import { ReactComponent as BellIcon } from "../../../assets/icons/Bell.svg";

const NotificationAvailable = styled.div`
  width: 6px;
  height: 6px;
  background: #51ba6f;
  border-radius: 50%;
`;

export function CommentNotification() {
  const { setCurrentTime, scrollToScene, findScene } = usePlayback();
  const { commentApi, unreadComments, setExpandedComment } = useComments();

  const readComment = useCallback(async () => {
    if (unreadComments.length) {
      const firstUnreadComment = unreadComments[0];
      const scene = findScene(firstUnreadComment.scene_id);
      if (scene) {
        await commentApi.readEditorComment(firstUnreadComment.id);
        setCurrentTime(scene.scene.start_time);
        scrollToScene(scene.index);
        setExpandedComment(firstUnreadComment.id);
      }
    }
  }, [
    commentApi,
    findScene,
    scrollToScene,
    setCurrentTime,
    setExpandedComment,
    unreadComments,
  ]);

  return (
    <div
      css={css`
        display: flex;
        position: relative;
        align-items: center;
        margin-right: 30px;
        ${!!unreadComments.length &&
        css`
          cursor: pointer;
        `}
      `}
      onClick={readComment}
    >
      <BellIcon />

      {!!unreadComments.length && (
        <NotificationAvailable
          css={css`
            position: absolute;
            top: 11px;
            right: -2px;
          `}
        />
      )}
    </div>
  );
}
