import { authorised } from "../../request";
import { buildQueryString } from "../helpers/buildQueryString";
import { CollaborationMode, Collaborator } from "../types/Collaborator";

type Args = {
  videoId: string;
  mode: CollaborationMode;
};

export async function getCollaborators(args: Args) {
  const API = authorised();

  const response = await API.request<Collaborator[]>({
    method: "GET",
    url: `/api/studio/themis/${args.videoId}/collaborators/?${buildQueryString({
      role: "ALL",
    })}`,
  });

  return response.data;
}
