import React from "react";
import { css } from "styled-components/macro";
import { ModalShareScreen, ShareProps } from "../../modals/ModalShare";
import { ReactComponent as ShareCollaborator } from "../../assets/icons/ShareCollaborator.svg";
import { ReactComponent as ShareBack } from "../../assets/icons/ShareBack.svg";
import ShareForm from "./ShareForm";
import { CollaborationMode } from "../../VideoEditor/types/Collaborator";
import { ManageCollaborators } from "./ManageCollaborators";
import { useTranslation } from "react-i18next";

export function InviteCollaborators(props: ShareProps) {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-top: 30px;
      `}
    >
      <div
        onClick={() => props.onScreenChange(ModalShareScreen.MAIN)}
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          width: fit-content;
          cursor: pointer;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-right: 15px;
          `}
        >
          <ShareBack />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-right: 15px;
          `}
        >
          <ShareCollaborator />
        </div>
        <div
          css={css`
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
          `}
        >
          {t("share.invite_collaborate")}
        </div>
      </div>
      <div
        css={css`
          margin-top: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #616161;
        `}
      >
        {t("share.collaborate_description")}
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <ShareForm
          mode={CollaborationMode.EDIT}
          onCancel={() => props.onScreenChange(ModalShareScreen.MAIN)}
        />
        <ManageCollaborators mode={CollaborationMode.EDIT} />
      </div>
    </div>
  );
}
