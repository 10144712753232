import { Button } from "../components/Button";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../components/Modal";
import { css } from "styled-components/macro";

type ModalDeleteProps = {
  onDelete: () => void;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  className?: string;
  cancelButtonText?: string;
  actionButtonText?: string;
};

export function ModalDelete(props: ModalDeleteProps) {
  const { t } = useTranslation();
  const {
    cancelButtonText = t("modal.delete.cancel"),
    actionButtonText = t("modal.delete.delete"),
  } = props;

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentLabel={props.title}
      contentStyles={{ maxWidth: "585px", padding: "42px 65px 45px 65px" }}
      className={props.className}
    >
      <ModalHeader
        css={css`
          font-weight: 500;
          font-size: 20px;
          line-height: 140%;
          color: #252424;
          text-align: center;
          .wrapper {
            padding-right: 0;
          }
        `}
      >
        {props.title}
      </ModalHeader>
      {props.description && (
        <ModalBody
          css={css`
            margin-top: 10px;
            padding: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            color: #616161;
            text-align: center;
          `}
        >
          {props.description}
        </ModalBody>
      )}
      <ModalFooter
        css={css`
          margin-top: 54px;
          justify-content: center;
        `}
      >
        <Button
          onClick={props.onClose}
          css={css`
            background-color: transparent;
            color: #252424;
            border: 1px solid #252424;
            padding: 6px 27px;
          `}
          hoverStyles={`color: #000; border-color:#000`}
          className="cancel-button"
        >
          {cancelButtonText}
        </Button>
        <Button
          onClick={props.onDelete}
          css={css`
            background-color: #e95b2e;
            color: #fff;
            padding: 6px 27px;
          `}
          hoverStyles={`color:#fff; border:none;background-color:#FE521C`}
          className="action-button"
        >
          {actionButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
