import { VideoElement, VideoScene } from "../../../VideoEditor/types/Video";
import { v4 as uuid } from "uuid";
import { Template } from "../types";
import { defaultSequenceDuration } from "../../../constants";

export function generateHandoverLessonsLearnedTemplate(
  data: Template,
  order: number,
  t: any
) {
  const sceneId = uuid();
  const scene: VideoScene = {
    id: sceneId,
    backgroundColor: "#fff",
    duration: defaultSequenceDuration,
    order,
    name: t("themi-builder.template.lessons-learned.scene-name"),
    enableCollaboration: true,
    start_time: 0,
    elements: [],
  };

  const firstElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    text: `<p style="font-family: Arial; font-size: 4.57cqw;"><strong>${t(
      "themi-builder.template.lessons-learned.title"
    )}</strong></p>`,
    type: "text",
    order: 0,
    states: [
      {
        id: uuid(),
        top: 21.21,
        left: 35.35,
        scale: 1,
        width: 636.3000000000001,
        height: 57.834724540901504,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const secondElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // If you could do anything differently to improve or make the role easier, what would you change? Share your lessons learned with your successor.
    text: `<p style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.lessons-learned.handover-description"
    )}</p>`,
    type: "text",
    order: 1,
    states: [
      {
        id: uuid(),
        top: 84.84,
        left: 35.35,
        scale: 1,
        width: 636.3000000000001,
        height: 44.85141903171953,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const thirdElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    // List lessons learned that your successor can take on, such as:
    // What's the best way to get a specific task done?
    // What mistakes should they avoid when doing that task?
    // What's likely to take them a long time?
    // Who can they go to for help when they get stuck?
    text: `<ul>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.lessons-learned.handover-points.one"
    )}</li>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.lessons-learned.handover-points.two"
    )}</li>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.lessons-learned.handover-points.three"
    )}</li>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.lessons-learned.handover-points.four"
    )}</li>
    <li style="font-family: Arial; font-size: 2cqw; color: rgba(97, 97, 97, 1);">${t(
      "themi-builder.template.lessons-learned.handover-points.five"
    )}</li>
    </ul>`,
    type: "text",
    order: 2,
    states: [
      {
        id: uuid(),
        top: 144.49312500000002,
        left: 35.35,
        scale: 1,
        width: 307.545,
        height: 203.01168614357263,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const fourthElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    type: "record-prompt",
    order: 3,
    states: [
      {
        id: uuid(),
        top: 144.49312500000002,
        left: 367.64,
        scale: 1,
        width: 307.545,
        height: 178.959375,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
    touched: true,
    instruction: t("themi-builder.template.record-prompt.general-description"),
  };

  const scenes: VideoScene[] = [scene];

  const elements: VideoElement[] = [
    firstElement,
    secondElement,
    thirdElement,
    fourthElement,
  ];

  return { scenes, elements };
}
