import { authorised } from "../../request";

type Args = {
  videoId: string;
  userIds: number[];
};

export async function sendInvitesToViewers(args: Args) {
  const API = authorised();

  return API.request({
    method: "POST",
    url: `/api/studio/themis/${args.videoId}/viewers/`,
    data: {
      users: args.userIds,
    },
  });
}
