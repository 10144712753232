import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { ReactComponent as CheckGreen } from "../assets/icons/CheckGreen.svg";

type IntructionsProps = {
  label: string;
  match: RegExpMatchArray | null;
};

function Instruction(props: IntructionsProps) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-bottom: 3px;
      `}
    >
      {props.match && <CheckGreen />}
      <span
        css={css`
          margin-left: ${props.match ? "10px" : "22px"};
          margin-top: 2px;
          color: ${props.match ? "#51BA6F;" : "#252424"};
        `}
      >
        {props.label}
      </span>
    </div>
  );
}

export default function PasswordInstructions({
  password,
}: {
  password: string;
}) {
  const { t } = useTranslation();

  const minLenght = new RegExp("^(?=.{9,150}$).*");
  const minUppercase = new RegExp("(?=.*[A-Z])");
  const minLowercase = new RegExp("(?=.*[a-z])");
  const minNumber = new RegExp("(?=.*[0-9])");
  const minSpecial = new RegExp(
    "(?=.*[!@#&$%^*+,.:;<=>?({//\\\\})\\[\\]`~|\"'_-])"
  );

  const instrunctions: IntructionsProps[] = [
    {
      label: t("password_instructions.length"),
      match: password.match(minLenght),
    },
    {
      label: t("password_instructions.uppercase"),
      match: password.match(minUppercase),
    },
    {
      label: t("password_instructions.lowercase"),
      match: password.match(minLowercase),
    },
    {
      label: t("password_instructions.number"),
      match: password.match(minNumber),
    },
    {
      label: t("password_instructions.special"),
      match: password.match(minSpecial),
    },
  ];

  return (
    <div
      css={css`
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        padding-left: 15px;
      `}
    >
      <div
        css={css`
          margin-bottom: 10px;
        `}
      >
        {t("password_instructions.rules")}
      </div>
      <div>
        {instrunctions.map((instruction, index) => (
          <Instruction key={index} {...instruction} />
        ))}
      </div>
    </div>
  );
}
