import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";

import { Headers } from "../helpers/headers";
import { Modal } from "../../components/Modal";
import { UpgrateToProAd } from "../../components/UpgrateToProAd";
import { InteractiveDocument } from "./InteractiveDocument";
import { InteractiveContactCard } from "./InteractiveContactCard";
import { Document } from "../types/Document";
import { Contact } from "../types/Contact";
import { ReactComponent as ArmIcon } from "../../assets/icons/Arm.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/icons/DocumentsIcon.svg";
import { useAuth } from "../../contexts/UserContext";

export enum InteractiveType {
  Document = "document",
  Contact = "contact",
}

function ChoseButton(props: {
  icon: React.ReactNode;
  children: string;
  onClick?: () => void;
  className?: string;
  isActive: boolean;
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 6px 14px;
        border-radius: 46px;
        transition: background 0.4s ease, box-shadow 0.4s ease;
        color: #252424;
        cursor: pointer;
        :hover {
          background: #ffffff;
          box-shadow: 0px 8px 13px #e3e3e3;
        }
        ${props.isActive &&
        css`
          background: #ffffff;
          box-shadow: 0px 8px 13px #e3e3e3;
        `}
      `}
      onClick={props.onClick}
      className={props.className}
    >
      <span
        css={css`
          display: flex;
          margin-right: 10px;
        `}
      >
        {props.icon}
      </span>

      <span
        css={css`
          display: flex;
          vertical-align: middle;
        `}
      >
        {props.children}
      </span>
    </div>
  );
}

export function InteractiveModal(props: {
  initialValues?: Contact | Document;
  interactiveType?: InteractiveType;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { interactiveType: initialInteractiveType } = props;
  const { t } = useTranslation();
  const { userprofile } = useAuth();

  const [interactiveType, setInteractiveType] = useState<InteractiveType>(
    initialInteractiveType || InteractiveType.Document
  );

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentStyles={{ maxWidth: "926px", padding: 0, maxHeight: "637px" }}
    >
      <div
        css={css`
          display: flex;
          max-height: 637px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 16px;
            background: #f1f1f1;
            width: 225px;
          `}
        >
          <div>
            <div
              css={css`
                margin-top: 16px;
                display: flex;
              `}
            >
              <ArmIcon color={"#616161"} />
              <span
                css={css`
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 140%;
                  color: #616161;
                  margin-left: 13px;
                `}
              >
                {t("video-editor.side-bar.interactive-content")}
              </span>
            </div>

            <Headers.H3
              css={css`
                margin-left: 22px;
              `}
            >
              {t("document.create.categories")}
            </Headers.H3>

            {(!initialInteractiveType ||
              initialInteractiveType === InteractiveType.Document) && (
              <ChoseButton
                icon={<DocumentsIcon />}
                css={css`
                  margin-top: 39px;
                `}
                onClick={() => setInteractiveType(InteractiveType.Document)}
                isActive={interactiveType === InteractiveType.Document}
              >
                {t("interactivity_settings.document_label")}
              </ChoseButton>
            )}
            {(!initialInteractiveType ||
              initialInteractiveType === InteractiveType.Contact) && (
              <ChoseButton
                icon={<ArmIcon />}
                css={css`
                  margin-top: 16px;
                `}
                onClick={() => setInteractiveType(InteractiveType.Contact)}
                isActive={interactiveType === InteractiveType.Contact}
              >
                {t("document.create.profile-cards")}
              </ChoseButton>
            )}
          </div>
          {!userprofile?.is_pro && (
            <UpgrateToProAd
              css={css`
                margin: 0;
                padding: 10px;
                max-width: 193px;
                margin-top: 32px;
              `}
              text={t("document.create.upgrade-to-pro")}
              location="Interactive Content Modal"
            />
          )}
        </div>

        {interactiveType === InteractiveType.Document ? (
          <InteractiveDocument
            initialValues={props.initialValues}
            interactiveType={interactiveType}
            onClose={props.onClose}
          />
        ) : (
          <InteractiveContactCard
            initialValues={props.initialValues}
            interactiveType={interactiveType}
            onClose={props.onClose}
          />
        )}
      </div>
    </Modal>
  );
}
