import React from "react";
import { css } from "styled-components/macro";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import { THEMIS_LANGUAGE_KEY } from "../i18n";
import { setLocale } from "yup";
import deLocale from "../helpers/localization/de";
import enLocale from "../helpers/localization/en";

type OptionType = { value: string; label: string; icon: string };

export function LanguageSelector(props: { className?: string }) {
  const { i18n } = useTranslation();
  const languages: OptionType[] = [
    {
      value: "de",
      icon: "German-flag.svg",
      label: "DE",
    },
    {
      value: "en",
      icon: "Britain-flag.svg",
      label: "EN",
    },
  ];
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      boxShadow: "none",
      border: "1px solid #e95b2e",
      minHeight: 22,
      flexDirection: "row-reverse",
      backgroundColor: "transparent",
      "&:hover": {
        border: "1px solid #e95b2e",
      },
    }),
    option: (base: any) => ({
      ...base,
      fontSize: 11,
      color: "#e95b2e",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(233, 91, 46, 0.2)",
      },
      padding: 3,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "#e95b2e",
      width: 20,
      display: "none",
    }),
    valueContainer: (base: any) => ({
      display: "block",
      paddingRight: 4,
    }),
  };

  const { Option, ValueContainer } = components;
  const IconOption = (props: any) => {
    return (
      <Option {...props}>
        <span
          style={{
            width: 14,
            display: "inline-block",
          }}
        >
          {props.data.label}
        </span>
        <img
          src={require("../assets/icons/" + props.data.icon)}
          alt={props.data.label}
          style={{
            width: "17px",
            marginLeft: "5px",
            verticalAlign: "middle",
          }}
        />
      </Option>
    );
  };
  const IconValueContainer = (props: any) => {
    return (
      <ValueContainer {...props}>
        {props.selectProps.value && (
          <img
            src={require("../assets/icons/" + props.selectProps.value.icon)}
            alt={props.selectProps.value.label}
            style={{
              width: "17px",
              verticalAlign: "middle",
            }}
          />
        )}
        {props.children}
      </ValueContainer>
    );
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          style={{ width: 7 }}
          src={require("../assets/icons/DownArrow.png")}
          alt="Down-Arrow"
        />
      </components.DropdownIndicator>
    );
  };
  const language = languages.find((lang) => {
    return lang.value === i18n.language;
  });

  const handleChange = (
    lang: {
      value: string;
      label: string;
      icon: string;
    } | null
  ) => {
    if (lang) {
      i18n.changeLanguage(lang.value);
      localStorage.setItem(THEMIS_LANGUAGE_KEY, lang.value);
      if (lang.value === "en") {
        setLocale(enLocale);
      } else {
        setLocale(deLocale);
      }
    }
  };
  return (
    <Select
      options={languages}
      defaultValue={language}
      isSearchable={false}
      styles={customStyles}
      menuPlacement="auto"
      components={{
        DropdownIndicator,
        Option: IconOption,
        IndicatorSeparator: () => null,
        ValueContainer: IconValueContainer,
      }}
      onChange={handleChange}
      className={props.className}
      css={css`
        margin-left: 30px;
      `}
    />
  );
}
