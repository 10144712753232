import { VideoElement } from "../../../VideoEditor/types/Video";
import { v4 as uuid } from "uuid";
import { defaultSequenceDuration } from "../../../constants";

export function generateContactCardsGrid(
  sceneId: string,
  orderFrom: number,
  t: any
): VideoElement[] {
  const contactCardInitialState = {
    name: t("themi-builder.template.common.contact-name"),
    job_title: t("themi-builder.template.common.job-title"),
  };

  return [
    {
      id: uuid(),
      scene_id: sceneId,
      type: "contact",
      contact: {
        name: contactCardInitialState.name,
        job_title: contactCardInitialState.job_title,
      },
      order: orderFrom,
      states: [
        {
          id: uuid(),
          top: 151,
          left: 36,
          scale: 1,
          width: 267,
          height: 71.5,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      config: {},
    },
    {
      id: uuid(),
      scene_id: sceneId,
      type: "contact",
      contact: {
        name: contactCardInitialState.name,
        job_title: contactCardInitialState.job_title,
      },
      order: orderFrom + 1,
      states: [
        {
          id: uuid(),
          top: 228,
          left: 36,
          scale: 1,
          width: 267,
          height: 71.5,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      config: {},
    },
    {
      id: uuid(),
      scene_id: sceneId,
      type: "contact",
      contact: {
        name: contactCardInitialState.name,
        job_title: contactCardInitialState.job_title,
      },
      order: orderFrom + 2,
      states: [
        {
          id: uuid(),
          top: 308,
          left: 36,
          scale: 1,
          width: 267,
          height: 71.5,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      config: {},
    },
    {
      id: uuid(),
      scene_id: sceneId,
      type: "contact",
      contact: {
        name: contactCardInitialState.name,
        job_title: contactCardInitialState.job_title,
      },
      order: orderFrom + 3,
      states: [
        {
          id: uuid(),
          top: 151,
          left: 350,
          scale: 1,
          width: 267,
          height: 71.5,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      config: {},
    },
    {
      id: uuid(),
      scene_id: sceneId,
      type: "contact",
      contact: {
        name: contactCardInitialState.name,
        job_title: contactCardInitialState.job_title,
      },
      order: orderFrom + 4,
      states: [
        {
          id: uuid(),
          top: 228,
          left: 350,
          scale: 1,
          width: 267,
          height: 71.5,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      config: {},
    },
    {
      id: uuid(),
      scene_id: sceneId,
      type: "contact",
      contact: {
        name: contactCardInitialState.name,
        job_title: contactCardInitialState.job_title,
      },
      order: orderFrom + 5,
      states: [
        {
          id: uuid(),
          top: 308,
          left: 350,
          scale: 1,
          width: 267,
          height: 71.5,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      config: {},
    },
  ];
}
