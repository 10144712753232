import { VideoElementRecordPrompt, VideoElementState } from "../../types/Video";
import { css } from "styled-components/macro";
import { Headers } from "../../helpers/headers";
import { ReactComponent as RecordIcon } from "../../assets/icons/RecordCircle.svg";
import { useEditor } from "../../contexts/EditorContext";
import { calculateProportionalSize } from "../../../VideoPlayer/helpers/renderer";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

export function ElementRecordRendererPrompt(props: {
  element: VideoElementRecordPrompt;
  state: VideoElementState;
}) {
  const { canvasSize, setRecordModalVisible } = useEditor();
  const { t } = useTranslation();
  const elementRef = useRef<HTMLDivElement>(null);
  const [showInstruction, setShowInstruction] = useState(false);

  useEffect(() => {
    const checkSize = () => {
      if (elementRef.current) {
        const size = elementRef.current.getBoundingClientRect();
        const area = size.width * size.height;

        setShowInstruction(area > 500 * 200);
      }
    };

    checkSize();

    window.addEventListener("resize", checkSize);

    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, [props.state]);

  return (
    <div
      ref={elementRef}
      css={css`
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #fff;
        border: 1px solid #eae8e8;
        border-radius: ${calculateProportionalSize(30, canvasSize.width)}px;
      `}
    >
      {showInstruction && (
        <Headers.H3
          style={{
            fontSize: calculateProportionalSize(10, canvasSize.width),
            lineHeight: 1.6,
          }}
        >
          {props.element.instruction}
        </Headers.H3>
      )}
      <button
        css={css`
          border: none;
          background: none;
          cursor: pointer;
          margin: ${calculateProportionalSize(15, canvasSize.width)}px 0;

          &:hover {
            color: #e95b2e;
          }
        `}
        onClick={(e) => {
          e.stopPropagation();
          setRecordModalVisible(true);
        }}
      >
        <RecordIcon
          css={css`
            display: inline-block;
            vertical-align: middle;
            margin-right: ${calculateProportionalSize(10, canvasSize.width)}px;
          `}
          width={calculateProportionalSize(15, canvasSize.width)}
        />
        <span
          css={css`
            display: inline-block;
            vertical-align: middle;
            font-style: normal;
            font-weight: 600;
          `}
          style={{
            fontSize: calculateProportionalSize(14, canvasSize.width),
            lineHeight: 1.6,
          }}
        >
          {t("record-prompt.cta_label")}
        </span>
      </button>
    </div>
  );
}
