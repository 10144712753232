import React from "react";
import { css } from "styled-components/macro";

import { SEO } from "./SEO";

import { Headers } from "../helpers/layout";

export function PageTitle(props: {
  title: string;
  children?: React.ReactNode | React.ReactNode[] | string | null;
  seo?: React.ReactNode | React.ReactNode[];
}) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <SEO>
        <title>{props.title}</title>
        {props.seo}
      </SEO>

      <Headers.H1
        css={css`
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
          color: #252424;
          flex: 1 0 1%;
        `}
      >
        {props.title}
      </Headers.H1>

      <div
        css={css`
          flex: 0 0 auto;
        `}
      >
        {props.children}
      </div>
    </div>
  );
}
