import React, { useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { VideoContact, VideoElementState } from "../../types/Video";
import { useEditor } from "../../contexts/EditorContext";
import {
  InteractiveModal,
  InteractiveType,
} from "../../components/InteractiveModal";
import { Avatar } from "../../components/Avatar";

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 9px;
  background: #ffffff;
  border: 1px solid #eae8e8;
  border-radius: 6px;
  cursor: pointer;
`;

export function ElementContactRenderer(props: {
  element: VideoContact;
  state: VideoElementState;
}) {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [interactiveModal, setInteractiveModal] = useState<boolean>(false);
  const { getInPixel } = useEditor();

  const rootElement = document.getElementById("root");

  if (!rootElement) return;

  return (
    <div
      css={css`
        background: #ffffff;
        border: 1px solid #eae8e8;
        border-radius: 9px;
        display: flex;
        align-items: center;
        padding: ${getInPixel(19, 0.65)}px ${getInPixel(20, 0.65)}px;
        font-weight: 500;
        line-height: 120%;
        position: relative;
        height: 100%;
      `}
      ref={wrapperRef}
    >
      <Avatar
        size={getInPixel(61, 0.65)}
        name={`${props.element.contact.name}`}
        url={props.element.contact.avatar?.file}
        filler
        css={css`
          margin-right: ${getInPixel(25, 0.65)}px;
        `}
      />
      <div
        css={css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        <div
          css={css`
            display: inline-block;
            background: #c9b1ff;
            border-radius: 16px;
            padding: ${getInPixel(7, 0.65)}px ${getInPixel(20, 0.65)}px;
          `}
        >
          <span
            css={css`
              font-size: ${getInPixel(14, 0.65)}px;
              line-height: 1;
              text-align: center;
              color: #fff;
              display: flex;
              align-items: center;
            `}
          >
            {props.element.contact.job_title}
          </span>
        </div>

        {props.element.contact.name && (
          <div
            css={css`
              font-size: ${getInPixel(20, 0.65)}px;
              color: #000000;
              margin-top: ${getInPixel(9, 0.65)}px;
              line-height: 1.3;
              text-overflow: ellipsis;
              overflow: hidden;
            `}
          >
            {props.element.contact.name}
          </div>
        )}
      </div>

      <InteractiveModal
        interactiveType={InteractiveType.Contact}
        isOpen={interactiveModal}
        onClose={() => setInteractiveModal(false)}
      />
    </div>
  );
}
