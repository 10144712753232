import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { ReactComponent as MobileFallbackIcon } from "../assets/icons/MobileFallback.svg";
import { Trans } from "react-i18next";
import { FeedbackWidget } from "../components/FeedbackWidget";
import logo from "../assets/img/logo.png";

export function InternetExplorerFallbackPage() {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 30px 20px;
        `}
      >
        <img
          css={css`
            width: 140px;
            margin: 0 auto 30px auto;
          `}
          src={logo}
          alt={`Themis Logo`}
        />
        <div
          css={css`
            background-color: ${theme.colors.white};
            justify-content: center;
            text-align: center;
            padding: 30px;
            border-radius: 20px;
            margin-bottom: 40px;
          `}
        >
          <MobileFallbackIcon />
          <div
            css={css`
              margin-top: 25px;
            `}
          >
            <Trans i18nKey="old-browsers.fallback">
              The Themis experience is not supported with Internet Explorer. For
              an optimal experience, please use an alternative browser.
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.microsoft.com/en-gb/edge/download?form=MA13FJ"
              >
                Download Microsoft Edge browser
              </a>
            </Trans>
          </div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            background-color: ${theme.colors.white};
            justify-content: center;
            text-align: center;
            padding: 20px;
            border-radius: 20px;
          `}
        >
          <a
            href={"https://themis.eu/impressum/"}
            css={css`
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 10px;
            `}
          >
            Imprint
          </a>
          <a
            href={"https://themis.eu/datenschutzerklarung/"}
            css={css`
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 10px;
            `}
          >
            Datenschutzerklarung
          </a>
        </div>
      </div>
      <FeedbackWidget />
    </div>
  );
}
