import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import Bowser from "bowser";
import { BigMessage } from "../components/BigMessage";
import { ReactComponent as WarningIcon } from "../assets/icons/Warning.svg";

const allowedBrowsersVersion: { [key: string]: number } = {
  chrome: 105,
  edge: 105,
  safari: 16,
  firefox: 110,
  opera: 91,
};

const downloadLinks: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  chrome: {
    de: "https://www.google.com/intl/de/chrome/update/",
    en: "https://www.google.com/chrome/update/",
  },
  edge: {
    de: "https://support.microsoft.com/de-de/topic/microsoft-edge-updateeinstellungen-af8aaca2-1b69-4870-94fe-18822dbb7ef1",
    en: "https://support.microsoft.com/en-us/topic/microsoft-edge-update-settings-af8aaca2-1b69-4870-94fe-18822dbb7ef1",
  },
  safari: {
    de: "https://support.apple.com/de-de/HT204416",
    en: "https://support.apple.com/en-us/HT204416",
  },
  firefox: {
    de: "https://support.mozilla.org/de/kb/firefox-auf-die-letzte-version-aktualisieren",
    en: "https://support.mozilla.org/en-US/kb/update-firefox-latest-release",
  },
  opera: {
    de: "https://www.opera.com/de",
    en: "https://www.opera.com/",
  },
};

export function BrowserNotification() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const browser = Bowser.getParser(window.navigator.userAgent);

  useEffect(() => {
    const name = browser.getBrowserName(true);
    if (name === "internet explorer") {
      return history.push("/explorer-fallback");
    }
    const version = +browser.getBrowserVersion().split(".")[0];
    if (name in allowedBrowsersVersion) {
      if (version < allowedBrowsersVersion[name]) {
        setIsOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <BigMessage
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      icon={<WarningIcon width={16} height={16} />}
      title={t("old-browsers.title")}
      description={t("old-browsers.description")}
      goToDashboard
      button={{
        text: (
          <Trans i18nKey="old-browsers.update">
            Update {{ browser: browser.getBrowserName() }}
          </Trans>
        ),
        onClick: () => {
          setIsOpen(false);
          window.open(
            downloadLinks[browser.getBrowserName(true)][i18n.language],
            "_blank"
          );
        },
      }}
    />
  );
}
