import React, { useRef, useState } from "react";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Column, Spacing } from "../../helpers/layout";
import { InputField } from "../fields/InputField";
import { Button } from "../../components/Button";
import { InputComponent, InputType } from "../../components/InputComponent";
import { UserProfile } from "../../types/UserProfile";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { theme } from "../../themes/variables";
import { ReactComponent as ProfilePasswordKey } from "../../assets/icons/ProfilePasswordKey.svg";
import { ReactComponent as ChevronSmall } from "../../assets/icons/ChevronSmall.svg";
import { changePassword } from "../../actions/account";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/UserContext";

export interface FormValues {
  password: string;
  new_password: string;
  confirm_new_password: string;
}

export function PasswordForm(props: { data: UserProfile }) {
  const [showForm, setShowForm] = useState(false);
  const { deauthenticate } = useAuth();
  const history = useHistory();
  const content = useRef(null as null | HTMLDivElement);

  const { t } = useTranslation();

  const initialValues: FormValues = {
    password: "",
    new_password: "",
    confirm_new_password: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(),
    new_password: Yup.string().min(8).required(),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], t("auth.pwd-not-match"))
      .required(),
  });

  const redirectToResetPassword = () => {
    deauthenticate();
    history.push("/reset-password");
  };

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div>
        <InputComponent
          name={"password"}
          disabled
          type={InputType.PASSWORD}
          label={t("account.current-password")}
          placeholder={`*****************`}
          css={css`
            width: calc(50% - ${Spacing.xl} / 2);
            input {
              border: none;
              background-color: ${theme.colors.gray2};
            }
          `}
        />
      </div>

      <div
        onClick={() => setShowForm(!showForm)}
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 50px;
          cursor: pointer;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-right: 25px;
          `}
        >
          <ProfilePasswordKey />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 40px;
          `}
        >
          <div
            css={css`
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
            `}
          >
            {t("profile_page.change_password")}
          </div>
          <div
            css={css`
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #505050;
            `}
          >
            {t("profile_page.change_any_time")}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ChevronSmall
            css={css`
              transition: transform 0.6s ease;
              transform: ${showForm ? `rotate(90deg)` : `none`};
            `}
          />
        </div>
      </div>
      <div
        ref={content}
        css={css`
          overflow: hidden;
          transition: max-height 0.6s ease;
          max-height: ${!showForm
            ? "0px"
            : content &&
              content.current &&
              content.current.scrollHeight + "px"};
        `}
      >
        <Formik<FormValues>
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (
            values: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>
          ) => {
            try {
              setSubmitting(true);

              await changePassword(values);
              customToast.success(t("status.success"));
            } catch (error: any) {
              customToast.error(
                t("status.error", {
                  error: serverErrorHandler(error),
                })
              );
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Form
              noValidate
              css={css`
                width: 100%;
                margin-top: 32px;
              `}
            >
              <Column gutter={Spacing.xl}>
                <InputField
                  name={"password"}
                  type={InputType.PASSWORD}
                  label={`${t("account.current-password")}`}
                  placeholder={t("profile_page.write_down_password")}
                  css={css`
                    width: calc(50% - ${Spacing.xl} / 2);
                  `}
                />
                <div
                  onClick={redirectToResetPassword}
                  css={css`
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: #e95b2e;
                    cursor: pointer;
                    margin-top: 5px;

                    :hover {
                      text-decoration: underline;
                    }
                  `}
                >
                  {t("profile_page.forgot_password")}
                </div>

                <div
                  css={css`
                    margin-top: 40px;
                    width: 100%;
                    border-bottom: 1px solid #eae8e8;
                  `}
                ></div>

                <InputField
                  name={"new_password"}
                  type={InputType.PASSWORD}
                  placeholder={`${t("account.new-password")}`}
                  css={css`
                    width: calc(50% - ${Spacing.xl} / 2);
                  `}
                />

                <InputField
                  name={"confirm_new_password"}
                  type={InputType.PASSWORD}
                  placeholder={`${t("profile_page.confirm_password")}`}
                  css={css`
                    width: calc(50% - ${Spacing.xl} / 2);
                  `}
                />

                <div>
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    {t("profile_page.save")}
                  </Button>
                </div>
              </Column>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
