import { css } from "styled-components/macro";
import { InteractivitySettings } from "./components/InteractivitySettings";
import { Navigation } from "./components/Navigation";
import { SceneEditor } from "./components/SceneEditor";
import { SceneNavigator } from "./components/SceneNavigator";
import { SidebarSelector } from "./components/SidebarSelector";
import { StatusBar } from "./components/StatusBar";
import { useEditor, NavType } from "./contexts/EditorContext";
import { WalkThrough } from "./components/WalkThrough";
import { ShapeToolbar } from "./components/Toolbars/ShapeToolbar";
import { SettingsToolbar } from "./components/Toolbars/SettingsToolbar";
import { useLocation } from "react-router-dom";
import CommentsSidebar from "./components/Comments/CommentsSidebar";
import { ModalAiSuccess } from "../modals/ModalAiSuccess";
import { ModalAiError } from "../modals/ModalAiError";
import { useAi } from "./contexts/AiContext";
import { canEdit } from "../VideoEditor/helpers/collaborator";
import { useStorage } from "./contexts/StorageContext";
import { ModalRecord } from "../modals/ModalRecord";
import { Timeline } from "./components/TimeLine";
import { PlayButton } from "./components/PlayButton";
import { ViewingMode } from "./components/ViewingMode";
import { useRecording } from "./contexts/RecordingContext";
import { RecordingToolbar } from "./components/Toolbars/RecordingToolbar";
import { ModalAI } from "../modals/ModalAI";
import { TextToolbar } from "./components/Toolbars/TextToolbar";
import { BrowserNotification } from "../components/BrowserNotification";

export function EditorLayout() {
  const {
    activeNav,
    canvasSize,
    activeNavType,
    recordModalVisible,
    setRecordModalVisible,
    AIModalVisible,
    setAIModalVisible,
    currentElement,
    textEditorInit,
  } = useEditor();
  const { recording } = useRecording();
  const location = useLocation();
  const { video } = useStorage();

  const { statusModal, closeStatusModal } = useAi();

  const isShapeToolbarActive =
    currentElement?.type === "ellipse" ||
    currentElement?.type === "rectangle" ||
    currentElement?.type === "polygon" ||
    currentElement?.type === "arrow-1" ||
    currentElement?.type === "arrow-2" ||
    currentElement?.type === "arrow-3" ||
    currentElement?.type === "line";

  const isRecordingActive = recording === "active" || recording === "paused";

  return (
    <div
      css={css`
        display: flex;
        height: 100vh;
        background: #fff;
      `}
    >
      {location.search === "?product_tour=true" && <WalkThrough />}

      <div
        css={css`
          position: relative;
          flex: 1 0 10%;
          height: 100%;
          display: flex;
          flex-flow: column;
          overflow: hidden;
          min-height: 0;
        `}
      >
        <div
          css={css`
            padding: 10px 25px;
            border-bottom: 1px solid #eae8e8;
            box-shadow: 0px 2px 8px #e6e6e6;
            z-index: 1;
          `}
        >
          <StatusBar />
        </div>

        <div
          css={css`
            display: flex;
            flex: 1;
            min-height: 0;
            background: #f5f5f5;
          `}
        >
          <div
            css={css`
              width: 250px;
              flex-basis: 250px;
              height: 100%;
              min-height: 0;
              border-right: 1px solid #eae8e8;
            `}
          >
            <SceneNavigator />
          </div>

          <div
            css={css`
              flex: 1;
              height: 100%;
              display: flex;
              flex-flow: column;
              background: #f5f5f5;
            `}
          >
            <div
              css={css`
                height: 48px;
                margin: 0 auto;
                margin-top: 22px;
                width: ${canvasSize.width - 150}px;
                min-width: fit-content;
              `}
            >
              {canEdit(video?.current_user_role) && (
                <>
                  <TextToolbar
                    visible={
                      textEditorInit &&
                      !isRecordingActive &&
                      !isShapeToolbarActive
                    }
                  />

                  {isRecordingActive ? (
                    <RecordingToolbar />
                  ) : isShapeToolbarActive ? (
                    <ShapeToolbar />
                  ) : !textEditorInit ? (
                    <SettingsToolbar />
                  ) : null}
                </>
              )}
            </div>

            <div
              css={css`
                flex: 1 0 10%;
                padding: 20px 30px;
                display: flex;
                flex-flow: column;
              `}
            >
              <SceneEditor />

              <div
                css={css`
                  position: relative;
                  width: ${canvasSize.width}px;
                  align-self: center;
                  display: flex;
                  margin-top: 30px;
                `}
              >
                <PlayButton />
                <Timeline />
                <ViewingMode />
              </div>
            </div>
          </div>

          <div
            css={css`
              width: 50px;
              flex-basis: 50px;
              margin-right: 30px;
              height: 100%;
              background: #f5f5f5;
              position: relative;
              z-index: 2;
            `}
          >
            <Navigation />
          </div>

          <div
            css={css`
              width: 295px;
              min-width: 295px;
              flex-basis: 295px;
              height: 100%;
              min-height: 0;
              padding-top: 10px;
              padding-right: 10px;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
              border-right: 1px solid #cdcdcd;
              margin-right: -295px;
              transition: margin 500ms;
              background: #fff;

              ${activeNav &&
              activeNavType === NavType.Select &&
              css`
                margin-right: 0;
              `}
            `}
          >
            <div
              css={css`
                height: 100%;
                min-height: 0;
                padding: 20px 30px 20px 40px;
                box-sizing: border-box;
                overflow: scroll;

                ::-webkit-scrollbar {
                  width: 9px;
                  border-radius: 5px;
                }

                ::-webkit-scrollbar-track {
                  background: transparent;
                  border-radius: 5px;
                }

                ::-webkit-scrollbar-thumb {
                  background: #cdcdcd;
                  border-radius: 5px;
                }

                ::-webkit-scrollbar-thumb:hover {
                  background: #555;
                }
              `}
            >
              {activeNav && activeNavType === NavType.Select && (
                <SidebarSelector />
              )}
            </div>
          </div>
        </div>

        <CommentsSidebar />

        <InteractivitySettings />
      </div>
      <ModalAiSuccess
        isOpen={statusModal === "success"}
        onClose={closeStatusModal}
      />
      <ModalAiError
        isOpen={statusModal === "error"}
        onClose={closeStatusModal}
      />
      <ModalRecord
        isOpen={recordModalVisible}
        onClose={() => {
          setRecordModalVisible(false);
        }}
        video={video}
      />
      <ModalAI
        isOpen={AIModalVisible}
        onClose={() => {
          setAIModalVisible(false);
        }}
      />
      <BrowserNotification />
    </div>
  );
}
