import React from "react";
import { css } from "styled-components/macro";

export type NavigationType = {
  title: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  isActive?: boolean;
};

export function Navigation(props: NavigationType) {
  return (
    <div
      css={css`
        display: flex;
        position: relative;
        cursor: pointer;
        align-items: center;
        color: ${props.isActive ? "#E95B2E" : "#252424"};
        margin-right: 18px;
        :hover {
          color: ${props.isActive ? "#E95B2E" : "#616161"};
        }
      `}
      onClick={props.onClick}
    >
      {props.isActive && (
        <div
          css={css`
            position: absolute;
            height: 2px;
            width: calc(100% + 18px);
            background-color: #e95b2e;
            bottom: -1.5px;
            border-radius: 1.5px;
            z-index: 1;
            left: -18px;
          `}
        />
      )}
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          /* padding: 0 18px; */
          padding-right: 18px;
        `}
      >
        {props.icon && (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {props.icon}
          </div>
        )}
        <span
          css={css`
            margin-left: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
          `}
        >
          {props.title}
        </span>
      </div>
    </div>
  );
}
