import React, { useState, useEffect, useCallback } from "react";
// import useInterval from "@use-it/interval";
import { useAuth } from "../../contexts/UserContext";
import { uploadAiFile } from "../actions/uploadAiFile";
import {
  FileStatus,
  getAiFileStatus,
  UploadStatus,
} from "../actions/getAiFileStatus";
import { downloadMLSchema } from "../actions/downloadMLSchema";
import { useStorage } from "./StorageContext";
import { useTranslation } from "react-i18next";
import { VideoElement } from "../types/Video";
import { sendEvent } from "../../helpers/tracking";
import { defaultSequenceDuration } from "../../constants";
import { generateAIContent, Slides } from "../actions/generateAIContent";

export const statusPercent: {
  [key in UploadStatus]: number;
} = {
  [UploadStatus.ERROR]: 0,
  [UploadStatus.UPLOADING]: 0,
  [UploadStatus.PARSING]: 25,
  [UploadStatus.SUMMARIZING]: 50,
  [UploadStatus.GENERATING_LAYOUT]: 75,
  [UploadStatus.COMPLETED]: 100,
};

const AiContext = React.createContext<{
  uploadFiles: (files: File[]) => Promise<void>;
  generateContent: (prompt: string) => Promise<{
    slides: Slides;
  } | null>;
  status: FileStatus[];
  errors?: string[] | null;
  percentage: number;
  statusModal: "success" | "error" | false;
  closeStatusModal: () => void;
  lastCreatedSceneId: string | null;
}>({
  uploadFiles: () => Promise.resolve(),
  generateContent: () => Promise.resolve(null),
  status: [],
  errors: [],
  percentage: 0,
  statusModal: false,
  closeStatusModal: () => {},
  lastCreatedSceneId: null,
});

function formatElements(elements: VideoElement[]) {
  return elements.reduce((newElements: VideoElement[], element, index) => {
    if (element.type !== "text") return newElements;

    const isTitle = element.config && element.config.fontSize === 24;
    const fontSize = isTitle ? 4.57 : 2;

    let newText = element.text;

    if (isTitle) {
      // remove all bulletpoints
      newText = newText.replace(/•\s?/g, "");
      // remove . at the end of the text
      newText = newText.replace(/\.$/, "");
      // trim text
      newText = newText.length > 22 ? `${newText.slice(0, 22)}...` : newText;

      newText = `<p style="font-family: Arial; font-size: ${fontSize}cqw;"><strong>${newText}</strong></p>`;
    }
    // if text contains bulletpoints
    else if (newText.match(/•/g)) {
      // match all strings that contain •
      const matches = newText.match(/•[^•]+/g);

      if (matches && matches.length > 1) {
        matches.forEach((match) => {
          newText = newText.replace(
            match,
            `<li>${match.replace(/•\s?/g, "")}</li>`
          );
        });

        newText = `<ul style="color: rgba(97, 97, 97, 1); font-family: Arial; font-size: ${fontSize}cqw;">${newText}</ul>`;
      } else {
        newText = newText.replace(/•\s?/g, "");

        newText = `<p style="color: rgba(97, 97, 97, 1); font-family: Arial; font-size: ${fontSize}cqw;">${newText}</p>`;
      }
    } else {
      newText = newText.replace(/•\s?/g, "");

      newText = `<p style="color: rgba(97, 97, 97, 1); font-family: Arial; font-size: ${fontSize}cqw;">${newText}</p>`;
    }

    const top = newElements[index - 1]
      ? newElements[index - 1].states[0].top +
        newElements[index - 1].states[0].height +
        20
      : 21.21;
    const width = 636.3000000000001;
    const charsPerLine = width / (fontSize * 3.5);
    const lineWidth = fontSize * 11.47;
    const pureText = newText.replace(/<[^>]*>?/gm, "");
    const height = Math.ceil(pureText.length / charsPerLine) * lineWidth;

    const newElement = {
      id: element.id,
      text: newText,
      type: element.type,
      order: element.order,
      states: [
        {
          id: element.states[0].id,
          top: top,
          left: 35.35,
          scale: 1,
          width: width,
          height: height,
          duration: defaultSequenceDuration,
          rotation: 0,
          start_time: 0,
        },
      ],
      scene_id: element.scene_id,
    };

    return [...newElements, newElement];
  }, []);
}

export function AiProvider(props: {
  children: React.ReactNode;
  videoId: string;
}) {
  const { userprofile } = useAuth();
  const [status, setStatus] = useState<FileStatus[]>([]);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [percentage, setPercentage] = useState(0);
  const { api } = useStorage();
  const [statusModal, setStatusModal] = useState<"success" | "error" | false>(
    false
  );
  const { t } = useTranslation();
  const [lastCreatedSceneId, setLastCreatedSceneId] = useState<string | null>(
    null
  );

  const getFileStatus = useCallback(async () => {
    const { data } = await getAiFileStatus({
      videoId: props.videoId,
    });

    if (data.length > 0) {
      setPercentage(
        data.reduce((acc, val) => {
          return acc + statusPercent[val.status];
        }, 0) / data.length
      );

      setStatus(data);
    } else {
      setStatus([]);
    }
  }, [props.videoId]);

  const uploadFiles = useCallback(
    async (files: File[]) => {
      const arrayOfFiles = files;

      try {
        await Promise.all(
          arrayOfFiles.map(async (file) => {
            const response = await uploadAiFile({
              file,
              user_id: userprofile?.id!,
              video_id: props.videoId,
            });

            const uploadedFilesValue = localStorage.getItem(
              "THEMIS_ML_UPLOADED_FILE_IDS"
            );

            const uploadedFiles = uploadedFilesValue
              ? (JSON.parse(uploadedFilesValue) as string[])
              : [];

            localStorage.setItem(
              "THEMIS_ML_UPLOADED_FILE_IDS",
              JSON.stringify([...uploadedFiles, response.file_id])
            );
          })
        );

        await getFileStatus();
      } catch (e: any) {
        setErrors((errors) => {
          return errors ? [e, ...errors] : [e];
        });
      }
    },
    [userprofile, props.videoId, getFileStatus]
  );

  const generateContent = useCallback(
    async (prompt: string) => {
      return await generateAIContent(props.videoId, prompt);
    },
    [props.videoId]
  );

  useEffect(() => {
    const downloadSchema = async (fileId: string) => {
      const response = await downloadMLSchema({
        videoId: props.videoId,
        fileId,
      });

      return response.data;
    };

    async function createSequence(fileId: string) {
      const response = await downloadSchema(fileId);
      const processedFilesValue = localStorage.getItem(
        "THEMIS_ML_PROCESSED_FILE_IDS"
      );

      const processedFiles = processedFilesValue
        ? (JSON.parse(processedFilesValue) as string[])
        : [];

      if (processedFiles.includes(fileId)) return null;

      const elements = formatElements(response.elements);

      const scene = await api.createSceneWithContent(
        {
          name: t("ai.default-sequence-name"),
          start_time: 0,
        },
        elements
      );

      localStorage.setItem(
        "THEMIS_ML_PROCESSED_FILE_IDS",
        JSON.stringify([...processedFiles, fileId])
      );

      sendEvent("Summarized document", {
        video_id: props.videoId,
      });

      return scene;
    }

    async function processFiles() {
      const scenes = await Promise.all(
        status.map(async (fileStatus) => {
          return await createSequence(fileStatus.file_id);
        })
      );
      setStatusModal("success");

      const lastScene = scenes.find((scene) => scene !== null);

      setLastCreatedSceneId(lastScene ? lastScene.id : null);
    }

    if (percentage === 100) {
      const notProcessedFiles = status.filter((fileStatus) => {
        const processedFiles = localStorage.getItem(
          "THEMIS_ML_PROCESSED_FILE_IDS"
        );

        const uploadedFilesValue = localStorage.getItem(
          "THEMIS_ML_UPLOADED_FILE_IDS"
        );

        return (
          fileStatus.status === UploadStatus.COMPLETED &&
          !processedFiles?.includes(fileStatus.file_id) &&
          uploadedFilesValue?.includes(fileStatus.file_id)
        );
      });

      if (notProcessedFiles.length > 0) {
        processFiles();
      }
    }
  }, [status, percentage, api, props.videoId, t]);

  useEffect(() => {
    if (errors && errors.length) setStatusModal("error");
  }, [errors]);

  useEffect(() => {
    getFileStatus();

    const interval = setInterval(() => {
      getFileStatus();
    }, 10000);

    return () => clearInterval(interval);
  }, [getFileStatus]);

  return (
    <AiContext.Provider
      value={{
        uploadFiles,
        generateContent,
        status,
        percentage,
        errors,
        statusModal,
        closeStatusModal: () => setStatusModal(false),
        lastCreatedSceneId,
      }}
    >
      {props.children}
    </AiContext.Provider>
  );
}

export function useAi() {
  const context = React.useContext(AiContext);

  if (context === undefined) {
    throw new Error("useAi must be used within a AiProvider");
  }

  return context;
}
