import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

export function ToggleSwitch(props: {
  value?: boolean;
  onChange: () => void;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}) {
  const { t } = useTranslation();
  return (
    <div
      className={props.className}
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      {props.children}
      <label
        css={css`
          position: relative;
          display: inline-block;
          width: 30px;
          height: 7px;
          input {
            opacity: 0;
            width: 0;
            height: 0;
          }
          input:checked + span {
            background-color: #ffc39c;
          }
          input:focus + span {
            box-shadow: 0 0 1px #ffc39c;
          }
          input:checked + span:before {
            transform: translateX(18px);
          }
          input:disabled + span:before {
            background-color: "#F2F2F2";
          }
        `}
      >
        <input
          type="checkbox"
          checked={props.value}
          onChange={props.onChange}
        />
        <span
          css={css`
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #f2f2f2;
            border-radius: 15px;
            -webkit-transition: 0.4s;
            transition: 0.4s;

            :before {
              position: absolute;
              content: "";
              height: 12px;
              width: 12px;
              left: 0px;
              bottom: -2px;
              background-color: #e95b2e;
              border-radius: 50%;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }
          `}
        ></span>
      </label>
      <div
        css={css`
          color: #e95b2e;
          font-size: 10px;
          font-weight: 400;
          margin-left: 4px;
        `}
      >
        {props.value
          ? t("canvas.settings.sidebar.mode.collaboration.on")
          : t("canvas.settings.sidebar.mode.collaboration.off")}
      </div>
    </div>
  );
}
