import { authorised } from "../../request";

export async function profileSetup(company_role: string) {
  const API = authorised();

  return await API.request({
    method: "POST",
    url: `/api/studio/me/profile-setup/`,
    data: { company_role },
  });
}
