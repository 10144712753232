import { css } from "styled-components/macro";
import { useAuth } from "../../contexts/UserContext";
import { useSharing } from "../../VideoEditor/contexts/SharingContext";
import { CollaborationMode } from "../../VideoEditor/types/Collaborator";
import { CollaboratorItem } from "./CollaboratorItem";
import { PermissionRequest } from "./PermissionRequest";
import { useTranslation } from "react-i18next";
import { useStorage } from "../../VideoEditor/contexts/StorageContext";

type ManageProps = {
  mode: CollaborationMode;
};

export function ManageCollaborators(props: ManageProps) {
  const { t } = useTranslation();
  const { collaborators, viewers, permissionRequests } = useSharing();
  const { userprofile } = useAuth();
  const { video } = useStorage();

  const collaboratorsToShow =
    props.mode === CollaborationMode.EDIT ? collaborators : viewers;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-top: 55px;
      `}
    >
      <div
        css={css`
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 20px;
        `}
      >
        {props.mode === CollaborationMode.EDIT
          ? t("share.manage.collaborators")
          : t("share.manage.viewers")}
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          max-height: 214px;
          overflow-x: hidden;
          overflow: auto;

          ::-webkit-scrollbar {
            width: 5px;
            border-radius: 5px;
          }

          ::-webkit-scrollbar-track {
            background: #eae8e8;
            border-radius: 5px;
          }

          ::-webkit-scrollbar-thumb {
            background: #252424;
            border-radius: 5px;
          }
        `}
      >
        {permissionRequests && <PermissionRequest mode={props.mode} />}
        {collaboratorsToShow.length <= 0 ? (
          <div
            css={css`
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #616161;
            `}
          >
            {props.mode === CollaborationMode.EDIT
              ? t("share.manage.no-collaborators")
              : t("share.manage.no-viewers")}
          </div>
        ) : (
          collaboratorsToShow.map((collaborator, key) => (
            <CollaboratorItem
              key={key}
              hideControls={userprofile?.id === collaborator.user.id}
              collaborator={collaborator}
              mode={props.mode}
              currentRole={video!.current_user_role}
            />
          ))
        )}
      </div>
    </div>
  );
}
