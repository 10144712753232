import React, { useEffect, useCallback, useMemo } from "react";
import { customToast } from "../components/customToast";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { useTranslation } from "react-i18next";
import { UserWorkspace, WorkspaceOverview } from "../types/Workspace";
import { getWorkspace } from "../actions/workspace/getWorkspace";
import { createWorkspace } from "../actions/workspace/createWorkspace";
import { updateWorkspace } from "../actions/workspace/updateWorkspace";

const WorkspaceContext = React.createContext<{
  workspace: UserWorkspace | null;

  workspaceApi: {
    fetchWorkspace: () => Promise<void>;
    onCreateWorkspace: (values: WorkspaceOverview) => Promise<void>;
    onUpdateWorkspace: (
      values: WorkspaceOverview,
      workspace_id: string
    ) => Promise<void>;
  };
}>({
  workspace: null,

  workspaceApi: {
    fetchWorkspace: () => Promise.resolve(),
    onCreateWorkspace: () => Promise.resolve(),
    onUpdateWorkspace: () => Promise.resolve(),
  },
});

export function WorkspaceProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const [workspace, setWorkspace] = React.useState<UserWorkspace | null>(null);

  const fetchWorkspace = useCallback(async () => {
    try {
      const request = await getWorkspace();
      setWorkspace(request);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  }, [t]);

  const onCreateWorkspace = useCallback(
    async (values: WorkspaceOverview) => {
      try {
        await createWorkspace(values);
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t]
  );

  const onUpdateWorkspace = useCallback(
    async (values: WorkspaceOverview, workspace_id: string) => {
      try {
        await updateWorkspace(values, workspace_id);
        await fetchWorkspace();
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    },
    [t, fetchWorkspace]
  );

  useEffect(() => {
    fetchWorkspace();
  }, [fetchWorkspace]);

  const workspaceApi = useMemo(
    () => ({
      fetchWorkspace,
      onCreateWorkspace,
      onUpdateWorkspace,
    }),
    [fetchWorkspace, onCreateWorkspace, onUpdateWorkspace]
  );

  return (
    <WorkspaceContext.Provider
      value={{
        workspace,
        workspaceApi,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
}

export function useWorkspace() {
  const context = React.useContext(WorkspaceContext);

  if (context === undefined) {
    throw new Error("useWorkspace must be used within a WorkspaceProvider");
  }

  return context;
}
