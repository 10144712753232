import React, { useState, useEffect, useCallback } from "react";
import { getProfile } from "../actions/auth/getProfile";
import { identifyUser } from "../helpers/tracking";
import { UserProfile, Permission } from "../types/UserProfile";
import { useEnterprise } from "./EnterpriseContext";

export const THEMIS_AUTH_TOKEN = "THEMIS_AUTH_TOKEN";

type State = {
  isAuthenticated: boolean | null;
  userprofile: UserProfile | null;
};

const emptyState = {
  isAuthenticated: null,
  userprofile: null,
  authenticate: () => {},
  deauthenticate: () => {},
  refetch: () => {},
  hasPermission: () => false,
  hasOrganisationPermission: () => false,
};

type Actions = {
  authenticate: (token: string, user: UserProfile) => void;
  deauthenticate: () => void;
  refetch: () => void;
  hasPermission: (permission: Permission) => boolean;
  hasOrganisationPermission: (permission: Permission) => boolean;
};

type Context = State & Actions;

const UserStateContext = React.createContext<Context>(emptyState);

const tokenExist = function () {
  return !!localStorage.getItem(THEMIS_AUTH_TOKEN);
};

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<State>(emptyState);
  const { refetch: refetchEnterprise } = useEnterprise();

  const fetchProfile = useCallback(async () => {
    try {
      const request = await getProfile();

      setState({
        userprofile: request.data,
        isAuthenticated: true,
      });

      identifyUser(request.data.id, request.data);
    } catch {
      setState({
        isAuthenticated: false,
        userprofile: null,
      });
    }
  }, []);

  // TODO: check token validity
  useEffect(() => {
    if (tokenExist()) {
      fetchProfile();
    } else {
      setState({
        isAuthenticated: false,
        userprofile: null,
      });
    }
  }, [fetchProfile]);

  const actions = {
    authenticate: (token: string, userprofile: UserProfile) => {
      setState({
        isAuthenticated: true,
        userprofile,
      });

      identifyUser(userprofile.id, userprofile);

      localStorage.setItem(THEMIS_AUTH_TOKEN, token);

      refetchEnterprise();
    },
    deauthenticate: () => {
      setState({
        isAuthenticated: false,
        userprofile: null,
      });

      localStorage.removeItem(THEMIS_AUTH_TOKEN);
    },
    refetch: () => fetchProfile(),
    hasPermission: (permission: Permission): boolean => {
      if (state.userprofile)
        return state.userprofile.permissions
          .map((el) => el.key)
          .includes(permission);

      return false;
    },
    hasOrganisationPermission: (permission: Permission): boolean => {
      if (state.userprofile)
        return state.userprofile.organisation_permissions
          .map((el) => el.key)
          .includes(permission);

      return false;
    },
  };

  return (
    <UserStateContext.Provider value={{ ...state, ...actions }}>
      {children}
    </UserStateContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(UserStateContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a UserProvider");
  }

  return context;
}
