import { notAuthorised } from "../../request";

export async function confirmEmail(code: string) {
  const API = notAuthorised;

  return await API.request({
    method: "POST",
    url: `/api/studio/confirm-email/`,
    data: {
      code: code,
    },
  });
}
