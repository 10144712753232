import { viewsAPI } from "../../request";

export type RegisterViewArgs = {
  themi_id: string;
  user_id: number;
};

export async function registerView(data: RegisterViewArgs) {
  const API = viewsAPI;
  const response = await API.request({
    method: "POST",
    url: `/views/register_view`,
    data,
  });

  return response.data;
}
