import { useTranslation } from "react-i18next";
import { ReactComponent as ThemiLogoWhite } from "../assets/icons/ThemiLogoWhite.svg";
import TryPro from "../assets/icons/TryPro.svg";
import { Button } from "./Button";
import { css } from "styled-components/macro";
import { sendEvent } from "../helpers/tracking";

export function UpgrateToProAd(props: {
  className?: string;
  text?: string;
  title?: string;
  buttonText?: string;
  location: string;
}) {
  const { t } = useTranslation();
  const {
    text = t("upgrade-ad.text"),
    title = t("upgrade-ad.title"),
    buttonText = t("upgrade-ad.button"),
  } = props;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        background-image: url(${TryPro});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px 15px;
        border-radius: 10px;
      `}
      className={props.className}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <ThemiLogoWhite />
        </div>
        <span
          css={css`
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            color: #c9b1ff;
            margin-left: 15px;
          `}
        >
          {title}
        </span>
      </div>
      <span
        css={css`
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #fff;
          margin-bottom: 15px;
        `}
      >
        {text}
      </span>
      <Button
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          color: #252424;
          width: 150px;
          font-weight: 700;
          font-size: 16px;
          line-height: 32px;
          padding: 0 10px;
        `}
        background="#fff"
        hoverStyles="none"
        onClick={() => {
          sendEvent("Upgrade button clicked", {
            type: "Upgrade Banner",
            location: props.location,
          });

          window.open(
            "https://calendly.com/themis-sales/demo",
            "_blank"
          );
        }}
      >
        {buttonText}
      </Button>
    </div>
  );
}
