import { useTranslation } from "react-i18next";
import { Headers } from "../helpers/layout";
import logo from "../assets/img/logo.png";
import bgOne from "../assets/img/splash-bg-1.png";
import bgTwo from "../assets/img/splash-bg-2.png";
import { css } from "styled-components/macro";

export function SplashPage() {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        text-align: center;
        background: #fae7d9;

        &:before {
          content: "";
          position: absolute;
          top: -15vmin;
          right: 10vmin;
          width: 30vmin;
          height: 30vmin;
          background: url(${bgOne}) no-repeat;
          background-size: contain;
        }

        &:after {
          content: "";
          position: absolute;
          left: -5vmin;
          top: 30vmin;
          width: 30vmin;
          height: 30vmin;
          background: url(${bgTwo}) no-repeat;
          background-size: contain;
        }
      `}
    >
      <div
        css={css`
          position: absolute;
          left: 30px;
          top: 30px;
        `}
      >
        <img
          css={css`
            width: 180px;
          `}
          src={logo}
          alt={`Themi Logo`}
        />
      </div>
      <div
        css={css`
          max-width: 690px;
          width: 90%;
          margin: 0 auto;
        `}
      >
        <Headers.H2
          css={css`
            font-size: 24px;
            font-weight: normal;
          `}
        >
          {t("splash-page.subtitle")}
        </Headers.H2>
        <div
          css={css`
            margin: 75px 0;
          `}
        >
          <Headers.H1
            css={css`
              font-size: 29px;
              margin-top: 0;
            `}
          >
            {t("splash-page.title")}
          </Headers.H1>
          <p
            css={css`
              font-size: 20px;
            `}
          >
            {t("splash-page.description")}
          </p>
        </div>
        <Headers.H3
          css={css`
            font-size: 24px;
          `}
        >
          {t("splash-page.bottom-text")}
        </Headers.H3>
        <Headers.H2
          css={css`
            font-size: 51px;
            font-weight: bold;
          `}
        >
          16. Mai 2023
        </Headers.H2>
      </div>
    </div>
  );
}
