import { authorised } from "../../request";

export type Slide = {
  name: string;
  title: string;
  subtitle: string;
  description: string;
};

export type Slides = Slide[];

export async function generateAIContent(videoId: string, prompt: string) {
  const API = authorised();

  const response = await API.request<{
    slides: Slides;
  }>({
    method: "POST",
    url: `/api/studio/themis/${videoId}/ml/generate-content/`,
    data: {
      prompt,
    },
  });

  return response.data;
}
