import React, { useRef } from "react";
import { css } from "styled-components/macro";
import { Avatar } from "../../VideoEditor/components/Avatar";
import {
  CollaborationMode,
  Collaborator,
  CollaboratorRole,
} from "../../VideoEditor/types/Collaborator";
import { ReactComponent as ShareDelete } from "../../assets/icons/ShareDelete.svg";

import { useSharing } from "../../VideoEditor/contexts/SharingContext";
import { useTranslation } from "react-i18next";
import { Select } from "../../components/Select";

type CollaboratorItemProps = {
  collaborator: Collaborator;
  hideControls: boolean;
  mode: CollaborationMode;
  currentRole: CollaboratorRole;
};

function getRoleOptions(
  currentRole?: CollaboratorRole,
  userRole?: string
): Array<{
  value: CollaboratorRole | "REMOVE";
  label: string;
}> {
  if (currentRole === "OWNER") {
    return [
      {
        value: CollaboratorRole.OWNER,
        label: "share.dropdown.owner",
      },
      {
        value: CollaboratorRole.EDITOR,
        label: "share.dropdown.editor",
      },
      {
        value: CollaboratorRole.COMMENTER,
        label: "share.dropdown.commenter",
      },
      {
        value: "REMOVE",
        label: "share.cta.remove",
      },
    ];
  } else if (currentRole === "EDITOR") {
    if (userRole === "EDITOR" || userRole === "COMMENTER") {
      return [
        {
          value: CollaboratorRole.EDITOR,
          label: "share.dropdown.editor",
        },
        {
          value: CollaboratorRole.COMMENTER,
          label: "share.dropdown.commenter",
        },
        {
          value: "REMOVE",
          label: "share.cta.remove",
        },
      ];
    } else {
      return [];
    }
  }
  return [];
}

export function CollaboratorItem(props: CollaboratorItemProps) {
  const { t } = useTranslation();
  const { sharingApi } = useSharing();

  const [opened, setOppened] = React.useState<boolean>(false);
  const reff = useRef<any>(null);

  const roleOptions = getRoleOptions(
    props.currentRole,
    props.collaborator.role
  ).map((option) => ({
    value: option.value,
    label: t(option.label),
  }));

  const onUpdateCollaborator = (role: CollaboratorRole) => {
    if (role !== props.collaborator.role) {
      sharingApi.onUpdateCollaborator(props.collaborator.user.id, role);
    }
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 32px;
        height: 43px;

        :first-child {
          margin-top: 0px;
        }
      `}
    >
      <Avatar
        url={props.collaborator.user.avatar?.image}
        border={props.collaborator.user.avatar?.image ? false : true}
        size={43}
        name={
          props.collaborator.user.first_name +
          " " +
          props.collaborator.user.last_name
        }
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-left: 24px;
          height: 100%;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #252424;
          `}
        >
          {props.collaborator.user.first_name +
            " " +
            props.collaborator.user.last_name}
        </div>
        <div
          css={css`
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #616161;
          `}
        >
          {props.collaborator.user.email}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          margin: 0 30px 0 auto;
        `}
      >
        {props.mode === CollaborationMode.VIEW ? (
          <div
            onClick={() => {
              sharingApi.onRemoveCollaborator(props.collaborator.user.id);
            }}
            css={css`
              display: flex;
              align-items: center;
              cursor: pointer;
            `}
          >
            <ShareDelete />
          </div>
        ) : (
          !props.hideControls &&
          roleOptions.length > 0 && (
            <div
              ref={reff}
              onClick={() => setOppened(!opened)}
              css={css`
                display: flex;
                top: 18px;
                right: 14px;
                cursor: pointer;
              `}
            >
              <Select
                options={roleOptions}
                value={roleOptions.find(
                  (option) => option.value === props.collaborator.role
                )}
                onChange={(value) => {
                  if (!value) return;

                  if (value.value === "REMOVE") {
                    sharingApi.onRemoveCollaborator(props.collaborator.user.id);
                  } else {
                    onUpdateCollaborator(value.value as CollaboratorRole);
                  }
                }}
                menuPortalTarget={document.body}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
}
