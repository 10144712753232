import styled from "styled-components/macro";

export const Badge = styled.div`
  padding: 2px 12px;
  background: linear-gradient(270deg, #a47aff 13.21%, #7109f9 106.6%);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
`;
