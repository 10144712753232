import React, { useCallback } from "react";
import { css } from "styled-components/macro";
import { Form, Formik } from "formik";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";
import { useAuth } from "../../contexts/UserContext";
import { useEditor } from "../contexts/EditorContext";

import { Headers } from "../helpers/headers";
import { Column, Row, Spacing } from "../../helpers/layout";
import { ModalBody, ModalHeader } from "../../components/Modal";
import { Button } from "../../components/Button";
import { Contact } from "../types/Contact";
import { InputField } from "../../forms/fields/InputField";
import { TextareaField } from "../../forms/fields/TextareaField";

import { theme } from "../../themes/variables";
import { InteractiveType } from "./InteractiveModal";
import { InteractiveContactCardAvatarUpload } from "./InteractiveContactCardAvatarUpload";
import { sendEvent } from "../../helpers/tracking";

import { defaultSequenceDuration } from "../../constants";

export function InteractiveContactCard(props: {
  initialValues?: Contact;
  interactiveType: InteractiveType;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { activeScene } = usePlayback();
  const { api, video } = useStorage();
  const { userprofile } = useAuth();
  const { activeElementId } = useEditor();

  const {
    initialValues = {
      name: "",
      avatar: null,
      job_title: "",
      email: "",
      link: "",
      notes: "",
    },
  } = props;

  const emptyStateValues = {
    name: t("themi-builder.template.common.contact-name"),
    job_title: t("themi-builder.template.common.job-title"),
    avatar: null,
    email: "",
    link: "",
    notes: "",
  };

  const createElement = async (values: Contact) => {
    if (!activeScene || !userprofile) return;

    const emptyState = Object.values(values).every((value) => !value);
    const valuesToCreate = emptyState ? emptyStateValues : values;

    api.createElement({
      id: uuid(),
      scene_id: activeScene.id,
      order: activeScene.elements.length,
      type: "contact",
      contact: valuesToCreate,
      states: [
        {
          id: uuid(),
          left: 100,
          top: 120,
          width: 367 * 0.65,
          height: 110 * 0.65,
          scale: 1,
          rotation: 0,
          start_time: 0,
          duration: defaultSequenceDuration,
        },
      ],
      config: {},
    });

    if (video) {
      sendEvent("Created Profile Card", {
        video_id: video.uuid,
      });
    }
  };

  const updateElement = useCallback(
    async (values: Contact) => {
      if (!activeScene || !userprofile || !activeElementId) return;

      await api.updateElement(activeElementId, {
        contact: {
          ...values,
        },
      });
    },
    [activeElementId, activeScene, api, userprofile]
  );

  return (
    <div
      css={css`
        width: 100%;
        /* max-height: 720px; */
        overflow: auto;
        display: flex;
        flex-direction: column;
      `}
    >
      <ModalHeader
        closeIcon
        onClose={props.onClose}
        css={css`
          padding: 14px 21px 13px 32px;
        `}
      >
        <Headers.H2
          css={css`
            margin-left: 92px;
            font-weight: 500;
            font-size: 20px;
          `}
        >
          {t("contact-card.create.add-contact-profile")}
        </Headers.H2>
      </ModalHeader>

      <ModalBody
        css={css`
          padding: 0 122px;
          height: 100%;
          max-height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <Formik<Contact>
          initialValues={initialValues}
          onSubmit={async (values: Contact, { setSubmitting }) => {
            try {
              setSubmitting(true);
              if (props.initialValues) {
                updateElement(values);
              } else {
                createElement(values);
              }
              props.onClose();
            } catch (error: any) {
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            return (
              <Form
                noValidate
                css={css`
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  justify-content: space-between;
                `}
              >
                <Column gutter={Spacing.l}>
                  <Row gutter={Spacing.l} justify="flex-start">
                    <InputField
                      name="name"
                      label={t("contact-card.create.name")}
                      placeholder={t("contact-card.create.add-contact-name")}
                      hideTooltip
                      errorBottom
                      css={css`
                        width: 100%;
                      `}
                    />
                  </Row>

                  <InteractiveContactCardAvatarUpload
                    avatar={values.avatar}
                    onUpload={(avatar) => {
                      setFieldValue("avatar", avatar);
                    }}
                  />

                  <InputField
                    name="job_title"
                    label={t("contact-card.create.job-title")}
                    placeholder={t("contact-card.create.add-job-title")}
                    hideTooltip
                    errorBottom
                    css={css`
                      width: 100%;
                    `}
                  />

                  <InputField
                    name="email"
                    label={t("contact-card.create.email")}
                    placeholder={t("contact-card.create.add-email")}
                    hideTooltip
                    errorBottom
                    css={css`
                      width: 100%;
                    `}
                  />

                  <InputField
                    name="link"
                    label={t("contact-card.create.link")}
                    placeholder={t("contact-card.create.link-placeholder")}
                    hideTooltip
                    errorBottom
                    css={css`
                      width: 100%;
                    `}
                  />

                  <TextareaField
                    name="notes"
                    label={t("contact-card.create.notes")}
                    placeholder={t("contact-card.create.notes-placeholder")}
                    hideTooltip
                    errorBottom
                    css={css`
                      width: 100%;
                      min-height: 140px;
                    `}
                  />
                </Column>

                <Row justify="center">
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    css={css`
                      background: ${theme.colors.primary};
                      margin-top: 40px;
                      font-weight: 500;
                      margin-bottom: 16px;
                    `}
                  >
                    {t("contact-card.create.done")}
                  </Button>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </div>
  );
}
