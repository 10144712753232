import React, { useCallback, useMemo } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { usePlayback } from "../../contexts/PlaybackContext";
import { useStorage } from "../../contexts/StorageContext";
import { useAuth } from "../../../contexts/UserContext";
import { ColorPicker } from "../ColorAndVisibility";
import { Divider } from "./components/Divider";
import { Section } from "./components/Section";
import { ToolbarButton } from "./components/ToolbarButton";
import { TooltipArea } from "../../../components/Tooltip";

import { getRecordingDuration } from "../../helpers/video";
import { defaultSequenceDuration } from "../../../constants";

export function SettingsToolbar() {
  const { t } = useTranslation();
  const { activeScene, videoDuration } = usePlayback();
  const { video, api } = useStorage();
  const { userprofile } = useAuth();

  const recordingDuration = useMemo(() => {
    if (video && activeScene)
      return (
        getRecordingDuration(video?.schema.schema, activeScene?.id) ||
        defaultSequenceDuration
      );
    return defaultSequenceDuration;
  }, [video, activeScene]);

  const onDurationChange = useCallback(
    (value: number) => {
      if (activeScene) {
        api.updateScene(activeScene.id, {
          duration: value * 1000,
        });
      }
    },
    [activeScene, api]
  );

  const getDisabledState = useCallback(
    (duration: number) => {
      if (userprofile && activeScene)
        return (
          videoDuration - activeScene.duration + duration * 1000 >=
          userprofile?.restrictions.duration
        );
      return false;
    },
    [activeScene, userprofile, videoDuration]
  );

  const getDurationTooltip = useCallback(
    (text: string, duration: number) => {
      if (userprofile && activeScene) {
        if (getDisabledState(duration)) {
          return t("video-editor.limits.video-length");
        }
      }

      return t(text);
    },
    [activeScene, getDisabledState, t, userprofile]
  );

  if (!activeScene) return null;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        background: #fff;
        height: 100%;
        border: 1px solid #f2f2f2;
        box-shadow: 0px 0px 13px #e5e5e5;
        border-radius: 21.5px;
      `}
      id="themis-canvasSettings"
    >
      <Section>
        <ColorPicker
          title={t("canvas.settings.sidebar.title.background-color")}
          color={activeScene.backgroundColor}
          colorChangeHandler={(fillColor) => {
            api.updateScene(activeScene.id, {
              backgroundColor: fillColor,
            });
          }}
        />
        <Divider />
      </Section>

      <Section>
        <span
          css={css`
            margin-right: 10px;
          `}
        >
          {t("video-editor.tooltip.sequence-duration")}:
        </span>

        <ToolbarButton
          css={css`
            border-radius: 50%;
            box-shadow: 0px 2px 4px #e3e3e3;
            border: none;
            :hover {
              border: none;
            }
          `}
          onClick={() => onDurationChange(activeScene.duration / 1000 - 1)}
        >
          -
        </ToolbarButton>
        <span
          css={css`
            margin: 0 10px;
            padding: 3px 10px 3px 10px;
            font-weight: bold;
            background: #f2f2f2;
            border-radius: 2px;
            font-size: 14px;
            line-height: 120%;
            text-align: center;
          `}
        >
          {activeScene.duration / 1000} {t("video-editor.tooltip.seconds")}
        </span>
        <TooltipArea
          show={
            !!userprofile && videoDuration >= userprofile?.restrictions.duration
          }
          text={t("video-editor.limits.video-length")}
          staticPosition
          offsetX={-160}
          offsetY={40}
          maxWidth={300}
          noArrow
        >
          <ToolbarButton
            disabled={
              !!userprofile &&
              videoDuration >= userprofile?.restrictions.duration
            }
            css={css`
              border-radius: 50%;
              box-shadow: 0px 2px 4px #e3e3e3;
              border: none;
              :hover {
                border: none;
              }
            `}
            onClick={() => onDurationChange(activeScene.duration / 1000 + 1)}
          >
            +
          </ToolbarButton>
        </TooltipArea>
      </Section>

      <Section
        css={css`
          button {
            margin-right: 10px;
          }
        `}
      >
        <TooltipArea
          text={t("video-editor.header.dropdown.tooltip-3s")}
          staticPosition
          offsetX={-150}
          offsetY={30}
          maxWidth={300}
          noArrow
        >
          <ToolbarButton
            onClick={() => onDurationChange(3)}
            css={
              activeScene.duration / 1000 === 3 &&
              css`
                background: #fae7d9;
                color: #e95b2e;
                border: 1px solid #e95b2e;
              `
            }
          >
            3
          </ToolbarButton>
        </TooltipArea>

        <TooltipArea
          text={getDurationTooltip(
            "video-editor.header.dropdown.tooltip-5s",
            5
          )}
          staticPosition
          offsetX={-150}
          offsetY={30}
          maxWidth={300}
          noArrow
        >
          <ToolbarButton
            disabled={getDisabledState(5)}
            css={
              activeScene.duration / 1000 === 5 &&
              css`
                background: #fae7d9;
                color: #e95b2e;
                border: 1px solid #e95b2e;
              `
            }
            onClick={() => onDurationChange(5)}
          >
            5
          </ToolbarButton>
        </TooltipArea>

        <TooltipArea
          text={getDurationTooltip(
            "video-editor.header.dropdown.tooltip-10s",
            10
          )}
          staticPosition
          offsetX={-150}
          offsetY={40}
          maxWidth={300}
          noArrow
        >
          <ToolbarButton
            disabled={getDisabledState(10)}
            css={
              activeScene.duration / 1000 === 10 &&
              css`
                background: #fae7d9;
                color: #e95b2e;
                border: 1px solid #e95b2e;
              `
            }
            onClick={() => onDurationChange(10)}
          >
            10
          </ToolbarButton>
        </TooltipArea>

        <TooltipArea
          text={getDurationTooltip(
            "video-editor.header.dropdown.tooltip-20s",
            20
          )}
          staticPosition
          offsetX={-170}
          offsetY={40}
          maxWidth={300}
          noArrow
        >
          <ToolbarButton
            disabled={getDisabledState(20)}
            css={
              activeScene.duration / 1000 === 20 &&
              css`
                background: #fae7d9;
                color: #e95b2e;
                border: 1px solid #e95b2e;
              `
            }
            onClick={() => onDurationChange(20)}
          >
            20
          </ToolbarButton>
        </TooltipArea>

        <TooltipArea
          text={t("video-editor.header.dropdown.tooltip-automatic")}
          staticPosition
          offsetX={-200}
          offsetY={50}
          maxWidth={300}
          noArrow
        >
          <ToolbarButton
            onClick={() => onDurationChange(recordingDuration / 1000)}
            css={
              activeScene.duration === recordingDuration &&
              ![3, 5, 10, 20].includes(activeScene.duration / 1000) &&
              css`
                background: #fae7d9;
                color: #e95b2e;
                border: 1px solid #e95b2e;
              `
            }
          >
            {t("video-editor.tooltip.automatic")}
          </ToolbarButton>
        </TooltipArea>
      </Section>
    </div>
  );
}
