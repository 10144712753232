import * as React from "react";
import { css } from "styled-components/macro";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  FilterFn,
} from "@tanstack/react-table";

export function Table<T>(props: {
  columns: ColumnDef<T, any>[];
  data: T[];
  filterFn?: Record<string, FilterFn<any>>;
  className?: string;
}) {
  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    filterFns: props.filterFn,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div
      css={css`
        margin-top: 20px;
      `}
    >
      <table
        css={css`
          border-top: 1px solid #eae8e8;
          padding: 20px 20px 0 20px;
          width: 100%;
          font-weight: 500;
          font-size: 14px;
        `}
        className={props.className}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              css={css`
                th {
                  padding-bottom: 30px;
                }
              `}
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  css={css`
                    color: #505050;
                    text-align: left;
                  `}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              css={css`
                td {
                  padding-bottom: 40px;
                }
                :last-of-type td {
                  padding-bottom: 0;
                }
              `}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  css={css`
                    color: #252424;
                  `}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
}

/**
 * Example
 * 
 * component: <Table<TablePlaceholder> data={data} columns={columns} />
 * 
 * usage 
 * 
 * import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
} from "@tanstack/react-table";
import { Table } from "../components/Table";
 * 
 * type TablePlaceholder = {
  name: string;
  email: string;
  access: string;
  team: {
    title: string;
  }[];
  videoCount: number;
};
 * 
 *   const data: TablePlaceholder[] = [
    {
      name: "Aino Kivinen",
      email: "mia.wong@adidas.com",
      access: "Admin",
      team: [{ title: "Hiring team" }, { title: "Payroll team " }],
      videoCount: 15,
    },
    {
      name: "Aino Kivinen",
      email: "mia.wong@adidas.com",
      access: "Admin",
      team: [{ title: "Hiring team" }, { title: "Payroll team " }],
      videoCount: 15,
    },
    {
      name: "Aino Kivinen",
      email: "mia.wong@adidas.com",
      access: "Admin",
      team: [{ title: "Hiring team" }, { title: "Payroll team " }],
      videoCount: 15,
    },
  ];

  const columnHelper = createColumnHelper<TablePlaceholder>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("team", {
      cell: (info) => {
        const val = info.getValue();
        return val.map((el) => {
          return <span>{el.title}</span>;
        });
      },
    }),
    columnHelper.accessor("videoCount", {
      id: "lastName",
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span>Themi videos</span>,
    }),
    columnHelper.display({
      id: "edit",
      cell: (info) => {
        console.log(info.row);
        return <button>edit</button>;
      },
    }),
  ];

 */
