import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { FieldInputProps } from "formik";
import { Hint } from "./Hint";
import { InputLabel } from "./InputLabel";

export function TextareaComponent(props: {
  id?: string;
  name?: string;
  label?: string;
  description?: string;
  hint?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  field?: FieldInputProps<any>;
  maxLength?: number;
  error?: boolean;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}) {
  return (
    <label>
      <InputLabel label={props.label} description={props.description} />

      <textarea
        id={props.id}
        name={props.name}
        className={props.className}
        placeholder={props.placeholder || ""}
        readOnly={props.readonly}
        disabled={props.disabled}
        maxLength={props.maxLength}
        css={css`
          display: block;
          width: 100%;
          min-height: 200px;
          color: #616161;
          background: #f8f8f8;
          background-clip: padding-box;
          border: 1px solid #8e8e8e;
          border-radius: 8px;
          outline: none;
          resize: none;
          padding: 7px 30px 7px 24px;
          line-height: 1.5;
          font-size: 14px;
          ::-webkit-scrollbar {
            width: 0 !important;
          }
          overflow: -moz-scrollbars-none;
          -ms-overflow-style: none;
        `}
        {...props.field}
        onBlur={(e) => {
          if (props.field) {
            props.field.onBlur(e);
          }
        }}
        onChange={(e) => {
          if (props.field) {
            props.field.onChange(e);
          }
          if (props.onChange) props.onChange(e);
        }}
      />

      {props.hint && <Hint>{props.hint}</Hint>}
    </label>
  );
}
