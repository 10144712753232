import { VideoElement, VideoScene } from "../../../VideoEditor/types/Video";
import { v4 as uuid } from "uuid";
import { Template } from "../types";
import { defaultSequenceDuration } from "../../../constants";

export function generateHandoverIntroductionTemplate(
  data: Template,
  order: number,
  t: any
) {
  const { userprofile } = data.context;
  const name = userprofile
    ? `${userprofile.first_name} ${userprofile.last_name}`
    : "(User's name)";
  const sceneId = uuid();
  const numberOfLines = Math.ceil(data.title.length / 30);

  const scene: VideoScene = {
    id: sceneId,
    backgroundColor: "#fff",
    duration: defaultSequenceDuration,
    order,
    name: t("themi-builder.template.introduction.scene-name"),
    enableCollaboration: true,
    start_time: 0,
    elements: [],
  };

  const firstElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    text: `<p style="font-family: Arial; font-size: 4.57cqw; text-align: center;"><strong>${data.title}</strong></p>`,
    type: "text",
    order: 1,
    states: [
      {
        id: uuid(),
        top: 51.56165148665495,
        left: 83.9003164556962,
        scale: 1,
        width: 539.7988551594433,
        height: 59 * numberOfLines,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const secondElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    text: `<p style="font-family: Arial; font-size: 1.71cqw; color: rgb(97, 97, 97); text-align: center;">${t(
      "themi-builder.template.record-prompt.description"
    )}</p>`,
    type: "text",
    order: 2,
    states: [
      {
        id: uuid(),
        top: firstElement.states[0].top + firstElement.states[0].height + 10,
        left: 152.13924050632912,
        scale: 1,
        width: 403.3210070581771,
        height: 23.316772662870346,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const thirdElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    type: "record-prompt",
    order: 3,
    states: [
      {
        id: uuid(),
        top: secondElement.states[0].top + secondElement.states[0].height + 30,
        left: 266.80300632911394,
        scale: 1,
        width: 176.19066455696202,
        height: 46.45805231408224,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
    instruction: "",
  };

  const fourthElement: VideoElement = {
    id: uuid(),
    scene_id: sceneId,
    text: `<p style="font-family: Arial; font-size: 1.43cqw; text-align: center;"><strong>${name}</strong></p>`,
    type: "text",
    order: 4,
    states: [
      {
        id: uuid(),
        top: 345.1711456685127,
        left: 272.95569620253167,
        scale: 1,
        width: 162.0144041903099,
        height: 16.78006329113924,
        duration: defaultSequenceDuration,
        rotation: 0,
        start_time: 0,
      },
    ],
  };

  const scenes: VideoScene[] = [scene];

  const elements: VideoElement[] = [
    firstElement,
    secondElement,
    thirdElement,
    fourthElement,
  ];

  return { scenes, elements };
}
