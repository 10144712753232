import React, { useRef, useEffect, useCallback, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

import { usePlayback } from "../contexts/PlaybackContext";
import { useStorage } from "../contexts/StorageContext";
import { useAuth } from "../../contexts/UserContext";
import { useRecording } from "../contexts/RecordingContext";
import { useCollaboration } from "../contexts/CollaborationContext";
import { useEditor } from "../contexts/EditorContext";
import { useComments } from "../contexts/CommentsContext";
import { useFileUpload } from "../contexts/FileUploadContext";

import { FrameRenderer } from "../renderers/FrameRenderer";

import { VideoElement } from "../types/Video";
import { ImageType } from "../types/Image";
import { Drawing } from "../types/Drawing";
import { CollaboratorRole } from "../../VideoEditor/types/Collaborator";

import { DrawArea } from "./DrawArea";

import { AvatarCursor } from "../../components/AvatarCursor";
import { Loader } from "./Loader";
import { RecordingIndicator, RecordingWidth } from "./RecordingIndicator";
import { CommentDialog } from "./Comments/CommentDialog";
import { uploadFile } from "../../actions/file";
import { uploadImage } from "../actions/uploadImage";

import CursorCommentIcon from "../assets/icons/CursorComment.png";

import {
  calculateProportionalSize,
  calculateRelativeSize,
  calculateReverseProportionalSize,
} from "../helpers/renderer";
import {
  calculateThemiAudioElements,
  calculateThemiVideoElements,
  calculateThemiDocumentElements,
} from "../helpers/video";
import { canvasHeight, canvasWidth } from "./ScenePreview";
import { calculateCanvasSize } from "../helpers/video";
import { getColorByText } from "../../helpers/color";
import { canEdit } from "../helpers/collaborator";
import { ElementEditorPortalRoot } from "../portals/ElementEditorPortal";
import { localAPI } from "../API";
import { Gridlines } from "./Gridlines";
import { isArray, isObject, isString } from "lodash";
import { calculateFrameSize } from "./ElementControls/GroupElementsFrame";
import { CommentsRenderer } from "../renderers/CommentRenderer";
import { sendEvent } from "../../helpers/tracking";

import { defaultSequenceDuration } from "../../constants";

function getDefaultSize(type: VideoElement["type"]) {
  if (type === "poll") {
    return { width: 255, height: 230 };
  }

  if (type === "open-question") {
    return { width: 225, height: 125 };
  }

  if (type === "reaction") {
    return { width: 50, height: 50 };
  }

  if (type === "user-tagging") {
    return { width: 50, height: 50 };
  }

  if (["arrow-1", "arrow-2", "arrow-3", "line"].includes(type)) {
    return { width: 100, height: 30 };
  }

  return {
    width: 100,
    height: 100,
  };
}

function getVerifiedPosition(
  position: {
    left: number;
    top: number;
    width: number;
    height: number;
  },
  canvasSize: {
    width: number;
    height: number;
  }
) {
  const newPosition = { ...position };

  if (newPosition.left < 0) {
    newPosition.left = 0;
  }

  if (newPosition.top < 0) {
    newPosition.top = 0;
  }

  if (newPosition.left + newPosition.width > canvasSize.width) {
    newPosition.left = canvasSize.width - newPosition.width;
  }

  if (newPosition.top + newPosition.height > canvasSize.height) {
    newPosition.top = canvasSize.height - newPosition.height;
  }

  return newPosition;
}

function isUrl(str: string) {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
}

const isVideoElement = (item: VideoElement | any): item is VideoElement => {
  return "id" in item || "type" in item;
};

export function SceneEditor() {
  const { t } = useTranslation();
  const [commentDialog, setCommentDialog] = React.useState(false);
  const [commentDialogPosition, setCommentDialogPosition] = React.useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const { video, api, undo, redo } = useStorage();
  const { userprofile } = useAuth();
  const { currentFrame, activeScene, currentTime } = usePlayback();
  const { setPosition, userPositions } = useCollaboration();
  const {
    setActiveElementId,
    activeElementId,
    setActiveElementsId,
    activeElementsId,
    drawConfig,
    drawEnabled,
    setDrawEnabled,
    setDrawConfig,
    wrapperRef,
    setActiveNav,
    settingsOpen,
    canvasSize,
    setCanvasSize,
    setEditingElement,
  } = useEditor();
  const { uploadFile: uploadFileFunc, generateId } = useFileUpload();

  const [loading, setLoading] = useState(false);

  const { commentsOpen, closeComments, commentMode } = useComments();

  useEffect(() => {
    const handle = async (e: Event) => {
      const clipboardEvent: ClipboardEvent = e as ClipboardEvent;

      if (!activeScene || commentsOpen) return;

      const isElementInFocus =
        document.activeElement?.attributes.getNamedItem("contenteditable")
          ?.value === "true";
      const isInputInFocus =
        document.activeElement?.tagName === "INPUT" ||
        document.activeElement?.tagName === "TEXTAREA";

      if (isElementInFocus || isInputInFocus) {
        if (
          clipboardEvent.clipboardData &&
          clipboardEvent.clipboardData.files &&
          clipboardEvent.clipboardData.files.length
        )
          return;

        const copiedText = await navigator.clipboard.readText();
        let jsonContent, textContent;

        try {
          jsonContent = JSON.parse(copiedText);
        } catch (e) {
          textContent = copiedText;
        }

        if (jsonContent) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }

        if (textContent) return;
      }

      if (
        clipboardEvent.clipboardData &&
        clipboardEvent.clipboardData.files &&
        clipboardEvent.clipboardData.files.length
      ) {
        const files = clipboardEvent.clipboardData.files;
        const file = files && files.length ? files[0] : null;

        if (file) {
          const id = generateId();
          setLoading(true);
          const { file: image } = await uploadFileFunc(id, uploadImage, {
            file: file,
            title: file.name,
            type: ImageType.USER_UPLOADS,
          });
          setLoading(false);

          if (activeScene && image) {
            api.createElement({
              id: uuid(),
              type: "image",
              image: await image,
              scene_id: activeScene.id,
              config: {},
              order: activeScene.elements.length,
              states: [
                {
                  id: uuid(),
                  left: 100,
                  top: 100,
                  width: 100,
                  height: image.height / (image.width / 100),
                  scale: 1,
                  rotation: 0,
                  start_time: 0,
                  duration: defaultSequenceDuration,
                },
              ],
            });
          }
        }
      } else {
        const copiedText = await navigator.clipboard.readText();
        let jsonContent, textContent;

        try {
          jsonContent = JSON.parse(copiedText);
        } catch (e) {
          textContent = copiedText;
        }

        if (isArray(jsonContent)) {
          const elements = jsonContent;
          const frameSize = calculateFrameSize(elements);
          const normalisedPosition = getVerifiedPosition(
            {
              left: frameSize.left + 50,
              top: frameSize.top + 50,
              width: frameSize.width,
              height: frameSize.height,
            },
            {
              width: canvasWidth,
              height: canvasHeight,
            }
          );

          const xShift = normalisedPosition.left - frameSize.left;
          const yShift = normalisedPosition.top - frameSize.top;

          const clonedElements = elements.reduce(
            (validElements: VideoElement[], element) => {
              if (isObject(element) && isVideoElement(element)) {
                const newElement = {
                  ...element,
                  states: [
                    {
                      ...element.states[0],
                      left: element.states[0].left + xShift,
                      top: element.states[0].top + yShift,
                    },
                  ],
                };

                return [...validElements, newElement];
              }

              return validElements;
            },
            []
          );

          await api.createElements(clonedElements);

          setActiveElementsId([...clonedElements.map((element) => element.id)]);
        } else if (isObject(jsonContent) && isVideoElement(jsonContent)) {
          const element = jsonContent;
          const restrictions = userprofile?.restrictions;
          if (element.type === "audio") {
            const elementsCount = calculateThemiAudioElements(
              video?.schema.schema.elements
            );
            if (restrictions?.audio && elementsCount >= restrictions.audio)
              return;
          }
          if (element.type === "video") {
            const elementsCount = calculateThemiVideoElements(
              video?.schema.schema.elements
            );
            if (restrictions?.video && elementsCount >= restrictions.video)
              return;
          }
          if (element.type === "document") {
            const elementsCount = calculateThemiDocumentElements(
              video?.schema.schema.elements
            );
            if (
              restrictions?.uploaded_document &&
              elementsCount >= restrictions.uploaded_document
            )
              return;
          }
          const newElementId = uuid();
          const position = getVerifiedPosition(
            {
              left: element.states[0].left + 50,
              top: element.states[0].top + 50,
              width: element.states[0].width,
              height: element.states[0].height,
            },
            {
              width: canvasWidth,
              height: canvasHeight,
            }
          );

          await api.createElement({
            ...element,
            id: newElementId,
            scene_id: activeScene.id,
            states: [
              {
                ...element.states[0],
                left: position.left,
                top: position.top,
              },
            ],
          });

          setActiveElementId(newElementId);
        } else if (isString(textContent)) {
          const text = isUrl(textContent)
            ? `<a href="${textContent}" rel="noopener noreferrer" target="_blank">${textContent}</a>`
            : textContent;
          const newElementId = uuid();

          await api.createElement({
            type: "text",
            id: newElementId,
            scene_id: activeScene.id,
            order: activeScene.elements.length,
            text: `<p style="font-family: Arial;font-size: ${calculateRelativeSize(
              16
            )};">${text}</p>`,
            states: [
              {
                id: uuid(),
                left: 100,
                top: 100,
                width: 200,
                height: 100,
                scale: 1,
                rotation: 0,
                start_time: 0,
                duration: defaultSequenceDuration,
              },
            ],
          });

          setActiveElementId(newElementId);
        }
      }
    };
    window.addEventListener("paste", handle);

    return () => {
      window.removeEventListener("paste", handle);
    };
  }, [
    activeScene,
    api,
    generateId,
    uploadFileFunc,
    setActiveElementId,
    setActiveElementsId,
    commentsOpen,
    userprofile?.restrictions,
    video?.schema.schema,
  ]);

  const { recording } = useRecording({
    onRecordingStop: async ({ type, file, duration }) => {
      if (!activeScene) return;

      const seconds = Math.ceil(duration / 1000);

      const prompt = activeScene.elements.find(
        (element) => element.type === "record-prompt"
      );
      const previewId = uuid();

      if (prompt) {
        await api.deleteElement(prompt.id);
      }

      /* Video Preview before file upload */
      if (type === "video") {
        await api.createElement(
          {
            id: previewId,
            type: "video-preview",
            link: URL.createObjectURL(file),
            order: activeScene.elements.length,
            config: {},
            scene_id: activeScene.id,
            states: [
              {
                id: uuid(),
                left: prompt
                  ? prompt.states[0].left +
                    (prompt.states[0].width - RecordingWidth) / 2
                  : canvasWidth - RecordingWidth - 20,
                top: prompt ? prompt.states[0].top : 17,
                width: RecordingWidth,
                height: RecordingWidth,
                scale: 1,
                rotation: 0,
                start_time: 0,
                duration,
              },
            ],
          },
          { fake: true }
        );
      }
      const id = generateId();
      const { file: fileResponse } = await uploadFileFunc(id, uploadFile, {
        file: file,
        title: file.name,
        duration: seconds,
      });

      /* Remove video preview */
      if (type === "video") {
        await api.deleteElement(previewId, { fake: true });
        const events = await localAPI.findFakeEvents(video?.uuid!);
        for (const event of events) {
          localAPI.deleteEvent(event.id!);
        }
      }

      if (type === "video" || type === "audio") {
        if (fileResponse) {
          await api.createElement({
            id: uuid(),
            type: type,
            file: fileResponse,
            order: activeScene.elements.length,
            title: type === "video" ? "Video Title" : "Audio Title",
            config: {},
            scene_id: activeScene.id,
            states: [
              {
                id: uuid(),
                left: prompt
                  ? prompt.states[0].left +
                    (prompt.states[0].width - RecordingWidth) / 2
                  : canvasWidth - RecordingWidth - 20,
                top: prompt ? prompt.states[0].top : 17,
                width: RecordingWidth,
                height: RecordingWidth,
                scale: 1,
                rotation: 0,
                start_time: 0,
                duration,
              },
            ],
          });

          if (video) {
            sendEvent(type === "video" ? "Recorded video" : "Recorded audio", {
              themiId: video.uuid,
            });
          }
        }
      }
    },
  });

  const ref = React.useRef<HTMLDivElement>(null);

  const uploadDropImage = useCallback(
    async (item: File) => {
      if (activeScene) {
        const generatedId = generateId();

        setLoading(true);
        const { file: image } = await uploadFileFunc(generatedId, uploadImage, {
          file: item,
          title: item.name,
          type: ImageType.USER_UPLOADS,
        });
        setLoading(false);

        if (image) {
          api.createElement({
            id: uuid(),
            type: "image",
            image: image,
            scene_id: activeScene.id,
            config: {},
            order: activeScene.elements.length,
            states: [
              {
                id: uuid(),
                left: 100,
                top: 100,
                width: 100,
                height: image.height / (image.width / 100),
                scale: 1,
                rotation: 0,
                start_time: 0,
                duration: defaultSequenceDuration,
              },
            ],
          });
        }
      }
    },
    [activeScene, api, uploadFileFunc, generateId]
  );

  const [{ isOver }, drop] = useDrop<
    VideoElement,
    void,
    {
      isOver: boolean;
      canDrop: boolean;
    }
  >(
    () => ({
      accept: ["element", NativeTypes.FILE],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      drop: (
        item:
          | VideoElement
          | {
              files: File[];
            },
        monitor
      ) => {
        if (!isVideoElement(item)) {
          if (item.files.length) {
            const file = item.files[0];
            uploadDropImage(file);
          }
        }
        const dragItemOffset = monitor.getSourceClientOffset();

        if (
          dragItemOffset &&
          wrapperRef.current &&
          video &&
          activeScene &&
          userprofile &&
          isVideoElement(item)
        ) {
          const wrapperRect = wrapperRef.current.getBoundingClientRect();

          if (item.id) {
            api.updateElement(item.id, {
              states: [
                {
                  ...item.states[0],
                  left: calculateReverseProportionalSize(
                    dragItemOffset.x - wrapperRect.x,
                    canvasSize.width
                  ),
                  top: calculateReverseProportionalSize(
                    dragItemOffset.y - wrapperRect.y,
                    canvasSize.width
                  ),
                },
              ],
            });
          } else {
            const defaultSize = getDefaultSize(item.type);
            const elementId = uuid();
            const position = getVerifiedPosition(
              {
                left: calculateReverseProportionalSize(
                  dragItemOffset.x - wrapperRect.x,
                  canvasSize.width
                ),
                top: calculateReverseProportionalSize(
                  dragItemOffset.y - wrapperRect.y,
                  canvasSize.width
                ),
                width: defaultSize.width,
                height: item.states
                  ? item.states[0].height /
                    (item.states[0].width / defaultSize.width)
                  : defaultSize.height,
              },
              {
                width: canvasWidth,
                height: canvasHeight,
              }
            );
            api.createElement({
              ...item,
              id: elementId,
              scene_id: activeScene.id,
              states: [
                {
                  id: uuid(),
                  ...position,
                  scale: 1,
                  rotation: 0,
                  start_time: 0,
                  duration: defaultSequenceDuration,
                },
              ],
            });

            if (item.type === "user-tagging") {
              setEditingElement({
                id: elementId,
              });
            }
          }
        }
      },
    }),
    [api, video, activeScene, userprofile, canvasSize]
  );

  React.useEffect(() => {
    function updateSize() {
      if (ref.current) {
        const { width, height } = calculateCanvasSize({
          width: ref.current.offsetWidth - 1,
          height: ref.current.offsetHeight,
        });

        setCanvasSize({
          width,
          height,
        });
      }
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [setCanvasSize]);

  React.useEffect(() => {
    const activeElement = currentFrame.find(
      (element) => element.id === activeElementId
    );

    const activeElements = currentFrame.filter((element) =>
      activeElementsId.includes(element.id)
    );

    const onKeydown = async (event: KeyboardEvent) => {
      if (!activeScene || commentsOpen) return;

      if (event.key === "Escape") {
        setActiveElementId(null);
        setActiveElementsId([]);
      }

      const isElementInFocus =
        document.activeElement?.attributes.getNamedItem("contenteditable")
          ?.value === "true";
      const isInputInFocus =
        document.activeElement?.tagName === "INPUT" ||
        document.activeElement?.tagName === "TEXTAREA";

      if (activeElement && !isElementInFocus && !isInputInFocus) {
        const shift = event.shiftKey ? 30 : 5;

        if (event.key === "ArrowDown") {
          await api.updateElement(activeElement.id, {
            states: [
              {
                ...activeElement.states[0],
                top: Math.min(
                  activeElement.states[0].top + shift,
                  canvasHeight - activeElement.states[0].height
                ),
              },
            ],
          });
        }

        if (event.key === "ArrowUp") {
          await api.updateElement(activeElement.id, {
            states: [
              {
                ...activeElement.states[0],
                top: Math.max(0, activeElement.states[0].top - shift),
              },
            ],
          });
        }

        if (event.key === "ArrowLeft") {
          await api.updateElement(activeElement.id, {
            states: [
              {
                ...activeElement.states[0],
                left: Math.max(0, activeElement.states[0].left - shift),
              },
            ],
          });
        }

        if (event.key === "ArrowRight") {
          await api.updateElement(activeElement.id, {
            states: [
              {
                ...activeElement.states[0],
                left: Math.min(
                  activeElement.states[0].left + shift,
                  canvasWidth - activeElement.states[0].width
                ),
              },
            ],
          });
        }
      }

      if (!isElementInFocus && !isInputInFocus) {
        if (event.key === "Delete" || event.key === "Backspace") {
          if (activeElement) {
            await api.deleteElement(activeElement.id);
            event.preventDefault();
          } else if (activeElements.length > 0) {
            await api.deleteElements(activeElements);
            event.preventDefault();
          }
        }
        if (
          (event.ctrlKey && event.key === "z") ||
          (event.metaKey && event.key === "z")
        ) {
          event.preventDefault();
          undo();
        }

        if (
          (event.ctrlKey && event.key === "y") ||
          (event.metaKey && event.key === "y")
        ) {
          event.preventDefault();
          redo();
        }

        if (
          (event.ctrlKey && event.key === "c") ||
          (event.metaKey && event.key === "c")
        ) {
          event.preventDefault();

          if (activeElement) {
            navigator.clipboard.writeText(JSON.stringify(activeElement));
          } else if (activeElements.length > 0) {
            navigator.clipboard.writeText(JSON.stringify(activeElements));
          }
        }

        if (
          (event.ctrlKey && event.key === "x") ||
          (event.metaKey && event.key === "x")
        ) {
          event.preventDefault();

          if (activeElement) {
            navigator.clipboard.writeText(JSON.stringify(activeElement));
            await api.deleteElement(activeElement.id);
          } else if (activeElements.length > 0) {
            navigator.clipboard.writeText(JSON.stringify(activeElements));
            await api.deleteElements(activeElements);
          }
        }
      }
    };

    window.addEventListener("keydown", onKeydown);

    return () => {
      window.removeEventListener("keydown", onKeydown);
    };
  }, [
    activeElementId,
    activeElementsId,
    setActiveElementId,
    setActiveElementsId,
    setDrawEnabled,
    setDrawConfig,
    api,
    currentFrame,
    undo,
    redo,
    setActiveNav,
    activeScene,
    settingsOpen,
    commentsOpen,
  ]);

  const onDrawEnd = React.useCallback(
    async (
      drawing: Drawing,
      data: {
        width: number;
        height: number;
        left: number;
        top: number;
      }
    ) => {
      if (!drawing || !activeScene || !userprofile) return;

      api.createElement({
        id: uuid(),
        type: "drawing",
        order: activeScene.elements.length,
        drawing: drawing,
        config: drawConfig,
        scene_id: activeScene.id,
        states: [
          {
            id: uuid(),
            width: calculateReverseProportionalSize(
              data.width,
              canvasSize.width
            ),
            height: calculateReverseProportionalSize(
              data.height,
              canvasSize.width
            ),
            left: calculateReverseProportionalSize(data.left, canvasSize.width),
            top: calculateReverseProportionalSize(data.top, canvasSize.width),
            scale: 1,
            rotation: 0,
            start_time: 0,
            duration: defaultSequenceDuration,
          },
        ],
      });

      setDrawEnabled(false);
    },
    [setDrawEnabled, activeScene, userprofile, api, drawConfig, canvasSize]
  );

  const currentUserRoleRef = useRef<CollaboratorRole | null>(null);

  useEffect(() => {
    if (video?.current_user_role)
      currentUserRoleRef.current = video?.current_user_role;
  }, [video?.current_user_role]);

  const handleDoubleClick = React.useCallback(
    async (event: React.MouseEvent) => {
      if (
        !activeScene ||
        !userprofile ||
        activeElementId ||
        !canEdit(currentUserRoleRef.current)
      )
        return;

      const { left, top } = event.currentTarget.getBoundingClientRect();
      const elementId = uuid();

      await api.createElement({
        id: elementId,
        type: "text",
        text: `<p style="font-family: Arial;font-size: ${calculateRelativeSize(
          8
        )};">${t("text.sidebar.typeSomething")}</p>`,
        order: activeScene.elements.length,
        scene_id: activeScene.id,
        states: [
          {
            id: uuid(),
            width: 200,
            height: 30,
            left: calculateReverseProportionalSize(
              event.clientX - left,
              canvasSize.width
            ),
            top: calculateReverseProportionalSize(
              event.clientY - top,
              canvasSize.width
            ),
            scale: 1,
            rotation: 0,
            start_time: 0,
            duration: defaultSequenceDuration,
          },
        ],
      });

      setActiveElementId(elementId);
    },
    [
      activeScene,
      userprofile,
      api,
      setActiveElementId,
      canvasSize,
      activeElementId,
      t,
    ]
  );

  const onSetCommentDialog = (event: React.MouseEvent) => {
    const { left, top } = event.currentTarget.getBoundingClientRect();

    const leftPosition = calculateReverseProportionalSize(
      event.clientX - left,
      canvasSize.width
    );

    const topPosition = calculateReverseProportionalSize(
      event.clientY - top,
      canvasSize.width
    );

    setCommentDialogPosition({ top: topPosition, left: leftPosition });
    setCommentDialog(true);
  };

  function openCommentDialog() {
    if (!activeScene || !userprofile) return;

    return (
      <div
        style={{
          position: "absolute",
          top: calculateProportionalSize(
            commentDialogPosition.top,
            canvasSize.width
          ),
          left: calculateProportionalSize(
            commentDialogPosition.left,
            canvasSize.width
          ),
          zIndex: 9999,
        }}
      >
        <CommentDialog
          top={commentDialogPosition.top}
          left={commentDialogPosition.left}
          scene_id={activeScene?.id}
          onClose={() => {
            setCommentDialog(false);
            closeComments();
          }}
        />
      </div>
    );
  }

  return (
    <div
      ref={ref}
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
      `}
    >
      <div
        ref={wrapperRef}
        id="themis-scene-editor"
        className="themis-canvas-wrapper"
        css={css`
          position: absolute;
          width: ${canvasSize.width}px;
          height: ${canvasSize.height}px;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          container-type: inline-size;

          :before {
            content: "";
            position: absolute;
            pointer-events: none;
            z-index: -1;
            height: 100%;
            width: 100%;
            border-width: 1px;
            border-style: ${isOver || drawEnabled ? "dashed" : "solid"};
            border-color: ${isOver || drawEnabled ? "#000" : "#EAE8E8"};
            bottom: -1px;
            right: -1px;
            background: #cdcdcd;
          }

          ${drawEnabled &&
          css`
            cursor: crosshair;
          `}
          ${commentsOpen &&
          commentMode &&
          !commentDialog &&
          css`
            cursor: url(${CursorCommentIcon}), auto;
          `}
        `}
        onDoubleClick={handleDoubleClick}
        onClick={(event) =>
          !commentDialog &&
          commentMode &&
          commentsOpen &&
          onSetCommentDialog(event)
        }
      >
        {activeScene && (
          <>
            {loading && <Loader absoluteCenter />}
            <div
              ref={drop}
              id="themis-sceneEditor"
              css={css`
                background: ${activeScene.backgroundColor};
                width: 100%;
                height: 100%;
              `}
              onClick={() => {
                setActiveElementId(null);
                setActiveElementsId([]);
              }}
              onMouseMove={(e) => {
                const rect = wrapperRef.current?.getBoundingClientRect();

                if (!rect) return;

                // Mouse position
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                setPosition(
                  currentTime,
                  calculateReverseProportionalSize(x, canvasSize.width),
                  calculateReverseProportionalSize(y, canvasSize.width)
                );
              }}
            >
              <FrameRenderer elements={currentFrame} />
              {userPositions.map((userPosition) => {
                if (
                  !activeScene ||
                  (userprofile && userPosition.user.id === userprofile.id)
                )
                  return null;

                const isVisible =
                  userPosition.time >= activeScene?.start_time &&
                  userPosition.time <
                    activeScene?.start_time + activeScene?.duration;

                if (!isVisible) return null;

                return (
                  <AvatarCursor
                    size={25}
                    key={userPosition.user.id}
                    color={getColorByText(userPosition.user.name)}
                    avatarURL={userPosition.user.avatar?.image}
                    name={userPosition.user.name}
                    css={css`
                      position: absolute;
                      transform: translate3d(
                        ${calculateProportionalSize(
                          userPosition.x,
                          canvasSize.width
                        )}px,
                        ${calculateProportionalSize(
                          userPosition.y,
                          canvasSize.width
                        )}px,
                        0
                      );
                      transition: all 1s ease;
                    `}
                  />
                );
              })}
              <ElementEditorPortalRoot />
            </div>
            {drawEnabled && (
              <DrawArea config={drawConfig} onDrawEnd={onDrawEnd} />
            )}
            {(recording === "active" || recording === "paused") && (
              <RecordingIndicator />
            )}
          </>
        )}
        <Gridlines />
      </div>
      <CommentsRenderer />
      {commentDialog && commentMode && openCommentDialog()}
    </div>
  );
}
