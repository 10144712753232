import { VideoElementAudio, VideoElementState } from "../../types/Video";
import { css } from "styled-components/macro";
import { useEffect, useRef } from "react";
import { usePlayback } from "../../contexts/PlaybackContext";

export function ElementVideoRenderer(props: {
  element: VideoElementAudio;
  state: VideoElementState;
}) {
  const ref = useRef<HTMLVideoElement>(null);
  const { activeScene, currentTime, playing } = usePlayback();

  useEffect(() => {
    if (ref.current && activeScene) {
      // Change video time to match the current time
      if (ref.current.paused) {
        let videoTime = currentTime - activeScene.start_time;

        videoTime = Math.min(videoTime, activeScene.duration);
        videoTime = Math.max(videoTime, 0);
        videoTime = videoTime / 1000;

        ref.current.currentTime = videoTime;

        setTimeout(() => {
          if (ref.current) {
            ref.current.muted = false;
          }
        }, 0);
      }
    }
  }, [currentTime, activeScene]);

  useEffect(() => {
    if (ref.current) {
      if (playing) {
        ref.current.muted = false;
        ref.current.play();
      } else {
        ref.current.pause();
      }
    }
  }, [playing]);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          user-select: none;
          overflow: hidden;
          border-radius: 50%;
          user-select: none;
          pointer-events: none;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      `}
    >
      <video
        autoPlay={false}
        disablePictureInPicture={true}
        disableRemotePlayback={true}
        loop={false}
        playsInline={true}
        ref={ref}
        controls={false}
      >
        <source src={props.element.file.file} type="video/mp4" />
        Your browser does not support the video element.
      </video>
    </div>
  );
}
