import React, { useEffect, useState } from "react";
import { css } from "styled-components/macro";

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function MovingElement(props: {
  className?: string;
  positionBoundaries: {
    x: number[];
    y: number[];
  };
  timeout: number;
  initialPositions?: {
    x: number;
    y: number;
  };
  children: React.ReactNode;
}) {
  const [position, setPosition] = useState({
    x: props.initialPositions?.x || 0,
    y: props.initialPositions?.y || 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const x =
        randomIntFromInterval(
          props.positionBoundaries.x[0],
          props.positionBoundaries.x[1]
        ) + (props.initialPositions?.x || 0);
      const y =
        randomIntFromInterval(
          props.positionBoundaries.y[0],
          props.positionBoundaries.y[1]
        ) + (props.initialPositions?.y || 0);

      setPosition({
        x,
        y,
      });
    }, props.timeout);

    return () => clearInterval(interval);
  }, [
    props.positionBoundaries,
    props.timeout,
    props.initialPositions?.x,
    props.initialPositions?.y,
  ]);

  return (
    <div
      className={props.className}
      css={css`
        position: absolute;
        transition: all 1s ease-out;
        z-index: 1;
      `}
      style={{
        transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
      }}
    >
      {props.children}
    </div>
  );
}
