import React from "react";
import { css } from "styled-components/macro";

type ModalFooterProps = {
  children?: React.ReactNode | React.ReactNode[] | string;
  className?: string;
};

export function ModalRightside(props: ModalFooterProps) {
  return (
    <div
      css={css`
        min-width: 0;
        flex: 1 0 10%;
        padding: 45px 17px 17px;
        background: #fff;
      `}
      className={props.className}
    >
      <div
        css={css`
          width: 100%;
          max-width: 455px;
          margin: 0 auto;
        `}
      >
        {props.children}
      </div>
    </div>
  );
}
