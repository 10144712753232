import React from "react";
import { css } from "styled-components/macro";
import { useSharing } from "../../VideoEditor/contexts/SharingContext";
import {
  CollaborationMode,
  CollaboratorRole,
} from "../../VideoEditor/types/Collaborator";
import { UserPermissionRequest } from "./UserPermissionRequest";

type PermissionRequestProps = {
  mode: CollaborationMode;
};

export function PermissionRequest(props: PermissionRequestProps) {
  const { permissionRequests, sharingApi } = useSharing();

  const viewPermissionRequests = permissionRequests.filter(
    (request) => request.role === CollaboratorRole.VIEWER
  );

  const editPermissionRequests = permissionRequests.filter(
    (request) => request.role !== CollaboratorRole.VIEWER
  );

  const requestsToShow =
    props.mode === CollaborationMode.EDIT
      ? editPermissionRequests
      : viewPermissionRequests;

  if (requestsToShow.length === 0) {
    return null;
  }

  return (
    <div
      css={css`
        border-bottom: 1px solid #eae8e8;
        width: 95%;
      `}
    >
      {requestsToShow.map((request, key) => {
        return (
          <>
            <div
              key={key}
              css={css`
                display: flex;
                align-items: center;
                margin-bottom: 23px;
              `}
            >
              <UserPermissionRequest
                request={request}
                onApprove={() => sharingApi.onApprovePermissionRequest(request)}
                onDeny={() => sharingApi.onDenyPermissionRequest(request)}
              />
            </div>
          </>
        );
      })}
    </div>
  );
}
