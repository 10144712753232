import { css } from "styled-components/macro";
import { useHistory, useLocation } from "react-router";
import { SEO } from "../components/SEO";
import { PageLayout } from "../components/PageLayout";
import { FeedbackWidget } from "../components/FeedbackWidget";
import {
  Navigation,
  NavigationType,
} from "../businessComponents/Workspace/Navigation";
import { FeaturesForm } from "../forms/workspace/FeaturesForm";
import { useTranslation } from "react-i18next";

export function WorkspaceFeaturePage() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const data = {
    workspace_acccess: true,
    content_restrictions: true,
    themi_creator: true,
  };

  const menuItems: NavigationType[] = [
    {
      title: t("workspace-settings.overview"),
      onClick: () => {
        history.push("/workspace-settings");
      },
      isActive: location.pathname === "/workspace-settings",
    },
    {
      title: t("workspace-settings.feature-access"),
      onClick: () => {
        history.push("/workspace-settings/feature-access");
      },
      isActive: location.pathname === "/workspace-settings/feature-access",
    },
  ];

  return (
    <PageLayout>
      <SEO>
        <title>{t("workspace-settings.settings")}</title>
      </SEO>

      <div
        css={css`
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
        `}
      >
        {t("workspace-settings.settings")}
      </div>

      <div
        css={css`
          margin-top: 60px;
          width: 100%;
          position: relative;
          display: flex;
        `}
      >
        {menuItems.map((item, key) => {
          return <Navigation {...item} key={key} />;
        })}
      </div>

      <FeaturesForm data={data} refetch={() => {}} />

      <FeedbackWidget />
    </PageLayout>
  );
}
