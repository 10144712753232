import { ModalBody, ModalFooter, ModalHeader } from "../components/Modal";
import { css } from "styled-components/macro";
import { Headers } from "../helpers/layout";
import { Button } from "../components/Button";
import { Dots } from "./Dots";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sendEvent } from "../helpers/tracking";

export const SCREENS_TOUR_SEEN = "SCREENS_TOUR_SEEN";

export function Carousel(props: {
  children?: React.ReactNode;
  content: { image: JSX.Element; title: string; description: string }[];
  onClose: () => void;
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();
  const { t } = useTranslation();

  async function onClickNext() {
    if (activeIndex < props.content.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
    if (activeIndex === props.content.length - 1) {
      sendEvent("Welcome Tour Completed");

      history.push({
        pathname: `/themi-builder`,
        search: "?product_tour=true",
      });
    }
  }

  useEffect(() => {
    if (activeIndex === 3) {
      localStorage.setItem(SCREENS_TOUR_SEEN, "true");
    }
  }, [activeIndex]);

  return (
    <>
      {props.content &&
        props.content.map((item, index) => {
          if (index !== activeIndex) {
            return null;
          }
          return (
            <div key={index}>
              <ModalHeader
                closeIcon
                noPadding
                onClose={props.onClose}
                css={css`
                  justify-content: flex-end;
                  align-items: flex-start;
                  height: 320px;
                  width: 100%;

                  button {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                  }
                `}
              >
                <div
                  css={css`
                    padding-top: 44px;
                    text-align: center;
                  `}
                >
                  {item.image}
                </div>
              </ModalHeader>

              <ModalBody
                css={css`
                  justify-content: center;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                `}
              >
                <Dots
                  imageSlider={props.content}
                  currentIndex={activeIndex}
                  onDotClick={(activeIndex) => setActiveIndex(activeIndex)}
                  css={css`
                    width: 100%;
                    height: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    z-index: 2;
                  `}
                />
                <Headers.H3
                  css={css`
                    font-weight: 500;
                    font-size: 20px;
                    margin-top: 30px;
                  `}
                >
                  {item.title}
                </Headers.H3>
                <Headers.H4
                  css={css`
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #7f7f7f;
                    align-self: center;
                    padding: ${index === 1 ? "0 75px" : "0 58px"};
                    text-align: center;
                  `}
                >
                  {item.description}
                </Headers.H4>
              </ModalBody>
              <ModalFooter
                css={css`
                  justify-content: center;
                  margin-top: 30px;
                `}
              >
                <div>
                  <Button
                    onClick={() => {
                      if (activeIndex < props.content.length - 1) {
                        setActiveIndex(activeIndex - 1);
                      }
                      if (activeIndex === props.content.length - 1) {
                        props.onClose();
                      }
                    }}
                    css={css`
                      border: 1px solid #858585;
                      background-color: transparent;
                      color: #858585;
                      font-weight: 700;
                      font-size: 16px;
                      ${activeIndex === 0 &&
                      css`
                        display: none;
                      `};
                    `}
                    hoverStyles={"none"}
                  >
                    {activeIndex === 3
                      ? t("dashboard.product-tour.forth-screen.stay-button")
                      : t(
                          "dashboard.product-tour.second-screen.previous-button"
                        )}
                  </Button>
                  <Button
                    onClick={() => onClickNext()}
                    css={css`
                      color: #ffffff;
                      background-color: #e95b2e;
                      font-weight: 700;
                      font-size: 16px;
                    `}
                    hoverStyles={`background:#FE521C`}
                  >
                    {activeIndex === 0
                      ? t("dashboard.product-tour.first-screen.start-button")
                      : activeIndex === 3
                      ? t("dashboard.product-tour.forth-screen.go-button")
                      : t("dashboard.product-tour.second-screen.next-button")}
                  </Button>
                </div>
              </ModalFooter>
            </div>
          );
        })}
    </>
  );
}
