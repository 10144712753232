import { Form, Formik } from "formik";
import { css } from "styled-components/macro";
import * as Yup from "yup";
import { CheckboxField } from "../fields/CheckboxField";
import { Button } from "../../VideoEditor/components/Button";
import { theme } from "../../themes/variables";

type FormValues = {
  workspace_acccess: boolean;
  content_restrictions: boolean;
  themi_creator: boolean;
};

export function FeaturesForm(props: { data: FormValues; refetch: () => void }) {
  const validationSchema = Yup.object().shape({
    workspace_acccess: Yup.boolean(),
    content_restrictions: Yup.boolean(),
    themi_creator: Yup.boolean(),
  });

  const initialValues: FormValues = {
    workspace_acccess: props.data.workspace_acccess,
    content_restrictions: props.data.content_restrictions,
    themi_creator: props.data.themi_creator,
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {({ isSubmitting, isValid, values }) => {
        return (
          <Form noValidate>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-top: 70px;
                  margin-bottom: 60px;
                  margin-left: 50px;
                `}
              >
                <div
                  css={css`
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    min-width: 300px;
                  `}
                >
                  {"Workspace access"}
                </div>
                <CheckboxField
                  disabled
                  name="workspace_acccess"
                  hideTooltip
                  errorBottom
                  css={css`
                    .checkmark {
                      top: 14px;
                    }
                  `}
                >
                  <div
                    css={css`
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      color: #505050;
                      margin-left: 50px;
                    `}
                  >
                    {
                      " Only those with a direct email invitation are able to sign up to the company Themis workspace."
                    }
                  </div>
                </CheckboxField>
              </div>
              <div
                css={css`
                  border-bottom: 1px solid #eae8e8;
                `}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-top: 50px;
                  margin-bottom: 60px;
                  margin-left: 50px;
                `}
              >
                <div
                  css={css`
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    min-width: 300px;
                  `}
                >
                  {"Content and access restrictions"}
                </div>
                <CheckboxField
                  disabled
                  name="content_restrictions"
                  hideTooltip
                  errorBottom
                  css={css`
                    .checkmark {
                      top: 14px;
                    }
                  `}
                >
                  <div
                    css={css`
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      color: #505050;
                      margin-left: 50px;
                    `}
                  >
                    {
                      "Allow company workspace members to share Themi videos with viewers outside the company workspace."
                    }
                  </div>
                </CheckboxField>
              </div>
              <div
                css={css`
                  border-bottom: 1px solid #eae8e8;
                `}
              />
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-top: 50px;
                  margin-left: 50px;
                `}
              >
                <div
                  css={css`
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    min-width: 300px;
                  `}
                >
                  {"Themi Creator "}
                </div>
                <CheckboxField
                  disabled
                  name="themi_creator"
                  hideTooltip
                  errorBottom
                >
                  <div
                    css={css`
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                      color: #505050;
                      margin-left: 50px;
                    `}
                  >
                    {
                      "Allow members to use video recording in their Themi videos."
                    }
                  </div>
                </CheckboxField>
              </div>

              <Button
                isSubmitting={isSubmitting}
                type="submit"
                disabled
                css={css`
                  margin: 80px auto 0 auto;
                  color: ${theme.colors.white};
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 28px;
                `}
              >
                {"Update changes"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
